import React, { useState } from 'react'
// import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { registerUser } from '../../Redux/Actions/user_actions'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../UI/Form'
import Input from '../UI/Form/input'
import SubmitButton from '../UI/Buttons/submitButton'
import PrimaryButton from '../UI/Buttons/primaryButton'
 
import { makeStyles } from '@material-ui/core/styles'
import {Paper,Button, Grid, Typography} from '@material-ui/core';

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

const schema = yup.object().shape({
    email: yup.string().email("Please instert a valid email address.").required("Email address is reuired."),
    name: yup.string().required("First name is required."),
    lastname: yup.string().required("Last name is reuired."),
    password: yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required.')
        .matches(/.*[!@#$%^&*]/, "Your password must contain at least one special character")
        .matches(/.*[0-9]/, "Your password must contain at least 1 numeric character"),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
})

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        width: '350px'
    },
    grid:{
        background: `url(${LoginBackground}) no-repeat`, 
        height: '800px'
    }
}))

function Register(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {register, handleSubmit, errors} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });

    const [formError, setFormError] = useState();

    const registrationSuccess = useSelector(state => state.user.registrationSuccess);

    // updateForm = (element) => {
    //     const newFormData = update(element, this.state.formData, 'register');
    //     this.setState({
    //         formError: false,
    //         formData: newFormData
    //     })
    // }

    const onSubmit = (data) => {

        dispatch(registerUser(data)).then(response => {
            props.history.push('/register/email_verification/' + data.email);
        }).catch(err => {
            const errData = err.response.data;

            if(errData.msg){
                setFormError(errData.msg);
            }
        })
    }

    return (
            <Grid container direction="row" justify="center" alignItems="center" className={classes.grid}>
                <Grid item>
                    <Paper className={classes.root} elevation={5}>
                        <h2 className="h2login">Sign Up</h2>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                ref={register}
                                id="name"
                                label="First Name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                            />
                            <Input
                                ref={register}
                                id="lastname"
                                label="Last Name"
                                name="lastname"
                                autoComplete="lastname"
                                error={!!errors.lastname}
                                helperText={errors?.lastname?.message}
                            />
                            <Input
                                ref={register}
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                error={!!errors.email}
                                helperText={errors?.email?.message}
                            />
                            <Input
                                ref={register}
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                            />
                            <Input
                                ref={register}
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                autoComplete="current-password"
                                error={!!errors.confirmPassword}
                                helperText={errors?.confirmPassword?.message}
                            />

                            {formError ?
                                <Typography color='error'>{formError}</Typography>
                            : null}


                            <SubmitButton fullWidth>Register</SubmitButton>
                            <PrimaryButton fullWidth href="/">Back to Login</PrimaryButton>
                        </Form>
                    </Paper>
                </Grid>
            </Grid>
        )  
}

export default withRouter(Register);