import axios from 'axios';
import { GET_ORGANIZATIONAL_VALUES,
         GET_BYID_ORGANIZATIONAL_VALUE,
         CREATE_ORGANIZATIONAL_VALUE,
         UPDATE_ORGANIZATIONAL_VALUE,
         REMOVE_ORGANIZATIONAL_VALUE,
         GET_VISION_MISSION,
         GET_ORG_DATA,
         UPDATE_VISION_MISSION
          } from './types';
import { ORGANIZATIONAL_VALUES_SERVER, VISION_MISSON_SERVER, ORGANIZATION_DATA_SERVER } from '../../Components/Utils/Misc';

export function getAllOrganizationalValues(entity){
    const request = axios.get(`${ORGANIZATIONAL_VALUES_SERVER}/${entity ? entity : ''}`)
        .then(responce => responce.data);

        return {
            type: GET_ORGANIZATIONAL_VALUES,
            payload: request
        }
}

export function getByIdAllOrganizationalValues(id){
    const request = axios.get(`${ORGANIZATIONAL_VALUES_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_BYID_ORGANIZATIONAL_VALUE,
            payload: request
        }
}

export function createOrganizationalValue(organizational_value){
    const request = axios.post(`${ORGANIZATIONAL_VALUES_SERVER}`, organizational_value)
        .then(responce => responce.data);

        return {
            type: CREATE_ORGANIZATIONAL_VALUE,
            payload: request
        }
}

export function updateOrganizationalValue(id,organizational_value){
    const request = axios.put(`${ORGANIZATIONAL_VALUES_SERVER}/${id}`, organizational_value)
        .then(responce => responce.data);

        return {
            type: UPDATE_ORGANIZATIONAL_VALUE,
            payload: request
        }
}

export function removeOrganizationalValue(id){
    const request = axios.delete(`${ORGANIZATIONAL_VALUES_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_ORGANIZATIONAL_VALUE,
            payload: request
        }
}

export function getVisionMission(entity){
    console.log(entity)
    const request = axios.get(`${VISION_MISSON_SERVER}/${entity ? entity : ''}`)
        .then(responce => responce.data);

        return {
            type: GET_VISION_MISSION,
            payload: request
        }
}

export function updateVisionMission(data){
    const request = axios.post(`${VISION_MISSON_SERVER}`, data)
        .then(responce => responce.data);

        return {
            type: UPDATE_VISION_MISSION,
            payload: request
        }
}

export function getOrganizationalData(){
    const request = axios.get(`${ORGANIZATION_DATA_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_ORG_DATA,
            payload: request
        }
}