import React, { Component } from 'react';
import FSStepper from '../../UI/FSStepper'
import HeaderText from '../../UI/headerText';
import BackgroundItem from '../../UI/backgroundItem';
import GeneralBackground from '../../../Resources/images/backgrounds/background_pages.png';

import KeyObjectivesDrawer from '../../Partials/KeyObjectivesDrawer';

import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { GetAllIssuesByBusinessUnit, removeIssue } from '../../../Redux/Actions/issues_actions';
import { getBusinessUnit } from '../../../Redux/Actions/business_units_actions';

import IssueCreator from '../../Partials/IssueCreator';


class Issues extends Component { 
    componentDidMount() {
        const business_unit = this.props.match.params._business_unit_id ? this.props.match.params._business_unit_id : null;

        this.setState({
            business_unit
        })
        
        console.log(business_unit)
        if(business_unit != null){
            this.props.dispatch(GetAllIssuesByBusinessUnit(business_unit)).then(responce => {
            });
            this.props.dispatch(getBusinessUnit(business_unit)).then(responce => {
            });
        }
    }
    

    repopulateLists = () => {
        this.props.dispatch(GetAllIssuesByBusinessUnit(this.state.business_unit)).then(response => {});
    }

    handleIssueDelete = (issue_id) => {
        this.props.dispatch(removeIssue(issue_id)).then(response => {
            if(this.props.issue_remove_success){
                this.repopulateLists();
            }
            return "There was a problem deleting the issue.";
        });
    };

    StepContent = () => {
        return (
            <div style={{
                position: 'relative',
                padding: '20px 80px 20px 80px',
            }}>
                <KeyObjectivesDrawer />
                <Grid container spacing={16} direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} >
                        <div>
                            { this.props.current_business_unit ? 
                                <HeaderText name='Functional Unit Strategy' subHeader={ this.props.current_business_unit.name } size={50} />
                            : null }
                                
                                
                        </div>
                    </Grid>
                </Grid>

                {
                    this.props.issues ?
                        <IssueCreator 
                            onIssueDelete={(issue_id)=>this.handleIssueDelete(issue_id)} 
                            rePopulateIssues={() => this.repopulateLists()} 
                            issues={this.props.issues}
                            is_business_unit={true}
                            business_unit={this.state.business_unit}
                            area_type='functional'
                            creatorType='Functional Strategy'
                            strategyType='functional'
                            assignedUsersMax={20}/>
                    :null
                }
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={GeneralBackground} />
                {this.props.current_business_unit ? 
                <FSStepper Step={2} getStepContent={() => this.StepContent()}  
                    selectedBusinessUnit={{ 
                        selectedBusinessUnit: this.props.current_business_unit
                    }}/>
                    :null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    issues: state.strategy_issues.issues,
    current_business_unit: state.business_units.business_unit 
})


export default connect(mapStateToProps)(Issues);