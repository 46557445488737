import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getGroupStrategicObjectives } from '../../Redux/Actions/group_strategic_objectives_actions';

import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Paper, Typography } from '@material-ui/core';

import { Redirect } from 'react-router-dom';
import PrintalbeScreen from '../Print/printalbeScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    overflowX: 'auto',
    position: 'relative',
  },
  table: {
    minWidth: 700,
  },
  action_button: {
    margin: theme.spacing.unit,
  },
}));

function Objectives(props) {
  const classes = useStyles();
  const { dispatch } = props;

  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    dispatch(getGroupStrategicObjectives());
  }, []);

  const onSubmit = () => {
    props.history.push('/group_strategic_objectives');
  };

  return (
    <Paper className={classes.root}>
      <h2>{user && user.selectedOrganization.isGroup ? 'Group Key Objectives' : 'Key Objectives'}</h2>
      <Button
        variant="contained"
        size="small"
        onClick={onSubmit}
        style={{
          backgroundColor: '#005773',
          fontWeight: [500],
          fontSize: '14px',
          color: 'white',
          marginLeft: '20px',
        }}
      >
        Edit Key Objetives
      </Button>
      <PrintalbeScreen>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Issues (Status)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.objectives ? (
              props.objectives.map((item, i) => (
                <TableRow key={item._id}>
                  <TableCell>{item.objective}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    <ul>
                      {item.issues.map((issue) => (
                        <li>
                          <Typography key={issue._id} variant="caption" display="block">
                            {issue.description} (<span style={{ textTransform: 'capitalize' }}>{issue.issueStrategyType}</span>, {issue.status.name}
                            {issue.flowTo ? ', ' + issue.flowTo : null})
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="3">No Objectives</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </PrintalbeScreen>
    </Paper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  objectives: state.group_strategic_objectives.group_strategic_objectives,
});

export default connect(mapStateToProps)(Objectives);
