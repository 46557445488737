import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getAllGuides } from '../../Redux/Actions/guide_actions';
import FlagsDrawer from '../Partials/FlagsDrawer';

import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ButtonGroup } from '@material-ui/core';
import StepperButton from './Buttons/stepperButton';
//import { subscribeToGuide } from "../../HOC/subscribeToGuide";

import {Link} from 'react-router-dom';
import Permission from '../../HOC/Permission';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import BackToParentButton from './Buttons/backToParentButton';

const styles = theme => ({
  root: {
    width: '100%',
  },
  gridroot: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    paddingRight: '110px',
    paddingLeft: '110px',
    paddingBottom: '5px',
    paddingTop: '10px',
    backgroundColor: 'transparent',
    //width: '1000px',
    margin: '0 auto'
  },
  label: {
    marginTop: '4px !important',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  active_label: {

  },
  line: {
    top: '9px',
    left: 'calc(-50% )',
    right: 'calc(50%)',
    zIndex: 0
  },
  lineHorizontal: {
    border: '3px solid #162336'
  },
  loader:{
    margin: '0 auto',
    display: 'block'
  }
});

const StepIcon = ({ label, active = true }) => (
  <div style={{ position: 'relative' }}>
    <div className="dots">
      {active ?
        <div className="active_dot"></div>
        : ''}
      {label}</div>
  </div>
);

function getSteps() {
  return this.state.steps;
}

class HorizontalStepper extends React.Component {
  state = {
    activeStep: this.props.Step,
    steps: [],
    completed: new Set(),
    skipped: new Set(),
  };


  componentDidMount() {
    this.props.dispatch(getAllGuides()).then(responce => {
      this.setState({
        steps: this.props.guides.guide
      })
    });
  }


  totalSteps = () => {
    return getSteps().length;
  };

  isStepOptional = step => {
    return null;//step === 1;
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = this.state.steps;
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }

    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = step => () => {

  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed);
    completed.add(this.state.activeStep);
    this.setState({
      completed,
    });

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
      this.handleNext();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
    });
  };


  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  nextText = () => (
    <span>{ this.props.btnNextText ? this.props.btnNextText : 'Next' }</span>
  )

  renderSteps = (classes, activeStep) => {
    const steps = this.props.guides.guide;

    return (
      steps && steps.length !== 0 ?
        <div >

          <Grid container className={classes.root} spacing={16}>
            <Grid xs={12} item style={{ textAlign: 'center' }}>
            
              <Stepper alternativeLabel nonLinear
                activeStep={activeStep}
                className={classes.stepper}
                connector={
                  <StepConnector classes={{
                    line: classes.lineHorizontal,
                    root: classes.line
                  }} />
                }
              >
                {steps.map((item, index) => (
                  <Step key={index}>
                    <StepButton
                      href={item.path}
                      icon={<StepIcon label='' active={activeStep === index} />}
                      completed={this.isStepComplete(index)}
                    >
                      <StepLabel classes={{
                        label: classes.label,
                        active: classes.active_label
                      }}>
                        {item.stepName ? item.stepName : item.name}
                      </StepLabel>
                    </StepButton>
                  </Step>
                )
                )}
              </Stepper>
            </Grid>
          </Grid>
          <Grid container className={classes.root} spacing={16}>
            <Grid xs={4} item style={{ textAlign: 'center' }}>
                <StepperButton disabled={activeStep === 0} href={activeStep === 0 ? '' : steps[activeStep - 1].path}>
                    Back
                </StepperButton>
                { this.props.user && this.props.user.selectedOrganization.parent ? 
                  <Permission action="corporate.view" user={this.props.user} hideObject={true}>
                    <BackToParentButton retrunUrl={'/group/strategy/issues'} >
                        Back Group Strategy
                    </BackToParentButton>
                  </Permission>  : null }
            </Grid>
            <Grid xs={4} item style={{ textAlign: 'center' }}>

            </Grid>
            <Grid xs={4} item style={{ textAlign: 'center' }}>
              { !this.props.showSave ?
                <StepperButton href={activeStep === steps.length - 1 ? '/functional_strategy/introduction' : steps[activeStep + 1].path} >
                    {activeStep === steps.length - 1 ?
                        'Functional Strategy'
                    : this.nextText() }
                </StepperButton>
                :
                <StepperButton onClick={() => this.props.saveForm()}>
                  Save
                </StepperButton> 
                }        
            </Grid>
          </Grid>


        </div>
        : 
        <CircularProgress className={classes.loader}/>
    )
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <FlagsDrawer/>
        <div>
          {this.props.getStepContent(activeStep)}
        </div>
        {this.renderSteps(classes, activeStep)}
      </div>
    );
  }
}

HorizontalStepper.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    guides: state.guide,
    user: state.user.userData
  }
}

export default connect(mapStateToProps)(withStyles(styles)(HorizontalStepper));