import { combineReducers } from 'redux';
import user from './user_reducer';
import subscription from './subscription_reducer';
import guide from './guide_reducer';
import business_units from './business_units_reducer';
import organizational_values from './organizational_values_reducer';
import strategy_issues from './issues_reducer';
import areas from './area_reducer';
import group_strategic_objectives from './group_strategic_objectives_reducer';
import organization from './organization_reducer';
import role from './role_reducer';
import status from './status_reducer';
import report from './report_reducer';
import flags from './flag_reducer';
import notes from './note_reducer';
import plans from './plan_reducer';
import payment from './payment.reducer';
import comments from './comments.reducer';
import billingProcess from './billingProcess.reducer';
import userPlan from './userPlan.reducer';

const rootReducer = combineReducers({
  user,
  subscription,
  role,
  organization,
  guide,
  business_units,
  organizational_values,
  strategy_issues,
  areas,
  group_strategic_objectives,
  status,
  report,
  flags,
  notes,
  plans,
  billingProcess,
  payment,
  comments,
  userPlan,
});

export default rootReducer;
