import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FlagsDrawer from '../Partials/FlagsDrawer';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import {Link} from 'react-router-dom';
import Permission from '../../HOC/Permission';
import StepperButton from './Buttons/stepperButton';

const styles = theme => ({
  root: {
    width: '100%',
  },
  gridroot: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  stepper: {
    backgroundColor: 'transparent',
    paddingBottom: '5px',
    paddingTop: '10px',
    //width: '1000px',
    margin: '0 auto'
  },
  label: {
    marginTop: '4px !important',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  active_label: {

  },
  line: {
    top: '9px',
    left: 'calc(-50% )',
    right: 'calc(50%)',
    zIndex: 0
  },
  lineHorizontal: {
    border: '3px solid #162336'
  }
});

const StepIcon = ({ label, active = true }) => (
  <div style={{ position: 'relative' }}>
    <div className="dots">
      {active ?
        <div className="active_dot"></div>
        : ''}
      {label}</div>
  </div>
);

function getSteps() {
  return this.state.steps;
}

class FSStepper extends React.Component {
  state = {
    activeStep: this.props.Step,
    steps: [
      {
        name: "Functional Unit Strategy Introduction",
        path: "/functional_strategy/introduction/"
      },
      {
        name: "Select Functional Unit",
        path: "/functional_strategy/select-business-unit/"
      },
      {
        name: "Functional Unit Strategy",
        path: "/functional_strategy/issues/"
      }

    ],
    completed: new Set(),
    skipped: new Set(),
    business_unit: ""
  };

  componentWillReceiveProps() {
    if (this.props.selectedBusinessUnit && 
      this.props.selectedBusinessUnit.selectedBusinessUnit) {
      this.updateBusinessUnit(this.props.selectedBusinessUnit.selectedBusinessUnit);
    }
  }

  updateBusinessUnit = (business_unit) => {
    const new_steps = [...this.state.steps];
    if (new_steps[2]) {
      new_steps[2].path = "/functional_strategy/issues/" + business_unit
    }


    this.setState({
      steps: new_steps,
      business_unit
    })
  }

  totalSteps = () => {
    return getSteps().length;
  };

  isStepOptional = step => {
    return null;//step === 1;
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = this.state.steps;
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }

    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = step => () => {

  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed);
    completed.add(this.state.activeStep);
    this.setState({
      completed,
    });

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
      this.handleNext();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
    });
  };


  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  renderSteps = (classes, activeStep) => {
    const steps = this.state.steps;
    return (
      steps && steps.length !== 0 ?
        <div>
          <Grid container className={classes.root} spacing={16}>
            <Grid xs={12} item style={{ textAlign: 'center' }}>
              <Stepper alternativeLabel nonLinear
                activeStep={activeStep}
                className={classes.stepper}
                connector={
                  <StepConnector classes={{
                    line: classes.lineHorizontal,
                    root: classes.line
                  }} />
                }
              >
                {steps.map((item, index) => (
                  this.props.isbusinessUnitSelect && item.name === 'Functional Unit Strategy' ? 
                    this.props.selectedBusinessUnit && this.props.selectedBusinessUnit.selectedBusinessUnit ?
                    <Step key={index}>
                      <StepButton
                        href={item.path}
                        icon={<StepIcon label='' active={activeStep === index} />}
                        completed={this.isStepComplete(index)}
                      >
                        <StepLabel classes={{
                          label: classes.label,
                          active: classes.active_label
                        }}>
                          {item.stepName ? item.stepName : item.name}
                        </StepLabel>
                      </StepButton>
                  </Step> 
                    : null
                  : 
                  item.name === 'Functional Unit Strategy' && activeStep !== steps.length - 1 ? null :
                  <Step key={index}>
                    <StepButton
                      href={item.path}
                      icon={<StepIcon label='' active={activeStep === index} />}
                      completed={this.isStepComplete(index)}
                    >
                      <StepLabel classes={{
                        label: classes.label,
                        active: classes.active_label
                      }}>
                        {item.stepName ? item.stepName : item.name}
                      </StepLabel>
                    </StepButton>
                  </Step> 
                )
                )}
              </Stepper>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={10}>
            <Grid xs={4} item style={{ textAlign: 'center' }}>
              { activeStep > 0 ?
              <StepperButton  disabled={activeStep === 0} onClick={() => this.props.history.goBack()} href={activeStep === 0 ? '' : steps[activeStep - 1].path}>
                  Back
              </StepperButton>
                :
                  <Permission action="corporate.view" user={this.props.user} hideObject={true}>
                      <Link to="/corporate_strategy/issues" >
                          <StepperButton>
                            Back to Business Strategy
                          </StepperButton>
                      </Link>
                  </Permission>
                }
            </Grid>
            <Grid xs={4} item style={{ textAlign: 'center' }}>
              
            </Grid>
            <Grid xs={4} item style={{ textAlign: 'center' }}>
              {this.props.isbusinessUnitSelect ? 
                 this.props.selectedBusinessUnit && this.props.selectedBusinessUnit.selectedBusinessUnit ?
                 <StepperButton href={activeStep === steps.length - 1 ? '/dashboard' : steps[activeStep + 1].path}>
                   {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                 </StepperButton>
                  :
                  null 
                :
                 <StepperButton href={activeStep === steps.length - 1 ? '/dashboard' : steps[activeStep + 1].path}>
                   {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                 </StepperButton>
                }
            </Grid>
          </Grid>
        </div>
        : null
    )
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
       <FlagsDrawer/>
        <div>
          {this.props.getStepContent(activeStep)}
        </div>
        {this.renderSteps(classes, activeStep)}
      </div>
    );
  }
}

FSStepper.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    business_units: state.business_units.business_units,
    user: state.user.userData
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(FSStepper)));