import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addChildOrganization, updateChildOrganization } from '../../Redux/Actions/organization_actions';
import { addToOrganization, removeFromOrganization, getUsersByOrganization } from '../../Redux/Actions/user_actions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormField from '../Utils/Forms/FormField';
import { clearFields, update, generateData, isFormValid } from '../Utils/Forms/FormActions';
import { Paper, CircularProgress, Typography, Button, Divider, makeStyles, TableHead, TableRow, TableCell, TableBody, Table } from '@material-ui/core';
import UserSelect from '../UI/CustomFields/UserSelect';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../UI/Form';
import Input from '../UI/Form/input';

import PrimaryButton from '../UI/Buttons/primaryButton';
import SubmitButton from '../UI/Buttons/submitButton';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1),
  },
}));

const rowSchema = yup.object().shape({
  userId: yup.string().required('Please select a user.'),
  organizationId: yup.string(),
  role: yup.string().required('Please select a role'),
  enable: yup.boolean().default(true),
});

function Row(props) {
  const { user, entity, parentEntity } = props;
  const [userStatus, setUserStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const dispatch = useDispatch();

  const add = () => {
    setLoading(true);
    setStatus('Adding User...');
    dispatch(addToOrganization(user.id, entity._id)).then((responce) => {
      setStatus('User Added');
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    });
  };

  const remove = () => {
    setLoading(true);
    setStatus('Removing User...');
    dispatch(removeFromOrganization(user.id, entity._id)).then((responce) => {
      setStatus('User Removed');
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    });
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{user.fullname}</TableCell>
        <TableCell component="th" scope="row">
          {user.email}
        </TableCell>
        <TableCell component="th" scope="row">
          {loading && <Typography>{status}</Typography>}
        </TableCell>
        <TableCell align="right">
          <PrimaryButton onClick={() => add()}>Add</PrimaryButton>
          <PrimaryButton onClick={() => remove()}>Remove</PrimaryButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const UserDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const users = useSelector((state) => state.user.users);

  useEffect(() => {
    if (props.parentEntity) dispatch(getUsersByOrganization(props.parentEntity._id));
  }, [props.parentEntity]);

  return (
    <Dialog open={props.open} onClose={() => props.onClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle id="form-dialog-title">Add Business Unit</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>User Email</TableCell>
              <TableCell></TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{users && users.map((user, index) => <Row user={user} entity={props.entity} key={index} />)}</TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={() => props.onClose()}>Cancel</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
