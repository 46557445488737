import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addOrganization } from '../../Redux/Actions/organization_actions';
import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import { Paper, Typography, Divider, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../UI/Form'
import Input from '../UI/Form/input'
import Checkbox from '../UI/Form/checkbox'
import SubmitButton from '../UI/Buttons/submitButton'
import HookSelect from '../UI/Form/hookSelect';

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email('Please enter a valid email address').required('Please enter a email address'),
    contact_number: yup.string().required('Please enter a contact number.'),
    parent: yup.string(),
    isGroup: yup.bool()
})

const useStyles = makeStyles( theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        width: '350px'
    },
    grid:{
        background: `url(${LoginBackground}) no-repeat`, 
        height: '800px'
    },
    button: {
        backgroundColor: '#005773',
        color: 'white',
        fontWeight: [500],
        marginBottom: '10px',
        width: '170px',
        marginTop: '10px'
    },
    divider: {
        marginTop: '10px',
        marginBottom: '20px'
    }
}));

function OrganizationCreate(props) {
    const classes = useStyles();
    const {register, watch, handleSubmit,control, errors} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });

    const watchIsGroup = watch("isGroup", false);

    const user = useSelector(state => state.user.userData);
    const organizations = useSelector(state => state.user.userData.organizations);

    const dispatch = useDispatch();


    const onSubmit = (data) => {
        dispatch(addOrganization(data)).then(response => {
            if(response.payload.success){
                props.history.push('/welcome');
            }else{
               console.log('error: ' + response.payload.err)
                setFormError(response.payload.err);
            }
        }).catch(err => {
            const errData = err.response.data;

            if(errData.msg){
                setFormError(errData.msg);
            }
        })
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.grid}>
                <Grid item>
            <Paper className={classes.root} elevation={5}>
                <h2 className="h2login">New Entity</h2>
                <Typography >Add new orgnaization/company.</Typography>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        ref={register}
                        id="name"
                        label="Entity Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                    />
                    <Input
                        ref={register}
                        id="email"
                        label="Contact Email Address"
                        name="email"
                        autoComplete="email"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                    />
                    <Input
                        ref={register}
                        id="contact_number"
                        label="Contact Number"
                        name="contact_number"
                        autoComplete="contact_number"
                        error={!!errors.contact_number}
                        helperText={errors?.contact_number?.message}
                    />

                    <Divider className={classes.divider}/>

                    <Checkbox control={control} name="isGroup" label="Contains multiple other entities?" />
                    { !!errors.isGroup ?
                        <Typography color="error">{errors?.isGroup?.message}</Typography> : null }
                    

                    <Typography>Optionally select a parent: </Typography>
                    { !watchIsGroup && 
                        <HookSelect control={control} name="parent" label="Parent">
                            { organizations.filter(item => item.organization_id.isGroup && item.organization_id.owner == user.user_id).length > 0 ?  
                                organizations.filter(item => item.organization_id.isGroup).map(entity => (
                                    <MenuItem key={entity.organization_id._id} value={entity.organization_id._id}>{entity.organization_id.name}</MenuItem>
                                ))
                            : null}
                        </HookSelect> 
                    }

                    <Divider className={classes.divider}/>
                    <SubmitButton>Create</SubmitButton>
  
                </Form>
            </Paper>
            </Grid>
            </Grid>
    )
    
}

export default OrganizationCreate;
