import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 1, 1),
    backgroundColor: '#005773',
    color: 'white',
    fontWeight: [500],
    '&:hover': {
      color: '#005773',
    },
  },
}));

const SubmitButton = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Button type="submit" variant="contained" className={classes.root} {...props}>
      {children}
    </Button>
  );
};

export default SubmitButton;
