import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationsByParent } from '../../Redux/Actions/organization_actions';
import { makeStyles } from '@material-ui/core/styles';
import { reverseArray } from '../UI/misc';
import Permission from '../../HOC/Permission';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faUsersCog } from '@fortawesome/free-solid-svg-icons';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ScrollArea from 'react-scrollbar';

import CreateDialog from './createDialog';
import UserDialog from './userDialog';

const useStyles = makeStyles((theme) => ({
  rootPaper2: {
    ...theme.mixins.gutters(),
    padding: '0px !important',
    paddingBottom: theme.spacing.unit * 2,
    height: '320px',
    position: 'relative',
    overflowWrap: 'break-word',
  },
}));

function BusinessUnits(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const selectedOrganization = useSelector((state) => state.user.userData.selectedOrganization);
  const organizations = useSelector((state) => state.organization.organizations);

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState({});
  const [open, setOpen] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [dialogType, setDialogType] = useState('Add');

  useEffect(() => {
    dispatch(getOrganizationsByParent(selectedOrganization._id));
  }, []);

  const handleAdd = () => {
    setOpen(true);
  };

  const handleEdit = (organization) => {
    setSelectedBusinessUnit(organization);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedBusinessUnit({});
    setOpen(false);
  };

  const handleOpenUsers = (entity) => {
    setSelectedBusinessUnit(entity);
    setOpenUsers(true);
  };
  const handleCloseUsers = () => {
    setSelectedBusinessUnit({});
    setOpenUsers(false);
  };

  const handleDelete = (organization) => {
    //TODO
  };

  return (
    <div>
      <Paper className={classes.rootPaper2} elevation={5}>
        <ScrollArea
          speed={0.8}
          horizontal={false}
          smoothScrolling={true}
          style={{
            height: '320px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations ? (
                reverseArray(organizations).map((item, index) => {
                  const organization = item;

                  if (organization) {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {organization.name}
                        </TableCell>
                        <TableCell>
                          {organization.owner ? (
                            <div>
                              {organization.owner.name} {organization.owner.lastname}
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell align="right">
                          <FontAwesomeIcon icon={faEdit} style={{ color: '#005773', paddingRight: '5px', cursor: 'pointer' }} onClick={() => handleEdit(item)} />
                          <FontAwesomeIcon icon={faUsersCog} style={{ color: '#005773', paddingRight: '5px', cursor: 'pointer' }} onClick={() => handleOpenUsers(item)} />
                          {/* <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#CC171D', paddingRight: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)} /> */}
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow>
                        <TableCell colSpan={3}>No Records Found</TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <div>The list is empty. </div>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </Paper>

      <Permission action="organization.create" user={user} hideObject={true}>
        <Button variant="contained" onClick={() => handleAdd()} style={{ marginTop: '10px' }}>
          Add Business Unit
        </Button>
      </Permission>

      <CreateDialog selectedBusinessUnit={selectedBusinessUnit.name && selectedBusinessUnit} parent={selectedOrganization} open={open} onClose={() => handleClose()} />
      <UserDialog entity={selectedBusinessUnit.name && selectedBusinessUnit} parentEntity={selectedOrganization} open={openUsers} onClose={() => handleCloseUsers()} />
    </div>
  );
}

export default BusinessUnits;
