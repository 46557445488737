import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from './Layouts/Layout';
import AdminLayout from './Layouts/AdminLayout';

import { withStyles } from '@material-ui/core/styles';

import { auth } from '../Redux/Actions/user_actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Permission from './Permission';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#C9171D',
  },
  barColorPrimary: {
    backgroundColor: '#a01317',
  },
})(LinearProgress);

/**
 * reload = true, false, null
 * true = This route is completly private and needs login
 * false = This route is private (Register/Login)
 * null = this route is public
 */
export default function (ComposedClass, access, reload, checkSubscription, adminRoute = null, backend = false, title = '', checkOrganization = true, checkVerified = true) {
  class AuthenticationCheck extends Component {
    state = {
      loading: true,
    };

    _isMounted = false;

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount() {
      this._isMounted = true;

      this.props.dispatch(auth()).then((responce) => {
        let user = this.props.user.userData;

        if (!user.isAuth) {
          //NOT AUTHENTICATED
          if (reload) {
            this.props.history.push('/sign_in');
          }
        } else {
          //AUTHENTICATED

          const activeOrgPlan = user.organizationPlan?.active;
          const userIsParentOwner = user.parentEntity && user.parentEntity?.owner == user.user_id; //true
          const userIsOwner = user.selectedOrganization?.owner == user.user_id; //false

          if (checkSubscription && !userIsParentOwner && !userIsOwner) {
            if (!activeOrgPlan) {
              this.props.history.push('/payment/expired');
            }
          } else if (checkSubscription && userIsOwner && !user.plan && !activeOrgPlan) {
            this.props.history.push('/payment/select-plan');
          } else if (checkSubscription && userIsOwner && user.plan && !activeOrgPlan) {
            this.props.history.push('/payment/renew-plan');
          } else if (!user.isVerified && checkVerified) {
            this.props.history.push('/register/email_verification/' + user.email);
          }

          if (checkOrganization && user.organizations.length === 0) {
            this.props.history.push('/entity_select');
          }

          if (adminRoute && !user.isAdmin) {
            //This is admin route but user is not admin. Send to welcom screen
            this.props.history.push('/welcome');
          } else {
            if (reload === false && user.isVerified) {
              this.props.history.push('/welcome');
            }
          }
        }

        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
    }

    render() {
      // let user = this.props.user.userData;

      // if (user && user.organizations.length === 0) {
      //     return <Redirect to='/organization_select'/>;
      // }

      if (this.state.loading) {
        return (
          <div>
            <ColorLinearProgress thickness={10} />
            {!backend ? <Layout /> : <AdminLayout title={title} />}
          </div>
        );
      }

      return (
        <div>
          {access === null ? (
            !backend ? (
              <Layout>
                <ComposedClass {...this.props} user={this.props.user} />
              </Layout>
            ) : (
              <AdminLayout title={title} user={this.props.user}>
                <ComposedClass {...this.props} user={this.props.user} />
              </AdminLayout>
            )
          ) : (
            <Permission action={access} user={this.props.user.userData}>
              {!backend ? (
                <Layout>
                  <ComposedClass {...this.props} user={this.props.user} />
                </Layout>
              ) : (
                <AdminLayout title={title} user={this.props.user}>
                  <ComposedClass {...this.props} user={this.props.user} />
                </AdminLayout>
              )}
            </Permission>
          )}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  return connect(mapStateToProps)(AuthenticationCheck);
}
