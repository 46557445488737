import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getUsersByOrganization } from '../../../Redux/Actions/user_actions';
import { getStatuses } from '../../../Redux/Actions/status_action';
import { getAllBusinessUnitsByEntity, getAllBusinessUnits } from '../../../Redux/Actions/business_units_actions';
import { GetFilteredIssues } from '../../../Redux/Actions/issues_actions';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles, MenuItem } from '@material-ui/core';
import moment from 'moment'

import HookMultiSelect from '../../UI/Form/hookMultiSelect'
import SubmitButton from '../../UI/Buttons/submitButton';
import Form from '../../UI/Form';
import DatePicker from '../../UI/Form/datePicker';
import HookSelect from '../../UI/Form/hookSelect';
import MyCircularProgress from '../../UI/ProgressBars/myCircularProgress';

const useStyles = makeStyles((theme) => ({
    select: {
        width: '200px',
        margin: theme.spacing(2)
    },
    required:{
        color: theme.palette.error.main,
        fontSize: '15px'
    }
}))

const schema = yup.object().shape({
    users: yup.array(),
    functional_unit: yup.array(),
    entity: yup.string().required(),
    themes: yup.array(),
    kpi: yup.array(),
    status: yup.array(),
    startDate: yup.date(),
    endDate: yup.date()
})

const FilterBar = ({ ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false)
    const [selectedEntity, setSelectedEntity] = useState("");

    const statuses = useSelector(state => state.status.statuses)
    const users = useSelector(state => state.user.users)
    const user = useSelector(state => state.user.userData)
    const entities = useSelector(state => state.user.userData.organizations)
    const functional_units = useSelector(state => state.business_units.business_units)
    const entity = useSelector(state => state.user.userData )
    const kpis = [{ key: 'Finance', value: 'Finance' },
                { key: 'Customer', value: 'Customer' },
                { key: 'Operational', value: 'Operational' },
                { key: 'People', value: 'People' },
                { key: 'Research & Development', value: 'Research & Development' }];
    const themes = [{ key: 'Growth', value: 'Growth' },
                    { key: 'Risk', value: 'Risk' },
                    { key: 'Transition', value: 'Transition' }]
    const { register, handleSubmit, control, errors, setValue, watch } = useForm({
            mode: 'onBlur',
            resolver: yupResolver(schema)
        })

    useEffect(() => {
        dispatch(getUsersByOrganization(entity.selectedOrganization._id))
        dispatch(getStatuses())
    }, [])


    const watchEntity = watch("entity");
    useEffect(() => {
        if((watchEntity || user.selectedOrganization.parent ) && props.functionalUnitFilter){
            dispatch(getAllBusinessUnitsByEntity(watchEntity));
        }
    }, [watchEntity])




    const onSubmit = (data) => {
        setloading(true);
        dispatch(GetFilteredIssues(data.entity, data)).then(responce => {
            const issues = responce.payload;
            props.onFilter(issues, data);
            setloading(false);
        })
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>


{ props.groupReports ? 
    <Controller name='entity' defaultValue={user.selectedOrganization._id} control={control} as={
                <input type="hidden" />
             } /> :

             user.selectedOrganization.parent || !user.selectedOrganization.isGroup ? 
            <Controller name='entity' defaultValue={user.selectedOrganization._id} control={control} as={
                <input type="hidden" />
             } /> :
                <HookSelect id="entity-select"
                        className={classes.select}
                        name="entity"
                        label="Choose Business"
                        control={control}
                        error={ errors?.entity }
                        defaultValue={''}
                        required
                    >
                    {entities ? entities.map(entity => (
                        entity.organization_id.parent != null ?
                            <MenuItem key={entity.organization_id._id} value={entity.organization_id._id}>{entity.organization_id.name}</MenuItem>
                            :
                            null
                    )) : null}
                </HookSelect>
            }

            { props.functionalUnitFilter && functional_units ?
                <HookMultiSelect id="fu-select" className={classes.select} name="functional_unit" label="Choose Functional Units" control={control} error={ errors?.functional_unit } defaultValue={[]}>
                    { functional_units.map((fu) => (
                        <MenuItem key={fu._id} value={fu._id}>
                            { fu.name }
                        </MenuItem>
                    )) }
                </HookMultiSelect> : null }

                <HookMultiSelect id="users-select" className={classes.select} name="users" label="Choose Users" control={control} error={ errors?.users } defaultValue={[]}>
                    { users ? users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            { user.name + ' ' + user.lastname }
                        </MenuItem>
                    )) : null }
                </HookMultiSelect> 

                <HookMultiSelect id="kpi-select" className={classes.select} name="kpi" label="Choose KPI" control={control} error={ errors?.kpi } defaultValue={[]}>
                    { kpis ? kpis.map((kpi) => (
                        <MenuItem key={kpi.key} value={kpi.value}>
                            { kpi.value }
                        </MenuItem>
                    )) : null }
                </HookMultiSelect> 

                <HookMultiSelect id="theme-select" className={classes.select} name="theme" label="Choose Theme" control={control} error={ errors?.themes } defaultValue={[]}>
                    { themes ? themes.map((theme) => (
                        <MenuItem key={theme.key} value={theme.value}>
                            { theme.value }
                        </MenuItem>
                    )) : null }
                </HookMultiSelect> 

                <HookMultiSelect id="status-select" className={classes.select} name="status" label="Choose Statuses" control={control} error={ errors?.status } defaultValue={[]}>
                    { statuses ? statuses.map((status) => (
                        <MenuItem key={status._id} value={status._id}>
                            { status.name }
                        </MenuItem>
                    )) : null }
                </HookMultiSelect> 

                <DatePicker className={ classes.select } id="start-date-select" name="startDate" label="Choose Start Date" control={control} error={ errors?.startDate } setValue={setValue} />
                <DatePicker className={ classes.select } id="end-date-select" name="endDate" label="Choose End Date" control={control} error={ errors?.startDate } setValue={setValue} />

                { !loading ? 
                <SubmitButton>Submit</SubmitButton> 
                : <MyCircularProgress /> }
            </Form>
        </div>
    );
};

export default FilterBar;