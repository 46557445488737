import React, { Component } from 'react'

import BackendBackground from '../UI/backendBackground';
import Button from '@material-ui/core/Button';

import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';


// const uppy = Uppy({
//     debug: true,
//     autoProceed: false,
//     restrictions: {
//       maxFileSize: 1000000,
//       maxNumberOfFiles: 3,
//       minNumberOfFiles: 2,
//       allowedFileTypes: ['image/*', 'video/*']
//     }
//   })
//   .use(Dashboard, {
//     trigger: '.UppyModalOpenerBtn',
//     inline: true,
//     target: '.DashboardContainer',
//     replaceTargetContent: true,
//     showProgressDetails: true,
//     note: 'Images and video only, 2–3 files, up to 1 MB',
//     height: 470,
//     metaFields: [
//       { id: 'name', name: 'Name', placeholder: 'file name' },
//       { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
//     ],
//     browserBackButtonClose: true
//   })

class OrganizationSettings extends Component {

    render() {
        return (
        <div>
            <BackendBackground />
            <Button variant="contained" className="UppyModalOpenerBtn">Upload</Button>
            <Dashboard uppy={ <Uppy /> } />
        </div>
        )
    }
}

export default OrganizationSettings;