import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFlags, getFlag, createFlag, updateFlag, removeFlag, printFlags } from '../../Redux/Actions/flag_actions'
import Notes from './Notes'

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ScrollArea from 'react-scrollbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
//import Paper from '@material-ui/core/Paper';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid, clearFields } from '../Utils/Forms/FormActions'
import UserSelect from '../UI/CustomFields/UserSelect';

//import CircularProgress from '@material-ui/core/CircularProgress'
import FileSaver  from 'file-saver';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    list: {
        backgroundColor: theme.palette.background.paper,
        height: '50%',
        padding: '10px',
        minWidth: '200px',
        maxWidth: '440px'
    },
    title: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    },
    fab: {
        backgroundColor: 'rgb(242, 242, 242)',
        backgroundPosition: '16px 10px',
        '&:hover': {
            backgroundColor: 'rgb(242, 242, 242)'
        },
        color: '#0a1c2d',
        marginTop: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        position: 'absolute',
        right: '0',
        zIndex: '10'
    },
    drawer: {
        paddingRight: '10px'
    },
    footer:{
        textAlign: 'center',
        margin: '25px'
    },
    badge:{
        bottom: '18px',
        top: 'unset',
        backgroundColor: 'rgb(0, 87, 115)',
    },
    notesPaper:{
        margin: theme.spacing.unit * 2,
    }
});

class FlagsDrawer extends Component {

    state = {
        doanloading: false,
        selected_flag: {},
        totalFlags: 0,
        drawerOpen: false,
        addOpen: false,
        openSelected: false,
        formType: 'Add',
        formError: false,
        formSuccess: '',
        formData: {
            description: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'description',
                    type: 'text',
                    label: 'Description',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            impact: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'impact',
                    type: 'text',
                    label: 'Impact',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            proposed_actions: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'proposed_actions',
                    type: 'text',
                    label: 'Proposed Actions',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            anticipated_outcome: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'anticipated_outcome',
                    type: 'text',
                    label: 'Anticipated Outcome',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            raised_by: {
                value: '',
                validation: {
                    required: false,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            }
        }
      };

    toggleDrawer = (open) => () => {
        this.setState({
            drawerOpen: open,
        });
    };

    componentDidMount(){
        this.getTheFlags();
    }

    handleUserChange = (user) => {
        let newFormData = { ...this.state.formData };
        newFormData["raised_by"].value = user;

        this.setState({
            formData: newFormData
        })
        
    }

    getTheFlags = () => {
        this.props.dispatch(getFlags()).then(response => {
            
            this.setState({
                totalFlags: this.props.flags?.length
            })
        });
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'create_flag');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();
        let dataToSubmit = generateData(this.state.formData, 'create_flag');
        let formIsValid = isFormValid(this.state.formData, 'create_flag');

        if (formIsValid) {
            if(this.state.formType === 'Add')
            {  
                this.props.dispatch(createFlag(dataToSubmit)).then(responce => {
                    if (this.props.success) {
                        this.getTheFlags();

                        this.setState({
                            addOpen: false,
                            formData: clearFields(this.state.formData)
                        })
                    } else {
                        //const error = responce.payload.err;
                        this.setState({
                            formError: true
                        })
                    }
                });
            }
            else{
                if(this.state.selected_flag){
                    this.props.dispatch(updateFlag( this.state.selected_flag._id , dataToSubmit)).then(responce => {
                        if (this.props.success) {
                            this.getTheFlags();

                            this.setState({
                                addOpen: false,
                                formData: clearFields(this.state.formData)
                            })
                        } else {
                            //const error = responce.payload.err;
                            this.setState({
                                formError: true
                            })
                        }
                    });
                }
                
            }
        }
        else {
            this.setState({
                formErrorMsg: 'This creating is invalid.',
                formError: true
            })
        }
    }

    handleFlagDelete = (item) => {
        if(item){
            this.props.dispatch(removeFlag(item._id)).then(response => {
                if(this.props.success){
                    this.getTheFlags();
                }
            })
        }
    }

    handleFlagEdit = (item) => {
        clearFields(this.state.formData, 'flag_edit')

        if(item){
            this.props.dispatch(getFlag(item._id)).then(responce => {
                if(this.props.flag){
                    let newFormData = { ...this.state.formData };
    
                    for (let key in newFormData) {
                        newFormData[key].value = this.props.flag[key];

                        if(key === 'raised_by'){
                            newFormData[key].value = this.props.flag[key]._id;
                        }
                    }
        
                    this.setState({
                        selected_flag: item,
                        formData: newFormData,
                        formType: 'Edit',
                        addOpen : true
                    })
                }
            })
        }
    }

    handleOpenFlagDialog = () => {
        clearFields(this.state.formData, 'flag_add')
        this.setState({
            addOpen: true
        })
    }

    handleCloseFlagDialog = () => {
        this.setState({
            addOpen: false
        })
    }

    handleCloseSelected = () => {
        this.setState({
            openSelected: false,
            selected_flag: {}
        })
    }

    handlePrint = () => {
        this.setState({
            doanloading: true
        })


        this.props.dispatch(printFlags()).then(response => {

            FileSaver.saveAs(new Blob([this.props.pdf], {type: "application/pdf"}), "flags_" + moment().format("DD_MM_YYYY") + ".pdf");
            this.setState({
                doanloading: false
            })
        });
    }

    handleOpenSelected = (flag) => {
        this.setState({
            openSelected: true,
            selected_flag: flag
        })
    }

    list = () => {
        const { classes } = this.props;

        return (
            <div style={{ position: 'relative' }}>
                <Typography variant="h2" className={classes.title}>
                    Flags                     
                    <Button
                        variant="text"
                        onClick={this.toggleDrawer(false)}
                        style={{
                            fontWeight: [500],
                            fontSize: '14px',
                            width: '150px',
                            float:"right",
                        }}>
                        Close
                    </Button>
                </Typography>


                <div className={classes.list}>
                    <List dense={true}>
                        <ScrollArea
                            speed={0.8}
                            horizontal={false}
                            smoothScrolling={true}
                            className={classes.list}>
                            {this.props.flags ? this.props.flags.map(item => (
                                <ListItem key={item._id}>
                                    <ListItemText
                                        primary={<Button component='span' onClick={() => this.handleOpenSelected(item)}>{item.description}</Button>}
                                        secondary={ item.raised_by ? item.raised_by.name + " " + item.raised_by.lastname + "  (" + moment(item.createdAt).format("DD/MM/YYYY HH:MM") + ")" : "" }
                                    />
                                    <div>
                                        <Tooltip title="Edit">
                                            <FontAwesomeIcon icon={faEdit} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}} onClick={() => this.handleFlagEdit(item)}/>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <FontAwesomeIcon icon={faTrashAlt} style={{color: '#CC171D', paddingRight: '5px', cursor: 'pointer'}} onClick={() => this.handleFlagDelete(item)}/>
                                        </Tooltip>
                                    </div>
                                </ListItem>
                            ))
                                :
                                <div>No Items in list</div>
                            }
                        </ScrollArea>
                    </List>
                </div>
                {this.renderSelectedDialog()}
            </div>
        )
    }

    renderSelectedDialog = () => (
        <Dialog
            open={this.state.openSelected}
            onClose={this.handleCloseSelected}
            >
            <DialogTitle>Add New Flag</DialogTitle>
            <DialogContent>
                {this.state.selected_flag ?
                    <div>
                        <DialogContentText variant="h5">
                            Description
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selected_flag.description}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Impact
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selected_flag.impact}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Proposed Actions
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selected_flag.proposed_actions}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Anticipated Outcome
                        </DialogContentText>
                        <DialogContentText>
                            {this.state.selected_flag.anticipated_outcome}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Raised By
                        </DialogContentText>
                        <DialogContentText>
                            {this.state.selected_flag.raised_by ?  
                                this.state.selected_flag.raised_by.name + " " + this.state.selected_flag.raised_by.lastname
                            :null}
                        </DialogContentText>
                        <Divider />

                    </div>

                    :
                    <DialogContentText >
                        No Flag Selected
                </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseSelected} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

    render() {
        const { classes } = this.props;

        return (
            <div>
                
                <Fab variant="extended" className={classes.fab} onClick={this.toggleDrawer(true)}>
                    <Badge color="primary" badgeContent={this.state.totalFlags} classes={{ badge: classes.badge }} >
                        <div>Show Flags/Notes</div>
                    </Badge>
                </Fab>
                
                <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} className={ classes.drawer }>
                    <div tabIndex={0} >
                        { this.list() }
                    </div>
                    <div className={classes.footer}>
                        <Button
                            variant="contained"
                            onClick={() => this.handleOpenFlagDialog()}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '14px',
                                width: '200px',
                                color: 'white',
                                marginTop: '20px',
                                margin: '5px',
                            }}>
                            ADD FLAG
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => this.handlePrint()}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '14px',
                                width: '200px',
                                color: 'white',
                                marginTop: '20px',
                                margin: '5px',
                            }}>
                            DOWNLOAD
                        </Button>
                    </div>
                    <div>
                        
                        <Notes />
                        
                    </div>
                </Drawer>
                <Dialog
                    open={this.state.addOpen}
                    onClose={this.handleCloseFlagDialog}
                    >
                    <DialogTitle>Add New Flag</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please give a description of your flag.
                        </DialogContentText>
                        <FormField
                            id={'description'}
                            formData={this.state.formData.description}
                            change={(element) => this.updateForm(element)}
                        />
                        <FormField
                            id={'impact'}
                            formData={this.state.formData.impact}
                            change={(element) => this.updateForm(element)}
                        />
                        <FormField
                            id={'proposed_actions'}
                            formData={this.state.formData.proposed_actions}
                            change={(element) => this.updateForm(element)}
                        />
                        <FormField
                            id={'anticipated_outcome'}
                            formData={this.state.formData.anticipated_outcome}
                            change={(element) => this.updateForm(element)}
                        />

                    <UserSelect change={(user) => this.handleUserChange(user)} selectedUser={this.state.formData.raised_by.value} label="Select" header="Raised By" btnCreateName="Add new person" user_type="flag_user"/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseFlagDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(event) => this.submitForm(event)} color="primary">
                         { this.state.formType === 'Add' ? 
                            "Add"
                            :
                            "Save"
                         }
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    flags: state.flags.flags,
    flag: state.flags.flag,
    success: state.flags.success,
    pdf: state.flags.pdf
})

export default connect(mapStateToProps)(withStyles(styles)(FlagsDrawer))