import React from 'react';
import HeaderText from '../UI/headerText';
import Organization from '../UI/organization';
import BackgroundItem from '../UI/backgroundItem';
import Button from '@material-ui/core/Button';

import IntroBackground from '../../Resources/images/backgrounds/background_introduction.png'

const AuthorisedAccessOnly = (props) => {
    return (       
        <div>
            <BackgroundItem path={IntroBackground}/>
            <div style={{
                    height: '470px',
                    padding: '110px 120px 20px 120px'
                }}>

                <div className="intro_header" style={{
                    position:'relative',
                    left: '120px',
                    paddingTop: '50px',
                    textAlign: 'center'
                }}>
                    
                    <HeaderText name="You do not have access to this" size="30px"/>
                    <Organization/>
                    <Button variant="contained" component="a" href="/"
                        style={{
                            margin: '10px',
                            backgroundColor: '#005773',
                            color: 'white',
                            fontWeight: [500]
                        }}>
                        Home
                    </Button>
                </div>
            </div>   
        </div>
    );
}


export default AuthorisedAccessOnly;
