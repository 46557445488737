import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class EULA extends Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };


    render() {
        return (
            <span>
                <Button size="small" variant="text" onClick={this.handleClickOpen}>
                    IQFIN Software (Pty) Ltd END-USER LICENSE AGREEMENT
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">IQFIN Software (Pty) Ltd END-USER LICENSE AGREEMENT</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p>
                                This is a legally binding agreement between you (either an individual or a single entity), referred to herein as YOU and IQFIN Software (Pty) Ltd, referred to herein as IQFIN SOFTWARE (PTY) LTD. This software entitled IQFIN and all Schedules and Statements and materials included in it are referred to herein as the SOFTWARE or the SCHEDULES and STATEMENTS. Installing, opening or using all or any portion of this software indicates your acceptance of all the terms and conditions of this End-User License Agreement referred to herein as EULA and that this agreement is equivalent to a written agreement signed by you. By selecting the box I AGREE or performing a similar electronic function, you are providing your acceptance of the terms of this agreement. This agreement is enforceable against you and any legal entity that obtained the software and on whose behalf it is used. If you do not agree to the terms of this agreement, please do not use this software.
              </p>
                            <p>IT IS ILLEGAL AND STRICTLY PROHIBITED TO DISTRIBUTE, PUBLISH, OFFER FOR SALE, LICENSE OR SUBLICENSE, GIVE OR DISCLOSE TO ANY OTHER PARTY, THIS SOFTWARE OR THE SCHEDULES and STATEMENTS IN HARD COPY, DIGITAL FORM OR ANY OTHER MEDIUM WHETHER EXISTING OR NOT YET EXISTING, EXCEPT AS SPECIFICALLY PERMITTED BELOW.</p>
                            <p>The use of this software is governed by the terms and conditions set forth herein. Please read them carefully. This EULA is applicable to all of the Schedules and Statements included in the Software and to the Software in its whole part. You are deemed to have read, understood and accepted the terms of this EULA when you install or use the Software.</p>
                            <p>1.  LIMITED LICENSE.  You are granted a limited, non-exclusive license to install the Software on one individual computer for your own use provided that such computer does not render the Software accessible to other users through local or Internet networks or other methods. Only the individual that installed the Software, as indicated by the user information provided when installing the Software, will have the license to use the Schedules and Statements. Use by any other person, company, affiliate, corporation, Limited Liability Company, trust, or other separate legal entity will require a separate license. This includes companies that may be affiliated to you by ownership or otherwise. The license granted herein shall remain in effect perpetually, but shall terminate upon your use of the Software beyond the scope licensed herein or upon your violation of any term or condition hereof. All protections with which IQFIN Software (Pty) Ltd is provided under this EULA shall survive the termination of your license to use the Software.
</p>
                            <p>2.  PROPRIETARY RIGHTS.  IQFIN Software (Pty) Ltd and its suppliers retain all title, ownership, and intellectual property rights in the Software, including but not limited to all supporting documentation, files, marketing material, images, multimedia and applets. The Software is protected by copyright and other intellectual property laws and by international treaties. The Software may include security measures designed to control access and prevent unauthorized copying and use. You agree not to interfere with any such security components. IQFIN Software (Pty) Ltd permits you to download, install, use, or otherwise benefit from the functionality or intellectual property of the Software only in accordance with the terms of this EULA. Use of third party material included in the Software may be subject to the terms and conditions typically found in the party's own license agreement.
</p>
                            <p>3.  CONFIDENTIALITY.  Each party agrees that it shall not disclose to any third party any information concerning the customers, trade secrets, methods, processes or procedures or any other confidential, financial or business information of the other party which it learns during the course of its performance of this EULA, without the prior written consent of such other party. This obligation shall survive the cancellation or other termination of this EULA. The Software contains trade secrets and proprietary know-how that belong to IQFIN Software (Pty) Ltd and it is being made available to you in strict confidence. ANY USE OR DISCLOSURE OF THE SOFTWARE, OR OF ITS ALGORITHMS, PROTOCOLS OR INTERFACES, OTHER THAN IN STRICT ACCORDANCE WITH THIS EULA, MAY BE ACTIONABLE AS A VIOLATION OF "IQFIN SOFTWARE (PTY) LTD"’S TRADE SECRET RIGHTS.
</p>
                            <p>4.  NON-TRANSFER OF RIGHTS.  You may not rent, lease, sell, sublicense, assign or transfer your rights in the software, or authorize any portion of the software to be copied onto another individual or legal entity's computer.
</p>
                            <p>5.  ALLOWED USAGE.  Upon fulfilling your obligations towards properly installing and registering the Software or obtaining a valid Software license key, you are permitted to do only the following:
</p>
                            <p>(a) Use the Schedules and Statements in transactions and agreements as well as Planning, in which you are one of the parties. You may have your auditor or accountant , review, comment and modify the Schedules and Statements to fit your particular needs. You are encouraged to obtain competent counsel to advise you on the use of the Schedules and  Statements .
</p>
                            <p>(b) If you are an auditor or Accountant, you may modify the Schedules and Statements and use them only for preparing Schedules and Statements for your immediate clients with whom you have an auditor-client relationship. The Auditor or accountant license is only for one individual auditor or accountant use. As such, only you can use the Schedules and Statements. Other auditors or accountants  or associates within your firm cannot use the Schedules and Statements. They must acquire a license for themselves.</p>
                            <p>(c) Display or post online as a part of your website or online property for your own use, only Schedules and Statements that are meant for public display or use on a website, such as Online Licenses, Privacy Statements, Affiliate Program Agreements, Linking Agreements and the like.</p>
                            <p>(d) You may not make copies of the Software. You agree that any copies of the Software which you make pursuant to this EULA shall bear all copyright, trademark and other proprietary notices included therein by IQFIN Software (Pty) Ltd and, except as expressly authorized herein, you shall not distribute the same to any third party without "IQFIN SOFTWARE (PTY) LTD"'s prior written consent.
</p>
                            <p>6.  DISTRIBUTION.  You may not unpack or remove Schedules and Statements and distribute them in any way except as prescribed by the Allowed Usage defined in section 5. Only distribution of the full and complete unaltered software application is allowed provided that IQFIN Software (Pty) Ltd has given you prior written authorization (through your participation in any of "IQFIN SOFTWARE (PTY) LTD"s partner Agency programs) and that all trademarks, ownership and copyright notices remain in the Software.</p>
                            <p>7.  RESTRICTIONS.  You may not: (i) modify or create any derivative works of the Software or documentation, including customization, any modifications or enhancements, translation or localization without "IQFIN SOFTWARE (PTY) LTD"'s express written consent; (ii) reverse engineer, decompile, disassemble, make or otherwise attempt to derive the source code of the Software, or the underlying ideas or algorithms of the Software; (iii) attempt to gain unauthorized access to Schedules and Statements, IQFIN Software (Pty) Ltd servers or any other service, account, computer system or network associated with the Software or with "IQFIN SOFTWARE (PTY) LTD", it's affiliates, agents, partners and customers; (iv) remove or alter any trademark, logo, copyright or other proprietary notices or symbols in the Software; (v) block, disable, or otherwise affect any advertising, banner window, tab, link to websites and services, or other features that are part of the Software; (vi) incorporate, integrate or otherwise include the Software or any portion thereof into any software, program or product; (vii) provide false information when registering the Software; (viii) digitally transmit or make available the Software or its content through local networks, intranets, extranets, FTP, online discussion boards, forums, list-serve, peer-to-peer networks or technologies, newsgroups, bulletin boards, or any other mode of shared communication system, or place the Software onto a server so that it is accessible via a public network such as the Internet; (ix) use the Software in any way that violates this EULA or any law; or (x) authorize or assist any third party to do any of the things described in this section.
</p>
                            <p>8.  PUBLICITY.  You shall not refer to the existence of this EULA in any press release, website, advertising or any publicly distributed or accessible material, without the prior written consent of "IQFIN SOFTWARE (PTY) LTD".</p>
                            <p>9.  ADDITIONAL FEATURES and CONTENT.  Certain portions of the Software may be locked or unavailable in your  specific  version. In order to upgrade the Software or use additional content and features you must (i) provide valid registration information and (ii) pay an additional fee to obtain a valid Software license key. All Software license key holders are subject to the terms and conditions of this EULA.</p>
                            <p>10.  SOFTWARE LICENSE KEY.  This  Software  require the purchase of a Software license key. You agree not to attempt to, and not to, control, disable, modify or remove any component of the Software license key protection system. You also agree not to access, attempt to access, copy,  share or distribute the license key for any purpose.</p>
                            <p>11.  SOFTWARE UPDATES.  By using the Software, you consent to receiving software updates, modifications, and/or patches that address issues such as security, interoperability, and performance. Your use of the updates shall be governed by this EULA unless you are asked to agree to a new EULA at the time of download or installation.</p>
                            <p>12.  PRIVACY</p>
                            <p>(a) SOFTWARE IMPROVEMENT PROGRAM.  By participating in the IQFIN Software (Pty) Ltd Software Improvement Program, you allow IQFIN Software (Pty) Ltd to collect anonymous information relating to your use of the Software, such as the frequency of your use of the Software and of certain features, and information on errors occurring during your use of the Software. This anonymous usage data will be used to diagnose performance issues and improve the reliability of the Software and its features. This data will not be tied to any information that would personally identify you.
</p>
                            <p>(b) PERSONAL INFORMATION.  During the installation process, or at some time thereafter, you may be asked to provide certain information that will be used to personalize certain Schedules and Statements, send you IQFIN Software (Pty) Ltd information via email or mail, and customize some information presented to you through the Software. For example, you may be asked for your state/province/country, which could be used by the Software to provide you with locally relevant Schedules and Statements and/or related products. All personal and billing information is transmitted using the secure SSL protocol. Both personal and non-personal information collected by the Software is safeguarded according to the highest privacy and data protection standards adopted worldwide, and will not be shared or given without your consent as described in our Privacy Policy (available at http://www."IQFIN SOFTWARE (PTY) LTD".com/privacy/index.html).
</p>
                            <p>13.  LIMITATION OF LIABILITY.  EXCEPT AS REQUIRED BY LAW, IQFIN Software (Pty) Ltd AND ITS EMPLOYEES, DIRECTORS, LICENSORS, CONTRIBUTORS AND AGENTS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THIS EULA OR THE USE OF OR INABILITY TO USE THE SOFTWARE, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED. "IQFIN SOFTWARE (PTY) LTD"'S LIABILITY UNDER THIS EULA IS LIMITED TO REPLACEMENT OF A CD CONTAINING THE  SOFTWARE .
</p>
                            <p>14.  DISCLAIMER OF WARRANTY.  YOU ACCEPT THE SOFTWARE AS IS AND WITH ALL FAULTS (IF ANY). IQFIN Software (Pty) Ltd DISCLAIMS  ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. IQFIN Software (Pty) Ltd MAKES NO WARRANTY OR REPRESENTATIONS WHATSOEVER REGARDING THE CONTENT OF THE SCHEDULES and STATEMENTS AND YOUR USE OF THE SCHEDULES and STATEMENTS, OR THE SUITABILITY OF THE SCHEDULES and STATEMENTS TO MEET YOUR SPECIFIC NEEDS. THE SCHEDULES and STATEMENTS ARE DETAILED, BUT GENERIC IN FORM. THERE MAY BE LAWS APPLICABLE IN YOUR STATE/PROVINCE/COUNTRY THAT  NEED TO BE ADDRESSED IN ANY FINAL DOCUMENT. YOU ARE STRONGLY URGED TO OBTAIN COMPETENT COUNSEL TO REVIEW THE SCHEDULES and STATEMENTS YOU CREATE USING THE SOFTWARE AND TO ADVISE YOU ON ANY TRANSACTION IN WHICH YOU INTEND TO ENGAGE.
</p>
                            <p>IQFIN Software (Pty) Ltd makes no warranties with respect to the Schedules and Statements as described above and as such, there should be no reason for you to bring any grievance against "IQFIN SOFTWARE (PTY) LTD". However, if you do bring any action, claim, suit, threat or demand against "IQFIN SOFTWARE (PTY) LTD", and you do not substantially prevail, you shall pay "IQFIN SOFTWARE (PTY) LTD"’s entire attorney fees and costs attached to such action. In the same token, if IQFIN Software (Pty) Ltd is forced to take legal action to enforce this EULA or any of its rights described herein or under any state or federal law, you will pay "IQFIN SOFTWARE (PTY) LTD"s attorney fees and costs.
</p>
                            <p>15.  VIOLATIONS OF THIS EULA.  IQFIN Software (Pty) Ltd reserves all rights not specifically granted to you above. IQFIN Software (Pty) Ltd will have the right to proceed against you in the event that you infringe against "IQFIN SOFTWARE (PTY) LTD” s rights. Any use not within the precise scope of the license set forth herein will be considered an infringement. You acknowledge and agree that "IQFIN SOFTWARE (PTY) LTD"s damages in the event of your violation of this EULA will be substantial and that IQFIN Software (Pty) Ltd
              will suffer irreparable harm in such event. As such, IQFIN Software (Pty) Ltd
              shall have the right to obtain equitable remedies, including but not limited to recovery of damages, obtaining injunctions, recovering statutory damages, recovering attorney fees, and any other available legal remedy.
</p>
                            <p>16.  TERMINATION.  IQFIN Software (Pty) Ltd shall have the sole and exclusive right to immediately terminate this EULA and suspend your license to use the Software should you fail to perform any obligation required under this EULA, engage in activity that IQFIN Software (Pty) Ltd deems harmful to its well-being or if you become bankrupt or insolvent. This EULA takes effect upon your installation of the Software and remains effective until terminated. Upon termination of this EULA by "IQFIN SOFTWARE (PTY) LTD", you shall return to IQFIN Software (Pty) Ltd the original and all copies of the Software and Schedules and Statements including partial copies and modifications.
</p>
                            <p>17.  WAIVER and SEVERABILITY.  "IQFIN SOFTWARE (PTY) LTD"'s waiver of any breach of this EULA shall not constitute an amendment to this EULA or "IQFIN SOFTWARE (PTY) LTD"'s waiver of subsequent breaches. If any part of this EULA is found void and unenforceable, it will not affect the validity of the balance of this EULA, which will remain valid and enforceable according to its terms.
</p>
                            <p>18.  ELECTRONIC NOTICES.  You consent that IQFIN Software (Pty) Ltd may provide you with information and notices regarding the Software and IQFIN Software (Pty) Ltd via the email address you designate when installing the Software or thereafter. IQFIN Software (Pty) Ltd may provide notices to you via (i) email if you have provided IQFIN Software (Pty) Ltd with a valid email address, or (ii) by posting the notice on the IQFIN Software (Pty) Ltd website or the Software download site which IQFIN Software (Pty) Ltd owns and operates. You may withdraw your consent for electronic notices.
</p>
                            <p>19.  AMENDMENTS.  IQFIN Software (Pty) Ltd reserves the right to unilaterally amend all offers, pricing terms or other matters pertaining to the Software, "IQFIN SOFTWARE (PTY) LTD"s website or this EULA. No course of dealing or trade usage shall be deemed to amend the terms of this EULA.
</p>
                            <p>20.  GOVERNING LAW.  This EULA shall be governed by and construed under the laws of the United Kingdom. You consent and agree that all legal proceedings relating to the subject matter of this EULA shall be maintained in courts sitting within the United Kingdom, and that jurisdiction and venue for such proceedings shall lie exclusively with such courts.
</p>
                            <p>21.  COMPLETE AGREEMENT.  This is the entire EULA between IQFIN Software (Pty) Ltdand you relating to the Software and it supersedes any prior representations, discussions, undertakings, warranties, communications or advertising relating to the Software.
</p>
                            <p>For inquiries please contact:
              
</p>
                            <p>IQFIN Software (Pty) Ltd Address :Unit 9 Rust andVrede Business Park ,Durbanville Cape South Africa
</p>
                            <p>IQFIN Software (Pty) Ltd and "SOFTWARE", are either the registered trademarks or trademarks of "IQFIN SOFTWARE (PTY) LTD".
</p>
                            <p></p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        )
    }
}

export default EULA