import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getNotes, getNote, updateNote, createNote, removeNote   } from '../../Redux/Actions/note_actions'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ScrollArea from 'react-scrollbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid, clearFields } from '../Utils/Forms/FormActions'

const styles = theme => ({
    paper:{
        margin: theme.spacing.unit *2
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        height: '50%',
        padding: '10px',
        minWidth: '200px',
        maxWidth: '440px'
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
    fab: {
        backgroundColor: 'rgb(242, 242, 242)',
        backgroundPosition: '16px 10px',
        '&:hover': {
            backgroundColor: 'rgb(242, 242, 242)'
        },
        color: '#0a1c2d',
        marginTop: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        position: 'absolute',
        right: '0',
        zIndex: '10'
    },
    drawer: {
        paddingRight: '10px'
    },
    footer:{
        textAlign: 'center',
        margin: '25px'
    },
    badge:{
        bottom: '18px',
        top: 'unset',
        backgroundColor: 'rgb(0, 87, 115)',
    },
    notesPaper:{
        margin: theme.spacing.unit * 2,
    }
});
class Notes extends Component {
    state = {
        deleteConfirmOpen: false,
        notesOpen: false,
        openSelected: false,
        selectedNote: {},
        formType: 'Add',
        formError: false,
        formSuccess: '',
        formData: {
            note: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'note',
                    type: 'text',
                    label: 'Note',
                    maxRows: 4
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            }
        }
      };

    componentDidMount(){
        this.getTheNotes();
    }

    getTheNotes = () => {
        this.props.dispatch(getNotes()).then(response => {
            let newFormDataNotes = { ...this.state.formData };
            newFormDataNotes.note.value = this.props.note;

            this.setState({
                formData : newFormDataNotes
            })
        });
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'change_notes');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitNotes(event) {
        event.preventDefault();
        let dataToSubmit = generateData(this.state.formData, 'update_notes');
        let formIsValid = isFormValid(this.state.formData, 'update_notes');

        if (formIsValid) {
            if(this.state.formType === "Add"){
                
                this.props.dispatch(createNote(dataToSubmit)).then(responce => {
                    if (this.props.success) {   
                        this.getTheNotes();
                        this.setState({
                            notesOpen: false
                        })
                    } else {
                        //const error = responce.payload.err;
                        this.setState({
                            formError: true
                        })
                    }
                });
            }
            else{
                this.props.dispatch(updateNote(this.state.selectedNote._id, dataToSubmit)).then(responce => {
                    if (this.props.success) {   
                        this.getTheNotes();
                        this.setState({
                            notesOpen: false
                        })
                    } else {
                        //const error = responce.payload.err;
                        this.setState({
                            formError: true
                        })
                    }
                });
            }
        }
    }

    handleOpenNotesDialog = (note) =>  {
        if(note){
            clearFields(this.state.formData, 'note_edit')
            this.props.dispatch(getNote(note._id)).then(responce => {
                if(this.props.note){
                    let newFormData = { ...this.state.formData };
                    newFormData["note"].value = this.props.note.note;
   
                    this.setState({
                        selectedNote : note,
                        formData: newFormData,
                        formType: 'Edit',
                        notesOpen: true,
                    })
                }
            })
        }
        else{
            clearFields(this.state.formData, 'note_edit')
            this.setState({
                notesOpen: true
            })
        }
    }

    handleDeleteDialog = (note) => {

        this.setState({
            deleteConfirmOpen : true,
            selectedNote: note
        })
    }

    deleteNote = () => {
        this.props.dispatch(removeNote(this.state.selectedNote._id)).then(response => {
            if(this.props.success){
                this.getTheNotes();
                this.setState({
                    deleteConfirmOpen: false,
                    selectedNote: {}
                })
            }
        })
    }

    handleCloseNotesDialog = () =>  {
        this.setState({
            notesOpen: false,
            selectedNote: {}
        })
    }

    handleDeleteNotesDialog = () =>  {
        this.setState({
            deleteConfirmOpen: false,
            selectedNote: {}
        })
    }

    notes = () => {
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                <Typography variant="h2" className={classes.title}>
                    Notes
                    <Button
                            variant="contained"
                            size="small"
                            onClick={() => this.handleOpenNotesDialog()}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '14px',
                                color: 'white',
                                marginLeft: '20px'
                            }}>
                            ADD NOTE
                    </Button>
                </Typography>

                <ScrollArea
                        speed={0.8}
                        horizontal={false}
                        smoothScrolling={true}
                        className={classes.list}>
                            { this.props.notes ? this.props.notes.map((note, index) => (
                                <Card raised={true} className={classes.paper} key={index}>
                                    <CardContent>
                                        <Typography>{note.note}</Typography>                    
                                    </CardContent>
                                    <CardActions>
                                    <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => this.handleOpenNotesDialog(note)}
                                            style={{
                                                backgroundColor: '#005773',
                                                fontWeight: [500],
                                                fontSize: '14px',
                                                color: 'white',
                                                marginLeft: '20px'
                                            }}>
                                            EDIT NOTE
                                    </Button>
                                    <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => this.handleDeleteDialog(note)}
                                            style={{
                                                backgroundColor: '#005773',
                                                fontWeight: [500],
                                                fontSize: '14px',
                                                color: 'white',
                                                marginLeft: '20px'
                                            }}>
                                            REMOVE NOTE
                                    </Button>
                                    <small><b>{ note.note.length } Characters</b></small>
                                    </CardActions>
                                </Card>
                            ))
                            : null}
                </ScrollArea>
                

                { this.renderNotesDialog() }
            </div>
        )
    }

    renderNotesDialog = () => (
        <div>
        <Dialog
            open={this.state.deleteConfirmOpen}
            onClose={this.handleCloseNotesDialog}
            fullWidth={true}
            >
            <DialogTitle>Notes</DialogTitle>
            <DialogContent>
                <Typography>Ar you sure you want to delete this item?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => this.handleCloseNotesDialog(event)} color="primary">
                    Cancel
                </Button>
                <Button onClick={(event) => this.deleteNote(event)} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={this.state.notesOpen}
            onClose={this.handleCloseNotesDialog}
            fullWidth={true}
            >
            <DialogTitle>Notes</DialogTitle>
            <DialogContent>
                <FormField
                    id={'note'}
                    formData={this.state.formData.note}
                    change={(element) => this.updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => this.submitNotes(event)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
    
    render() {
        return (
            <div>
                { this.notes() }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    notes: state.notes.notes,
    note: state.notes.note,
    success: state.notes.success,
})


export default connect(mapStateToProps)(withStyles(styles)(Notes));