import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { getUserTransactions } from '../../Redux/Actions/payment.actions';

import PrimaryButton from '../UI/Buttons/primaryButton';
import TextButton from '../UI/Buttons/textButton';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 3),
    margin: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(0, 0, 3),
    fontSize: '30px',
  },
}));

function UserTransactions() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const transactions = useSelector((state) => state.payment.transactions);

  useEffect(() => {
    dispatch(getUserTransactions());
  }, []);

  return (
    <Paper className={classes.root}>
      <Typography varient="p" className={classes.header}>
        Transactions
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions &&
            transactions.map((t) => (
              <TableRow>
                <TableCell>{moment(t.billingDate).format('DD MMMM YYYY')}</TableCell>
                <TableCell>{t.paymentStatus == 'COMPLETE' ? <Typography color="success">Paid</Typography> : <Typography color="error">Unpaid</Typography>}</TableCell>
                <TableCell>R {t.amountGross}</TableCell>
                <TableCell>{/* <PrimaryButton size="small">Download Invoice</PrimaryButton> */}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default UserTransactions;
