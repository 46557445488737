import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserSubscriptions } from '../../../Redux/Actions/subscription_actions';
import { assignLicense } from '../../../Redux/Actions/organization_actions';

import { Dialog, DialogActions, DialogTitle, DialogContent, Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid  } from '../../Utils/Forms/FormActions'

const useStyles = makeStyles( theme => ({
    button: {
        backgroundColor: '#005773',
        color: 'white',
        fontWeight: [500],
        marginBottom: '10px',
        width: '170px',
        marginTop: '10px'
    }
}));

function AssignLicense(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [formData, setFormData] = useState({
        subscription_license: {
            element: 'select',
            value: '',
            config: {
                name: 'subscription_license',
                label: 'Select Subscription',
                options: [],
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        }
    });

    let { subscriptions, user, dispatch } = props;

    // useEffect(() => {
        
    // }, [])

    const SetSubsctiptionList = (usub) => {
        let licenseItems = [];

        if(usub){
            usub.map((sub, index) => {
                let item = {
                    key: sub._id,
                    value: sub.product
                }
                licenseItems.push(item);
            })
        }
        return licenseItems;
    }

    const updateForm = (element) => {
        const newFormData = update(element, formData, 'assign_license');

        setFormError(false);
        setFormData(newFormData);
    }

    const submitForm = (event) => {
        event.preventDefault();

        let dataToSubmit = generateData(formData, 'assign_license');
        let formIsValid = isFormValid(formData, 'assign_license');

        if (formIsValid) {
             dispatch(assignLicense({ ...dataToSubmit, organization_id: props.selectedOrganization._id })).then(responce => {
                 if(responce.payload.assign_success){
                     setOpen(false);
                 }else{
                    console.log('error: ' + responce.payload.err)
                     setFormError(responce.payload.err);
                 }
             });
        }
        else {
            setFormError("An error accured. Please try again");
        }
    }

    const handleOpen = () => {
        setOpen(true);
        setLoading(true);

        dispatch(getUserSubscriptions(user.user_id)).then(response => {
            const newFormData = { ...formData };
            newFormData.subscription_license.config.options = SetSubsctiptionList(response.payload);
            setFormData(newFormData);
            setLoading(false);
        });

        
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Button color="primary"
                    onClick={handleOpen} style={{
                        color: '#005773'
                    }}>
                    Assign License
            </Button>
                <Dialog open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title">
            <DialogTitle>Assign a license</DialogTitle>
            <DialogContent>
                <form onSubmit={(event) => submitForm(event)}>
                    <p>Please select your subscription: </p>
                    { formData.subscription_license.config.options.length > 0 ?
                        <FormField
                            id={'subscription_license'}
                            formData={formData.subscription_license}
                            change={(element) => updateForm(element)}
                        />
                    :
                        loading ? 
                            <CircularProgress  />
                        :
                            <Typography>You do not have any licenses left.</Typography>
                    }
                </form>
            </DialogContent>
            <DialogActions>
                { formData.subscription_license.value != '' ? 
                    <Button variant="contained"
                        onClick={(event) => submitForm(event)}
                        className={classes.button}>
                        Assign
                    </Button>
                :
                null }
                { formError != '' ? 
                    <Typography color='error'>{ formError }</Typography>
                :null}

                <Button variant="contained"
                    onClick={handleDialogClose}
                    className={classes.button}>
                    Close
                </Button>
            </DialogActions>
    </Dialog>
    </div>
        
    )
}

const mapStateToProps = (state, ownProps) => ({
    success: state.organization.assign_success,
    user: state.user.userData,
    subscriptions: state.subscription.user_subscriptions
})

export default connect(mapStateToProps)(AssignLicense)




