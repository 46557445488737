import axios from 'axios';
import {
    GET_ORGANIZATIONS,
    GET_ORGANIZATION_BYID,
    GET_ORGANIZATIONS_BYPARENT,
    ADD_ORGANIZATION,
    UPDATE_ORGANIZATION,
    REMOVE_ORGANIZATION,
    ADD_CHILD_ORGANIZATION,
    UPDATE_CHILD_ORGANIZATION,
    REMOVE_CHILD_ORGANIZATION,
    SENT_INVITE,
    REQUEST_INVITE,
    RESPOND_INVITE,
    SELECT_ORGANIZATION,
    RETRIEVE_LICENSE,
    CHANGE_OWNERSHIP,
    ASSIGN_LICENSE,
    CLEAR_SELECTED,
    GET_ORGANIZATIONROLES_BYUSER
} from './types';
import { ORGANIZATION_SERVER } from '../../Components/Utils/Misc';

export function getAllOrganizations() {
    const request = axios.get(`${ORGANIZATION_SERVER}`)
        .then(responce => responce.data);

    return {
        type: GET_ORGANIZATIONS,
        payload: request
    }
}

export function getOrganization(id) {
    const request = axios.get(`${ORGANIZATION_SERVER}/getById/${id}`)
        .then(responce => responce.data);

    return {
        type: GET_ORGANIZATION_BYID,
        payload: request
    }
}

export function getOrganizationsByParent(organizationId, includeParent) {
    const request = axios.get(`${ORGANIZATION_SERVER}/getByParent/${organizationId}${includeParent ? '?includeParent=true' : ''}`)
        .then(responce => responce.data);

    return {
        type: GET_ORGANIZATIONS_BYPARENT,
        payload: request
    }
}

export function getOrganizationRolesByUser(userId) {
    const request = axios.get(`${ORGANIZATION_SERVER}/getOrganizationRolesByUser/${userId}`)
        .then(responce => responce.data);

    return {
        type: GET_ORGANIZATIONROLES_BYUSER,
        payload: request
    }
}

export function addOrganization(organization, user) {
    const request = axios.post(`${ORGANIZATION_SERVER}`, { organization, user })
        .then(responce => responce.data);

    return {
        type: ADD_ORGANIZATION,
        payload: request
    }
}

export function updateOrganization(id, item) {
    const request = axios.put(`${ORGANIZATION_SERVER}/${id}`, item)
        .then(responce => responce.data);

    return {
        type: UPDATE_ORGANIZATION,
        payload: request
    }
}

export function addChildOrganization(parentId, data) {
    const request = axios.post(`${ORGANIZATION_SERVER}/child/${parentId}`, data)
        .then(responce => responce.data);

    return {
        type: ADD_CHILD_ORGANIZATION,
        payload: request
    }
}

export function updateChildOrganization(childId, data) {
    const request = axios.put(`${ORGANIZATION_SERVER}/child/${childId}`, data)
        .then(responce => responce.data);

    return {
        type: UPDATE_CHILD_ORGANIZATION,
        payload: request
    }
}

export function changeOwnership(id, data) {
    const request = axios.post(`${ORGANIZATION_SERVER}/transfer/${id}`, data)
        .then(responce => responce.data);

    return {
        type: CHANGE_OWNERSHIP,
        payload: request
    }
}

export function assignLicense( data) {
    const request = axios.post(`${ORGANIZATION_SERVER}/assignlicense`, data)
        .then(responce => responce.data);

    return {
        type: ASSIGN_LICENSE,
        payload: request
    }
}

export function removeOrganization(id) {
    const request = axios.delete(`${ORGANIZATION_SERVER}/${id}`)
        .then(responce => responce.data);

    return {
        type: REMOVE_ORGANIZATION,
        payload: request
    }
}

export function SelectOrganization(organization_id) {
    const request = axios.get(`${ORGANIZATION_SERVER}/select/${organization_id}`)
        .then(responce => responce.data);

    return {
        type: SELECT_ORGANIZATION,
        payload: request
    }
}

export function ClearSelection() {
    return {
        type: CLEAR_SELECTED,
        payload: {}
    }
}

//Invitations
export function SentInvite(email) {
    const request = axios.post(`${ORGANIZATION_SERVER}/sendinvite`, { email })
        .then(responce => responce.data);

    return {
        type: SENT_INVITE,
        payload: request
    }
}

export function RequestInvite(app_id) {
    const request = axios.post(`${ORGANIZATION_SERVER}/requestinvite`, { app_id })
        .then(responce => responce.data);

    return {
        type: REQUEST_INVITE,
        payload: request
    }
}

export function RespondInvite(status, organization_id) {
    const request = axios.post(`${ORGANIZATION_SERVER}/invite/responce`, { status, organization_id })
        .then(responce => responce.data);

    return {
        type: RESPOND_INVITE,
        payload: request
    }
}



export function retrieveLicense(data) {
    const request = axios.post(`${ORGANIZATION_SERVER}/license/retrieve`, data)
        .then(responce => responce.data);

    return {
        type: RETRIEVE_LICENSE,
        payload: request
    }
}