import { GET_BUSINESSUNITS, 
    ADD_BUSINESSUNITS, 
    UPDATE_BUSINESSUNITS, 
    REMOVE_BUSINESSUNIT, 
    GET_BUSINESSUNITS_BYID,
    GET_BUSINESSUNITS_BYUSER,
    GET_BUSINESSUNITS_BYENTITY } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_BUSINESSUNITS:
            return {...state, business_units: action.payload }
        case GET_BUSINESSUNITS_BYENTITY:
            return {...state, business_units: action.payload }
        case GET_BUSINESSUNITS_BYUSER:
            return {...state, business_units: action.payload }
        case GET_BUSINESSUNITS_BYID:
            return {...state, business_unit: action.payload }
        case ADD_BUSINESSUNITS:
            return {...state, success: action.payload.success }
        case UPDATE_BUSINESSUNITS:
            return {...state, success: action.payload.success }
        case REMOVE_BUSINESSUNIT:
            return {...state, remove_success: action.payload.success }
        default:
            return state;
    }
}