import React, { Component } from 'react';
import Stepper from '../UI/Stepper'
import HeaderText from '../UI/headerText';
import MediaIcons from '../UI/mediaIcons';
import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';
import FunctionalUnitsList from '../FunctionalUnits/FunctionalUnitsList';


class BusinessUnits extends Component {
    state = {
        item: {},
        // videoFile: '',
        // audioFile: '',
    }


    
    StepContent = () => {
        return (
            <div style={{
                    position: 'relative',
                    padding: '20px 120px 20px 120px',
                }}>
                
                <HeaderText name="Functional Units" size={40}/>
                
                <MediaIcons audioFile={this.state.audioFile} videoFile={this.state.videoFile}/>
                
                <FunctionalUnitsList /> 
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={GeneralBackground}/>
                <Stepper Step={4} getStepContent={() => this.StepContent()}/>
            </div>
        );
    }
}


export default BusinessUnits;