import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../Redux/Actions/plan.actions';
import { updateBillingPlan } from '../../Redux/Actions/billingProcess.actions';

import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import PrimaryButton from '../UI/Buttons/primaryButton';

import { Redirect, useHistory } from 'react-router-dom';
import MyCircularProgress from '../UI/ProgressBars/myCircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  subHeader: {
    marginBottom: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(1),
  },
}));

const PaymentDone = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector((state) => state.user.userData);

  return (
    <React.Fragment>
      <BackgroundItem path={GeneralBackground} />
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h5">
            Payment was successfull.
          </Typography>

          <Grid container spacing={5} direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.text}>Your payment was successful.</Typography>
            </Grid>
            {user ? (
              <Grid item xs={12}>
                <Typography className={classes.text}>Plan : {user.plan.name}</Typography>
                <Typography className={classes.text}>Status : {user.plan.active ? 'Active' : null}</Typography>
                <Typography className={classes.text}>Renewal Date : {user.plan.expires}</Typography>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {user.selectedOrganization ? (
                <PrimaryButton href="/welcome">Continue to Strategy Tracker</PrimaryButton>
              ) : (
                <PrimaryButton href="/entity_select">Continue to Strategy Tracker</PrimaryButton>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default PaymentDone;
