import React, { Component } from 'react'
import AddEditDialog from '../Areas/addEditDialog';

import AppList from '../UI/CustomFields/AppList';
import AddEditCSIssue from '../UI/CustomForms/AddEditCSIssue';
import LinkList from '../UI/CustomFields/linkList';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { connect } from 'react-redux';
import { getAreas } from '../../Redux/Actions/area_actions';

import IssueViewDialog from '../UI/Dialog/issueViewDialog';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    gridRoot: {},
    typo: {
        color: 'white',
        paddingTop: '2px'
    },
    dialogContent: {},
    dialogPaper: {},
});


class IssueCreator extends Component {
    state = {
        issueOpen: false,
        openAreaDialog: false,
        areaDialogAction: 'Add',
        open: false,
        typeDelete: '',
        deleteOpen: false,
        currentListitems: [],
        anticipatedListitems: [],
        areas: [],
        selectedArea: {},
        selectedIndex: 0,
        selectedType: "",
        selectedIssue: {},
        business_unit: ""
    }

    static getDerivedStateFromProps(props, state) {
        if (props.business_unit) {
            return {
                business_unit : props.business_unit ? props.business_unit : null
            };
          }      

          return null;
    }

    componentDidMount() {
        this.getAreas();
    }

    getAreas = () => {
        this.props.dispatch(getAreas()).then(response => {
            
            if (this.props.areas && this.props.areas.length > 0) {
                let newArray = [];
                this.props.areas.forEach((item, i) => {
                    if (!item.parent) {

                        if(this.props.area_type === 'functional'){
                            if(this.props.area_type === 'functional' && item.type === 'functional' && this.props.area_type === item.type){
                                newArray.push(item);
                            }
                        }
                        else if(this.props.area_type === 'corporate'){
                            if(this.props.area_type === 'corporate' && item.type === 'corporate' && this.props.area_type === item.type){
                                newArray.push(item);
                            }
                        }

                    }
                })

                this.setState({
                    areas: newArray,
                    selectedArea: newArray[0],
                    selectedIndex: 0
                });

                this.setIssues(this.state.selectedArea)
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
      if(prevProps.issues !== this.props.issues){
        this.setState({
            currentListitems: [],
            anticipatedListitems: [],
            open: false
        });
        this.setIssues(this.state.selectedArea);
      }
    }

    setAreaIssueCount = () => {

        if(this.state.areas && this.state.areas.length > 0){
            let newAreas = [ ...this.state.areas ];

            newAreas.forEach((area, index) => {
                let area_issue_count = 0;

                if (this.props.issues && this.props.issues.length !== 0) {
                    this.props.issues.forEach((issue, index) => {
                        if(issue.area._id === area._id && this.props.area_type === 'corporate' && issue.business_unit == null){
                            area_issue_count = area_issue_count + 1;
                        }
                        else if(issue.area._id === area._id && this.props.area_type === 'functional' && issue.business_unit != null){
                            area_issue_count = area_issue_count + 1;
                        }
                    })
                }

                newAreas[index].issue_count = area_issue_count;
            })

            this.setState({
                areas: newAreas
            })
            
        }
    }
    
    assignIssue = (area_type, issue, list) => {
        //Corporate Issue in Functional Area. This is a non editable item.
        if(issue.hide_in_functional == "No" && (area_type === 'functional' && issue.business_unit == null) || issue.isGroup){
            list.push({ "primary": issue.description, editable: false,  ...issue });
        }
        //Corporate Issue
        else if(area_type === 'corporate' && issue.business_unit == null){
            list.push({ "primary": issue.description, editable: true,  ...issue });
        }
        //Functional Issue
        else if(area_type === 'functional' && issue.business_unit != null){
            list.push({ "primary": issue.description, editable: true,  ...issue });
        }
        else{
        }
    }

    setIssues = (area) => {
        
        const currentListitems = [];
        const anticipatedListitems = [];

        

        if (this.props.issues && this.props.issues.length !== 0) {
            let issues = this.props.issues;

            issues ? issues.map((issue, index) => {
                if (issue.area._id === area._id) {
                    switch (issue.issue_type) {
                        case "current":
                                this.assignIssue(this.props.area_type,issue,currentListitems )
                            break;
                        case "anticipated":
                                this.assignIssue(this.props.area_type,issue,anticipatedListitems )
                            break;
                        default:
                    }       
                }
                else if(issue.kpi === area.name && this.props.area_type === 'functional' && issue.hide_in_functional !== 'Yes'){
                    switch (issue.issue_type) {
                        case "current":
                                this.assignIssue(this.props.area_type,issue,currentListitems )
                            break;
                        case "anticipated":
                                this.assignIssue(this.props.area_type,issue,anticipatedListitems )
                            break;
                        default:
                    }
                }
            }) : null

            

            this.setAreaIssueCount();

            this.setState({
                currentListitems,
                anticipatedListitems
            })

            
        }
    }

    onAreaChange = (item, selectedIndex) => {
        this.setState({
            selectedArea: item,
            selectedIndex
        })

        this.setIssues(item);
    }

    handleClickOpen = (type, issue = {}) => {
        this.setState({ open: true, selectedType: type, selectedIssue: issue });
    };

    handleClose = (element) => {
        this.repopulateLists();
    };

    repopulateLists = () => {
        this.props.rePopulateIssues();

        if (this.props.issues && this.props.issues.length !== 0) {
            this.setState({
                currentListitems: [],
                anticipatedListitems: [],
                open: false
            });
            this.setIssues(this.state.selectedArea);
        }
    }

    handleClickDeleteOpen = (issue) => {
        this.setState({ deleteOpen: true, selectedIssue: issue });
    };

    handleDeleteClose = () => {
        this.setState({ deleteOpen: false });
    };

    handleDelete = () => {
        this.props.onIssueDelete(this.state.selectedIssue._id);
                this.setState({ deleteOpen: false });
                this.repopulateLists();
    };

    handleTypeDeleteChange = event => {
        this.setState({ typeDelete: event.target.value });
    };

    handleAreaClose = () => {
        this.getAreas();
        
        this.setState({
            openAreaDialog: false
        })
        
    }

    handleAreaOpen = () => {
        this.setState({
            openAreaDialog: true
        })
    }

    handleView = (issue) => {
        this.setState({
            issueOpen: true,
            selectedIssue: issue
        })
    }

    handleIssueClose = () => {
        this.setState({
            issueOpen: false
        })
    }

    renderIssueBlocks = (classes) => (
        <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start" >
            <Grid item xs={3} >
                <LinkList header="Focus Areas" headerSize="37px" links={this.state.areas} areaSelected={this.state.selectedArea} linkSelected={this.state.selectedIndex} onSelect={(selectedItem, selectedIndex) => this.onAreaChange(selectedItem, selectedIndex)} height="263px" />
                
                {/* { this.props.area_type !== null && this.props.area_type !== 'functional' ? */}

                <Button
                    variant="contained"
                    onClick={() => this.handleAreaOpen("Add")}
                    style={{
                        backgroundColor: '#005773',
                        fontWeight: [500],
                        fontSize: '24px',
                        width: '185px',
                        marginTop: '20px'
                    }}>
                    <Typography className={classes.typo}>Manage Focus Areas</Typography>
                </Button>

                {/* : null } */}

                <AddEditDialog action={this.state.areaDialogAction} open={this.state.openAreaDialog} close={(close) => this.handleAreaClose(close)} areaType={this.props.area_type}/>
            </Grid>
            {this.state.selectedArea && this.state.selectedArea._id ?
                <Grid item xs={4} >

                    <div>
                        <AppList
                            isEditable={true}
                            listItems={this.state.currentListitems}
                            header="Current Issues"
                            headerSize="27px"
                            listHeight="250px"
                            onEdit={(selectedIssue) => this.handleClickOpen("current", selectedIssue)}
                            onDelete={(selectedIssue) => this.handleClickDeleteOpen(selectedIssue)}
                            onView={(selectedIssue) => this.handleView(selectedIssue)}
                        />

                        <Button
                            variant="contained"
                            onClick={() => this.handleClickOpen("current")}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '24px',
                                width: '170px',
                                marginTop: '20px'
                            }}>
                            <Typography className={classes.typo}>Add Current Issue</Typography>
                        </Button>
                    </div>

                </Grid>
                : null
            }
            {this.state.selectedArea && this.state.selectedArea._id ?
                <Grid item xs={4} >

                    <div>
                        <AppList
                            isEditable={true}
                            listItems={this.state.anticipatedListitems}
                            header="Anticipated Issues"
                            headerSize="27px"
                            listHeight="250px"
                            onEdit={(selectedIssue) => this.handleClickOpen("anticipated", selectedIssue)}
                            onDelete={(selectedIssue) => this.handleClickDeleteOpen(selectedIssue)}
                        />
                        <Button
                            variant="contained"
                            onClick={() => this.handleClickOpen("anticipated")}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '24px',
                                width: '200px',
                                marginTop: '20px'
                            }}>
                            <Typography className={classes.typo}>Add Anticipated Issue</Typography>
                        </Button>
                    </div>

                </Grid>
                : null
            }
            {this.state.selectedArea && this.state.selectedArea._id ?
                null
                :
                <Grid item xs={8} >
                    <div>
                        You need to select a Area before you can add issues.
                        </div>
                </Grid>
            }
        </Grid>
    )



    render() {
        const { classes } = this.props;

        return (
            <div>
                {this.renderIssueBlocks(classes)}

                {this.state.selectedArea && this.state.selectedArea ?
                    <div>
                        <Dialog
                            fullWidth={true}
                            maxWidth='md'
                            open={this.state.open}
                            onClose={this.handleClose}
                            TransitionComponent={Transition}
                        >
                            <DialogContent >
                                <AddEditCSIssue
                                    closeDialog={(element) => this.handleClose(element)}
                                    selectedArea={this.state.selectedArea}
                                    selectedType={this.state.selectedType}
                                    selectedIssue={this.state.selectedIssue}
                                    areas={this.props.areas}
                                    business_unit={this.props.business_unit}
                                    creatorType={this.props.creatorType}
                                    assignedUsersMax={this.props.assignedUsersMax}
                                    strategyType={this.props.strategyType}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.deleteOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {"Confirm Delete"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    <strong>Are you sure you want to delete the issue with description:</strong>
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-slide-description">

                                    {this.state.selectedIssue.description}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="txtDelete"
                                    label="Type DELETE to confirm"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleTypeDeleteChange}
                                    />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDeleteClose} color="primary">
                                    Cancel
                            </Button>
                                {this.state.typeDelete.toLowerCase() === 'delete' ? 
                                <Button onClick={this.handleDelete} color="secondary">
                                    Confirm Delete
                                </Button>
                                :null}
                            </DialogActions>
                        </Dialog>
                    
                        <IssueViewDialog open={this.state.issueOpen} onClose={this.handleIssueClose} issue={ this.state.selectedIssue } ></IssueViewDialog>
                    </div>
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        areas: state.areas.areas,
        user: state.user,
        issues: state.strategy_issues.issues
    }
}

export default connect(mapStateToProps)(withStyles(styles)(IssueCreator));
