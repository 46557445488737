import React, { Component } from 'react';
import Stepper from '../../UI/GroupStepper'
import HeaderText from '../../UI/headerText';
import MediaIcons from '../../UI/mediaIcons';
import BackgroundItem from '../../UI/backgroundItem';
import GeneralBackground from '../../../Resources/images/backgrounds/background_pages.png';

import KeyObjectivesDrawer from '../../Partials/KeyObjectivesDrawer';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { GetAllCorporateIssues, removeIssue } from '../../../Redux/Actions/issues_actions';

import IssueCreator from '../../Partials/IssueCreator';

const styles = theme => ({
    gridRoot: {
    },
    typo: {
        color: 'white',
        paddingTop: '2px'
    },
    dialogContent: {

    },
    dialogPaper: {

    }
});

class StrategyIssues extends Component {
    state = {
        // videoFile: '123',
        // audioFile: '123',
    }

    componentDidMount() {
        this.props.dispatch(GetAllCorporateIssues()).then(response => {});
    }

   
    repopulateLists = () => {
        this.props.dispatch(GetAllCorporateIssues()).then(response => {
           
        });
    }

    handleIssueDelete = (issue_id) => {
        this.props.dispatch(removeIssue(issue_id)).then(response => {
            if(this.props.issue_remove_success){
                this.repopulateLists();
            }
            return "There was a problem deleting the issue.";
        });
    };

    StepContent = () => {
        return (
            <div style={{
                position: 'relative',
                padding: '20px 80px 20px 80px',
            }}>
                <KeyObjectivesDrawer />
                <Grid container spacing={16} direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} >
                        <HeaderText name="Group Strategy" size={40} />
                        
                        <MediaIcons audioFile={this.state.audioFile} videoFile={this.state.videoFile} />
                    </Grid>
                </Grid>

                {
                    this.props.issues ? 
                        this.props.issues.length > 0 && (this.props.user.userData.selectedOrganization._id === this.props.issues[0].organization) ?
                        <IssueCreator 
                            onIssueDelete={(issue_id)=>this.handleIssueDelete(issue_id)} 
                            rePopulateIssues={() => this.repopulateLists()} 
                            assignedUsersMax={20}
                            creatorType='Group Strategy'
                            area_type='corporate'
                            strategyType='group'/>
                        :
                        <IssueCreator 
                            onIssueDelete={(issue_id)=>this.handleIssueDelete(issue_id)} 
                            rePopulateIssues={() => this.repopulateLists()} 
                            assignedUsersMax={20}
                            creatorType='Group Strategy'
                            area_type='corporate'
                            strategyType='group'/>
                    :null
                }
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={GeneralBackground} />
                <Stepper Step={7} getStepContent={() => this.StepContent()} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        issues: state.strategy_issues.issues,
        areas: state.areas.areas,
        issue_remove_success: state.strategy_issues.remove_success,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(StrategyIssues));
