import React, { Component } from 'react';
import Stepper from '../UI/Stepper'
import HeaderText from '../UI/headerText';
import MediaIcons from '../UI/mediaIcons';
import BackgroundItem from '../UI/backgroundItem';

import IntroBackground from '../../Resources/images/backgrounds/background_introduction.png'

import { connect } from 'react-redux';
import { getOrganizationalData } from '../../Redux/Actions/organizational_values_actions';
import Media from '../Partials/Media';

class Introduction extends Component {
    state = {
        // videoFile: 'https://www.youtube.com/watch?v=ysz5S6PUM-U',
        // audioFile: 'https://api.soundcloud.com/tracks/18002254&color=%236399ab&auto_play=true&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true',
    }

    componentDidMount() {
        this.props.dispatch(getOrganizationalData()).then(responce => {
        })
    }

    StepContent = () => {
        return (
            <div style={{
                    height: '470px',
                    padding: '120px 120px 20px 120px'
                }}>

                <div className="intro_header" style={{
                    position:'relative',
                    //left: '100px',
                    paddingTop: '100px'
                }}>
                    
                    <HeaderText name="Introduction" size={40}/>

                </div>

                <div className="center">
                   <p>Welcome to <b>IQFIN STRATEGY TRACKER</b></p>

                   <p>This <i>APPLICATION</i> enables business Owners and Leaders to formulate, execute, evaluate and adapt their business strategy in near real-time.</p>

                   <Media />
                </div>

                
            </div>         
        )
    };

    updateBUName = (BU_name) => {
        console.log(BU_name);
    }

    render() {
        return (
            <div>
                <BackgroundItem path={IntroBackground}/>
                <Stepper Step={1} getStepContent={() => this.StepContent()} updateBUContent={(BU_name) => this.updateBUName(BU_name)} btnNextText="Next"/>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    organizational_data : state.organizational_values.data
})


export default connect(mapStateToProps)(Introduction);
