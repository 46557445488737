import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRoles } from '../../Redux/Actions/role_actions';

import BackendBackground from '../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';

import CreateRole from './Forms/createRole';
import EditRole from './Forms/editRole';

//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Permission from '../../HOC/Permission';



const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    }
});


class Roles extends Component {
    state = {
        createRoleDialogOpen: false,
        dialogType: "Create",
        editRoleId: null
    }

    componentDidMount() {
        this.props.dispatch(getRoles());
    }

    openRoleCreate = (type, roleId) => {
        this.setState({
            createRoleDialogOpen: true,
            dialogType: type,
            editRoleId: roleId
        })
    }

    handleDialogClose = () => {
        this.setState({
            createRoleDialogOpen: false
        })
    }

    handleRoleCreated = () => {
        this.handleDialogClose();
    }

    handleRoleUpdated = () => {
        this.handleDialogClose();
    }

    roleCreateDialog = () => {
        //const { classes } = this.props;
        return (
            <Dialog
                open={this.state.createRoleDialogOpen}
                onClose={this.handleDialogClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth='lg'
            >
                <DialogTitle id="form-dialog-title">Create a new Role</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add details about the role below.
                </DialogContentText>
                { this.state.dialogType !== "Edit" ?
                    <CreateRole
                        cancel={() => this.handleDialogClose()}
                        roleAdded={() => this.handleRoleCreated()} 
                        type={this.state.dialogType}
                        roleId={this.state.editRoleId}/>

                        :
                    <EditRole
                        cancel={() => this.handleDialogClose()}
                        updated={() => this.handleRoleUpdated()} 
                        type={this.state.dialogType}
                        roleId={this.state.editRoleId}/>
                    }

                </DialogContent>
            </Dialog>
        )
    }

    renderRoles = () => {
        const { classes } = this.props;

        if (this.props.roles && !this.props.roles.error) {
            return (
                this.props.roles.map((role, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            {role.name}
                        </TableCell>
                        <TableCell>{role.desc}</TableCell>
                        <TableCell>
                        <Permission action="roles" permission="Update" user={this.props.user}>
                            <Button className={classes.button} variant="contained" color="secondary" onClick={() => this.openRoleCreate("Edit", role._id)}>Edit</Button>
                        </Permission>
                        <Permission action="roles" permission="Delete" user={this.props.user}>
                            <Button className={classes.button} variant="contained" >Remove</Button>
                            </Permission>
                        </TableCell>
                    </TableRow>
                    )
                )
            )
        }
        else {
            return (
                <TableRow>
                    <TableCell scope="row" colSpan="8">Loading</TableCell>
                </TableRow>
            )
        }

    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackendBackground />
                <Permission action="roles" permission="Read" user={this.props.user}>
                    <Paper className={classes.root}>

                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.renderRoles() }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell >
                                        <Permission action="roles" permission="Create" user={this.props.user}>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.openRoleCreate()}>Add New Role</Button>
                                        </Permission>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>

                        {this.roleCreateDialog()}
                    </Paper>
                </Permission>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    roles: state.role.roles,
    user: state.user.userData
})


export default connect(mapStateToProps)(withStyles(styles)(Roles))