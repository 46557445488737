import axios from 'axios';
import { GET_ROLES,
    GET_ROLE,
        GET_ACTIONS,
        CREATE_ROLE,
        UPDATE_ROLE,
        //REMOVE_ROLE 
    } from './types'
import { ROLE_SERVER } from '../../Components/Utils/Misc'


export function getRoles(){
    const request = axios.get(`${ROLE_SERVER}`)
        .then(responce => responce.data);
        
        return {
            type: GET_ROLES,
            payload: request
        }
}

export function getRole(id){
    const request = axios.get(`${ROLE_SERVER}/${id}`)
        .then(responce => responce.data);
        
        return {
            type: GET_ROLE,
            payload: request
        }
}

export function getActions(){
    const request = axios.get(`${ROLE_SERVER}/actions`)
        .then(responce => responce.data);
        
        return {
            type: GET_ACTIONS,
            payload: request
        }
}

export function createRole(data){
    const request = axios.post(`${ROLE_SERVER}/`,data)
        .then(responce => responce.data);
        
        return {
            type: CREATE_ROLE,
            payload: request
        }
}

export function updateRole(data){
    const request = axios.put(`${ROLE_SERVER}/`,data)
        .then(responce => responce.data);
        
        return {
            type: UPDATE_ROLE,
            payload: request
        }
}