import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player';

import { makeStyles } from '@material-ui/core/styles'
import { Button, Fab, IconButton, Icon, Toolbar, AppBar, Slide } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AudioIcon from '../../Resources/images/icons/icon_play.png';
import VideoIcon from '../../Resources/images/icons/icon_video.png';

const useStyles = makeStyles(theme => ({
    play_button: {
        backgroundColor: '#0a1c2d',
        margin: theme.spacing.unit,
        background: `url(${AudioIcon}) no-repeat`,
        backgroundPosition: '19px 12px',
        '&:hover': {
            backgroundColor: '#0a1c2d'
        }
    },
    video_button: {
        backgroundColor: '#5683A5',
        margin: theme.spacing.unit,
        background: `url(${VideoIcon}) no-repeat`,
        backgroundPosition: '7px 15px',
        '&:hover': {
            backgroundColor: '#5683A5'
        }
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    dialogContent: {
        backgroundColor: 'black',
        height: '100%'
    },
    dialogPaper: {
        paddingTop: '64px',
        height: '500px'
    }
}))

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function Media(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [openMediaDialog, setOpenMediaDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [media, setMedia] = useState([
        {
            name : "Introduction To Strategy",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+1.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+1_converted.mp3"
        },
        {
            name : "Prepare to Strategise - Roles and Profiles",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+2.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+2_converted.mp3"
        },
        {
            name : "Prepare to Strategise - Strategic Competence",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+3.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+3_converted.mp3"
        },
        {
            name : "Prepare to Strategise - Strategic Framework",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+4.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+4_converted.mp3"
        },
        {
            name : "Foundation of Strategic Planning",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+5.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+5_converted.mp3"
        },
        {
            name : "Setting Strategic Objectives",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+6.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+6_converted.mp3"
        },
        {
            name : "Corporate Strategy Formulation",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+7.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+7_converted.mp3"
        },
        {
            name : "Functional Strategy Formulation within each Business Unit",
            video : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Video/IQfin+Video+8.mov",
            audio : "https://s3.eu-west-2.amazonaws.com/s3.iqfin.co.za/Strategy+Tracker/Files/Audio/IQfin+Video+8_converted.mp3"
        }
    ])

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () =>
    {
        setOpen(true);
    }

    const handleMediaDialogClose = () => {
        setSelectedItem();
        setOpenMediaDialog(false);
    }

    const handleMediaDialogOpen = (item, type) => {
        setSelectedItem({item, type});
        setOpenMediaDialog(true);
    }

    const MediaDialog = () => (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            open={openMediaDialog}
            onClose={handleMediaDialogClose}
            TransitionComponent={Transition}
            classes={{ paper: classes.dialogPaper }}
        >

            <DialogContent className={classes.dialogContent}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleMediaDialogClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <ReactPlayer
                    url={ selectedItem.type == 'video' ? selectedItem.item.video : selectedItem.item.audio}
                    playing
                    controls
                    width='100%'
                    height='100%'/>
            </DialogContent>
        </Dialog>
    )

    return (
        <div>
        <Button variant='contained' onClick={() => handleOpen()} >Training Videos</Button>
        <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={handleClose}>
            <DialogTitle>Audio and Video</DialogTitle>
            <DialogContent>
                 <Table>
                     <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Play Audio</TableCell>
                            <TableCell>Play Video</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {media.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                { item.audio ?
                                    <Fab classes={{ root: classes.play_button }} onClick={() => handleMediaDialogOpen(item, "audio")}>
                                        <Icon></Icon>
                                    </Fab>
                                    : null
                                }             
                                </TableCell>
                                <TableCell>
                                { item.video ?
                                    <Fab classes={{ root: classes.video_button }} onClick={() => handleMediaDialogOpen(item, "video")}>
                                        <Icon></Icon>
                                    </Fab>
                                    : null
                                } 
                                </TableCell>
                            </TableRow>
                        ))}
                     </TableBody>
                 </Table>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose} >Close</Button>
            </DialogActions>
        </Dialog>

        { selectedItem ? 
            <div>{ MediaDialog() } </div>
        : null }
        </div>
    )
}

export default Media
