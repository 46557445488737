import React, { Component } from 'react';
import ScrollArea from 'react-scrollbar';

import FormField from '../../Utils/Forms/FormField';
import {  update, generateData, clearFields, isFormValid } from '../../Utils/Forms/FormActions';
import { reverseArray } from '../misc';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Permission from '../../../HOC/Permission';
import { connect } from 'react-redux';
import { getAllOrganizationalValues, createOrganizationalValue, updateOrganizationalValue, removeOrganizationalValue, getByIdAllOrganizationalValues } from '../../../Redux/Actions/organizational_values_actions';

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    rootPaper1: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing.unit * 4,
        paddingTop: theme.spacing.unit * 2,
        position: 'relative'
    },
    rootPaper2: {
        ...theme.mixins.gutters(),
        padding: '0px !important',
        paddingBottom: theme.spacing.unit * 2,
        height: '320px',
        position: 'relative',
        overflowWrap: 'break-word'
    },
    PaperItem: {
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
        backgroundColor: "#e6e6e6",
        color: "#404040",
        fontSize: '12px',
        marginRight: '35px'
    },
    button: {
        backgroundColor: '#005773',
        margin: theme.spacing.unit,
        backgroundPosition: '19px 12px',
        '&:hover': {
            backgroundColor: '#005773'
        },
        position: 'absolute',
        bottom: theme.spacing.unit * -3.5,
        right: theme.spacing.unit * -3.5,
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
});

class OrganizationalValuesList extends Component {
    state = {
        progress:{
            deleting: false,
        },
        selectedOrganizationalValue: {},
        addDialogOpen: false,
        listItems: [],
        formType: 'Add',
        formError: false,
        formSuccess: '',
        loadingAdd: false,
        formData: {
            name: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'organizational_value',
                    type: 'editableList_Input',
                    label: 'Organizational Value',
                    helperText: 'ADD a NEW organizational value',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllOrganizationalValues()).then(response => {
            if (this.props.organizational_values) {
                this.setState({
                    listItems: this.props.organizational_values
                });
            }
        });
    }

    repopulateOrganizationalValues = () => {
        
        this.props.dispatch(getAllOrganizationalValues()).then(response => {
            if (this.props.organizational_values) {
                this.setState({
                    listItems: this.props.organizational_values
                })
            }
        });
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'organizational_value');

        this.setState({
            formError: false,
            formData: newFormData
        })
    }


    handleAdd = () => {
        this.setState({
            loadingAdd: true
        })
        

        let dataToSubmit = generateData(this.state.formData, 'organizational_value');
        let formIsValid = isFormValid(this.state.formData, 'organizational_value');

        if (formIsValid) {
            if(this.state.formType === "Add"){
                this.props.dispatch(createOrganizationalValue(dataToSubmit)).then(responce => {
                    if (this.props.success) {                     
                        clearFields(this.state.formData, 'organizational_value');
                        this.handleAddDialogClose();

                        this.repopulateOrganizationalValues();

                        this.setState({
                            loadingAdd: false
                        })
                    }
                })
            }
            else{
                this.props.dispatch(updateOrganizationalValue(this.state.selectedOrganizationalValue._id, dataToSubmit)).then(responce => {
                    if (this.props.success) {
                        clearFields(this.state.formData, 'organizational_value');
                        this.handleAddDialogClose();

                        this.repopulateOrganizationalValues();

                        this.setState({
                            loadingAdd: false
                        })
                    }
                })
            }

            
        }
        else {
            this.setState({
                formError: true,
                loadingAdd: false
            })
        }
    }

    handleEdit = (item) => {
        this.props.dispatch(getByIdAllOrganizationalValues(item._id)).then(responce => {
            let newFormData = { ...this.state.formData };

            newFormData["name"].value = this.props.organizational_value.name;

            this.setState({
                selectedOrganizationalValue: item,
                formData: newFormData,
                formType: 'Edit',
                addDialogOpen : true
            })

        })
    }

    handleDelete = (item) => {

        let newProgress = { ...this.state.progress };
        newProgress.deleting = true;

        this.setState({
            progress: newProgress
        })

        this.props.dispatch(removeOrganizationalValue(item._id)).then(response => {
            if (this.props.success) {
                newProgress.deleting = false;

                this.setState({
                    progress: newProgress
                })
                this.repopulateOrganizationalValues();
            }
        });
    }

    openAdd = () => {
        this.setState({
            selectedOrganizationalValue: {},
            formType: 'Add',
            addDialogOpen: true
        })
    }

    handleAddDialogClose = () => {
        this.setState({
            addDialogOpen: false
        })
    }

    generateList = () => {
        const { classes } = this.props;

        return (
            <div>
            <Paper className={classes.rootPaper2} elevation={5}>
                <ScrollArea
                    speed={0.8}
                    horizontal={false}
                    smoothScrolling={true}
                    style={{
                        height: '320px'
                    }}
                >

                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Organizational Value</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.listItems ? reverseArray(this.state.listItems).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell align="right">
                                            <FontAwesomeIcon icon={faEdit} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}}  onClick={() => this.handleEdit(item)}/>
                                            <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#CC171D', paddingRight: '5px', cursor: 'pointer' }} onClick={() => this.handleDelete(item)} />
                                    </TableCell>
                                </TableRow>
                            )) : <div>The list is empty. </div>}
                        </TableBody>
                    </Table>
                </ScrollArea>
            </Paper>
            <Permission action="organization.create" user={this.props.user} hideObject={true}>
                <Button variant="contained" onClick={() => this.openAdd()} style={{ marginTop: '10px'}}>Add Organizational Value</Button>
            </Permission>
            </div>
        )
    }

    addDialog = () => {
        //const { classes } = this.props;
        return (
        <Dialog
        open={this.state.addDialogOpen}
        //onClose={this.handleAddDialogClose}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Add Orgnaizational Value</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill in your organizational value.
                </DialogContentText>
                <FormField
                    id={'name'}
                    formData={this.state.formData.name}
                    change={(element) => this.updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleAddDialogClose} color="primary">
                Cancel
                </Button>
                <Button onClick={this.handleAdd} color="primary">
                { !this.state.loadingAdd ?
                    this.state.formType === "Add" ? 
                        <div>Add</div>
                        :
                        <div>Update</div>
                    : 
                    <CircularProgress size={20}/>
                }
                </Button>
            </DialogActions>
        </Dialog>            
        )
    }

    render() {
        //const { classes } = this.props;
        return (
            <div>
                {this.generateList()}
                {this.addDialog()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organizational_values: state.organizational_values.organizational_values,
        organizational_value: state.organizational_values.organizational_value,
        success: state.organizational_values.success,
        user: state.user.userData
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OrganizationalValuesList));
