import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganization } from '../../../Redux/Actions/organization_actions';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHead, TableBody, TableCell, TableFooter, TableRow, Paper, Container, Typography } from '@material-ui/core';
import moment from 'moment';
import StatusIndicator from './partials/statusIndicator';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  tablelRoot: {
    width: '100%',
    borderSpacing: '10px',
    borderCollapse: 'separate',
  },
  mainCell: {
    backgroundColor: '#6399AB',
    borderRadius: '4px',
    borderBottom: 'none',
  },
  cell: {
    backgroundColor: '#005773',
    color: 'white',
    borderRadius: '4px',
    width: '14.25%',
    height: '113px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 400,
    borderBottom: 'none',
  },
  tableHeader: {
    backgroundColor: '#005773',
    color: 'white',
    padding: '20px',
    fontWeight: 400,
    fontSize: 'x-large',
    textTransform: 'uppercase',
  },
  issueTable: {
    width: '100%',
    borderSpacing: '10px',
    color: 'white',
    fontWeight: 400,
    fontSize: '12px',
  },
  pdf: {
    padding: '10px 25px 25px 25px',
  },
}));

const Issues = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const organization = useSelector((state) => state.organization.organization);

  useEffect(() => {
    dispatch(getOrganization(props.entity));
  }, []);

  const renderIssueTable = (issue) => (
    <Table className={classes.issueTable}>
      <TableBody>
        <TableRow>
          <TableCell style={{ width: '14.25%', borderBottom: 'none', color: 'white' }}>
            <Typography>{issue.area.name}</Typography>
          </TableCell>
          <TableCell style={{ width: '14.25%', borderBottom: 'none', color: 'white' }}>
            <Typography>{issue.description}</Typography>
          </TableCell>
          <TableCell style={{ width: '14.25%', borderBottom: 'none', color: 'white' }}>
            <Typography>{issue.key_objective ? issue.key_objective.objective : null}</Typography>
          </TableCell>
          <TableCell style={{ width: '14.25%', borderBottom: 'none', color: 'white' }}>
            <Typography>{issue.action}</Typography>
          </TableCell>
          {props.includeFunctionalUnit && (
            <TableCell style={{ width: '14.25%', borderBottom: 'none', color: 'white', textAlign: 'center' }}>
              <Typography>{issue.business_unit.name}</Typography>
            </TableCell>
          )}
          <TableCell style={{ width: '14.25%', textAlign: 'center', borderBottom: 'none', color: 'white' }}>
            {issue.assigned_users.length > 0 ? issue.assigned_users.map((user) => <Typography>{user.name + ' ' + user.lastname}</Typography>) : null}
          </TableCell>
          <TableCell style={{ width: '14.25%', textAlign: 'center', borderBottom: 'none', color: 'white' }}>
            <Typography>{moment(issue.due_date).format('DD/MM/YYYY')}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const renderStatus = (issue) => {
    switch (issue.status.name) {
      case 'Not Started':
        return <StatusIndicator color="grey" includeBlanks />;
      case 'In Progress':
        return <StatusIndicator color="yellow" includeBlanks />;
      case 'Pending Approval':
        return <StatusIndicator color="yellow" includeBlanks />;
      case 'Done':
        return <StatusIndicator color="green" includeBlanks />;
      case 'Block':
        return <StatusIndicator color="red" includeBlanks />;
      case 'Not Done':
        return <StatusIndicator color="red" includeBlanks />;
    }
  };

  return (
    <Container>
      <div id="pdfdiv" className={classes.pdf}>
        <h2>
          {props.heading} - {organization?.name}
        </h2>

        <Table className={classes.tablelRoot}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={8} className={classes.tableHeader}>
                <Typography>{props.heading}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>
                <Typography>Focus Area</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>Description</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>Key Objective</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>Action(s)</Typography>
              </TableCell>
              {props.includeFunctionalUnit && (
                <TableCell className={classes.cell}>
                  <Typography>Unit</Typography>
                </TableCell>
              )}
              <TableCell className={classes.cell}>
                <Typography>Person(s)</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>Due Date</Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.issues
              ? props.issues.map((issue) => (
                  <TableRow>
                    <TableCell colSpan={props.includeFunctionalUnit ? 7 : 6} className={classes.mainCell}>
                      {renderIssueTable(issue)}
                    </TableCell>
                    <TableCell className={classes.mainCell} style={{ textAlign: 'center' }}>
                      {renderStatus(issue)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={4}>
            {moment().format('MM/DD/YYYY')}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            IQFin Strategy Tracker
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            Generated by {user.fullname}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Issues;
