import { Button, makeStyles, withTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 1, 1),
        backgroundColor: '#005773',
        color: 'white',
        fontWeight: [500],
        '&:hover': {
            color: '#005773',
        },
        width: '200px'
    }
}))

const StepperButton = ({ children, ...props}) => {
    const classes = useStyles();
    
    return (
        <Button variant="contained" className={classes.root} { ...props }>{children}</Button>
    );
};

export default StepperButton;