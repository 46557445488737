import {
  LOGIN_USER,
  CREATE_USER,
  UPDATE_USER,
  REGISTER_USER,
  COMPLETE_USER_SUBSCRIPTION,
  UPDATE_USER_SUBSCRIPTION,
  AUTH_USER,
  LOGOUT_USER,
  RESEND_EMAIL_VERIFICATION,
  EMAIL_VERIFICATION,
  GET_USER,
  GET_ALL_USERS,
  GET_BYORGANIZATION,
  PASSWORD_RESET,
  PASSWORD_CHANGE,
  ENABLE_USER,
  REMOVE_USER,
  DISABLE_USER,
  REINVITE_USER,
  UPDATE_USER_ROLES,
  UPDATE_USER_PASSWORD,
  GET_USER_LOGINS,
  GET_USER_BILLING,
  UPDATE_USER_BILLING,
  ADD_TO_ORGANIZATION,
  REMOVE_FROM_ORGANIZATION,
} from '../Actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_USER:
      return { ...state, get_user: action.payload };
    case GET_ALL_USERS:
      return { ...state, all_users: action.payload };
    case CREATE_USER:
      return { ...state, success: action.payload.success };
    case UPDATE_USER:
      return { ...state, success: action.payload.success };
    case UPDATE_USER_ROLES:
      return { ...state, success: action.payload.success };
    case UPDATE_USER_PASSWORD:
      return { ...state, success: action.payload.success };
    case ENABLE_USER:
      return { ...state, success: action.payload.success };
    case DISABLE_USER:
      return { ...state, success: action.payload.success };
    case GET_BYORGANIZATION:
      return { ...state, users: action.payload };
    case REGISTER_USER:
      return { ...state, register: action.payload, registrationSuccess: action.payload.registrationSuccess };
    case COMPLETE_USER_SUBSCRIPTION:
      return { ...state, register: action.payload, registrationSuccess: action.payload.registrationSuccess };
    case UPDATE_USER_SUBSCRIPTION:
      return { ...state, update_success: action.payload.success };
    case RESEND_EMAIL_VERIFICATION:
      return { ...state, verificationSent: action.payload.success };
    case LOGIN_USER:
      return { ...state, loginSuccess: action.payload };
    case AUTH_USER:
      return { ...state, userData: action.payload };
    case EMAIL_VERIFICATION:
      return { ...state, verified: action.payload.verified };
    case PASSWORD_RESET:
      return { ...state, success: action.payload.success };
    case PASSWORD_CHANGE:
      return { ...state, password_set: action.payload.password_set };
    case LOGOUT_USER:
      return { ...state };
    case REMOVE_USER:
      return { ...state, removeSuccess: action.payload.removeSuccess };
    case REINVITE_USER:
      return { ...state, reinviteSuccess: action.payload.success };
    case GET_USER_LOGINS:
      return { ...state, user_logins: action.payload };
    case GET_USER_BILLING:
      return { ...state, billing: action.payload.billing, success: action.payload.success };
    case UPDATE_USER_BILLING:
      return { ...state, billing: action.payload.billing, success: action.payload.success };
    case ADD_TO_ORGANIZATION:
      return { ...state, success: action.payload.success };
    case REMOVE_FROM_ORGANIZATION:
      return { ...state, success: action.payload.success };
    default:
      return state;
  }
}
