import React from 'react'

import GeneralBackgroundRight from '../../Resources/images/backgrounds/backgroundpattern_right.png';
import GeneralBackgroundLeft from '../../Resources/images/backgrounds/backgroundpattern_left.png';

import { BackgroundBottomItem } from './backgroundItem';

function backendBackground() {
    return (
        <div>
            <BackgroundBottomItem path={GeneralBackgroundRight} otherStyles={{
                width: '750px',
                height: '519px',
                bottom: '0px'
            }}/>

            <BackgroundBottomItem path={GeneralBackgroundLeft} otherStyles={{
                width: '750px',
                height: '519px',
                bottom: '0px',
                right: '0px'
            }}/>
        </div>
    )
}

export default backendBackground
