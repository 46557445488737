import axios from 'axios';
import { SUBSCRIPTION_VALIDATION,
         GET_USER_SUBSCROPTIONS,
         GET_ALLUSER_SUBSCROPTIONS,
         MIGRATE_SUBSCROPTIONS
         } from './types'
import { SUBSCRIPTION_SERVER } from '../../Components/Utils/Misc'


export function validateSubscription(hash){
    const request = axios.get(`${SUBSCRIPTION_SERVER}/validate/${hash}`)
        .then(responce => responce.data);
        
        return {
            type: SUBSCRIPTION_VALIDATION,
            payload: request
        }
}

export function getUserSubscriptions(user_id){
    const request = axios.get(`${SUBSCRIPTION_SERVER}/subscriptions/${user_id}`)
        .then(responce => responce.data);
        
        return {
            type: GET_USER_SUBSCROPTIONS,
            payload: request
        }
}

export function getAllSubscriptions(user_id){
    const request = axios.get(`${SUBSCRIPTION_SERVER}/subscriptions/all`)
        .then(responce => responce.data);
        
        return {
            type: GET_ALLUSER_SUBSCROPTIONS,
            payload: request
        }
}

export function migrateUser(user){
    const request = axios.put(`${SUBSCRIPTION_SERVER}/subscriptions/migrate`, { user })
        .then(responce => responce.data);
        
        return {
            type: MIGRATE_SUBSCROPTIONS,
            payload: request
        }
}