import React from 'react';

const BackgroundItem = (props) => {
    return (
        <div style={{
            position: 'absolute',
            background: `url(${ props.path }) no-repeat`,
            height: '800px',
            width: '1200px'
        }}>
        </div>
    );
}

export default BackgroundItem;

export const BackgroundBottomItem = (props) => {
    return (
        <div style={{
            position: 'fixed',
            background: `url(${ props.path }) no-repeat`,
            ...props.otherStyles
        }}>
        </div>
    );
}

