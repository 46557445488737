import React, { Component } from 'react'

import { connect } from 'react-redux';
import { getAllBusinessUnitsByUser } from '../../../Redux/Actions/business_units_actions';

import FormField from '../../Utils/Forms/FormField';
import { validate, toDropdown } from '../../UI/misc';

import FSStepper from '../../UI/FSStepper'
import HeaderText from '../../UI/headerText';
import MediaIcons from '../../UI/mediaIcons';
import BackgroundItem from '../../UI/backgroundItem';

import IntroBackground from '../../../Resources/images/backgrounds/background_introduction.png'

class FunctionalStrategyBusinessUnits extends Component {

    state = {
     formData: {
            functional_unit: {
                element: 'select',
                value: '',
                config: {
                    name: 'functional_unit',
                    type: 'select',
                    label: 'Select Functional Unit',
                    options: []
                },
                validation: {
                    required: true,
                },
                valid: true,
                validationMessage: '',
                showLabel: true 
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllBusinessUnitsByUser()).then(responce => {
                this.setFunctionalUnitsOptions(this.props.functional_units);
            });
        }

    setFunctionalUnitsOptions = (sub_bu_options) => {
        const newFormData = { ...this.state.formData }
        const newElement = { ...newFormData["functional_unit"] }

        newElement.value = '';
        newElement.config.options = [];

        if(newElement.config.options.length === 0){
            newElement.config.options = sub_bu_options ? toDropdown(sub_bu_options,'_id','name') : [];
            newFormData["functional_unit"] = newElement;
            this.setState({
                formData: newFormData
            });
        }
    }

    updateForm(element) {
        const newFormData = { ...this.state.formData }
        const newElement = { ...newFormData[element.id] }

        newElement.value = element.event.target.value;

        if(element.id === "functional_unit"){
            //this.setRevenueStreams(newElement.value);
        }

        let valiData = validate(newElement);
        newElement.valid = valiData[0];
        newElement.validationMessage = valiData[1];

        newFormData[element.id] = newElement;

        this.setState({
            formError: false,
            formData: newFormData
        });
    }

    StepContent = () => {

        return (
            <div style={{
                height: '470px',
                padding: '110px 120px 20px 120px'
            }}>

                <div className="intro_header" style={{
                    position: 'relative',
                    left: '120px',
                    paddingTop: '50px'
                }}>

                    <HeaderText name="Select Functional Unit" size={50}/>
                   
                </div>

                <div className="center">

                        <FormField
                            id={'functional_unit'}
                            formData={this.state.formData.functional_unit}
                            change={(element) => this.updateForm(element)}
                            style={{
                                minWidth: "150px"
                            }}
                        />
                </div>
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={IntroBackground} />
                <FSStepper Step={1} getStepContent={() => this.StepContent()}
                    isbusinessUnitSelect = {true}
                    selectedBusinessUnit={{ 
                        selectedBusinessUnit: this.state.formData["functional_unit"].value, 
                    }}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      functional_units: state.business_units.business_units
    }
  }

export default connect(mapStateToProps)(FunctionalStrategyBusinessUnits);