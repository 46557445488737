import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getActions, createRole } from '../../../Redux/Actions/role_actions'

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions'
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PermissionsCheckbox from '../../UI/CustomFields/PermissionsCheckbox';

const styles = theme => ({
    dialog: {
        width: theme.spacing.unit * 2,
    },
});

class CreateRole extends Component {

    state = {
        fromType: "Create",
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: '',
        formData: {
            name: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'role_name',
                    type: 'input',
                    label: 'Role Name',
                    placeholder: 'Enter Role Name'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            desc: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'desc',
                    type: 'input',
                    label: 'Description',
                    placeholder: 'Enter Role Description'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            role_actions :{
                value: [],
                valid: true,
            }
        }

    }

    
    componentDidMount() {
       this.props.dispatch(getActions());
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'create_user');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();

        let dataToSubmit = generateData(this.state.formData, 'create_role');
        let formIsValid = isFormValid(this.state.formData, 'create_role');

        if (formIsValid) {
            this.props.dispatch(createRole(dataToSubmit)).then(responce => {
                if (this.props.roleCreated) {
                    this.props.roleAdded(this.props.roleCreated);
                } else {
                    //const error = responce.payload.err;

                    this.setState({
                        formError: true
                    })
                }
            });
        }
        else {
            this.setState({
                formErrorMsg: 'This creating is invalid.',
                formError: true
            })
        }
    }

    handleChecked(event, action_id, permission){
        let checked = event.target.checked;
        let newFormData = { ...this.state.formData};

        let role_actions = [];
        if(newFormData['role_actions'].value){
            role_actions = newFormData['role_actions'].value;
        }
        
        let _permissions = [];
        let _role_actions_index = this.getIndex(action_id, role_actions, "actionID");
        if(role_actions[_role_actions_index]){
            _permissions = role_actions[_role_actions_index].permissions;
        } 

        if(checked){
            if(_permissions.indexOf(permission) === -1){
                _permissions.push(permission)


                if(role_actions[_role_actions_index]){
                    role_actions[_role_actions_index].permissions = _permissions;
                }
                else{
                    role_actions.push({actionID : action_id, permissions: _permissions})
                }
                
            }
        }
        else{
            const permissionIndex = _permissions.indexOf(permission);
            if(permissionIndex !== -1){
                role_actions[_role_actions_index].permissions.splice(permissionIndex, 1);
            }
        }

        newFormData['role_actions'].value = role_actions;

        console.log(newFormData);


        this.setState({
            formData: newFormData
        })
    }

    getIndex = (value, arr, prop) => {
        for(var i = 0; i < arr.length; i++) {
            if(arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    generateActionsTable = () => {
            return (
                <div>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Action</TableCell>
                                <TableCell>Create</TableCell>
                                <TableCell>Read</TableCell>
                                <TableCell>Update</TableCell>
                                <TableCell>Delete</TableCell>
                                <TableCell>Execute</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.roleActions ?
                                this.props.roleActions.map((action, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{action.name}</TableCell>
                                            <TableCell>
                                                <PermissionsCheckbox 
                                                    role= {null}
                                                    permissions= {null}
                                                    action_id={action._id} 
                                                    action="Create"
                                                    change={ (event) => this.handleChecked(event,action._id, "Create" )}/>
                                            </TableCell>
                                            <TableCell>
                                            <PermissionsCheckbox 
                                                    role= {null}
                                                    permissions= {null}
                                                    action_id={action._id} 
                                                    action="Read"
                                                    change={ (event) => this.handleChecked(event,action._id, "Read" )}/>
                                            </TableCell>
                                            <TableCell>
                                            <PermissionsCheckbox 
                                                    role= {null}
                                                    permissions= {null}
                                                    action_id={action._id} 
                                                    action="Update"
                                                    change={ (event) => this.handleChecked(event,action._id, "Update" )}/>
                                            </TableCell>
                                            <TableCell>
                                            <PermissionsCheckbox 
                                                    role= {null}
                                                    permissions= {null}
                                                    action_id={action._id} 
                                                    action="Delete"
                                                    change={ (event) => this.handleChecked(event,action._id, "Delete" )}/>
                                            </TableCell>
                                            <TableCell>
                                            <PermissionsCheckbox 
                                                    role= {null}
                                                    permissions= {null}
                                                    action_id={action._id} 
                                                    action="Execute"
                                                    change={ (event) => this.handleChecked(event,action._id, "Execute" )}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) :
                                <TableRow>
                                    <TableCell scope="row" colSpan="8">Loading</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>



                </div>
            )
    }


  render() {
    const { cancel } = this.props;


    return (
      <div>
        <FormField
            id={'name'}
            formData={this.state.formData.name}
            change={(element) => this.updateForm(element)}
        />
        <FormField
            id={'desc'}
            formData={this.state.formData.desc}
            change={(element) => this.updateForm(element)}
        />
       
        { this.generateActionsTable() }
       

        {this.state.formError ?
            <div className="error_label">{this.state.formErrorMsg}</div>
        : null}

        <Button
            onClick={() => cancel()}
            style={{
                color: '#005773',
                fontWeight: [500],
                marginTop: '40px',
                marginBottom: '20px',
                width: '150px'
            }}>
            Cancel
        </Button>

        <Button variant="contained"
            onClick={(event) => this.submitForm(event)}
            style={{
                backgroundColor: '#005773',
                color: 'white',
                fontWeight: [500],
                marginTop: '40px',
                marginBottom: '20px',
                width: '150px'
            }}>
            Create Role
        </Button>

        
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    roleCreated : state.role.success,
    roleActions: state.role.actions
})

export default connect(mapStateToProps)(withStyles(styles)(CreateRole))