import {
    GET_ORGANIZATIONS,
    GET_ORGANIZATIONS_BYPARENT,
    ADD_ORGANIZATION,
    UPDATE_ORGANIZATION,
    REMOVE_ORGANIZATION,
    ADD_CHILD_ORGANIZATION,
    UPDATE_CHILD_ORGANIZATION,
    REMOVE_CHILD_ORGANIZATION,
    GET_ORGANIZATION_BYID,
    SENT_INVITE,
    REQUEST_INVITE,
    RESPOND_INVITE,
    SELECT_ORGANIZATION,
    RETRIEVE_LICENSE,
    CHANGE_OWNERSHIP,
    ASSIGN_LICENSE,
    CLEAR_SELECTED,
    GET_ORGANIZATIONROLES_BYUSER
} from '../Actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case GET_ORGANIZATIONS:
            return { ...state, organizations: action.payload }
        case GET_ORGANIZATION_BYID:
            return { ...state, organization: action.payload }
        case GET_ORGANIZATIONS_BYPARENT:
            return { ...state, organizations: action.payload }
        case GET_ORGANIZATIONROLES_BYUSER: 
            return { ...state, organization_roles: action.payload }
        case ADD_ORGANIZATION:
            return { ...state, create_success: action.payload.success }
        case UPDATE_ORGANIZATION:
            return { ...state, success: action.payload.success }
        case REMOVE_ORGANIZATION:
            return { ...state, remove_success: action.payload.success }
        case ADD_CHILD_ORGANIZATION:   
            let organizations = [ ...state.organizations ];
            organizations.push(action.payload.organization);

            return { ...state, success: action.payload.success, organizations }
        case UPDATE_CHILD_ORGANIZATION:
            let update_organizations = [ ...state.organizations ];

            if(action.payload.success){
                update_organizations = update_organizations.map(
                    (organization, i) => organization._id === action.payload.organization._id ? { ...action.payload.organization }
                                            : organization
                )
            }

            return { ...state, success: action.payload.success, organizations: update_organizations }
        case REMOVE_CHILD_ORGANIZATION:
            return { ...state, success: action.payload.success }
        case ASSIGN_LICENSE:
            return { ...state, assign_success: action.payload.assign_success }
        case SENT_INVITE:
            return { ...state, message: action.payload.message }
        case REQUEST_INVITE:
            return { ...state, message: action.payload.message }
        case RESPOND_INVITE:
            return { ...state, respond_success: action.payload.success, respond_status: action.payload.status  }
        case SELECT_ORGANIZATION:
            return { ...state, selection_success: action.payload.success  }
        case CLEAR_SELECTED:
            return { ...state, selection_success: false  }
        case RETRIEVE_LICENSE:
            return { ...state, license_information: action.payload  }
        case CHANGE_OWNERSHIP:
            return { ...state, transfer_success: action.payload }
        default:
            return state;
    }
}