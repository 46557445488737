import { makeStyles } from '@material-ui/core';
import React from 'react';

import PayFastLogo from '../../Resources/images/logos/payfast-logo.svg'

const useStyles = makeStyles((theme) => ({
    payfastLogo: {
        background: `url(${PayFastLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '150px',
        height: '50px'
    }
}))

const PaymentLogos = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.payfastLogo}></div>
    );
}

export default PaymentLogos;

