import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { password_reset } from '../../Redux/Actions/user_actions'

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

import Form from '../UI/Form'
import Input from '../UI/Form/input'
import PrimaryButton from '../UI/Buttons/primaryButton';

import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContent from '../UI/mySnackbarContent';
import SubmitButton from '../UI/Buttons/submitButton';

const schema = yup.object().shape({
    email: yup.string().email("Please ensert a valid email address").required("A email address is required.")
})

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: '20px',
        height: '450px',
        width: '350px'
    },
    grid:{
        background: `url(${LoginBackground}) no-repeat`,
        height: '800px',
    }
}))

function ForgotPassword(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {register, handleSubmit, errors} = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema)
    })

    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)


    const onSubmit = (data) => {
        dispatch(password_reset(data)).then(response => {
            if (response.payload.success) {
                setSuccessOpen(true);
            } else {
                 setErrorOpen(true);
            }
        }).catch(err => {
            setErrorOpen(true);
        }) 
    }


    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccessOpen(false)
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setErrorOpen(false)
    };


    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.grid}>
            <Grid item>
                <Paper className={classes.root} elevation={5}>
                    <h2 className="h2login">Password Reset</h2>
                    <p>Please provide us with your email address</p>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            ref={register}
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                        />

                        <Grid container justify="space-between">
                            <SubmitButton >Recover Password</SubmitButton>
                            <PrimaryButton href="/" >Back to Login</PrimaryButton>
                        </Grid>
                    </Form>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}

                            open={successOpen}
                            autoHideDuration={6000}
                            onClose={handleSuccessClose}
                        >
                        <MySnackbarContent
                            onClose={handleSuccessClose}
                            variant="success"
                            message="The reset email was been sent. Please check your inbox."
                        />
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}

                            open={errorOpen}
                            autoHideDuration={6000}
                            onClose={handleErrorClose}
                        >
                        <MySnackbarContent
                            onClose={handleErrorClose}
                            variant="error"
                            message="An Error accured. Please try again later."
                        />
                    </Snackbar>
                </Paper>
            </Grid>
        </Grid>
    )
    
}

export default ForgotPassword;