import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getUser, getUserLogins } from '../../../Redux/Actions/user_actions'

import Reinvite from '../../Users/Dialogs/Reinvite'

import moment from 'moment'
import { Line } from 'react-chartjs-2'

import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, Button, ButtonGroup, Paper, Chip, Typography, CircularProgress } from '@material-ui/core';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const useStyles = makeStyles(theme => {

})


function UserDetails(props) {
    const classes = useStyles();
    const { dispatch } = props;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        if (props.selectedUserID) {
            dispatch(getUser(props.selectedUserID)).then(responce => {
                setLoading(false);
            });

            // dispatch(getUserLogins(props.selectedUserID)).then(responce => {
            //     if(responce.payload)
            //     {
            //         let labels = [];
            //         let datasets = [];
                    
            //         let dataset = {
            //             label : 'User Logins',
            //             data : []
            //         }

            //         responce.payload.map(item => {



            //         })
            //     }
            // });
        }
    }, [])

    const handleClose = () => {




        props.handleClose();
    }

    return (
            <Dialog fullWidth={true} maxWidth='lg' open={props.open} onClose={props.handleClose} >
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    {props.selected_user ?
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant='caption'>Details</Typography>
                                        <Typography variant='body2'>
                                            Name: {props.selected_user.name} {props.selected_user.lastname}
                                        </Typography>
                                        <Typography variant='body2'>
                                            Email: {props.selected_user.email}
                                        </Typography>
                                        <Typography variant='body2'>
                                            Is Verified: {props.selected_user.verified ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='caption'>Logins</Typography>
                                        <Typography variant='body2'>
                                            Last Login: { moment(props.selected_user.last_login).format('DD-MM-Y H:m:s') }
                                        </Typography>
                                        {/* <Line data={null}/> */}
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant='caption'>Organization(s)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='caption'>Subscription(s)</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        :
                        loading ? 
                            <CircularProgress />
                        : null }
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={props.handleClose} color="primary">
                        Force Logout
                    </Button> */}
                    {props.selected_user ?
                        <Reinvite user={props.selected_user} />
                    :null }
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => ({
    selected_user: state.user.get_user
})


export default connect(mapStateToProps)(UserDetails)
