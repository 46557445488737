import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentObject } from '../../Redux/Actions/payment.actions';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import HeaderText from '../UI/headerText';
import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';
import Form from '../UI/Form';
import Input from '../UI/Form/input';
import SubmitButton from '../UI/Buttons/submitButton';
import { Redirect, useHistory } from 'react-router-dom';
import PrimaryButton from '../UI/Buttons/primaryButton';
import MyCircularProgress from '../UI/ProgressBars/myCircularProgress';
import PaymentLogos from '../UI/paymentLogos';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  subHeader: {
    marginBottom: theme.spacing(1),
  },
  planHeader: {
    fontWeight: 200,
    fontSize: '23px',
  },
  planPrice: {
    fontWeight: 400,
    fontSize: '23px',
    color: theme.palette.primary.main,
  },
  planFrequency: {
    fontWeight: 300,
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  billingName: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
  },
  billingCompany: {
    fontSize: '16px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    margin: theme.spacing(5),
  },
}));

const schema = yup.object().shape({});

function OrderReview(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const billingProcess = useSelector((state) => state.billingProcess);
  const plan = useSelector((state) => state.billingProcess.plan);
  const billingDetails = useSelector((state) => state.billingProcess.billingDetails);
  const paymentDetails = useSelector((state) => state.payment.paymentDetails);

  const formRef = useRef();

  const handleMakePayment = () => {
    setOpen(true);
    dispatch(getPaymentObject(billingProcess)).then((responce) => {
      setTimeout(() => {
        formRef.current.submit();
      }, 3000);
    });
  };

  const paymentDialog = () => (
    <Dialog fullWidth={true} maxWidth="sm" open={open} aria-labelledby="max-width-dialog-title">
      <DialogContent>
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
            <MyCircularProgress caption="Preparing to be redirected to Payfast" />
            {paymentDetails && paymentDetails.transactionObject ? (
              <form className={classes.form} action={paymentDetails.postUrl} ref={formRef}>
                {Object.entries(paymentDetails.transactionObject).map(([key, value]) => (
                  <input type="hidden" name={key} value={value} />
                ))}
              </form>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  return (
    <React.Fragment>
      <BackgroundItem path={GeneralBackground} />
      <Container maxWidth="sm">
        <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
            <HeaderText name="Review youe order" size={40} hideCompanyName />
          </Grid>
        </Grid>

        {plan ? (
          <Card className={classes.card}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item sm={8}>
                <Typography className={classes.planHeader}>{plan.name}</Typography>
                <Typography>{plan.description}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography className={classes.planPrice}>R {plan.price}</Typography>
                <Typography className={classes.planFrequency}>{plan.planFrequency == 'Monthly' ? 'per month' : 'anually'}</Typography>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Redirect to="/payment/select-plan" />
        )}

        {billingDetails ? (
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h5">
              Billing Details
            </Typography>
            <Grid container spacing={5} direction="row" justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.billingName}>
                  {billingDetails.firstName} {billingDetails.lastName}
                </Typography>
                {billingDetails.companyName ? (
                  <Typography className={classes.billingCompany}>
                    {billingDetails.companyName} {billingDetails.registrationNumber ? `(${billingDetails.registrationNumber})` : ''}
                    <br />
                    {billingDetails.vatNumber ? `VAT: ${billingDetails.vatNumber}` : ''}
                  </Typography>
                ) : null}

                <Typography>
                  {billingDetails.address1}
                  <br />
                  {billingDetails.address2 ? (
                    <div>
                      {billingDetails.address2}
                      <br />
                    </div>
                  ) : null}
                  {billingDetails.city}
                  <br />
                  {billingDetails.postalCode}
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Redirect to="/payment/billing-details" />
        )}

        {billingDetails && plan ? (
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h5" className={classes.subHeader}>
              Payment Details
            </Typography>
            <Grid container spacing={5} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography>{plan.name}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>R {plan.price}</Typography>
              </Grid>
            </Grid>
            <Typography component="small" variant="caption">
              You wil be redirected to Payfast Secured portal to complete your payment.
            </Typography>
            <Grid container spacing={5} direction="row" justify="flex-end" alignItems="flex-end">
              <Grid container item xs={6} direction="row" justify="flex-start" alignItems="flex-end">
                <PaymentLogos />
              </Grid>
              <Grid container item xs={6} direction="row" justify="flex-end" alignItems="flex-end">
                <PrimaryButton onClick={handleMakePayment}>Make Payment</PrimaryButton>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      </Container>
      {paymentDialog()}
    </React.Fragment>
  );
}

export default OrderReview;
