import React, { Component } from 'react';
import Stepper from '../../UI/GroupStepper'
import HeaderText from '../../UI/headerText';
import MediaIcons from '../../UI/mediaIcons';

import BackgroundItem from '../../UI/backgroundItem';

import IntroBackground from '../../../Resources/images/backgrounds/background_introduction.png'

class StrategyIntoruction extends Component {
    state = {
        // videoFile: '',
        // audioFile: '',
        //text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In dictum non consectetur a erat nam at lectus urna.psum consequat nisl vel pretium lectus quam id leo in."
    }

    StepContent = () => {
        return (
            <div style={{
                height: '470px',
                padding: '50px 120px 20px 300px'
            }}>

                <div className="intro_header" style={{
                    position: 'relative',
                    //left: '120px',
                    paddingTop: '90px'
                }}>

                    <HeaderText name="Group Strategy Introduction" size={40}/>
                    
                    <MediaIcons audioFile={this.state.audioFile} videoFile={this.state.videoFile} />
                </div>

                <div className="center">
                    <p>
                        Strategy takes a portfolio approach to strategic decision making by looking across all businesses or business units to determine how to create the most value.  In order to develop a corporate strategy, firms must look at how the various businesses or business units they own fit together, how they impact each other, and how the parent company is structured in order to optimize human capital, processes, and governance. 
                    <br /><i style={{ fontSize: '11px'}}>SOURCE: adapt from Corporate Finance Institute</i>
                    </p>
                </div>
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={IntroBackground} />
                <Stepper Step={6} getStepContent={() => this.StepContent()} />
            </div>
        );
    }
}

export default StrategyIntoruction;
