import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getGroupStrategicObjectives,
  addGroupStrategicObjective,
  removeGroupStrategicObjective,
  updateGroupStrategicObjective,
} from '../../../Redux/Actions/group_strategic_objectives_actions';
// import { setSettings, clearSettings } from '../../../Redux/Actions/objectiveSettings.actions';

import Stepper from '../../UI/GroupStepper';
import HeaderText from '../../UI/headerText';
import MediaIcons from '../../UI/mediaIcons';
import BackgroundItem from '../../UI/backgroundItem';
import GeneralBackground from '../../../Resources/images/backgrounds/background_pages.png';

import Grid from '@material-ui/core/Grid';

import StrategicObjectivesDialogList from '../../UI/CustomFields/StrategicObjectivesDialogList';
import PrimaryButton from '../../UI/Buttons/primaryButton';
import { useHistory } from 'react-router';

const GroupStrategicObjectives = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // useEffect(() => {
  //   dispatch(clearSettings());
  // }, []);
  // const handleDeepDiveOpen = () => {
  //   dispatch(setSettings('Group', '/group/strategic_objectives', 'Group'));
  //   history.push('/strategic-objectives/vission_mission_objectives');
  // };

  const StepContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          padding: '20px 120px 20px 120px',
        }}
      >
        <HeaderText name="Group Strategic Objectives" size={40} />

        <Grid container spacing={2} justify="center">
          {/* <PrimaryButton onClick={() => handleDeepDiveOpen()}>Open Strategic Objectives Deep Dive</PrimaryButton> */}

          <Grid item xs={4}>
            <StrategicObjectivesDialogList
              dialogTitle="Growth Objective"
              dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
              dialogSuccessButtonText="Add"
              dialogFieldLabel="Write your objective here."
              openDialogButtonText="Add Growth Objective"
              typeObjective="Growth"
            />
          </Grid>
          <Grid item xs={4}>
            <StrategicObjectivesDialogList
              dialogTitle="Risk Objective"
              dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
              dialogSuccessButtonText="Add"
              dialogFieldLabel="Write your objective here."
              openDialogButtonText="Add Risk Objective"
              typeObjective="Risk"
            />
          </Grid>
          <Grid item xs={4}>
            <StrategicObjectivesDialogList
              dialogTitle="Transition Objective"
              dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
              dialogSuccessButtonText="Add"
              dialogFieldLabel="Write your objective here."
              openDialogButtonText="Add Transition Objective"
              typeObjective="Transition"
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <BackgroundItem path={GeneralBackground} />
      <Stepper Step={5} getStepContent={() => StepContent()} />
    </div>
  );
};

export default GroupStrategicObjectives;
