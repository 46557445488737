import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ScrollArea from 'react-scrollbar';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '@material-ui/core';
import sortJsonArray from 'sort-json-array';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listRoot: {
    padding: '0',
  },
  badge: {
    top: '0px;',
    left: '200px',
    backgroundColor: 'rgb(0, 87, 115)',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

class LinkList extends Component {
  state = {
    selectedIndex: 0,
  };

  handleListItemClick = (event, index, item) => {
    this.setState({ selectedIndex: index });
    this.props.onSelect(item, index);
  };

  tooltipText = (title) => <Typography fontSize="14px">{title}</Typography>;

  render() {
    const { classes } = this.props;

    if (this.props.links) {
      sortJsonArray(this.props.links, 'issue_count', 'des');
    }

    var mainIndex = 0;
    this.props.links.map((item, index) => {
      if (item.name == this.props.areaSelected.name) {
        mainIndex = index;
        console.log(mainIndex);
      }
    });

    return (
      <Paper className={classes.root} elevation={5}>
        {this.props.header ? <h2 style={{ margin: '0px', fontSize: this.props.headerSize ? this.props.headerSize : 'initial' }}>{this.props.header}</h2> : null}

        {this.props.links ? (
          <List component="nav" className={classes.listRoot}>
            <ScrollArea
              speed={0.8}
              horizontal={false}
              smoothScrolling={true}
              style={{
                height: this.props.height,
              }}
            >
              {this.props.links.map((item, index) => (
                <LightTooltip title={item.description ? this.tooltipText(item.description) : ''} enterDelay={1000} key={index}>
                  <ListItem key={index} button onClick={(event) => this.handleListItemClick(event, index, item)}>
                    {item.issue_count && item.issue_count > 0 ? (
                      <Badge color="primary" badgeContent={item.issue_count} classes={{ badge: classes.badge }}>
                        <div></div>
                      </Badge>
                    ) : null}

                    {mainIndex === index ? (
                      <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: '#162336' }} />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} style={{ color: 'lightgrey' }} />
                    )}

                    <ListItemText primary={item.name} style={{ marginLeft: '10px' }} />
                  </ListItem>
                </LightTooltip>
              ))}
            </ScrollArea>
          </List>
        ) : null}
      </Paper>
    );
  }
}

export default withStyles(styles)(LinkList);
