import React from 'react';
import jsPDF from 'jspdf';  
import html2canvas from 'html2canvas';  
import { Button } from '@material-ui/core';

const PrintLayout = ({children, ...props}) => {

    const printDocument = () => {  
        const input = document.getElementById('pdfdiv');  

        html2canvas(input)  
          .then((canvas) => { 
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF(props.landscape ? 'l' : 'p', 'px', [ canvas.width, canvas.height ]);

            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);  
            pdf.setProperties({
                    title: props.title ? props.title : new Date().toDateString()
            });

            window.open(pdf.output('bloburl'), '_blank');
        });

    }


    return (
        <div>
            <Button onClick={printDocument} variant="contained" color="primary">  
                Print  
            </Button>  
            {children}
        </div>
    );
};

export default PrintLayout;