import { TextField } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
        color: '#162336',
    }
}))

const Input = forwardRef((props, ref) => {
    const classes = useStyles();

    return <TextField className={classes.textField} variant="outlined" margin='normal' size="small" inputRef={ref} fullWidth { ...props }/>
});

export default Input;