import React, { Component } from 'react'
import { connect } from 'react-redux';
import FSStepper from '../../UI/FSStepper'
import HeaderText from '../../UI/headerText';
import MediaIcons from '../../UI/mediaIcons';
import BackgroundItem from '../../UI/backgroundItem';

import IntroBackground from '../../../Resources/images/backgrounds/background_introduction.png'

class FunctionalStrategyIntroduction extends Component {

    state = {
        // videoFile: '',
        // audioFile: '',
        //text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In dictum non consectetur a erat nam at lectus urna.psum consequat nisl vel pretium lectus quam id leo in.",
    }


    StepContent = () => {
        return (
            <div style={{
                height: '470px',
                padding: '110px 120px 20px 120px'
            }}>

                <div className="intro_header" style={{
                    position: 'relative',
                    left: '120px',
                    paddingTop: '50px'
                }}>

                    <HeaderText size={40}>
                    FUNCTIONAL UNITS <br />STRATEGY DEVELOPMENT
                    </HeaderText>
                </div>

                <div className="center" style={{ marginLeft: "180px" }}>
                    <p>Functional business strategies seek to improve implementation of the business and corporate strategies. The Functional Strategy' is the strategy adopted by each functional area or business unit in line with the overall business or corporate strategy, to achieve organisational level objectives.
                    <br /><i style={{ fontSize: '11px'}}>SOURCE: adapt from MBASkool.com</i></p>
                </div>
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={IntroBackground} />
                <FSStepper Step={0} getStepContent={() => this.StepContent()} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      business_units: state.business_units.business_units
    }
  }

export default connect(mapStateToProps)(FunctionalStrategyIntroduction);