import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import ReCAPTCHA from "react-google-recaptcha";
let recaptchaRef = React.createRef();

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
        color: '#162336',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    textLabel: {
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '11px',
        color: '#162336',
    },
    select: {
        margin: theme.spacing.unit,
        minWidth: 220,
        maxWidth: 400,
    },
    badge:{
        bottom: '25px',
        top: 'unset'
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class FormField extends React.Component {
    state = {
        password: '',
        showPassword: false,
        selectedDate: new Date(),
        checkedItems: []
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    dateChanged = (element , date) => {
        element.value = date;
        this.props.change( element, date.toISOString());
    }

    handleChecked = ((event, index, key , id) => {
        console.log(index + " " + event.target.checked + " " + key);

        this.props.change({event: event.target.checked, key}, id, 'checkbox_list');
    })

    richtextChanged = (editor, id) => {
        this.props.change(editor.getContents(), id, 'richtext');
    }

    renderTemplate = () => {
        //let formTemplate = null;
        const { classes } = this.props;
        const { formData } = this.props;
        const { change,keypress } = this.props;
        const { id } = this.props;

        this.handleChange = prop => event => {
            this.setState({ [prop]: event.target.value });
            change({ event, id });
        };

        this.handleInput = (event) =>{
            const currentCount = event.target.value.length;

            if(formData.config.limit){
                const limit = formData.config.limit;
                if(currentCount >= limit){
                    event.target.value = event.target.value.substr(0, formData.config.limit - 1);
                }
            }

            if(keypress)
            {
                this.props.keypress({event,id,blur:true});
            }
        }

        switch (formData.element) {
            case ('input'):
                return (
                    <FormControl className={classNames(classes.margin, classes.textField)}>
                        <InputLabel htmlFor={id} className={classes.textLabel}>{ formData.config.placeholder ? formData.config.placeholder : formData.config.label}</InputLabel>
                        <Input
                            id={id}
                            name={formData.config.name}
                            type='text'
                            value={formData.value}
                            onChange={(event) => change({ event, id })}
                            onBlur={(event)=>change({event,id,blur:true})}
                            onKeyPress={(event)=>this.handleInput(event)}
                            disabled = {formData.config.disabled ? formData.config.disabled : false}
                            //helperText={formData.config.helperText ? formData.config.helperText : null}
                            error={!formData.valid}
                        />
                    </FormControl>
                )
            case ('richtext'):
                return (
                    <FormControl className={classNames(classes.margin, classes.textField)}>
                        {/* <InputLabel htmlFor={id} className={classes.textLabel}>{ formData.config.placeholder ? formData.config.placeholder : formData.config.label}</InputLabel> */}
                        <ReactQuill 
                            id={id}
                            value={formData.value} 
                            onChange={(editor) => this.richtextChanged(editor, id)}
                            />
                    </FormControl>
                )
            case ('slug'):
                return (
                    <FormControl className={classNames(classes.margin, classes.textField)}>
                        <InputLabel htmlFor={id} className={classes.textLabel}>{formData.config.label}</InputLabel>
                        <Input disabled id={id} type={formData.config.type}
                            value={formData.value}
                            onChange={(event) => change({ event, id })} 
                            onBlur={(event)=>change({event,id,blur:true})}
                            onKeyPress={keypress ? (event)=>keypress({event,id}) : null}
                            />
                    </FormControl>
                )
            case ('hidden'):
                return (
                        <Input id={id} type={formData.config.type} value={formData.value}/>
                )
            case ('recatcha'):
                return (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lem-dYUAAAAAH8yGDvGptPBYDIYIUf4sQp5YrK-"
                        //onChange={onReChange}
                        size="invisible"
                    />
                )
            case ('multiline'):
                return (
                    <div style={{ position:"relative"}}>
                        <TextField
                            id={id}
                            name={formData.config.name}
                            label={formData.config.label}
                            multiline
                            rowsMax="4"
                            value={formData.value}
                            onChange={(event) => change({ event, id })}
                            onBlur={(event)=>change({event,id,blur:true})}
                            onKeyPress={(event)=>this.handleInput(event)}
                            className={classes.textField}
                            helperText={formData.config.helperText ? formData.config.helperText : null}
                            margin="normal"
                            error={!formData.valid}
                            rowsMax={formData.config.maxRows ? formData.config.maxRows : 4 }
                        />

                        {/* Character Count */}
                        { formData.config.limit ?
                            <div style={{
                                fontSize: "11px",
                                marginLeft: "8px",
                                color: "#75828B",
                                position: 'absolute',
                                bottom: "-9px",
                                right: "16px",  
                            }}>{formData.value ? formData.value.length : 0}/{formData.config.limit}</div>
                        : null }
                    </div>
                )
            case ('password'):
                return (
                    <FormControl className={classNames(classes.margin, classes.textField)}>
                        <InputLabel htmlFor={id} className={classes.textLabel}>{ formData.config.placeholder ? formData.config.placeholder : null}</InputLabel>
                        <Input
                            id={id}
                            name={formData.config.name}
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                            onBlur={(event)=>change({event,id,blur:true})}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                    >
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )
            case ('select'):
                return (
                    <FormControl className={classNames(classes.select, classes.margin)} error={formData.validation && !formData.valid}>
                        <InputLabel id={"label-" + formData.config.name} htmlFor={formData.config.name}>{formData.config.label}</InputLabel>
                        <Select
                            labelId={"label-" + formData.config.name}
                            value={formData.value}
                            onChange={(event) => change({ event, id })}
                            onBlur={(event) => change({event,id,blur:true})}
                            //input={<Input name={formData.config.name} id={id} />}
                            className={classNames(classes.select)}
                        >
                            { formData.config.noneText ? 
                            <MenuItem value="">
                                <em>{ formData.config.noneText }</em>
                            </MenuItem>
                            : null } 
                            {
                                formData.config.options.map((item) => (
                                    <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                    </MenuItem >
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            case ('multi-select'):
                return (
                    <Badge 
                    color="primary" 
                    classes={{ badge: classes.badge }} 
                    badgeContent={formData.value.length > 0 ? formData.value.length : formData.config.default ? formData.config.default : 0}>

                        <FormControl className={classNames(classes.select, classes.margin)} error={formData.validation && !formData.valid}>
                            {formData.value.length === 0 ?
                                <InputLabel htmlFor={formData.config.name}>{formData.config.label}</InputLabel>   
                                :
                                null }
                            <Select
                                multiple
                                value={formData.value ? formData.value : []}
                                onChange={(event) => change({ event, id })}
                                onBlur={(event) => change({event,id,blur:true})}
                                input={<Input name={formData.config.name} id={id} />}
                                renderValue={select => formData.config.label}
                                MenuProps={MenuProps}
                                >
                                { formData.config.options.map(option => (
                                    <MenuItem key={option.key} value={option.key}>
                                        <Checkbox checked={formData.value.indexOf(option.key) > -1} />
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Badge>
                )
            case ('parent-select'):
                return (
                    <FormControl className={classNames(classes.margin,classes.select)} error={formData.validation && !formData.valid}>
                        <InputLabel htmlFor={formData.config.name}>{formData.config.label}</InputLabel>
                        <Select
                            value={formData.value}
                            onChange={(event) => change({ event, id })}
                            onBlur={(event)=>change({event,id,blur:true})}
                            input={<Input name={formData.config.name} id={id} />}
                        >
                            <MenuItem value="">Select</MenuItem >
                            {
                                formData.config.options.map((item) => (
                                    ( !item.parent ?
                                        <div key={item._id}>
                                            <MenuItem  value={item._id}>
                                                {item.name}
                                            </MenuItem >
                                            {
                                                formData.config.options.map((sub_item) => (
                                                    ( sub_item.parent && sub_item.parent._id === item._id ?
                                                        <MenuItem key={sub_item._id} value={sub_item._id}>
                                                            <div> --- {sub_item.name}</div>
                                                        </MenuItem >
                                                    :null
                                                    )
                                                ))
                                            }
                                        </div>
                                        :
                                        null
                                    )
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            case ('radio'):
                return (
                    <FormControl className={classNames(classes.margin)} error={formData.validation && !formData.valid}>
                    <FormLabel htmlFor={formData.config.name}>{formData.config.label}</FormLabel>
                        <RadioGroup
                            id={id}
                            aria-label={formData.config.label}
                            name={formData.config.name}
                            value={formData.value}
                            onChange={(event) => change({ event, id })}
                            onBlur={(event)=>change({event,id,blur:true})}
                        >
                            {
                                formData.config.options.map((item) => (
                                    <FormControlLabel key={item.key} value={item.value} control={<Radio color="default" />} label={ item.label ? item.label : item.value } />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                )
            case ('checkbox'):
                    return (
                        <FormControl className={classNames(classes.margin)} error={formData.validation && !formData.valid}>

                        <FormControlLabel
                                control={
                                    <Checkbox id={id} 
                                        name={formData.config.name} 
                                        checked={formData.value} 
                                        onChange={(event) => change({ event, id })} 
                                        value={formData.config.name} 
                                        onBlur={(event) => change({event,id,blur:true})} />
                                    }
                                label={formData.config.label}
                        />

                        
                        </FormControl>
                )
            case ('checkbox_list'):
                return (
                    <FormControl className={classNames(classes.margin)} error={formData.validation && !formData.valid}>
                        { formData.label ? 
                            <FormLabel component="legend">{formData.label}</FormLabel>
                        : null }
                        <FormGroup>
                            {
                                formData.config.options.map((item, index) => {
                                    item.checked = false;
                                    for(var r = 0; r < formData.value.length; r++)
                                    {  
                                        var role = formData.value[r];
                                        if(role === item.key){
                                            item.checked = true;
                                            break;
                                        }
                                    }
                                    
                                    return (
                                        <FormControlLabel key={index} 
                                            control={<Checkbox checked={item.checked} value={item.key} onChange={(event) => change({ event, id })} name={ formData.config.name } />}
                                            label={ item.value }
                                        />
                                    )  
                                })
                            }
                        </FormGroup>
                    </FormControl>
                )
            case ('date'):
                const now = moment();
                const element = {
                    id,
                    value : ''
                }
                return (
                    
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="normal"
                        label={formData.config.label}
                        value={ formData.value !== "" ? formData.value : now}
                        onChange={(date) => this.dateChanged(element , date)}
                        format='DD/MM/YYYY'
                    />
                    </MuiPickersUtilsProvider>
                )
            default:
                return null;
        }

    };


    render() {
        return (
            this.renderTemplate()
        )
    }
}

export default withStyles(styles)(FormField)