import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import BackendBackground from '../UI/backendBackground';
import FlagsDrawer from '../Partials/FlagsDrawer'

import { getByUser } from '../../Redux/Actions/issues_actions';
import TotalObjects from '../UI/InfoCards/TotalObjects';
import Permission from '../../HOC/Permission';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      zIndex: 2,
      position: 'absolute',
    },
  });

let _isMounted = false;

class Dashboard extends Component {
    state = {

    }

    componentDidMount = () => {
        _isMounted = true
        this.props.dispatch(getByUser()).then(responce => {});
        //this.props.dispatch(GetAllCorporateIssues()).then(responce => {});
    }

    componentWillUnmount = () => {
        _isMounted = false
    }

    getTotalIssues = (isAll) => {
        if(this.props.issues){
            let total = 0;
            
            if(!isAll){
                this.props.issues.forEach(element => {
                    if(element.assigned_users && element.assigned_users.map(i => i.email).indexOf(this.props.user.email) !== -1){
                        total = total + 1;
                    }
                });
            }
            else{
                this.props.issues.forEach(element => {
                    total = total + 1;
                });
            }

            return (
                <TotalObjects header="Issues" total={total} />
            )
        }
    };
  
    getTotalInProgress = (isAll) => {
        if(this.props.issues){
            let total = 0;

             if(!isAll){
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fd30be47547b087a16e"){
                        if(issue.assigned_users && issue.assigned_users.map(i => i.email).indexOf(this.props.user.email) !== -1){
                            total = total + 1;
                        }
                    }
                });
            }
            else{
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fd30be47547b087a16e"){
                        total = total + 1;
                    }
                });
            }

            return (
                <TotalObjects header="In Progress" total={total} />
            )
        }
    };
  
    getTotalPending = (isAll) => {
        if(this.props.issues){
            let total = 0;

            if(!isAll){
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fa3174ad047b000c650"){
                        if(issue.assigned_users && issue.assigned_users.map(i => i.email).indexOf(this.props.user.email) !== -1){
                            total = total + 1;
                        }
                    }
                });
            }
            else{
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fa3174ad047b000c650"){
                        total = total + 1;
                    }
                });
            }

            return (
                <TotalObjects header="Not Started" total={total} />
            )
        }
    };
  
    getTotalWaitingForApproval = (isAll) => {
        if(this.props.issues){
            let total = 0;

            if(!isAll){
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fdf0be47547b087a16f"){
                        if(issue.assigned_users && issue.assigned_users.map(i => i.email).indexOf(this.props.user.email) !== -1){
                            total = total + 1;
                        }
                    }
                });
            }
            else{
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fdf0be47547b087a16f"){
                        total = total + 1;
                    }
                });
            }


            return (
                <TotalObjects header="Waiting For Approval" total={total} />
            )
        }
    };
  
    getTotalDone = (isAll) => {
        if(this.props.issues){
            let total = 0;

            if(!isAll){
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fe70be47547b087a170"){
                        if(issue.assigned_users && issue.assigned_users.map(i => i.email).indexOf(this.props.user.email) !== -1){
                            total = total + 1;
                        }
                    }
                });
            }
            else{
                this.props.issues.forEach(issue => {
                    if(issue.status && issue.status._id === "5c5c0fe70be47547b087a170"){
                        total = total + 1;
                    }
                });
            }

            return (
                <TotalObjects header="Done" total={total} />
            )
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                
                <BackendBackground />
                <div className={classes.root}>
                <Grid container>
                    {/* <Grid item sm={6}>
                    
                        <h2>Viewer Objectives</h2>
                        {this.getTotalIssues(false)}      
                        {this.getTotalInProgress(false)}     
                        {this.getTotalPending(false)}     
                        {this.getTotalWaitingForApproval(false)}   
                        {this.getTotalDone(false)}   
                    
                    </Grid> */}
                    <Grid item sm={12}>
                        <Permission action="corporate.view" user={this.props.user} hideObject={true}>
                            
                                <h2>Organization Objectives</h2>
                                {this.getTotalIssues(true)}      
                                {this.getTotalInProgress(true)}     
                                {this.getTotalPending(true)}     
                                {this.getTotalWaitingForApproval(true)}   
                                {this.getTotalDone(true)}   

                        </Permission>
                    </Grid>
                </Grid>
                </div>
                

               
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user : state.user.userData,
    issues: state.strategy_issues.issues_by_user,
    all_issues: state.strategy_issues.issues,
})




export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
