import 'date-fns';
import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Controller } from "react-hook-form";
import DateFnsUtils from '@date-io/date-fns';

const DatePicker = ({
    name,
    label,
    control,
    setValue,
    defaultValue,
    children,
    ...props
  }) => {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <Controller 
           as={ 
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={label}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    { ...props }
                />
             }
            control={control}
            name={name}
            defaultValue={defaultValue}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;