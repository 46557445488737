import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../UI/Form'
import Input from '../UI/Form/input';
import PrimaryButton from '../UI/Buttons/submitButton';

import { updateUser } from '../../Redux/Actions/user_actions';
import { Container, Grid, Typography } from '@material-ui/core';
import UpdatePassword from './updatePassword';
import SubmitButton from '../UI/Buttons/submitButton';

const schema = yup.object().shape({
    name: yup
        .string()
        .matches(/^([^0-9]*)$/, "First name should not contain any numbers")
        .required('First name is required'),
    lastname: yup
        .string()
        .matches(/^([^0-9]*)$/, "Last name should not contain any numbers")
        .required('Last name is required'),
    email: yup
        .string()
        .email('It seems like this is not a email address.')
        .required('Email is required')
})

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData)
    
    const [updated, setUpdated] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        defaultValues: { name: user.name, lastname: user.lastname, email: user.email },
        mode: 'onBlur',
        resolver: yupResolver(schema) 
    });

    const onSubmit = (data) => {
        dispatch(updateUser(data)).then(responce => {
            setUpdated(true);
        });
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={4} >

                </Grid>
                <Grid item sx={8}>
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Input name="name" type='text' label="First Name" ref={register} error={ !! errors.name } helperText={ errors?.name?.message }/>
                            <Input name="lastname" type='text' label="Last Name"  ref={register} error={ !! errors.lastname } helperText={ errors?.lastname?.message }/>  
                            <Input name="email" type='email' label="Email Address"  ref={register} error={ !! errors.email } helperText={ errors?.email?.message }/>      

                            <SubmitButton type="submit">Update</SubmitButton>

                            { updated ? 
                                <Typography component='span' color='error'>Updated!</Typography>
                            : null }
                        </Form>
                    </Container>
                    
                    <UpdatePassword />
                </Grid>
            </Grid>
            
        </div>
    );
};

export default Profile;