    export const canAddNewOrganization = (plan, organizations, user_id) => {
        if(plan){
            let totalLicenses = TotalLicenses(plan);

            for(var i = 0; i < organizations.length; i++){
                const org = organizations[i];

                if(org.organization_id.owner.toString() == user_id.toString())
                {
                    totalLicenses--;
                }
            }
            return totalLicenses > 0 || isConsultant(plan);
        }
        return false;
    }
    
    export const TotalLicenses = (plan) => {
        if(plan){
            return plan.totalEntitiesAllowed;
        }

        return 0
    }

    export const isConsultant = (plan) => {
        if(plan && plan.unlimitedEntities){
            return true;
        }
        return false;
    }