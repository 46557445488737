import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Auth from './HOC/Auth';

// //import Home from './Components/Home';
import SignIn from './Components/SignIn';
import Logout from './Components/SignIn/logout';
import Register from './Components/SignIn/register';
import RegisterSubscription from './Components/SignIn/registerSubscription';
import ConfirmEmail from './Components/SignIn/confirmEmail';
import VerifeidEmail from './Components/SignIn/verifeid';
import ForgotPassword from './Components/SignIn/forgotPassword';
import PasswordChange from './Components/SignIn/password_change';
import OrganizationSelect from './Components/Organization/organization_select';
import OrganizationCreate from './Components/Organization/create';
import OrganizationChange from './Components/Organization/organization_change';

import SelectBusinessStrategy from './Components/Organization/selectBusinessStrategy';
import Welcome from './Components/Welcome';
import Introduction from './Components/Steps/Introduction';
import FunctionalUnits from './Components/Steps/FunctionalUnits';
import VisionMission from './Components/Steps/VisionMission';
import OrganizationalValues from './Components/Steps/OrganizationalValues';
import CorporateStrategyIntoruction from './Components/Steps/CorporateStrategyIntroduction';
import CorporateStrategyIssues from './Components/Steps/CorporateStrategyIssues';
import GroupStrategicObjectives from './Components/Steps/GroupStrategicObjectives';
import CSArchived from './Components/CorporateStrategy/Archive';
import CSTasks from './Components/CorporateStrategy';
import FSArchived from './Components/FunctionalStrategy/Archive';
import FSTasks from './Components/FunctionalStrategy';
import GSArchived from './Components/GroupStrategy/Archive';
import GSTasks from './Components/GroupStrategy';

import GroupIntroduction from './Components/Steps/Group/Introduction';
import GroupBusinessUnits from './Components/Steps/Group/GroupBusinessUnits';
import GroupVisionMission from './Components/Steps/Group/VisionMission';
import GroupOrganizationalValues from './Components/Steps/Group/OrganizationalValues';
import MainGroupStrategicObjectives from './Components/Steps/Group/StrategicObjectives';
import GroupStrategyIntoruction from './Components/Steps/Group/StrategyIntroduction';
import GroupStrategyIssues from './Components/Steps/Group/StrategyIssues';

import FunctionalStrategyIntroduction from './Components/Steps/FunctionalStrategy/Introduction';
import FunctionalStrategyBusinessUnits from './Components/Steps/FunctionalStrategy/SelectBusinessUnit';
import FunctionalStrategyIssues from './Components/Steps/FunctionalStrategy/Issues';

import AuthorisedAccessOnly from './Components/Access/AuthorisedAccessOnly';

//Backend
import Dashboard from './Components/Home/dashboard';
import StrategicObjectives from './Components/Home/StrategicObjectives';
import Users from './Components/Users';
import Roles from './Components/Roles';
import Areas from './Components/Areas';
import OrganizationSettings from './Components/Organization/settings';
import OrganizationDetails from './Components/Organization';
import OrganizationUpadte from './Components/Organization/update';
import Objectives from './Components/Objectives';

//Backend System Admin
import CompanyLicensing from './Components/SystemAdmin/CompanyLicensing';
import SystemUsers from './Components/SystemAdmin/SystemUsers';

//Reports
import Reports from './Components/Reports';
import BusinessScoreCard from './Components/Reports/business/score_card';
import BusinessStatements from './Components/Reports/business/statements';
import BusinessRoadMap from './Components/Reports/business/road_map';
import BusinessStructure from './Components/Reports/business/structure';
import BusinessRiskRegister from './Components/Reports/business/risk_register';
import BusinessFUDeliverables from './Components/Reports/business/fu_deliverables';
import FunctionalRSDeliverables from './Components/Reports/functional/rs_deliverables';

import GroupScoreCard from './Components/Reports/group/score_card';
import GroupStatements from './Components/Reports/group/statements';
import GroupRoadMap from './Components/Reports/group/road_map';
import GroupStructure from './Components/Reports/group/structure';
import GroupRiskRegister from './Components/Reports/group/risk_register';

import FunctionalScoreCard from './Components/Reports/functional/score_card';
import FunctionalRoadMap from './Components/Reports/functional/road_map';
import FunctionalStructure from './Components/Reports/functional/structure';
import FunctionalRiskRegister from './Components/Reports/functional/risk_register';
import EntitySelect from './Components/Organization/entitySelect';

import Profile from './Components/Users/profile';
import ListEntities from './Components/Entity/list';
import Statements from './Components/Reports/ReportLayouts/statements';
import PaymentPlans from './Components/Payment/paymentPlans';
import BillingDetails from './Components/Payment/billingDetails';
import OrderReview from './Components/Payment/orderReview';
import FunctionalIssues from './Components/Reports/functional/functional_issues';
import BusinessIssues from './Components/Reports/business/business_issues';
import GroupIssues from './Components/Reports/group/group_issues';
import PaymentCanceled from './Components/Payment/paymentCanceled';
import PaymentDone from './Components/Payment/paymentDone';
import Billing from './Components/Billing';
import RenewPlan from './Components/Payment/renewPlan';
import PlanMigration from './Components/PlanMigration';
import PlanExpired from './Components/Payment/planExpired';

const Routes = (props) => {
  return (
    <div>
      <Switch>
        <Route path="/reports" exact component={Auth(Reports, 'reports.create', true, true, null, true, 'Reports')} />

        <Route path="/report/layout/statements" exact component={Auth(Statements, 'everyone', true, true, null, true, 'Reports - Business Statements')} />

        <Route path="/report/group/score_card" exact component={Auth(GroupScoreCard, 'reports.create', true, true, null, true, 'Reports - Group Score Card')} />
        <Route path="/report/group/risk_register" exact component={Auth(GroupRiskRegister, 'reports.create', true, true, null, true, 'Reports - Group Risk Register')} />
        <Route path="/report/group/statements" exact component={Auth(GroupStatements, 'reports.create', true, true, null, true, 'Reports - Group Statements')} />
        <Route path="/report/group/structure" exact component={Auth(GroupStructure, 'reports.create', true, true, null, true, 'Reports - Group Structure')} />
        <Route path="/report/group/roadmap" exact component={Auth(GroupRoadMap, 'reports.create', true, true, null, true, 'Reports - Group Road Map')} />
        <Route path="/report/group/issues" exact component={Auth(GroupIssues, 'reports.create', true, true, null, true, 'Reports -  Functional Strategies')} />

        <Route
          path="/report/functional/rs_deliverables"
          exact
          component={Auth(FunctionalRSDeliverables, 'reports.create', true, true, null, true, 'Reports - Revenue Stream Deliverables')}
        />
        <Route path="/report/functional/score_card" exact component={Auth(FunctionalScoreCard, 'reports.create', true, null, true, 'Reports - Business Unit Score Card')} />
        <Route
          path="/report/functional/risk_register"
          exact
          component={Auth(FunctionalRiskRegister, 'reports.create', true, true, null, true, 'Reports -  Business Unit Risk Register')}
        />
        <Route path="/report/functional/structure" exact component={Auth(FunctionalStructure, 'reports.create', true, true, null, true, 'Reports -  Business Unit Structure')} />
        <Route path="/report/functional/roadmap" exact component={Auth(FunctionalRoadMap, 'reports.create', true, true, null, true, 'Reports -  Business Unit Road Map')} />
        <Route path="/report/functional/issues" exact component={Auth(FunctionalIssues, 'reports.create', true, true, null, true, 'Reports -  Functional Strategies')} />

        <Route
          path="/report/business/bu_deliverables"
          exact
          component={Auth(BusinessFUDeliverables, 'reports.create', true, true, null, true, 'Reports - Business Functional Unit Deliverables')}
        />
        <Route path="/report/business/score_card" exact component={Auth(BusinessScoreCard, 'reports.create', true, true, null, true, 'Reports - Business Score Card')} />
        <Route path="/report/business/risk_register" exact component={Auth(BusinessRiskRegister, 'reports.create', true, true, null, true, 'Reports - Business Risk Register')} />
        <Route path="/report/business/statements" exact component={Auth(BusinessStatements, 'reports.create', true, true, null, true, 'Reports - Business Statements')} />
        <Route path="/report/business/structure" exact component={Auth(BusinessStructure, 'reports.create', true, true, null, true, 'Reports - Business Structure')} />
        <Route path="/report/business/roadmap" exact component={Auth(BusinessRoadMap, 'reports.create', true, true, null, true, 'Reports - Business Road Map')} />
        <Route path="/report/business/issues" exact component={Auth(BusinessIssues, 'reports.create', true, true, null, true, 'Reports -  Business Strategies')} />

        <Route path="/profile" exact component={Auth(Profile, 'everyone', true, true, null, true, 'My Profile')} />
        <Route path="/profile/billing" exact component={Auth(Billing, 'everyone', true, true, null, true, 'Billing')} />
        <Route path="/admin/entities" exact component={Auth(ListEntities, 'everyone', true, true, null, true, 'Entites')} />

        <Route path="/organization" exact component={Auth(OrganizationDetails, 'organization.view', true, true, null, true, 'Organization Details')} />
        <Route path="/organization/create" exact component={Auth(OrganizationCreate, null, true, false, null, false, '', false, true)} />
        <Route path="/organization/settings" exact component={Auth(OrganizationSettings, 'organization.update', true, true, null, true, 'Organization Settings')} />
        <Route path="/organization/:id" exact component={Auth(OrganizationUpadte, 'organization.update', true, true, null, true, 'Organization Update')} />

        <Route path="/areas" exact component={Auth(Areas, 'super.create', true, true, null, true, 'Areas')} />
        <Route path="/roles" exact component={Auth(Roles, 'roles.view', true, true, null, true, 'Roles')} />
        <Route path="/users" exact component={Auth(Users, 'users.view', true, true, null, true, 'Users')} />
        <Route path="/strategic_objectives" exact component={Auth(StrategicObjectives, 'everyone', true, true, null, true)} />
        <Route path="/dashboard" exact component={Auth(Dashboard, 'everyone', true, true, null, true, 'Dashboard')} />

        <Route path="/plan/migration" exact component={Auth(PlanMigration, 'super.create', true, true, null, true, 'Plan Migration')} />

        <Route path="/admin/licensing" exact component={Auth(CompanyLicensing, 'admin.create', true, true, null, true, 'Company Licensing')} />
        <Route path="/admin/users" exact component={Auth(SystemUsers, 'admin.create', true, true, null, true, 'All Users')} />

        <Route path="/functional_strategy/tasks" exact component={Auth(FSTasks, 'functional_strategy.view', true, true, null, true, 'Functional Tasks')} />
        <Route path="/functional_strategy/tasks/archive" exact component={Auth(FSArchived, 'functional_strategy.update', true, true, null, true, 'Archive')} />
        <Route path="/functional_strategy/issues/:_business_unit_id" exact component={Auth(FunctionalStrategyIssues, 'functional_strategy.view', true, true)} />
        <Route path="/functional_strategy/select-business-unit" exact component={Auth(FunctionalStrategyBusinessUnits, 'functional_strategy.view', true, true)} />
        <Route path="/functional_strategy/introduction" exact component={Auth(FunctionalStrategyIntroduction, 'functional_strategy.view', true, true)} />

        <Route path="/corporate_strategy/issues" exact component={Auth(CorporateStrategyIssues, 'corporate.view', true, true)} />
        <Route path="/corporate_strategy/tasks" exact component={Auth(CSTasks, 'corporate.view', true, true, null, true, 'Business Tasks')} />
        <Route path="/corporate_strategy/tasks/archive" exact component={Auth(CSArchived, 'corporate.update', true, true, null, true, 'Archive')} />
        <Route path="/corporate_strategy/introduction" exact component={Auth(CorporateStrategyIntoruction, 'corporate.view', true, true)} />

        <Route path="/group/strategy/tasks" exact component={Auth(GSTasks, 'corporate.view', true, true, null, true, 'Group Tasks')} />
        <Route path="/group/strategy/tasks/archive" exact component={Auth(GSArchived, 'corporate.update', true, true, null, true, 'Archive')} />

        <Route path="/objectives" exact component={Auth(Objectives, 'corporate.view', true, true, null, true, '')} />

        <Route path="/organizational_values" exact component={Auth(OrganizationalValues, 'corporate.view', true, true)} />
        <Route path="/vision_mission" exact component={Auth(VisionMission, 'corporate.view', true, true)} />
        <Route path="/group_strategic_objectives" exact component={Auth(GroupStrategicObjectives, 'corporate.view', true, true)} />
        <Route path="/functional_units" exact component={Auth(FunctionalUnits, 'corporate.view', true, true)} />
        <Route path="/introduction" exact component={Auth(Introduction, 'corporate.view', true, true)} />

        <Route path="/group/organizational_values" exact component={Auth(GroupOrganizationalValues, 'corporate.view', true, true)} />
        <Route path="/group/vision_mission" exact component={Auth(GroupVisionMission, 'corporate.view', true, true)} />
        <Route path="/group/strategic_objectives" exact component={Auth(MainGroupStrategicObjectives, 'corporate.view', true, true)} />
        <Route path="/group/business_units" exact component={Auth(GroupBusinessUnits, 'corporate.view', true, true)} />
        <Route path="/group/introduction" exact component={Auth(GroupIntroduction, 'corporate.view', true, true)} />
        <Route path="/group/strategy/introduction" exact component={Auth(GroupStrategyIntoruction, 'corporate.view', true, true)} />
        <Route path="/group/strategy/issues" exact component={Auth(GroupStrategyIssues, 'corporate.view', true, true)} />

        <Route path="/entity/business/select" exact component={Auth(SelectBusinessStrategy, 'organization.view', null, true, true, null, false, '', false, true)} />
        <Route path="/entity_select" exact component={Auth(EntitySelect, null, true, false, null, false, '', false, true)} />
        <Route path="/organization_select" exact component={Auth(OrganizationSelect, null, true, true, null, false, '', false, true)} />
        <Route path="/organization_change" exact component={Auth(OrganizationChange, null, true, true)} />
        {/* <Route path="/no_subscription" exact component={Auth(NoSubscription,null, true,false)} /> */}
        <Route path="/welcome" exact component={Auth(Welcome, null, true, true)} />

        {/* <Route path="/subscription/update/:hash" exact component={Auth(SubscriptionUpdate,null, true, false)} />  */}

        <Route path="/payment/expired" exact component={Auth(PlanExpired, null, true, false, false, false, 'Plan Expired', false, true)} />
        <Route path="/payment/cancel" exact component={Auth(PaymentCanceled, null, true, false, false, false, 'Canceled Payment', false, true)} />
        <Route path="/payment/done" exact component={Auth(PaymentDone, null, true, false, false, false, 'Payment Completed', false, true)} />
        <Route path="/payment/select-plan" exact component={Auth(PaymentPlans, null, true, false, false, false, 'Payment Plan', false, true)} />
        <Route path="/payment/renew-plan" exact component={Auth(RenewPlan, null, true, false, false, false, 'Renew Plan', false, true)} />
        <Route path="/payment/billing-details" exact component={Auth(BillingDetails, null, true, false, false, false, 'Billing Details', false, true)} />
        <Route path="/payment/order-review" exact component={Auth(OrderReview, null, true, false, false, false, 'Order Review', false, true)} />

        <Route path="/verify/:token" exact component={Auth(VerifeidEmail, null, false, false)} />
        <Route path="/forgotpassword" exact component={Auth(ForgotPassword, null, false, false)} />
        <Route path="/password_change/:token" exact component={Auth(PasswordChange, null, false, false)} />
        <Route path="/register/email_verification/:email" exact component={Auth(ConfirmEmail, null, false, false, null, false, '', false, false)} />
        {/* <Route path="/register/subscription/:hash" exact component={Auth(RegisterSubscription, null, false, false)} /> */}

        <Route path="/register" exact component={Auth(Register, null, false, false)} />
        <Route path="/authorised_access_only" exact component={Auth(AuthorisedAccessOnly, null, true, false)} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/sign_in" exact component={Auth(SignIn, null, false, false)} />

        <Route path="/" exact component={Auth(SignIn, null, false, false)} />
      </Switch>
    </div>
  );
};

export default Routes;
