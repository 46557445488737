import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Container } from "@material-ui/core";
import {
  getVisionMission,
  getAllOrganizationalValues,
} from "../../../Redux/Actions/organizational_values_actions";
import { getOrganization } from "../../../Redux/Actions/organization_actions";
import moment from "moment";
import MyCircularProgress from "../../UI/ProgressBars/myCircularProgress";
import { getStrategicObjectives } from "../../../Redux/Actions/issues_actions";
import { getGroupStrategicObjectives } from "../../../Redux/Actions/group_strategic_objectives_actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {},
  headings: {
    textTransform: "uppercase",
    fontSize: "25px",
    borderLeft: "5px solid",
    margin: 0,
    padding: "10px 10px",
  },
  copy: {
    padding: theme.spacing(2),
  },
  content: {
    backgroundColor: "#F2F2F2",
  },
  pdf: {
    padding: "10px 25px 25px 25px",
  },
}));

const Statements = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const values = useSelector((state) => state.organizational_values);
  const organization = useSelector((state) => state.organization.organization);
  const user = useSelector((state) => state.user.userData);
  const objectives = useSelector(
    (state) => state.group_strategic_objectives.group_strategic_objectives
  );

  useEffect(() => {
    dispatch(getVisionMission(props.entity));
    dispatch(getAllOrganizationalValues(props.entity));
    dispatch(getOrganization(props.entity));
    dispatch(getGroupStrategicObjectives());
  }, []);

  return (
    <Container>
      {organization ? (
        <div id="pdfdiv" className={classes.pdf}>
          <h2>Statements - {organization?.name}</h2>

          <Grid container className={classes.root} spacing={3}>
            <Grid item xs={12} className={classes.gridItem}>
              <div className={classes.content}>
                <Typography variant="h2" className={classes.headings}>
                  Our Purpose
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.copy}
                >
                  {values?.vision_mission?.purpose}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={3}>
            <Grid item xs={6} className={classes.gridItem}>
              <div className={classes.content}>
                <Typography variant="h2" className={classes.headings}>
                  Our Vision
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.copy}
                >
                  {values?.vision_mission?.vision}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <div className={classes.content}>
                <Typography variant="h2" className={classes.headings}>
                  Our Mission
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.copy}
                >
                  {values?.vision_mission?.mission}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container className={classes.root} spacing={3}>
            <Grid item xs={6} className={classes.gridItem}>
              <div className={classes.content}>
                <Typography variant="h2" className={classes.headings}>
                  Our Values
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.copy}
                >
                  {values.organizational_values
                    ? values.organizational_values.map((item) => (
                        <div>{item.name}</div>
                      ))
                    : null}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <div className={classes.content}>
                <Typography variant="h2" className={classes.headings}>
                  Our Objectives
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.copy}
                >
                  {objectives
                    ? objectives.map((obj) => (
                        <div>
                          {obj.type} - {obj.objective}
                        </div>
                      ))
                    : null}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={4}>
              {moment().format("MM/DD/YYYY")}
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              IQFin Strategy Tracker
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              Generated by {user.fullname}
            </Grid>
          </Grid>
        </div>
      ) : (
        <MyCircularProgress caption="Generating Statement Report..." />
      )}
    </Container>
  );
};

export default Statements;
