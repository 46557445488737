import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React, { forwardRef } from 'react';

const Checkout = ({ control, name, label, ...props }) => {
    return <FormControlLabel
            control={
            <Controller
                name={name}
                control={control}
                render={(props) => (
                <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                />
                )}
            />
            }
            label={label}
        />
};

export default Checkout;