import React from 'react';
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux';

const HeaderText = (props) => {
    return (
        <div className="center_container">
            { props.user.userData.selectedOrganization && !props.hideCompanyName ? 
                <Typography style={{ display: 'inline-block' }}>{props.user.userData.selectedOrganization.name}</Typography>
            :null}

            { props.name ?
                <h1 style={{
                    fontSize: props.size ? props.size : null,
                    marginBottom: props.subHeader ? "initial" : "2px",
                }}> {props.name} </h1>
            : props.children ? 
            <h1 style={{
                    fontSize: props.size ? props.size : null,
                    marginBottom: props.subHeader ? "initial" : "2px",
                }}> {props.children} </h1>
            : null}



        { props.subHeader ?
            <h4 style={{
                fontSize:'20px',
                marginBottom: "20px",
            }}>
                 { props.subHeader }
            </h4>
        : null }
            
        </div>

    );
}

function mapStateToProps(state){
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(HeaderText);
