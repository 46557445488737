import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectOrganization } from '../../Redux/Actions/organization_actions';

import queryString from 'query-string';

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import OrganizationCard from '../UI/Cards/organizationCard';

import { canAddNewOrganization } from '../Utils/OrganizationActions'

import ScrollArea from 'react-scrollbar';


class OrganizationChange extends Component {
    state = {
    }

    changeOrganization(organization_id) {
        if (organization_id) {
            this.props.dispatch(SelectOrganization(organization_id)).then(responce => {
                if (this.props.success) {

                    const qs_values = queryString.parse(this.props.location.search);
                    if (qs_values.redirectTo) {
                        this.props.history.push(qs_values.redirectTo)
                    }
                    else {
                        this.props.history.push('/welcome')
                    }
                }
            })
        }
    }

    handleBack = () => {
        const qs_values = queryString.parse(this.props.location.search);
        if (qs_values.redirectTo) {
            this.props.history.push(qs_values.redirectTo)
        }
        else {
            this.props.history.push('/welcome')
        }
    }

    render() {
        const selectedOrganizationID = this.props.user.selectedOrganization._id;
        return (

            <div style={{
                height: '800px',
                width: '100%',
                padding: '30px',
                paddingTop: '70px',
                position: 'absolute'
            }}>

             <ScrollArea
                    speed={0.8}
                    horizontal={false}
                    smoothScrolling={true}
                    style={{
                        height:  '700px'
                    }}>
            {this.props.user ?
                <div>
                    {this.props.user.organizations.map(org => (
                        org ?
                            <OrganizationCard key={org.organization_id._id} 
                            organization={org} 
                            selectedOrganizationID={selectedOrganizationID} 
                            changeOrganization={(organization_id) => this.changeOrganization(organization_id)} 
                            canAssignLicense={ org.organization_id.owner === this.props.user.user_id }></OrganizationCard>
                        : null
                    ))} 

                    { canAddNewOrganization(this.props.subscriptions, this.props.organizations, this.props.user.user_id ) ?
                        <Button style={{
                            margin: '0 auto',
                            marginBottom: '10px',
                            width: '170px',
                            display: 'block',
                            textAlign: 'center',
                        }} 
                        href="/organization_select">Add New</Button> 
                    :null}

                    

                    <Button style={{
                        margin: '0 auto',
                        marginBottom: '10px',
                        width: '170px',
                        display: 'block',
                        textAlign: 'center',
                    }} 
                    onClick={() => this.handleBack()}>Go Back</Button>
                </div>
            :
            null}
            </ScrollArea>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    success: state.organization.selection_success,
    subscriptions: state.user.userData.subscriptions,
    organizations: state.user.userData.organizations,
    user: state.user.userData
})


export default connect(mapStateToProps)(OrganizationChange);