import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reInviteUser } from '../../../Redux/Actions/user_actions';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));

function Reinvite(props) {
  const classes = useStyles();
  const { dispatch } = props;

  const [confirmSendInviteDialog, setConfirmSendInviteDialog] = useState(false);
  const [inviteSentAlert, setInviteSentAlert] = useState(false);

  const handleOpenConfirmSendInviteDialog = () => {
    setConfirmSendInviteDialog(true);
  };

  const handleOpenInviteSentAlert = () => {
    setInviteSentAlert(true);
  };

  const handleCloseConfirmSendInviteDialog = (send) => {
    if (send) {
      dispatch(reInviteUser(props.user.id)).then((repsonce) => {
        if (repsonce.payload.success) {
          handleOpenInviteSentAlert(true);
        }
      });
    }

    setConfirmSendInviteDialog(false);
  };

  const handleCloseInviteSentAlert = () => {
    setInviteSentAlert(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpenConfirmSendInviteDialog}>
        Re-Invite User
      </Button>
      <Dialog
        open={confirmSendInviteDialog}
        onClose={() => handleCloseConfirmSendInviteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to re-invite {props.user.name} {props.user.lastname} ({props.user.email})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmSendInviteDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleCloseConfirmSendInviteDialog(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={inviteSentAlert} onClose={handleCloseInviteSentAlert} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Success'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">A re-invite was sent to {props.user.email}.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInviteSentAlert} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  success: state.user.reinviteSuccess,
});

export default connect(mapStateToProps)(Reinvite);
