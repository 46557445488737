import React, { useState } from 'react'
import { connect } from 'react-redux';

import { update, generateData, isFormValid } from '../Utils/Forms/FormActions'

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContent from '../UI/mySnackbarContent';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        marginLeft: theme.spacing(50),
        marginRight: theme.spacing(70),
        marginTop: theme.spacing(20),
        height: '450px',
        width: '350px'
    },
}));

function OrganizationSelect(props) {
    const classes = useStyles();

    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formData, setFormData] = useState({
        organizationAppID: {
            element: 'input',
            value: '',
            config: {
                name: 'organizationAppID',
                type: 'text',
                label: ' Organization App ID'
            },
            validation: {
                required: true,
                email: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        }
    });

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
    };

    const submitForm = (event) => {
        event.preventDefault();

        generateData(formData, 'organization_select');
        let formIsValid = isFormValid(formData, 'organization_select');

        if (formIsValid) {
            // this.props.dispatch(loginUser(dataToSubmit)).then(responce => {
            //     if(responce.payload.loginSuccess){
            //         console.log(responce.payload);
            //         this.props.history.push('/welcome');
            //     }else{
            //         this.setState({
            //             formError: true
            //         })
            //     }
            // });
        }
        else {
            setFormError(true);
        }
    }

    const updateForm = (element) => {
        const newFormData = update(element, formData, 'organization_select');

        setFormError(false);
        setFormData(newFormData);
    }
        
    return (
        <div style={{
            background: `url(${LoginBackground}) no-repeat`,
            height: '800px',
            position: 'absolute'
        }}>
            <Paper className={classes.root} elevation={5}>
                <h2 className="h2login">Organization</h2>

                {props.subscriptions && props.subscriptions.length > 0 ?
                    // (props.subscription.canAddOrganization) ?
                        <div>
                            <p>Create new organization:</p>
                            <Button variant="contained"
                                href="/organization/create"
                                style={{
                                    backgroundColor: '#005773',
                                    color: 'white',
                                    fontWeight: [500],
                                    width: '220px'
                                }}>
                                Create Organization
                            </Button>
                        </div>
                    // :
                    // <Typography>You have reached your limit on adding organizations. Please contact IQFin for more informtion.</Typography>
                    
                    :
                    <Typography>You do not have a subsctiption to create a organization.</Typography>
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}

                        open={successOpen}
                        autoHideDuration={6000}
                        onClose={handleSuccessClose}
                    >
                    <MySnackbarContent
                        onClose={handleSuccessClose}
                        variant="success"
                        message="The verification email was resent. Please check your inbox."
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}

                        open={errorOpen}
                        autoHideDuration={6000}
                        onClose={handleErrorClose}
                    >
                    <MySnackbarContent
                        onClose={handleErrorClose}
                        variant="error"
                        message="An Error accured. Please try again later."
                    />
                </Snackbar>
            </Paper>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    subscriptions: state.user.userData.subscriptions,
    user: state.user.userData
})


export default connect(mapStateToProps)(OrganizationSelect);