import { GET_CORPORATESTRATEGY_ISSUES, 
    ARCHIVE_CORPORATESTRATEGY_ISSUE,
    GET_DISTINCT_AREAS,
    ADD_CORPORATESTRATEGY_ISSUE,
    UPDATE_CORPORATESTRATEGY_ISSUE,
    REMOVE_CORPORATESTRATEGY_ISSUE,
    GET_STRATEGIC_OBJECTIVES,
    UPDATE_STRATEGIC_OBJECTIVES,
    GET_FS_BUSINESS_UNIT_ISSUE,
    GET_ISSUES_BY_USER,
    UPDATE_STATUS,
    UPDATE_APPROVAL,
    GET_ARCHIVED_ISSUES,
    GET_FILTERED_ISSUES,
    RESTORE_ARCHIVED_ISSUE } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_CORPORATESTRATEGY_ISSUES:
            return {...state, issues: action.payload }
        case GET_FILTERED_ISSUES:
            return {...state, filtered: action.payload }
        case GET_ARCHIVED_ISSUES:
            return {...state, archived_issues: action.payload }
        case GET_FS_BUSINESS_UNIT_ISSUE:
            return {...state, issues: action.payload }
        case ADD_CORPORATESTRATEGY_ISSUE:
            return {...state, issue: action.payload }
        case UPDATE_CORPORATESTRATEGY_ISSUE:
            return {...state, edit_success: action.payload.success }
        case ARCHIVE_CORPORATESTRATEGY_ISSUE:
            return {...state, success: action.payload.success }
        case REMOVE_CORPORATESTRATEGY_ISSUE:
            return {...state, remove_success: action.payload.success }
        case RESTORE_ARCHIVED_ISSUE:
            return {...state, success: action.payload.success }
        case GET_DISTINCT_AREAS:
            return {...state, distinct_areas: action.payload }
        case GET_STRATEGIC_OBJECTIVES:
            return {...state, strategic_objectives: action.payload }
        case GET_ISSUES_BY_USER:
            return {...state, issues_by_user: action.payload } 
        case UPDATE_STATUS:
            return {...state, success: action.payload.success }
        case UPDATE_APPROVAL:
            return {...state, success: action.payload.success }
        case UPDATE_STRATEGIC_OBJECTIVES:
            return {...state, strategic_objectives: { updateStatus : action.payload.success } }
        default:
            return state;
    }
}