import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import EULA from './EULA';




const styles = {
    footer: {
        //position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: '20px',
        padding: '5px',
        textAlign: 'center'
    }
}




function Footer(props) {
    const { classes } = props;

    return (
        <footer className={classes.footer}>
            V1.1.2 - &copy; Copyright 2019 IQFin Strategy Tracker - <EULA/>
            <br/>
            <div style={{}}>Designed and Developed by <a href="https://www.codem.co.za">Codem (Pty) Ltd</a></div>
        </footer>
    )
}

export default withStyles(styles)(Footer)
