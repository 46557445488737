import React from 'react';
import Paper from '@material-ui/core/Paper';
import ProfileButtons from '../../Components/UI/profileButtons';
import Footer from '../../Components/Footer';
import { Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    paper: {
        backgroundColor: '#f2f2f2',
        margin: 'auto',
        marginTop: '50px',
        position: 'relative'
    }
}))


const Layout = (props) => {
    const classes = useStyles();
    return (
        <div className="layout">
            <Paper 
            className={clsx('paper', classes.paper)}
            square={true} 
            elevation={2}
            styles={{
                background: `url(${ props.layout_background })`,
            }}>
                <ProfileButtons />
                    {props.children}
            </Paper>
            <Footer/>
        </div>
    );
};

export default Layout;