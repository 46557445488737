import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import FormField from '../../Utils/Forms/FormField';
import FieldHelpInfo from '../fieldHelpInfo';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing.unit * 4,
        position: 'relative'
    },
  });

const PaperField = ({formData, id, change, classes }) => {
    return (
        <Paper className={classes.root} elevation={5}>
            <FormField
                id={id}
                formData={formData}
                change={(element) => change(element)}
            />
            <FieldHelpInfo formData={formData}/>
         </Paper>
    );
};

PaperField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaperField);