import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getGroupStrategicObjectives } from '../../Redux/Actions/group_strategic_objectives_actions'

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ScrollArea from 'react-scrollbar';

import Badge from '@material-ui/core/Badge';
//import Tooltip from '@material-ui/core/Tooltip'

//import FileSaver  from 'file-saver';
//import moment from 'moment';

const styles = theme => ({
    list: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        padding: '10px',
        minWidth: '200px',
        width: '460px'
    },
    title: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    },
    fab: {
        backgroundColor: 'rgb(242, 242, 242)',
        backgroundPosition: '16px 10px',
        '&:hover': {
            backgroundColor: 'rgb(242, 242, 242)'
        },
        color: '#0a1c2d',
        marginTop: theme.spacing.unit * 7,
        marginRight: theme.spacing.unit * 2,
        position: 'absolute',
        right: '0',
        zIndex: '10'
    },
    drawer: {
        paddingRight: '10px'
    },
    footer:{
        textAlign: 'center',
        margin: '25px'
    },
    badge:{
        bottom: '18px',
        top: 'unset',
        backgroundColor: 'rgb(0, 87, 115)',
    }
});

class KeyObjectivesDrawer extends Component {

    state = {
        doanloading: false,
        selected_flag: {},
        totalObjectives: 0,
        drawerOpen: false,
        addOpen: false,
        openSelected: false,
        formType: 'Add',
        formError: false,
        formSuccess: '',
        formData: {
            description: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'description',
                    type: 'text',
                    label: 'Description',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            impact: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'impact',
                    type: 'text',
                    label: 'Impact',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            proposed_actions: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'proposed_actions',
                    type: 'text',
                    label: 'Proposed Actions',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            anticipated_outcome: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'anticipated_outcome',
                    type: 'text',
                    label: 'Anticipated Outcome',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            raised_by: {
                value: '',
                validation: {
                    required: false,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            }
        }
    };

    toggleDrawer = (open) => () => {
        this.setState({
            drawerOpen: open,
        });
    };

    componentDidMount(){
        this.getObjectives();
    }

    handleUserChange = (user) => {
        let newFormData = { ...this.state.formData };
        newFormData["raised_by"].value = user;

        this.setState({
            formData: newFormData
        })
        
    }

    getObjectives = () => {
        this.props.dispatch(getGroupStrategicObjectives()).then(response => {
            if(this.props.group_strategic_objectives){
                this.setState({
                    totalObjectives: this.props.group_strategic_objectives.length
                })
            }
        });
    }

    handleCloseSelected = () => {
        this.setState({
            openSelected: false,
            selected_flag: {}
        })
    }

    handlePrint = () => {
        this.setState({
            doanloading: true
        })


        // this.props.dispatch(printFlags()).then(response => {
        //     FileSaver.saveAs(new Blob([this.props.pdf], {type: "application/pdf"}), "flags_" + moment().format("DD_MM_YYYY") + ".pdf");
        //     this.setState({
        //         doanloading: false
        //     })
        // });
    }

    handleOpenSelected = (flag) => {
        this.setState({
            openSelected: true,
            selected_flag: flag
        })
    }

    list = () => {
        const { classes } = this.props;

        return (
            <div style={{ position: 'relative' }}>
                <Typography variant="title" className={classes.title}>
                    Objectives                     
                    <Button
                        variant="text"
                        onClick={this.toggleDrawer(false)}
                        style={{
                            fontWeight: [500],
                            fontSize: '14px',
                            width: '150px',
                            float:"right",
                        }}>
                        Close
                    </Button>
                </Typography>


                <div className={classes.list}>
                    <List dense={true} >
                        <ScrollArea
                            speed={0.8}
                            horizontal={false}
                            smoothScrolling={true}
                            className={classes.list}>
                            {this.props.group_strategic_objectives ? this.props.group_strategic_objectives.map(item => (
                                <ListItem key={item._id}>
                                    <ListItemText
                                        primary={item.objective}
                                        secondary={item.type}
                                    />
                                </ListItem>
                            ))
                                :
                                <div>No Items in list</div>
                            }
                        </ScrollArea>
                    </List>
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                
                <Fab variant="extended" className={classes.fab} onClick={this.toggleDrawer(true)}>
                    <Badge color="primary" badgeContent={this.state.totalObjectives} classes={{ badge: classes.badge }} >
                        Show Key Objectives
                    </Badge>
                </Fab>
                
                <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} className={ classes.drawer }>
                    <div
                        tabIndex={0}
                        >
                        { this.list() }
                    </div>
                    <div className={classes.footer}>
                        {/* <Button
                            variant="contained"
                            onClick={() => this.handlePrint()}
                            style={{
                                backgroundColor: '#005773',
                                fontWeight: [500],
                                fontSize: '14px',
                                width: '200px',
                                color: 'white',
                                marginTop: '20px',
                                margin: '5px',
                            }}>
                            DOWNLOAD
                        </Button> */}
                    </div>
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    group_strategic_objectives: state.group_strategic_objectives.group_strategic_objectives
})

export default connect(mapStateToProps)(withStyles(styles)(KeyObjectivesDrawer))