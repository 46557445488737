import { GET_ORGANIZATIONAL_VALUES,
    GET_BYID_ORGANIZATIONAL_VALUE,
    CREATE_ORGANIZATIONAL_VALUE,
    UPDATE_ORGANIZATIONAL_VALUE,
    REMOVE_ORGANIZATIONAL_VALUE,
    GET_VISION_MISSION,
    UPDATE_VISION_MISSION,
    GET_ORG_DATA } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_ORGANIZATIONAL_VALUES:
            return {...state, organizational_values: action.payload }
        case GET_BYID_ORGANIZATIONAL_VALUE:
            return {...state, organizational_value: action.payload  }
        case CREATE_ORGANIZATIONAL_VALUE:
            return {...state, success: action.payload.success }
        case UPDATE_ORGANIZATIONAL_VALUE:
            return {...state, success: action.payload.success  }
        case REMOVE_ORGANIZATIONAL_VALUE:
            return {...state, success: action.payload.success  }
        case GET_VISION_MISSION:
            return {...state, vision_mission : {vision: action.payload.vision, mission: action.payload.mission, purpose: action.payload.purpose }}
        case UPDATE_VISION_MISSION:
            return {...state, data: action.payload }
        case GET_ORG_DATA:
            return {...state, success: action.payload.success, data: action.payload }
        default:
            return state;
    }
}