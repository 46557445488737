import React from 'react'
import jsPDF from 'jspdf';  
import html2canvas from 'html2canvas';

import TextButton from '../UI/Buttons/textButton';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { Fragment } from 'react';


function PrintalbeScreen(props) {
    const printDocument = () => {  
        const input = document.getElementById('pdfdiv');  

        html2canvas(input)  
          .then((canvas) => { 
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF('l', 'px', [ canvas.width, canvas.height ]);

            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);  
            pdf.setProperties({
                    title: props.title ? props.title : new Date().toDateString(),
            });
            
            window.open(pdf.output('bloburl'), '_blank');
        });
    }
    return (
        <Fragment>
        <Box id='pdfdiv' >
            { props.children }
        </Box>
        <Box display='flex' flexDirection='row' justifyContent="flex-end">
            <TextButton onClick={printDocument}>
                Print
            </TextButton>
        </Box>
        </Fragment>
    )
}

export default PrintalbeScreen
