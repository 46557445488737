import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SelectOrganization, ClearSelection } from '../../Redux/Actions/organization_actions';

import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';

import Paper from '@material-ui/core/Paper';
//import Button from '@material-ui/core/Button';

// import { withStyles } from '@material-ui/core/styles';

// import OrganizationCard from '../UI/Cards/organizationCard';

import { canAddNewOrganization } from '../Utils/OrganizationActions';
import { logoutUser } from '../../Redux/Actions/user_actions';

import ScrollArea from 'react-scrollbar';
import { ButtonGroup, makeStyles, Typography, Grid, Container } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import PrimaryButton from '../UI/Buttons/primaryButton';
import TextButton from '../UI/Buttons/textButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  scroll: {
    height: '700px',
  },
  paper: {
    marginTop: '100px',
    padding: theme.spacing(2),
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childCell: {
    border: 'none',
    marginTop: '0',
    paddingTop: '0',
  },
});

function EntityList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const selectSuccess = useSelector((state) => state.organization.selection_success);
  const organizations = useSelector((state) => state.user.userData.organizations);
  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (selectSuccess) {
      dispatch(ClearSelection());

      const qs_values = queryString.parse(location.search);
      if (props.redirectOnQueryString && qs_values.redirectTo) {
        history.push(qs_values.redirectTo);
      } else {
        props.redirectUrl ? history.push(props.redirectUrl) : history.push('/welcome');
      }
    }
  }, [selectSuccess]);

  const changeEntity = (entityId) => {
    if (entityId) {
      dispatch(SelectOrganization(entityId));
    }
  };

  const logoutHandler = () => {
    dispatch(logoutUser()).then((responce) => {
      if (responce.payload.success) {
        history.push('/');
      }
    });
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">
            <PrimaryButton onClick={() => changeEntity(row.id)}>Select</PrimaryButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small">
                  <TableBody>
                    {row.businessUnits && row.businessUnits.length > 0 ? (
                      row.businessUnits.map((bu, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.childCell}>
                            <Typography variant="caption" component="small" color="primary">
                              Business
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.childCell} scope="row" width="100%">
                            {bu.name}
                          </TableCell>
                          <TableCell className={classes.childCell}></TableCell>
                          <TableCell className={classes.childCell} align="right">
                            <TextButton onClick={() => changeEntity(bu.id)}>Select</TextButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell className={classes.childCell} component="th" scope="row" colSpan={4}>
                          No Business Units was found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const groupTable = () => {
    if (user) {
      let groups = organizations.filter((item) => item.organization_id.isGroup);
      let finalEntities = [];

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        let groupEntities = organizations.filter((item) => item.organization_id.parent && item.organization_id.parent._id == group.organization_id._id);

        const _entity = {
          id: group.organization_id._id,
          name: group.organization_id.name,
          businessUnits: [],
        };

        for (let k = 0; k < groupEntities.length; k++) {
          const gEnt = groupEntities[k];
          _entity.businessUnits.push({
            id: gEnt.organization_id._id,
            name: gEnt.organization_id.name,
          });
        }

        finalEntities.push(_entity);
      }

      return finalEntities.length > 0 ? (
        <React.Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Groups</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{finalEntities.map((entity) => (entity ? <Row row={entity} /> : null))}</TableBody>
          </Table>
        </React.Fragment>
      ) : null;
    } else {
      return null;
    }
  };

  const businessTable = () => {
    if (user) {
      let businesses = organizations.filter((item) => !item.organization_id.isGroup && !item.organization_id.parent);
      let finalEntities = [];

      for (let i = 0; i < businesses.length; i++) {
        const business = businesses[i];

        const _entity = {
          id: business.organization_id._id,
          name: business.organization_id.name,
        };

        finalEntities.push(_entity);
      }

      return finalEntities.length > 0 ? (
        <React.Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bsinesses</TableCell>
                <TableCell></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalEntities.map((item, index) => (
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">
                    <PrimaryButton onClick={() => changeEntity(item.id)}>Select</PrimaryButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </React.Fragment>
      ) : null;
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <BackgroundItem path={GeneralBackground} />
      <Container maxWidth="md" style={{ position: 'relative' }}>
        <Paper className={classes.paper}>
          <ScrollArea
            speed={0.8}
            horizontal={false}
            smoothScrolling={true}
            style={{
              height: '600px',
            }}
          >
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={12}>
                {groupTable()}
                {businessTable()}

                {user.plan ? (
                  canAddNewOrganization(user.plan, organizations, user.user_id) ? (
                    <PrimaryButton href="/organization/create">Add New</PrimaryButton>
                  ) : null
                ) : (
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={1} m={2}>
                    <Typography>Want to add your own organization?</Typography>
                    <PrimaryButton href="/payment/select-plan">Select plan</PrimaryButton>
                  </Box>
                )}
                <PrimaryButton onClick={() => logoutHandler()}>Logout</PrimaryButton>
              </Grid>
            </Grid>
          </ScrollArea>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

export default EntityList;

EntityList.PropTypes = {
  redirectUrl: PropTypes.string,
  redirectOnQueryString: PropTypes.bool,
};
