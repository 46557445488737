import axios from 'axios';
import { GET_PLANS,
        GET_PLAN,
        CREATE_PLAN,
        UPDATE_PLAN,
        REMOVE_PLAN 
    } from './types'
import { PLAN_SERVER } from '../../Components/Utils/Misc'


export function getPlans(){
    const request = axios.get(`${PLAN_SERVER}`)
        .then(responce => responce.data);
        
        return {
            type: GET_PLANS,
            payload: request
        }
}

export function getPlan(id){
    const request = axios.get(`${PLAN_SERVER}/${id}`)
        .then(responce => responce.data);
        
        return {
            type: GET_PLAN,
            payload: request
        }
}

export function createPlan(data){
    const request = axios.post(`${PLAN_SERVER}/`,data)
        .then(responce => responce.data);
        
        return {
            type: CREATE_PLAN,
            payload: request
        }
}

export function updatePlan(data){
    const request = axios.put(`${PLAN_SERVER}/`,data)
        .then(responce => responce.data);
        
        return {
            type: UPDATE_PLAN,
            payload: request
        }
}

export function removePlan(id){
    const request = axios.delete(`${PLAN_SERVER}/${id}`)
        .then(responce => responce.data);
        
        return {
            type: REMOVE_PLAN,
            payload: request
        }
}