import axios from 'axios';
import { GUIDE_STEPS } from './types'
import { GUIDE_SERVER } from '../../Components/Utils/Misc'

export function getAllGuides(){
    const request = axios.get(`${GUIDE_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GUIDE_STEPS,
            payload: request
        }
}