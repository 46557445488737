import axios from 'axios';
import { GET_STATUSES } from './types';
import { STATUS_SERVER } from '../../Components/Utils/Misc';

export function getStatuses(){
    const request = axios.get(`${STATUS_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_STATUSES,
            payload: request
        }
}