import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BackendBackground from '../../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import FilterBar from '../ReportLayouts/filterBar';
import PrintLayout from '../ReportLayouts/printLayout';
import Deliverables from '../ReportLayouts/delivrables';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative',
        padding: '10px',
    },
    table: {
        minWidth: 700,
    },
}))


const BusinessFUDeliverables = () => {
    const [loadingReport, setLoadingReport] = useState(false);
    const classes = useStyles()
    const [filterData, setFilterData] = useState();
    const [filteredIssues, setFilteredIssues] = useState([])

    const handleFilter = (issues, data) => {

        //Select only Risk Issues
        let newIssues = [];
        for(let i = 0; i < issues.length; i++){
            if(issues[i].business_unit){
                newIssues.push(issues[i])
            }
        }

        setFilteredIssues(newIssues);
        setFilterData(data);
    }

    return (
        <div>      
        <BackendBackground />
        <Paper className={classes.root}>

            <FilterBar onFilter={(issues, data) => handleFilter(issues, data)} functionalUnitFilter={true} />

            { filteredIssues && filterData?.entity ? 
            <PrintLayout landscape={true} title="Score Card">
                <Deliverables entity={filterData?.entity} issues={filteredIssues} corporate={true}/>
            </PrintLayout> : null }
        </Paper>
    </div>
    )
}

export default BusinessFUDeliverables