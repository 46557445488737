import axios from 'axios';
import { GETBYID_COMMENTS, CREATE_COMMENT } from './types';
import { COMMENTS_SERVER } from '../../Components/Utils/Misc';

export function getById(id){
    const request = axios.get(`${COMMENTS_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GETBYID_COMMENTS,
            payload: request
        }
}

export function create(data){
    const request = axios.post(`${COMMENTS_SERVER}/`, data)
        .then(responce => responce.data);

        return {
            type: CREATE_COMMENT,
            payload: request
        }
}