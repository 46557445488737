import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { addArea, updateArea, getCustomAreas, removeArea } from '../../Redux/Actions/area_actions';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid, clearFields } from '../Utils/Forms/FormActions';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContent from '../UI/mySnackbarContent';


const styles = {
    
}

class AddEditDialog extends Component {
    state = {
        areas: [],
        dialogOpen : false,
        dialogOpenDelete: false,
        selectedArea: {},
        fromType: "Add",
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: false,
        formData: {
            name: {
                element: 'input',
                value: '',
                config: {
                    name: 'name',
                    type: 'input',
                    label: 'Name',
                    placeholder: 'Enter focus area Name',
                    limit: 20
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: 'Please enter a valid name',
                touched: false,
                showLabel: true
            },
            custom: {
                element: 'hidden',
                value: true,
                config: {
                    name: 'custom',
                    type: 'input',
                    label: 'Custom'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
            },
            type:{
                element: 'hidden',
                value: this.props.areaType,
                config: {
                    name: 'type',
                    type: 'input',
                    label: 'Type'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
            }
        }
    }

    componentDidMount = () => {
        this.getAreas();
    }

    getAreas = () => {
        this.props.dispatch(getCustomAreas()).then(responce => {
            this.setAreaIssueCount();
        });
    }

    setAreaIssueCount = () => {
        if(this.props.areas && this.props.areas.length > 0){
            let newAreas = [ ...this.props.areas ];

            newAreas.forEach((area, index) => {
                let area_issue_count = 0;

                if (this.props.issues && this.props.issues.length !== 0) {
                    this.props.issues.forEach((issue, index) => {
                        if (issue.area._id === area._id) {
                            area_issue_count = area_issue_count + 1;
                        }
                        else if(issue.kpi === area.name && this.props.area_type){
                            area_issue_count = area_issue_count + 1;
                        }
                    })
                }

                newAreas[index].issue_count = area_issue_count;
            })
            this.setState({
                areas: newAreas
            })  
        }
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'create_area');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();

        let dataToSubmit = generateData(this.state.formData, 'create_area');
        let formIsValid = isFormValid(this.state.formData, 'create_area');

        

        if (formIsValid) {

            if(this.state.fromType === "Add")
            {
                this.props.dispatch(addArea(dataToSubmit)).then(responce => {
                    if (this.props.success) {
                        this.getAreas();

                        this.setState({
                            formSuccess: true,
                            dialogOpen: false
                        })  

                        clearFields(this.state.formData, "add_areas");
                    } else {
                        //const error = responce.payload.err;

                        this.setState({
                            formError: true
                        })
                    }
                });
            }
            else{
                this.props.dispatch(updateArea(this.state.selectedArea._id, dataToSubmit)).then(responce => {
                    if (this.props.success) {
                        this.props.dispatch(getCustomAreas());

                        this.setState({
                            formSuccess: true,
                            dialogOpen: false
                        })  
                        
                        clearFields(this.state.formData, "edit_areas");
                    } else {
                        //const error = responce.payload.err;

                        this.setState({
                            formError: true
                        })
                    }
                });
            }

            
        }
        else {
            this.setState({
                formErrorMsg: 'This creating is invalid.',
                formError: true
            })
        }
    }

    handleDialogClose = () => {
        this.setState({
            dialogOpen : false
        })        
    }

    handleOpenAddEdit = (type, area) => {
        if(type === "Edit"){
             let newFormData = { ...this.state.formData };
            newFormData['name'].value = area.name;
        }
       
        this.setState({
            dialogOpen: true,
            fromType: type,
            selectedArea: type === "Edit" ? area : {}
        })
    }

    handleSnackClose = () => {
        this.setState({
            formSuccess: false
        })
    }

    handleOpenDelete = (area) => {
        this.setState({
            dialogOpenDelete: true,
            selectedArea: area
        })
    }

    handleCancelDelete = () => {
        
    }

    handleDelete = (area) => {
        this.props.dispatch(removeArea(area._id)).then(response => {
            this.getAreas();
            
            this.setState({
                dialogOpenDelete: false,
                selectedArea: []
            })
        })
    }

    snackbarConent = () => (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={this.state.formSuccess}
            autoHideDuration={6000}
            onClose={() => this.handleSnackClose()}
        >
        <MySnackbarContent
          variant="success"
          message="This is a success message!"
        />
      </Snackbar>
    )

    renderEditAddDialog = () => (
        <Dialog
            fullWidth={true}
            open={this.state.dialogOpen}
            onClose={this.handleDialogClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{this.props.action} focus area.</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the name of the focus area you would like to add.
                </DialogContentText>

                <FormField
                    id={'name'}
                    formData={this.state.formData.name}
                    change={(element) => this.updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
                Cancel
            </Button>
            <Button onClick={(event) => this.submitForm(event)} color="primary">
                {this.state.fromType === "Add" ? "Add" : "Update"}
            </Button>
            </DialogActions>
        </Dialog>
    )

    renderDelete = () =>(
        <Dialog
        open={this.state.dialogOpenDelete}
        onClose={() => this.handleCancelDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this Focus Area
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCancelDelete()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.handleDelete(this.state.selectedArea)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )

    render() {
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    open={this.props.open}
                    onClose={() => this.props.close(true)}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">
                        <div>Manage Focus Areas</div>
                        <Button onClick={() => this.handleOpenAddEdit("Add")} color="primary">
                            Add New
                        </Button>  
                    </DialogTitle>
                    <DialogContent>

                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Total Issues</TableCell>
                                <TableCell>Action</TableCell>   
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { this.state.areas ? 
                            this.state.areas.map((area, index) => (
                                <TableRow key={index}>
                                    <TableCell>{area.name}</TableCell>
                                    <TableCell style={{ textTransform: 'capitalize' }}>{area.type ? area.type : 'Corporate'}</TableCell>
                                    <TableCell>{ area.issue_count ? area.issue_count : 0 }</TableCell>
                                    <TableCell>
                                        <Button onClick={() => this.handleOpenAddEdit("Edit", area)} color="primary">
                                            Edit
                                        </Button>
                                        { area.issue_count === 0 ?
                                        <Button onClick={() => this.handleOpenDelete(area)} color="secondary">
                                            Delete
                                        </Button>
                                        : null }
                                    </TableCell>
                                </TableRow>
                            ))
                        :
                         <div>
                             <br />
                             Add your first custom area.<br />
                             <Button onClick={() => this.handleOpenAddEdit("Add")} color="primary">
                                Add New
                            </Button>  
                         </div>
                        }
                        </TableBody>
                    </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.close(true)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.renderEditAddDialog()}
                {this.renderDelete()}
                {this.snackbarConent()}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    areas: state.areas.custom_areas,
    area : state.areas.area,
    success: state.areas.success,
    issues: state.strategy_issues.issues
})

export default connect(mapStateToProps)(withStyles(styles)(AddEditDialog));