import axios from 'axios';
import { GET_NOTES, GET_NOTE, CREATE_NOTE, REMOVE_NOTE, EDIT_NOTE } from './types';
import { NOTE_SERVER } from '../../Components/Utils/Misc';

export function getNotes(){
    const request = axios.get(`${NOTE_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_NOTES,
            payload: request
        }
}

export function getNote(id){
    const request = axios.get(`${NOTE_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_NOTE,
            payload: request
        }
}

export function createNote(data){
    const request = axios.post(`${NOTE_SERVER}`,data)
        .then(responce => responce.data);

        return {
            type: CREATE_NOTE,
            payload: request
        }
}

export function updateNote(id, data){
    const request = axios.put(`${NOTE_SERVER}/${id}`,data)
        .then(responce => responce.data);

        return {
            type: EDIT_NOTE,
            payload: request
        }
}

export function removeNote(id){
    const request = axios.delete(`${NOTE_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_NOTE,
            payload: request
        }
}