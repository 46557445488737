import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { create, getById } from '../../Redux/Actions/comments.acctions';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Grid, makeStyles, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';

import PrimaryButton from '../UI/Buttons/primaryButton';
import SubmitButton from '../UI/Buttons/submitButton';
import Form from '../UI/Form';
import Input from '../UI/Form/input';

import moment from 'moment';
import ScrollArea from 'react-scrollbar';
import MyCircularProgress from '../UI/ProgressBars/myCircularProgress'

const useStyles = makeStyles(theme => ({
    input:{
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    c_paper: {
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2)
    }
}))

const schema = yup.object().shape({
    comment: yup.string().required('Please enter a comment.'),
    commentType: yup.string().required('Please select a type.'),
    id: yup.string().required('Please select a id.'),
})


function Comments(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const comments = useSelector(state => state.comments.comments)

    const { register, handleSubmit, errors, reset } = useForm({
        mode:'onSubmit',
        resolver: yupResolver(schema)
    })


    const onSubmit = (data) => {
        dispatch(create(data)).then(response => {
            reset();
            dispatch(getById(props.id));
        })
    }

    const handleClickOpen = () => {
        setLoading(true);
        dispatch(getById(props.id)).then(response => {
            setLoading(false);
        });

        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <React.Fragment>
            <Tooltip title="Leave a comment">
                <IconButton onClick={handleClickOpen}>
                    <MessageIcon />
                </IconButton>
            </Tooltip>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          onClose={handleClose}
          aria-labelledby="comment-dialog-title"
        >
          <DialogTitle id="comment-dialog-title">Comment</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="flex-start" flexDirection="column">
                { loading ? 
                <MyCircularProgress caption="Loading Comments"/> :
                <Grid direction='column'>
                    <Grid item >
                        <ScrollArea
                        speed={0.8}
                        horizontal={false}
                        smoothScrolling={true}
                        style={{
                            maxHeight: 500
                        }}>
                        { comments && comments.map((comment, index) => (
                            <Paper key={index} className={classes.c_paper}>
                                <Typography variant='caption'>
                                    {comment.user.name} {comment.user.lastname} - <small>{ moment(comment.createdAt).format('YYYY/MM/DD') }</small>
                                </Typography>
                                <Typography>
                                    {comment.comment}
                                </Typography>
                            </Paper>
                        ))}
                        </ScrollArea>
                    </Grid>
                    <Grid item>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <TextField inputRef={register} name="commentType" id="commentType" value={props.commentType} inputProps={{ type: 'hidden' }} />
                            <TextField inputRef={register} name="id" id="id" value={props.id} inputProps={{ type: 'hidden' }} />
                            
                            <Grid container alignItems='flex-end' >
                                <Grid item xs={10}>
                                    <Input
                                        ref={register}
                                        id="comment"
                                        label="Comment"
                                        name="comment"
                                        autoFocus
                                        error={!!errors.comment}
                                        helperText={errors?.comment?.message}
                                        multiline
                                        fullWidth
                                        className={classes.input}
                                        maxrows={4}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton type='submit' >
                                        <SendIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            }
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
}

export default Comments

// Comments.prototype = {
//     id: PropTypes.string.isRequired(),
//     commentType: PropTypes.string.isRequired()
// }
