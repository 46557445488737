import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LockIcon from '@material-ui/icons/Lock'
import CompassCalibration from '@material-ui/icons/CompassCalibration'
import CompassCalibrationOutlined from '@material-ui/icons/CompassCalibrationOutlined'
import PlayForWork from '@material-ui/icons/PlayForWork'
import Permission from '../../../HOC/Permission';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logoutUser } from '../../../Redux/Actions/user_actions';




const MenuItems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user.userData);

  const [openBusinessReports, setOpenBusinessReports] = useState(false)
  const [openFunctionalReports, setOpenFunctionalReports] = useState(false)
  const [openCorporateStrategy, setOpenCorporateStrategy] = useState(false)
  const [openGroupStrategy, setOpenGroupStrategy] = useState(false)
  const [openFunctionalStrategy, setOpenFunctionalStrategy] = useState(false)
  const [openGroupReports, setOpenGroupReports] = useState(false)
  const [openAdmin, setOpenAdmin] = useState(false)


  const handleClick = (type) => {
    switch(type){
      case 'business_reports' :
        setOpenBusinessReports(!openBusinessReports);
      break;
      case 'group_reports' :
        setOpenGroupReports(!openGroupReports);
      break;
      case 'corporate_strategy' :
        setOpenCorporateStrategy(!openCorporateStrategy);
      break;
      case 'group_strategy' :
        setOpenGroupStrategy(!openGroupStrategy);
      break;
      case 'functional_reports' :
        setOpenFunctionalReports(!openFunctionalReports);
      break;
      case 'functional_strategy' :
        setOpenFunctionalStrategy(!openFunctionalStrategy);
      break;
      case 'admin' :
        setOpenAdmin(!openAdmin);
      break;
      default:
    }    
  };

  const logoutHandler = () => {
      dispatch(logoutUser()).then(responce => {
          if(responce.payload.success){
              history.push('/');
          }
      })
  }


    return (
      <List>
        <ListItem button component="a" href="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component="a" href="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component="a" href="/objectives">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={ user && user.selectedOrganization.isGroup ? "Group Key Objectives" : "Key Objectives" } />
        </ListItem>

        { user && user.selectedOrganization.isGroup &&
        <Permission action="corporate.update" user={user} hideObject={true}>
        <Divider />
        <ListSubheader inset>Strategy</ListSubheader>

          <ListItem button onClick={() => handleClick('group_strategy')}>
            <ListItemIcon>
            <CompassCalibration />
            </ListItemIcon>
            <ListItemText primary={"Group Strategy  "}/>
            {openGroupStrategy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGroupStrategy} timeout="auto" unmountOnExit>
            <ListItem button component="a" href={"/group/strategy/issues"}>
              <ListItemText primary="Go to Strategy" />
            </ListItem>
            <ListItem button component="a" href="/group/strategy/tasks">
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button component="a" href="/group/strategy/tasks/archive">
              <ListItemText primary="Archived" />
            </ListItem>
          </Collapse>

            <ListItem button onClick={() => handleClick('corporate_strategy')}>
              <ListItemIcon>
              <CompassCalibration />
              </ListItemIcon>
              <ListItemText primary={"Business Strategy  "}/>
              {openCorporateStrategy ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCorporateStrategy} timeout="auto" unmountOnExit>
              <ListItem button component="a" href={"/entity/business/select"}>
                <ListItemText primary="Go to Business Strategy" />
              </ListItem>
            </Collapse>
        </Permission> }

        { user && !user.selectedOrganization.isGroup &&
        <Permission action="corporate.update" user={user} hideObject={true}>
        <Divider />
        <ListSubheader inset>Strategy</ListSubheader>

          <ListItem button onClick={() => handleClick('corporate_strategy')}>
            <ListItemIcon>
            <CompassCalibration />
            </ListItemIcon>
            <ListItemText primary={"Business Strategy  "}/>
            {openCorporateStrategy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCorporateStrategy} timeout="auto" unmountOnExit>
            <ListItem button component="a" href={"/corporate_strategy/issues"}>
              <ListItemText primary="Go to Strategy" />
            </ListItem>
            <ListItem button component="a" href="/corporate_strategy/tasks">
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button component="a" href="/corporate_strategy/tasks/archive">
              <ListItemText primary="Archived" />
            </ListItem>
          </Collapse>
        </Permission> }

        { user && !user.selectedOrganization.isGroup &&
        <Permission action="functional_strategy.view" user={user} hideObject={true}>
        <ListItem button onClick={() => handleClick('functional_strategy')}>
            <ListItemIcon>
            <CompassCalibrationOutlined />
            </ListItemIcon>
            <ListItemText primary="Functional Strategy" />
            {openFunctionalStrategy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openFunctionalStrategy} timeout="auto" unmountOnExit>
            <ListItem button component="a" href="/functional_strategy/introduction">
              <ListItemText primary="Go to Functional Strategy" />
            </ListItem>
            <ListItem button component="a" href="/functional_strategy/tasks">
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button component="a" href="/functional_strategy/tasks/archive">
              <ListItemText primary="Archived" />
            </ListItem>
          </Collapse>
        </Permission> }
        
        <Permission action="reports.view" user={user} hideObject={true}>
        <Divider />
        <ListSubheader inset>Reports</ListSubheader>

        { user && user.selectedOrganization.isGroup ?
        <div>
          <ListItem button onClick={() => handleClick('group_reports')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Group Reports  " />
            {openGroupReports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openGroupReports} timeout="auto" unmountOnExit>
            <ListItem button component="a" href="/report/group/statements">
              <ListItemText primary="Statements" />
            </ListItem>
            <ListItem button component="a" href="/report/group/score_card">
              <ListItemText primary="Score Card" />
            </ListItem>
            <ListItem button component="a" href="/report/group/risk_register">
              <ListItemText primary="Risk Register" />
            </ListItem>
            <ListItem button component="a" href="/report/group/roadmap">
              <ListItemText primary="Road Map" />
            </ListItem>
            <ListItem button component="a" href="/report/group/issues">
              <ListItemText primary="Group Strategies" />
            </ListItem>
          </Collapse> 
        </div> : null }

          <ListItem button onClick={() => handleClick('business_reports')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Business Reports  " />
            {openBusinessReports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openBusinessReports} timeout="auto" unmountOnExit>
            <ListItem button component="a" href="/report/business/statements">
              <ListItemText primary="Statements" />
            </ListItem>
            <ListItem button component="a" href="/report/business/score_card">
              <ListItemText primary="Score Card" />
            </ListItem>
            <ListItem button component="a" href="/report/business/risk_register">
              <ListItemText primary="Risk Register" />
            </ListItem>
            <ListItem button component="a" href="/report/business/roadmap">
              <ListItemText primary="Road Map" />
            </ListItem>
            <ListItem button component="a" href="/report/business/issues">
              <ListItemText primary="Business Strategies" />
            </ListItem>
            <ListItem button component="a" href="/report/business/bu_deliverables">
              <ListItemText primary="Functional Deliverables" />
            </ListItem>
          </Collapse>

          <ListItem button onClick={() => handleClick('functional_reports')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText  primary="Functional Reports  " />
            {openFunctionalReports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openFunctionalReports} timeout="auto" unmountOnExit>
            <ListItem button component="a" href="/report/functional/score_card">
              <ListItemText primary="Score Card" />
            </ListItem>
            <ListItem button component="a" href="/report/functional/risk_register">
              <ListItemText primary="Risk Register" />
            </ListItem>
            <ListItem button component="a" href="/report/functional/roadmap">
              <ListItemText primary="Road Map" />
            </ListItem>
            <ListItem button component="a" href="/report/functional/issues">
              <ListItemText primary="Functional Strategies" />
            </ListItem>
            {/* <ListItem button component="a" href="/report/functional/rs_deliverables">
              <ListItemText primary="RS Deliverables" />
            </ListItem> */}
          </Collapse>
        </Permission>

        

        <Divider />

        <ListSubheader inset>Admin</ListSubheader>
        <Permission action="super.create" user={user} hideObject={true}>
          <ListItem button component="a" href="/areas">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Areas" />
          </ListItem>
        </Permission>

        <Permission action="users.view" user={user} hideObject={true}>
          <ListItem button component="a" href="/users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Permission>

        <Permission action="roles.view" user={user} hideObject={true}>
          <ListItem button component="a" href="/roles">
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Roles" />
          </ListItem>
        </Permission>

        <Permission action="organization.view" user={user} hideObject={true}>
          <ListItem button component="a" href="/organization">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Organization" />
          </ListItem>
        </Permission>

        <Divider />

        <Permission action="admin.create" user={user} hideObject={true}>
          <ListSubheader inset>System Admin</ListSubheader>
          <ListItem button component="a" href="/admin/licensing">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Company Licensing" />
          </ListItem>
        </Permission>

        <ListItem button onClick={() => logoutHandler()}>
            <ListItemIcon>
              <PlayForWork />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
      </List> 

    )
}


export default MenuItems