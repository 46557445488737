import React, { Component } from 'react';
import ReactPlayer from 'react-player';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import AudioIcon from '../../Resources/images/icons/icon_play.png';
import VideoIcon from '../../Resources/images/icons/icon_video.png';

const styles = theme => ({
    play_button: {
        backgroundColor: '#0a1c2d',
        margin: theme.spacing.unit,
        background: `url(${AudioIcon}) no-repeat`,
        backgroundPosition: '19px 12px',
        '&:hover': {
            backgroundColor: '#0a1c2d'
        }
    },
    video_button: {
        backgroundColor: '#5683A5',
        margin: theme.spacing.unit,
        background: `url(${VideoIcon}) no-repeat`,
        backgroundPosition: '7px 15px',
        '&:hover': {
            backgroundColor: '#5683A5'
        }
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    dialogContent: {
        backgroundColor: 'black',
        height: '100%'
    },
    dialogPaper: {
        paddingTop: '64px',
        height: '500px'
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class MediaIcons extends Component {
    state = {
        openVideo: false,
        openAudio: false
    };


    handleClickVideoOpen = () => {
        this.setState({ openVideo: true });
    };
    handleClickAudioOpen = () => {
        this.setState({ openAudio: true });
    };

    handleVideoClose = () => {
        this.setState({ openVideo: false });
    };
    handleAudioClose = () => {
        this.setState({ openAudio: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className="media_icon_container">
                    {this.props.audioFile ?
                        <Fab classes={{ root: classes.play_button }} onClick={this.handleClickAudioOpen}>
                            <Icon></Icon>
                        </Fab>
                        : null
                    }

                    {this.props.videoFile ?
                        <Fab classes={{ root: classes.video_button }} onClick={this.handleClickVideoOpen}>
                            <Icon></Icon>
                        </Fab>
                        : null
                    }


                </div>
                
                {this.props.audioFile !== '' ?
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={this.state.openAudio}
                        onClose={this.handleVideoClose}
                        TransitionComponent={Transition}
                        classes={{ paper: classes.dialogPaper }}
                    >

                        <DialogContent className={classes.dialogContent}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton color="inherit" onClick={this.handleAudioClose} aria-label="Close">
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <ReactPlayer
                                url={this.props.audioFile}
                                playing
                                controls
                                width='100%'
                                height='100%' />
                        </DialogContent>
                    </Dialog>
                    : null
                }

                {this.props.videoFile !== '' ?
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={this.state.openVideo}
                        onClose={this.handleVideoClose}
                        TransitionComponent={Transition}
                        classes={{ paper: classes.dialogPaper }}
                    >

                        <DialogContent className={classes.dialogContent}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton color="inherit" onClick={this.handleVideoClose} aria-label="Close">
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <ReactPlayer
                                url={this.props.videoFile}
                                playing
                                controls
                                width='100%'
                                height='100%' />
                        </DialogContent>
                    </Dialog>
                    : null
                }
            </div>
        );
    }
}

export default withStyles(styles)(MediaIcons);
