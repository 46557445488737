import axios from 'axios';
import { GET_GROUP_STRATEGIC_OBJECTIVES,
         GET_GROUP_STRATEGIC_OBJECTIVES_BYID,
         ADD_GROUP_STRATEGIC_OBJECTIVE,
         UPDATE_GROUP_STRATEGIC_OBJECTIVE,
         REMOVE_GROUP_STRATEGIC_OBJECTIVE} from './types';
import { GROUP_STRATEGIC_OBJECTIVES_SERVER } from '../../Components/Utils/Misc';

export function getGroupStrategicObjectives(){
    const request = axios.get(`${GROUP_STRATEGIC_OBJECTIVES_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_GROUP_STRATEGIC_OBJECTIVES,
            payload: request
        }
}

export function getGroupStrategicObjectiveByID(id){
    const request = axios.get(`${GROUP_STRATEGIC_OBJECTIVES_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_GROUP_STRATEGIC_OBJECTIVES_BYID,
            payload: request
        }
}

export function addGroupStrategicObjective(item){
    const request = axios.post(`${GROUP_STRATEGIC_OBJECTIVES_SERVER}`, item)
        .then(responce => responce.data);

        return {
            type: ADD_GROUP_STRATEGIC_OBJECTIVE,
            payload: request
        }
}

export function updateGroupStrategicObjective(id, item){
    const request = axios.put(`${GROUP_STRATEGIC_OBJECTIVES_SERVER}/${id}`, item)
        .then(responce => responce.data);

        return {
            type: UPDATE_GROUP_STRATEGIC_OBJECTIVE,
            payload: request
        }
}

export function removeGroupStrategicObjective(id){
    const request = axios.delete(`${GROUP_STRATEGIC_OBJECTIVES_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_GROUP_STRATEGIC_OBJECTIVE,
            payload: request
        }
}
