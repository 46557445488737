import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BackendBackground from '../../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import FilterBar from '../ReportLayouts/filterBar';
import RiskRegister from '../ReportLayouts/riskRegister';
import PrintLayout from '../ReportLayouts/printLayout';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative',
        padding: '10px',
    },
    table: {
        minWidth: 700,
    },
}))


const BusinessRiskRegister = () => {
    const [loadingReport, setLoadingReport] = useState(false);
    const classes = useStyles()
    const [filterData, setFilterData] = useState();
    const [filteredIssues, setFilteredIssues] = useState([])

    const handleFilter = (issues, data) => {
        setFilteredIssues(issues.filter(issue => issue.issueStrategyType == 'business' && issue.strategic_objective === 'Risk'));
        
        setFilterData(data);
    }

    return (
        <div>
            
            <BackendBackground />
            <Paper className={classes.root}>

                <FilterBar onFilter={(issues, data) => handleFilter(issues, data)} />

                { filteredIssues && filterData?.entity ? 
                <PrintLayout landscape={true} title="Score Card">
                    <RiskRegister entity={filterData?.entity} issues={filteredIssues} corporate={true}/>
                </PrintLayout> : null }
            </Paper>
        </div>
    )
    
}


export default BusinessRiskRegister