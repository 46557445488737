import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles} from '@material-ui/core/styles'

import { getArchivedIssues, restoreArchivedIssue } from '../../../Redux/Actions/issues_actions'

import BackendBackground from '../../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Permission from '../../../HOC/Permission';

import moment from 'moment';

import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContent from '../../UI/mySnackbarContent';

const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    action_button:{
        margin: theme.spacing.unit,
    }
});

class Archive extends Component {
    state={
        showSuccess : false
    }

    componentDidMount() {
        this.props.dispatch(getArchivedIssues());
    }

    handleRestoreIssue = (issue) => {
        if(issue){
            this.props.dispatch(restoreArchivedIssue(issue._id)).then(repsonce => {
                if(this.props.success){
                    this.setState({
                        showSuccess: true
                    })
                }
                this.props.dispatch(getArchivedIssues());
            })
        }
    }

    handleSnackClose = () => {
        this.setState({
            showSuccess: false
        })
    }

    restoreSnackConent = () => (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            open={this.state.showSuccess}
            autoHideDuration={6000}
            onClose={() => this.handleSnackClose()}
        >
        <MySnackbarContent
          variant="success"
          message="Restore Successful"
        />
      </Snackbar>
    )

    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackendBackground />
                <Paper className={classes.root}>
                    <h2>Archived Issues</h2>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Assigned</TableCell>
                                <TableCell>Focus Area</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.archived_issues ?
                                this.props.archived_issues.map((item,i) => {
                                    return (
                                        item.business_unit ?
                                        <TableRow key={item._id}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{ item.assigned_users.map(user => (
                                                <div key={user._id}>{user.name}</div>
                                            )) }</TableCell>
                                            <TableCell>{item.area.name}</TableCell>
                                            <TableCell>
                                                { moment(item.due_date) >= moment() || item.status.name === "Done" ? 
                                                    <Typography>
                                                        { moment(item.due_date).format('DD/MM/YYYY') }
                                                    </Typography>
                                                    :
                                                    <Typography color="error">
                                                        { moment(item.due_date).format('DD/MM/YYYY') }
                                                    </Typography>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.status.name}
                                            </TableCell>
                                            <TableCell>
                                                    <Permission action="corporate.view" user={this.props.user} hideObject={true}>
                                                        <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleRestoreIssue(item)}>Restore</Button>
                                                    </Permission>
                                            </TableCell>
                                        </TableRow>
                                        :null
                                    );
                                }) :
                                <TableRow>
                                    <TableCell colSpan="8">No Archived Issues</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Paper>
                {this.restoreSnackConent()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    archived_issues: state.strategy_issues.archived_issues,
    success: state.strategy_issues.success,
    user: state.user.userData
})


export default connect(mapStateToProps)(withStyles(styles)(Archive));