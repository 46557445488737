import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/HelpOutline'
import ToolTip from '@material-ui/core/Tooltip'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReactHtmlParser from 'react-html-parser';

class FieldHelpInfo extends Component {
    state = {
       open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

  render() {

    return (
        <div>


            { this.props.formData.helpContent ?
            <div>
                <ToolTip title="Help" >
                    <IconButton size="small" onClick={this.handleClickOpen} style={{ 
                        position: 'absolute',
                        right: `${ this.props.right ? this.props.right : 5}px`,
                        bottom: '8px'
                    }}>
                        <HelpIcon fontSize="small" />
                    </IconButton>
                </ToolTip>


                <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                >
                    <DialogTitle>Help</DialogTitle>
                        <DialogContent>
                            <DialogContentText >
                                {ReactHtmlParser(this.props.formData.helpContent)}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            :
            null }
        </div>
    )
  }
}

export default FieldHelpInfo