import axios from 'axios';
import {
  LOGIN_USER,
  CREATE_USER,
  UPDATE_USER,
  REGISTER_USER,
  COMPLETE_USER_SUBSCRIPTION,
  UPDATE_USER_SUBSCRIPTION,
  AUTH_USER,
  LOGOUT_USER,
  RESEND_EMAIL_VERIFICATION,
  EMAIL_VERIFICATION,
  GET_BYORGANIZATION,
  PASSWORD_RESET,
  PASSWORD_CHANGE,
  ENABLE_USER,
  DISABLE_USER,
  REMOVE_USER,
  GET_ALL_USERS,
  GET_USER,
  REINVITE_USER,
  GET_USER_LOGINS,
  UPDATE_USER_ROLES,
  UPDATE_USER_PASSWORD,
  GET_USER_BILLING,
  UPDATE_USER_BILLING,
  ADD_TO_ORGANIZATION,
  REMOVE_FROM_ORGANIZATION,
} from './types';
import { USER_SERVER } from '../../Components/Utils/Misc';

export function getUser(user_id) {
  const request = axios.get(`${USER_SERVER}/${user_id}`).then((responce) => responce.data);

  return {
    type: GET_USER,
    payload: request,
  };
}

export function getAllUsers() {
  const request = axios.get(`${USER_SERVER}/all`).then((responce) => responce.data);

  return {
    type: GET_ALL_USERS,
    payload: request,
  };
}

export function getUsersByOrganization(organization_id) {
  const request = axios.get(`${USER_SERVER}/byorganization/${organization_id}`).then((responce) => responce.data);

  return {
    type: GET_BYORGANIZATION,
    payload: request,
  };
}

export function createUser(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/`, dataToSubmit).then((responce) => responce.data);

  return {
    type: CREATE_USER,
    payload: request,
  };
}

export function updateUser(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/`, dataToSubmit).then((responce) => responce.data);

  return {
    type: UPDATE_USER,
    payload: request,
  };
}

export function updateRoles(id, dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/roles/${id}`, dataToSubmit).then((responce) => responce.data);

  return {
    type: UPDATE_USER_ROLES,
    payload: request,
  };
}

export function registerUser(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/register`, dataToSubmit).then((responce) => responce.data);

  return {
    type: REGISTER_USER,
    payload: request,
  };
}

export function enableUser(id) {
  const request = axios.put(`${USER_SERVER}/enable/${id}`).then((responce) => responce.data);

  return {
    type: ENABLE_USER,
    payload: request,
  };
}

export function disableUser(id) {
  const request = axios.put(`${USER_SERVER}/disable/${id}`).then((responce) => responce.data);

  return {
    type: DISABLE_USER,
    payload: request,
  };
}

export function completeSubscription(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/complete_subscription`, dataToSubmit).then((responce) => responce.data);

  return {
    type: COMPLETE_USER_SUBSCRIPTION,
    payload: request,
  };
}

export function updateSubscription(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/update_subscription`, dataToSubmit).then((responce) => responce.data);

  return {
    type: UPDATE_USER_SUBSCRIPTION,
    payload: request,
  };
}

export function resend_email_verification(email) {
  const request = axios.get(`${USER_SERVER}/resend_verification_email?email=` + email).then((responce) => responce.data);

  return {
    type: RESEND_EMAIL_VERIFICATION,
    payload: request,
  };
}

export function email_verification(token) {
  const request = axios.get(`${USER_SERVER}/verification/` + token).then((responce) => responce.data);

  return {
    type: EMAIL_VERIFICATION,
    payload: request,
  };
}

export function updatePassword(data) {
  const request = axios.put(`${USER_SERVER}/password/`, data).then((responce) => responce.data);

  return {
    type: UPDATE_USER_PASSWORD,
    payload: request,
  };
}

export function password_change(token, data) {
  const request = axios.post(`${USER_SERVER}/changepassword/` + token, data).then((responce) => responce.data);

  return {
    type: PASSWORD_CHANGE,
    payload: request,
  };
}

export function password_reset(data) {
  const request = axios.post(`${USER_SERVER}/resetpassword/`, data).then((responce) => responce.data);

  return {
    type: PASSWORD_RESET,
    payload: request,
  };
}

export function loginUser(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/login`, dataToSubmit).then((responce) => {
    return responce.data;
  });

  return {
    type: LOGIN_USER,
    payload: request,
  };
}

export function auth() {
  const request = axios.get(`${USER_SERVER}/auth`).then((responce) => {
    let user = JSON.parse(localStorage.getItem('user'));
    user = { ...responce.data };

    localStorage.setItem('user', JSON.stringify(user));
    return responce.data;
  });

  return {
    type: AUTH_USER,
    payload: request,
  };
}

export function logoutUser() {
  const request = axios.get(`${USER_SERVER}/logout`).then((responce) => responce.data);

  return {
    type: LOGOUT_USER,
    payload: request,
  };
}

export function removeUser(user_id) {
  const request = axios.get(`${USER_SERVER}/remove/${user_id}`).then((responce) => responce.data);

  return {
    type: REMOVE_USER,
    payload: request,
  };
}

export function reInviteUser(user_id) {
  const request = axios.get(`${USER_SERVER}/reinvite/${user_id}`).then((responce) => responce.data);

  return {
    type: REINVITE_USER,
    payload: request,
  };
}

export function getUserLogins(user_id) {
  const request = axios.get(`${USER_SERVER}/logins/${user_id}`).then((responce) => responce.data);

  return {
    type: GET_USER_LOGINS,
    payload: request,
  };
}

export function getUserBilling(user_id) {
  const request = axios.get(`${USER_SERVER}/billing`).then((responce) => responce.data);

  return {
    type: GET_USER_BILLING,
    payload: request,
  };
}

export function updateUserBilling(data) {
  const request = axios.put(`${USER_SERVER}/billing`, data).then((responce) => responce.data);

  return {
    type: UPDATE_USER_BILLING,
    payload: request,
  };
}

export function addToOrganization(userId, organizationId) {
  const request = axios.post(`${USER_SERVER}/organization`, { userId, organizationId }).then((responce) => responce.data);

  return {
    type: ADD_TO_ORGANIZATION,
    payload: request,
  };
}

export function removeFromOrganization(userId, organizationId) {
  const request = axios.put(`${USER_SERVER}/organization`, { userId, organizationId }).then((responce) => responce.data);

  return {
    type: ADD_TO_ORGANIZATION,
    payload: request,
  };
}
