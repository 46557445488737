import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '../UI/Stepper'
import HeaderText from '../UI/headerText';
import MediaIcons from '../UI/mediaIcons';
//import Organization from '../UI/organization';
import BackgroundItem from '../UI/backgroundItem';
import PaperField from '../UI/CustomFields/PaperField';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import ScrollArea from 'react-scrollbar';

import { validate } from '../UI/misc';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { getVisionMission, updateVisionMission } from '../../Redux/Actions/organizational_values_actions'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    button: {
        backgroundColor: '#005773',
        color: 'white',
        fontWeight: [500],
        fontSize: '17px',
        marginTop: '20px',
        "&:hover": {
            backgroundColor: '#005773',
        }
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        height: '250px'
    },
    title: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    },
    dialog: {
        
    },
    paper: {
        ...theme.mixins.gutters(),
        padding: theme.spacing(2),
    },
    h4:{
        marginTop: '0px',
        marginBottom: '5px'
    }
});

class VisionMission extends Component {
    state = {
        // videoFile: '',
        // audioFile: '',
        loadingUpdate: false,
        open: false,
        listItems: [],
        formError: false,
        formSuccess: '',
        formData: {
            purpose: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'purpose',
                    type: 'text',
                    label: 'Purpose (Why)',
                    helperText: 'Define and capture your Purpose.',
                    limit:300
                },
                validation: {
                    required: true,
                },
                helpContent: "<p>A collective corporate purpose influence behavior, create a sense of belonging, drive employee satisfaction and increase customer loyalty (in other words believing). During time of unprecedented change a strong purpose fuels (enables) a company's ability to transform." +
                             "When defining the COMPANY PURPOSE, answer the following questions: WHY does this BUSINESS exist? WHAT is the reason for Existence? and WHAT value would this business add to the economy?</p>" + 
                             "<b>HOW TO CHANGE CONTENT ALREADY LOGGED:</b>"+
                             "<p>Stand with cursor on word/sentance to be changed/replaced. Insert NEW content and SAFE. Information will only be override once SAVED.</p>",
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            vision: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'vision',
                    type: 'text',
                    label: 'Vision (What)',
                    helperText: 'Define and capture your Vision.',
                    limit: 300,
                    maxRows: 2
                },
                validation: {
                    required: true,
                },
                helpContent: "<p>The VISION serves as the ROADMAP for your STRATEGY – ‘WHAT will it look like in X no of years’. THREE to FIVE years is a good amount of time to set your vision. A good Vision Statement SHOULD be short and specific to your business.  Keep it simple and align to the Values that YOU want your people to exhibit as they perform their work. Do not use words that are open to interpretation. Ask questions such as: WHERE do we want to be? WHAT are the targeted conditions? and WHAT is our goal?</p>" + 
                "<b>HOW TO CHANGE CONTENT ALREADY LOGGED:</b>"+
                "<p>Stand with cursor on word/sentance to be changed/replaced. Insert NEW content and SAFE. Information will only be override once SAFED.</p>",
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            mission: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'mission',
                    type: 'text',
                    label: 'Mission (How)',
                    helperText: 'Define and capture your Mission.',
                    limit: 300,
                    maxRows: 2
                },
                validation: {
                    required: true,
                },
                helpContent: "<p>The MISSION STATEMENT defines the boundaries and scope we work in, in order to get where we want to go. Ask ‘WHAT DO WE DO, HOW DO WE DO IT, and for WHOM’. This also implies what we’re not going to do.</p>" + 
                "<b>HOW TO CHANGE CONTENT ALREADY LOGGED:</b>"+
                "<p>Stand with cursor on word/sentance to be changed/replaced. Insert NEW content and SAFE. Information will only be override once SAFED.</p>",
                valid: true,
                validationMessage: '',
                showLabel: true
            },
        }
    }

    componentDidMount() {
        this.props.dispatch(getVisionMission()).then(response => {
            this.setValues(this.props.vision_mission.vision, this.props.vision_mission.mission, this.props.vision_mission.purpose)
        })
    }

    setValues = (vision, mission, purpose)  => {
        const newFormData = { ...this.state.formData };

        newFormData['vision'].value = vision;
        newFormData['mission'].value = mission;
        newFormData['purpose'].value = purpose;

        this.setState({
            formData : newFormData
        })
    }

    updateForm(element) {
        const newFormData = { ...this.state.formData }
        const newElement = { ...newFormData[element.id] }

        newElement.value = element.event.target.value;

        let valiData = validate(newElement);
        newElement.valid = valiData[0];
        newElement.validationMessage = valiData[1];

        newFormData[element.id] = newElement;

        this.setState({
            formError: false,
            formData: newFormData,
            saveForm: true
        });
    }

    onUpdateHandle = () => {
        this.setState({
            loadingUpdate: true
        })
        

        this.props.dispatch(updateVisionMission({ 
            vision: this.state.formData['vision'].value,
            mission: this.state.formData['mission'].value,
            purpose: this.state.formData['purpose'].value })).then(response =>{

                this.setState({ open: false,
                loadingUpdate: false });

            })
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleCancel = () => {
        this.setState({ open: false });
    };



    stepContent = () => {
        const { classes } = this.props;

        return (
            <div style={{
                    position: 'relative',
                    height: '560px',
                    padding: '20px 120px 20px 120px',
                    
                }}>
                
                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={12} >
                            <HeaderText name="Business Purpose, Vision & Mission" size={40}/>
                            
                            <MediaIcons audioFile={this.state.audioFile} videoFile={this.state.videoFile}/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={12} >

                        <h4 className={classes.h4}>Purpose (Why)</h4>
                        <Paper className={classes.paper} elevation={5}>
                            <ScrollArea speed={0.8}
                                horizontal={false}
                                smoothScrolling={true}
                                style={{
                                    height: '70px'
                                }}>
                            {this.state.formData.purpose.value}
                            </ScrollArea>
                            </Paper>
                        </Grid>

                    </Grid>
                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={6} >

                        <h4 className={classes.h4}>Vision (What)</h4>
                        <Paper className={classes.paper} elevation={5}>
                        <ScrollArea speed={0.8}
                                horizontal={false}
                                smoothScrolling={true}
                                style={{
                                    height: '70px'
                                }}>
                            {this.state.formData.vision.value}
                            </ScrollArea>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>

                        <h4 className={classes.h4}>Mission (How)</h4>
                        <Paper className={classes.paper} elevation={5}>
                        <ScrollArea speed={0.8}
                                horizontal={false}
                                smoothScrolling={true}
                                style={{
                                    height: '70px'
                                }}>
                            {this.state.formData.mission.value}
                            </ScrollArea>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={12} >
                            <Button variant="outlined" color="primary" onClick={this.handleClickOpen} className={classes.button}>
                                Update Purpose, Vision or Mission
                            </Button>
                        </Grid>
                    </Grid>

                    
            </div>
        )
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackgroundItem path={GeneralBackground}/>
                <Stepper Step={2} getStepContent={() => this.stepContent()}/>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                    fullWidth={true}
                    maxWidth='lg'
                >
                    <DialogTitle id="form-dialog-title" >{this.props.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Edit Purpose, Vision & Mission
                        </DialogContentText>

                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={12} >
                            <PaperField
                                    id={'purpose'}
                                    formData={this.state.formData.purpose}
                                    change={(element) => this.updateForm(element)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={6} >
                            <PaperField
                                    id={'vision'}
                                    formData={this.state.formData.vision}
                                    change={(element) => this.updateForm(element)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PaperField
                                    id={'mission'}
                                    formData={this.state.formData.mission}
                                    change={(element) => this.updateForm(element)}
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">
                            Cancel
                        </Button>
                        { !this.state.loadingUpdate ? 
                        <Button onClick={this.onUpdateHandle} color="primary">
                            Update
                        </Button>
                        : 
                        <CircularProgress size={20}/> }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        vision_mission: state.organizational_values.vision_mission,
        user: state.user.userData
    }
}

export default connect(mapStateToProps)(withStyles(styles)(VisionMission));
