import React, { Component } from 'react';
import {connect} from 'react-redux';
import MainMenu from '../../Components/UI/MainMenu/mainMenu';
import Footer from '../../Components/Footer'
import FlagsDrawer from '../../Components/Partials/FlagsDrawer';


class AdminLayout extends Component {
    render(){
        return (
            <div className='layout'>          
                <MainMenu pageHeader={this.props.title} user={this.props.user}>  
                    <FlagsDrawer/>
                    {this.props.children}
                </MainMenu>
                {/* <Footer/> */}
            </div>
        );
    }
};

export default connect()(AdminLayout);