import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addChildOrganization, updateChildOrganization } from '../../Redux/Actions/organization_actions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CircularProgress, Typography, Divider, makeStyles, Box } from '@material-ui/core';
import UserSelect from '../UI/CustomFields/UserSelect';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../UI/Form';
import Input from '../UI/Form/input';

import PrimaryButton from '../UI/Buttons/primaryButton';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1),
  },
  progress: {
    marginRight: theme.spacing(2),
    width: '120px',
  },
  error: {
    color: 'red',
  },
}));

const schema = yup.object().shape({
  name: yup.string().required('Please enter a name.'),
  email: yup.string().email('Please enter a valid email address').required('Please enter a email address'),
  contact_number: yup.string().required('Please enter a contact number'),
  owner: yup.string().required('Please select a accountable user.'),
});

const CreateDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const [formError, setFormError] = useState();
  const [formType, setFormType] = useState('Add');
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  useEffect(() => {
    register({ name: 'owner' });
  }, []);

  useEffect(() => {
    if (props.selectedBusinessUnit && props.selectedBusinessUnit.name) {
      setFormType('Edit');
      //populateFields();
    }
  }, [props.selectedBusinessUnit]);

  const onSubmit = (data) => {
    setLoading(true);
    if (formType == 'Add') {
      data.parent = props.parent;

      dispatch(addChildOrganization(props.parent._id, data)).then((responce) => {
        if (responce.payload.success) {
          props.onClose();
        } else {
          setFormError(responce.payload.err);
        }

        setLoading(false);
      });
    } else {
      dispatch(updateChildOrganization(props.selectedBusinessUnit._id, data)).then((responce) => {
        if (responce.payload.success) {
          props.onClose();
        } else {
          setFormError(responce.payload.err);
        }
        setLoading(false);
      });
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle id="form-dialog-title">Add Business Unit</DialogTitle>
      <DialogContent>
        <DialogContentText>Fill in the details of your business unit and click add to add it to your list.</DialogContentText>
        <Form>
          <Input
            ref={register}
            id="name"
            label="Name of Business Unit"
            name="name"
            autoComplete="name"
            autoFocus
            defaultValue={props.selectedBusinessUnit ? props.selectedBusinessUnit.name : null}
            error={!!errors.name}
            helperText={errors?.name?.message}
          />
          <Input
            ref={register}
            id="email"
            label="Business Unit Email Address"
            name="email"
            autoComplete="email"
            defaultValue={props.selectedBusinessUnit ? props.selectedBusinessUnit.email : null}
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
          <Input
            ref={register}
            id="contact_number"
            label="Business Unit Contact Number"
            name="contact_number"
            autoComplete="contact_number"
            defaultValue={props.selectedBusinessUnit ? props.selectedBusinessUnit.contact_number : null}
            error={!!errors.contact_number}
            helperText={errors?.contact_number?.message}
          />

          <Divider className={classes.divider} />

          <UserSelect
            change={(user) => setValue('owner', user)}
            label="Select"
            header="Select / Add Accountable Person"
            btnCreateName="Add new person"
            user_type="business_unit"
            isLoading={(loading) => setUsersLoading(loading)}
            selectedUser={props.selectedBusinessUnit && props.selectedBusinessUnit.owner._id}
          />

          <Divider className={classes.divider} />
          <Typography color="error">{errors.owner?.message}</Typography>
        </Form>
      </DialogContent>
      <DialogActions>
        {!loading ? (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Typography className={classes.error}>{formError && formError}</Typography>
            <PrimaryButton onClick={() => props.onClose()}>Cancel</PrimaryButton>
            <PrimaryButton onClick={() => handleSubmit(onSubmit)()}>{formType === 'Add' ? <div>Add</div> : <div>Update</div>}</PrimaryButton>
          </Box>
        ) : (
          <Box className={classes.progress} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography>{'Saving...'}</Typography>
            <CircularProgress />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;
