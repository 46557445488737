import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../Redux/Actions/plan.actions'
import { updateBillingPlan } from '../../Redux/Actions/billingProcess.actions';

import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import PrimaryButton from '../UI/Buttons/primaryButton';

import { Redirect, useHistory } from 'react-router-dom';
import Organization from '../UI/organization';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    card:{
        padding: theme.spacing(3),
        margin: theme.spacing(3)
    },
    paper:{
        padding: theme.spacing(3),
        margin: theme.spacing(3)
    },
    subHeader:{
        marginBottom: theme.spacing(1)
    },
    text:{
        margin: theme.spacing(2)
    }
}))


const PlanExpired = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
   

    return (
        <React.Fragment>
            <BackgroundItem path={GeneralBackground}/>
            <Container maxWidth="sm">

                <Paper className={classes.paper}>
                    <Typography component='h2' variant='h5'>Plan Inactive</Typography>
                    <Grid container spacing={5} direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} >
                            <Typography className={classes.text}>
                                Plan inactive. Please get in touch with your administrator to get it re-activated again. Or switch to another company.
                            </Typography>
                            <Organization />
                            <PrimaryButton href='/logout'>
                                Logout
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Paper>
               
            </Container>
        </React.Fragment> 
    );
};

export default PlanExpired;