import React, { Component } from 'react'

import BackendBackground from '../../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { getByUser, updateStatus, updateApproval, archiveCorporateIssue } from '../../../Redux/Actions/issues_actions';
import { getStatuses } from '../../../Redux/Actions/status_action';

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions'

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
//import IconButton from '@material-ui/core/IconButton';
//import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';

import Permission from '../../../HOC/Permission';
//import { format } from 'date-fns/esm';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    action_button:{
        margin: theme.spacing.unit,
    }
});


class StrategicObjectives extends Component {
    state = {
        typeArchive: '',
        open: false,
        issueOpen: false,
        approvalOpen: false,
        actionOpen: false,
        confirmArchive: false,
        selectedIssue: {},
        status: '',
        formType: 'Add',
        formError: false,
        formSuccess: '',
        formData: {
            reason: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'reason',
                    type: 'text',
                    label: 'Reason',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            notes: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'notes',
                    type: 'text',
                    label: 'Notes',
                    limit: 200
                },
                validation: {
                    required: false,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            approval_status: {
                element: 'select',
                value: ' ',
                config: {
                    name: 'approval_status',
                    type: 'select',
                    label: 'Approval Status',
                    options: [
                        { key:'Approved', value:'Approved'}, 
                        { key:'Not Approved' , value:'Not Approved' }]
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
        }
    };

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'approvals');

        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();

        let dataToSubmit = generateData(this.state.formData, 'approvals');
        let formIsValid = isFormValid(this.state.formData, 'approvals');

        if (formIsValid) {
            this.props.dispatch(updateApproval(this.state.selectedIssue._id, dataToSubmit)).then(responce => {

                if (responce.payload.success) {
                    this.props.dispatch(getByUser()).then(response => {
                        this.handleApprovalClose();  
                    });

                           
                } else {
                    //const error = responce.payload.err;
                    this.setState({
                        formError: true
                    })
                }
            });
        }
        else {
            this.setState({
                formErrorMsg: 'This form is invalid.',
                formError: true
            })
        }
    }

    handleClickOpen = (issue) => {
        this.setState({ open: true, selectedIssue: issue });
    };

    handleClose = () => {
        this.setState({ open: false, selectedIssue: {} });
    };

    componentDidMount() {
        this.props.dispatch(getByUser()).then(responce => { console.log(responce) });
        this.props.dispatch(getStatuses()).then(response => {});
    }

    handleChange = event => {
        this.setState({ status: event.target.value });
    };

    handleIssueOpen = (issue) => {
        this.setState({
            issueOpen: true,
            selectedIssue: issue,
        })
    }
    handleIssueClose = () => {
        this.setState({
            issueOpen: false,
            selectedIssue: {},
        })
    }
    
    handleApprovalOpen = (issue) => {
        this.setState({
            approvalOpen: true,
            selectedIssue: issue,
        })
    }
    handleApprovalClose = () => {
        this.setState({
            approvalOpen: false,
            selectedIssue: {},
        })
    }

    changeStatus = () => {
        if(this.state.status){
            this.props.dispatch(updateStatus(this.state.selectedIssue._id, {status:this.state.status})).then(response => {
                this.props.dispatch(getByUser());
            })
        }

        this.setState({
            open: false,
        })
    }

    handleArchiveConfirmOpen = (task) => {
        this.setState({
            confirmArchive: true,
            selectedIssue: task
        })       
    }

    handleArchiveConfirmClose = (task, confirmed) => {

        if(confirmed){
            this.props.dispatch(archiveCorporateIssue(task._id)).then(responce => {
                console.log(responce);
            })
        }

        this.setState({
            confirmArchive: false,
            selectedIssue: {}
        })
    }

    handleTypeArchiveChange = (event) => {
        this.setState({ typeArchive: event.target.value });
    }

    issueDialog = () => (
        <Dialog
            open={this.state.issueOpen}
            onClose={this.handleIssueClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
        >
            <DialogTitle id="form-dialog-title">Issue</DialogTitle>
            <DialogContent>
                {this.state.selectedIssue ?
                    <div>
                        <DialogContentText variant="h5">
                            Description
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selectedIssue.description}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Impact
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selectedIssue.impact}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Action
                        </DialogContentText>
                        <DialogContentText >
                            {this.state.selectedIssue.action}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Outcome
                        </DialogContentText>
                        <DialogContentText>
                            {this.state.selectedIssue.outcome}
                        </DialogContentText>
                        <Divider />

                        <DialogContentText variant="h5">
                            Type
                        </DialogContentText>
                        <DialogContentText>
                            {this.state.selectedIssue.issue_type}
                        </DialogContentText>

                    </div>

                    :
                    <DialogContentText >
                        No Issue
                </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleIssueClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

    statusDialog = () => (
        <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
        >
            <DialogTitle id="form-dialog-title">Update Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a new status:
                </DialogContentText>
                <Select
                    value={this.state.status}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'status',
                        id: 'status',
                    }}
                    style={{
                        minWidth: '100px'
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { this.props.statuses ? 
                            this.props.statuses.map(item => (
                                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                            ))
                        :null
                    }
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={this.changeStatus} color="primary">
                    Change
            </Button>
            </DialogActions>
        </Dialog>
    )

    approvalDialog = () => (
        <Dialog
            open={this.state.approvalOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
        >
            <DialogTitle id="form-dialog-title">Update Approval</DialogTitle>
            <DialogContent>
                <FormField
                    id={'reason'}
                    formData={this.state.formData.reason}
                    change={(element) => this.updateForm(element)}
                />
                <FormField
                    id={'notes'}
                    formData={this.state.formData.notes}
                    change={(element) => this.updateForm(element)}
                />
                <FormField
                    id={'approval_status'}
                    formData={this.state.formData.approval_status}
                    change={(element) => this.updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleApprovalClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={(event) => this.submitForm(event)} color="primary">
                    Submit
            </Button>
            </DialogActions>
        </Dialog>
    )

    archiveDialog = () => (
        <Dialog
            open={this.state.confirmArchive}
            onClose={() => this.handleArchiveConfirmClose(this.state.selectedIssue, false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Archive Task</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You are about to archive a task. Are you sure you want to archive this task?<br />
                <strong>Description</strong><br />
                {this.state.selectedIssue.description}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="txtDelete"
                label="Type ARCHIVE to confirm"
                type="text"
                fullWidth
                onChange={this.handleTypeArchiveChange}
                />
            

            </DialogContent>
            <DialogActions>
            <Button onClick={() => this.handleArchiveConfirmClose(this.state.selectedIssue, false)} color="primary">
                Cancel
            </Button>
            {this.state.typeArchive.toLowerCase() === 'archive' ? 
                <Button onClick={() => this.handleArchiveConfirmClose(this.state.selectedIssue, true)} color="primary" autoFocus>
                    Yes
                </Button>
            : null
            }
            </DialogActions>
        </Dialog>
    )

    render() {
        const { classes } = this.props;
        return (
            <div>
                <BackendBackground />
                <Paper className={classes.root}>
                    <h2>Corporate</h2>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Issue Type</TableCell> */}
                                <TableCell>Description</TableCell>
                                <TableCell>Assigned</TableCell>
                                <TableCell>Focus Area</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.issues ?
                                this.props.issues.map((item,i) => {
                                    return (
                                        !item.business_unit ?
                                        <TableRow key={item._id}>
                                            {/* <TableCell component="th" scope="row">
                                                    {item.issue_type}
                                                </TableCell> */}
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{ item.assigned_users.map(user => (
                                                <div key={user._id}>{user.name}</div>
                                            )) }</TableCell>
                                            <TableCell>{item.area.name}</TableCell>
                                            <TableCell>
                                                {item.due_date ? 
                                                    moment(item.due_date) >= moment() || item.status.name === "Done" ? 
                                                        <Typography>
                                                            { moment(item.due_date).format('DD/MM/YYYY') }
                                                        </Typography>
                                                    :
                                                        <Typography color="error">
                                                            { moment(item.due_date).format('DD/MM/YYYY') }
                                                        </Typography>
                                                :
                                                <div>No Date</div>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item.status.name}
                                            </TableCell>
                                            <TableCell>

                                                    { item.status.name !== "Pending Approval" ?
                                                        item.status.name !== "Done" ?
                                                        <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleClickOpen(item)}>Update Status</Button>
                                                        :
                                                        <Permission action="corporate.update" user={this.props.user} hideObject={true}>
                                                            <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleClickOpen(item)}>Update Status</Button>
                                                            <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleArchiveConfirmOpen(item)}>Archive</Button>
                                                        </Permission>
                                                    :
                                                    <Permission action="corporate.update" user={this.props.user} hideObject={true}>
                                                        <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleApprovalOpen(item)}>Approval</Button>
                                                    </Permission>
                                                    }
                                                    <Button className={classes.action_button} variant="contained" color="primary" onClick={() => this.handleIssueOpen(item)}>View</Button>
                                               
                                            </TableCell>
                                        </TableRow>
                                        :null
                                    );
                                }) :
                                <TableRow>
                                    <TableCell colSpan="8">No Issues</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Paper>

                <Paper className={classes.root}>
                    <h2>Functional</h2>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Issue Type</TableCell> */}
                                <TableCell>Description</TableCell>
                                <TableCell>Outcome</TableCell>
                                <TableCell>Focus Area</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Business Unit</TableCell>
                                <TableCell>Revenue Stream</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.issues ?
                                this.props.issues.map(item => {
                                    return (
                                        item.business_unit ?
                                            <TableRow key={item._id}>
                                                {/* <TableCell component="th" scope="row">
                                                    {item.issue_type}
                                                </TableCell> */}
                                                <TableCell>{item.description}</TableCell>
                                                <TableCell>{item.outcome}</TableCell>
                                                <TableCell>{item.area.name}</TableCell>
                                                <TableCell>
                                                {item.due_date ? 
                                                    moment(item.due_date) >= moment() || item.status.name === "Done" ? 
                                                        <Typography>
                                                            { moment(item.due_date).format('DD/MM/YYYY') }
                                                        </Typography>
                                                    :
                                                        <Typography color="error">
                                                            { moment(item.due_date).format('DD/MM/YYYY') }
                                                        </Typography>
                                                :
                                                <div>No Date</div>
                                                }
                                                </TableCell>
                                                <TableCell>{item.business_unit ? item.business_unit.name : ""}</TableCell>
                                                <TableCell>{item.revenue_stream ? item.revenue_stream : ""}</TableCell>
                                                <TableCell>
                                                    {item.status.name}
                                                </TableCell>
                                                <TableCell>
                                                    { item.status.name !== "Pending Approval" ?
                                                        item.status.name !== "Done" ?
                                                        <Button className={classes.action_button} variant="contained" size="small" color="primary" onClick={() => this.handleClickOpen(item)}>Update Status</Button>
                                                        :
                                                        <Permission action="functional_strategy.update" user={this.props.user} hideObject={true}>
                                                            <Button className={classes.action_button} variant="contained" size="small" color="primary" onClick={() => this.handleClickOpen(item)}>Update Status</Button>
                                                            <Button className={classes.action_button} variant="contained" size="small" color="primary" onClick={() => this.handleArchiveConfirmOpen(item)}>Archive</Button>
                                                        </Permission>
                                                    :
                                                        <Permission action="functional_strategy.update" user={this.props.user} hideObject={true}>
                                                            <Button className={classes.action_button} variant="contained" size="small" color="primary" onClick={() => this.handleApprovalOpen(item)}>Approval</Button>
                                                        </Permission>
                                                    }
                                                    <Button className={classes.action_button} variant="contained" size="small" color="primary" onClick={() => this.handleIssueOpen(item)}>View</Button>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            null
                                    );
                                }) :
                                <TableRow>
                                    <TableCell colSpan="8">No Issues</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Paper>
                {this.archiveDialog()}
                {this.issueDialog()}
                {this.statusDialog()}
                {this.approvalDialog()}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    issues: state.strategy_issues.issues_by_user,
    statuses: state.status.statuses,
    user: state.user.userData
})


export default connect(mapStateToProps)(withStyles(styles)(StrategicObjectives));