import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { cancelUserPlan, getUserPlan } from '../../Redux/Actions/userPlan.actions';

import PrimaryButton from '../UI/Buttons/primaryButton';
import TextButton from '../UI/Buttons/textButton';
import MyCircularProgress from '../UI/ProgressBars/myCircularProgress';

import moment from 'moment';
import UpdatePlan from './updatePlan';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 3),
    margin: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(0, 0, 3),
    fontSize: '30px',
  },
}));

function PlanDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelProgress, setCancelProgress] = useState(false);

  const [dialogUpdatePlanOpen, setDialogUpdatePlanOpen] = useState(false);

  const userPlan = useSelector((state) => state.userPlan.userplan);
  const updateCardUrl = useSelector((state) => state.userPlan.updateCardUrl);
  const success = useSelector((state) => state.userPlan.success);

  useEffect(() => {
    dispatch(getUserPlan());
  }, []);

  const handleCancelDialogOpen = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
  };

  const handlePlanCancel = () => {
    setCancelProgress(true);
    dispatch(cancelUserPlan()).then((responce) => {
      if (responce.payload.success) {
        setCancelDialogOpen(false);
        setCancelProgress(false);
      } else {
        setCancelDialogOpen(false);
        setCancelProgress(false);
      }
    });
  };

  const handlePlanUpdateOpen = () => {
    setDialogUpdatePlanOpen(true);
  };

  const handlePlanUpdate = () => {
    setDialogUpdatePlanOpen(false);
  };

  const handlePlanUpdateClose = () => {
    setDialogUpdatePlanOpen(false);
  };

  const cancelDialog = () => {
    if (cancelProgress)
      return (
        <Dialog open={cancelProgress} maxWidth="sm" fullWidth aria-labelledby="cancel-dialog-title" aria-describedby="cancel-dialog-description">
          <MyCircularProgress caption="Canciling Plan..." />
        </Dialog>
      );
    else
      return (
        <Dialog open={cancelDialogOpen} onClose={handleCancelDialogClose} aria-labelledby="cancel-dialog-title" aria-describedby="cancel-dialog-description">
          <DialogTitle id="cancel-dialog-title">{'Cancel Plan?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="cancel-dialog-description">Are you sure you want to cancel your plan?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDialogClose} color="primary">
              No
            </Button>
            <Button onClick={handlePlanCancel} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      );
  };

  const updateDialog = () => (
    <Dialog open={dialogUpdatePlanOpen} onClose={handlePlanUpdateClose} aria-labelledby="updatePlan-dialog-title" aria-describedby="updatePlan-dialog-description">
      <DialogTitle id="updatePlan-dialog-title">{'Update Plan'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="updatePlan-dialog-description">Please slect the plan you want to update to: </DialogContentText>
        <UpdatePlan onClose={handlePlanUpdateClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePlanUpdateClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper className={classes.root}>
      <Typography varient="p" className={classes.header}>
        Current Plan
      </Typography>
      {userPlan ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={5}>
            <Typography>
              {userPlan.plan.name} ({userPlan.plan.planFrequency})
            </Typography>
            <Typography>
              R{userPlan.plan.price} {userPlan.plan.planFrequency == 'Monthly' ? 'per month' : 'anually'}
            </Typography>
            <Typography>
              {userPlan.status == 'Active' &&
                `Renewing${' '}
              ${moment(userPlan.activeFrom)
                .add(1, userPlan.plan.planFrequency == 'Monthly' ? 'month' : 'year')
                .format('DD MMMM YYYY')}`}

              {userPlan.status == 'Canceled' &&
                `Your plan has been cnaceled and wil end on ${moment(userPlan.activeFrom)
                  .add(1, userPlan.plan.planFrequency == 'Monthly' ? 'month' : 'year')
                  .format('DD MMMM YYYY')}`}

              {userPlan.status == 'Not Active' && 'Your plan has expired.'}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <PrimaryButton href={updateCardUrl}>Update Card Details</PrimaryButton>
              <PrimaryButton onClick={handlePlanUpdateOpen}>Update Plan</PrimaryButton>
              {userPlan.status == 'Active' && <TextButton onClick={handleCancelDialogOpen}>Cancel Plan</TextButton>}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={1} m={2}>
          <Typography>Get your own Licence</Typography>
          <PrimaryButton href="/payment/select-plan">Select plan</PrimaryButton>
        </Box>
      )}
      {cancelDialog()}
      {updateDialog()}
    </Paper>
  );
}

export default PlanDetails;
