import React from 'react'
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isConsultant, TotalLicenses } from '../Utils/OrganizationActions'


function Organization(props) {
    const handleOrganizationChangeClick = () => {
        props.history.push(`/entity_select?redirectTo=${props.location.pathname}`);
    }

    
    return (
        props.user.userData ? 
            (props.user.userData.isAuth ?
                <div>
                        <div style={{textAlign: 'center', paddingBottom: "10px"}}>
                            <div style={{ fontSize: "1.81em", textTransform: "uppercase"}}>
                                { props.user.userData.selectedOrganization ?  props.user.userData.selectedOrganization.name : "No Company"}
                            </div>
                            <br />
                            { props.user.userData.organizations.length > 1 || props.user.userData.organizations.length <= TotalLicenses(props.user.userData.plan) || isConsultant(props.user.userData.plan) ?
                            <Button style={{ margin: "0px 10px" }} variant="contained" color="default" size="small" onClick={() => handleOrganizationChangeClick()}>
                                Switch Organization
                            </Button>

                                

                            :null }
                        </div>

                </div>
            :null)
        :null
    )
}

function mapStateToProps(state){
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(withRouter(Organization));
