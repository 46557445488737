import { GET_PAYMENT_OBJECT, GET_USER_TRANSACTIONS } from '../Actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_OBJECT:
      return { ...state, paymentDetails: action.payload };
    case GET_USER_TRANSACTIONS:
      return { ...state, transactions: action.payload.transactions };
    default:
      return state;
  }
}
