import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getGroupStrategicObjectives, 
        addGroupStrategicObjective, 
        removeGroupStrategicObjective,
        updateGroupStrategicObjective } from '../../Redux/Actions/group_strategic_objectives_actions'

import Stepper from '../UI/Stepper'
import HeaderText from '../UI/headerText';
import MediaIcons from '../UI/mediaIcons';
import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';

import Grid from '@material-ui/core/Grid';

import StrategicObjectivesDialogList from '../UI/CustomFields/StrategicObjectivesDialogList';


const GroupStrategicObjectives = () => {
    const dispatch = useDispatch();

    const StepContent = () => {
        return (
            <div style={{
                position: 'relative',
                padding: '20px 120px 20px 120px',
            }}>

                <HeaderText name="Business Strategic Objectives" size={40} />

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <StrategicObjectivesDialogList 
                            dialogTitle="Growth Objective" 
                            dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
                            dialogSuccessButtonText="Add" 
                            dialogFieldLabel = "Write your objective here."
                            openDialogButtonText = "Add Growth Objective"
                            typeObjective="Growth"
                            level="business"/>
                    </Grid>
                    <Grid item xs={4}>
                        <StrategicObjectivesDialogList 
                            dialogTitle="Risk Objective" 
                            dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
                            dialogSuccessButtonText="Add" 
                            dialogFieldLabel = "Write your objective here."
                            openDialogButtonText = "Add Risk Objective"
                            typeObjective="Risk"
                            level="business"/>
                    </Grid>
                    <Grid item xs={4}>
                        <StrategicObjectivesDialogList 
                            dialogTitle="Transition Objective" 
                            dialogContentText="Goal-setting is important to measuring the success of the business. Determining goals and objectives
                            and implementing plans to achieve them is a proactive stance on the part of the business owners. It
                            communicates to stakeholder groups, including employees, customers and managers, that the
                            business takes seriously the feedback it receives and is seeking to grow for the good of all who have
                            an interest in the business&#39;s longevity."
                            dialogSuccessButtonText="Add" 
                            dialogFieldLabel = "Write your objective here."
                            openDialogButtonText = "Add Transition Objective"
                            typeObjective="Transition"
                            level="business"/>
                    </Grid>
                </Grid>

            </div>
        )
    };

    return (
        <div>
            <BackgroundItem path={GeneralBackground} />
            <Stepper Step={5} getStepContent={() => StepContent()} />
        </div>
    )
}

export default GroupStrategicObjectives