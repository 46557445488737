import { Card, CardContent, CardActions, CardHeader, makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../UI/Buttons/primaryButton';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: '#1E5774',
    color: '#fff',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

function PlanCard({ plan, handlePlanSelected }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        title={plan.name}
        subheader={plan.description}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={plan.title === 'Consultant' ? <StarIcon /> : null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h1" variant="h5" color="textPrimary">
            R {plan.price}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {plan.planFrequency == 'Monthly' ? '/month' : '/year'}
          </Typography>
        </div>
        <ul>
          {plan.features
            ? plan.features.map((line) => (
                <Typography component="li" variant="subtitle1" align="center" key={line}>
                  {line}
                </Typography>
              ))
            : null}
        </ul>
      </CardContent>
      <CardActions>
        <PrimaryButton fullWidth variant="contained" onClick={() => handlePlanSelected(plan)}>
          Select
        </PrimaryButton>
      </CardActions>
    </Card>
  );
}

export default PlanCard;
