import axios from 'axios';
import { GET_AREAS, GET_CUSTOM_AREAS, GET_AREA, CREATE_AREA, UPDATE_AREA, DELETE_AREA } from './types';
import { AREA_SERVER } from '../../Components/Utils/Misc';

    export function getAreas(){
        const request = axios.get(`${AREA_SERVER}`)
            .then(responce => responce.data);

            return {
                type: GET_AREAS,
                payload: request
            }
    }   

    export function getCustomAreas(){
        const request = axios.get(`${AREA_SERVER}/custom`)
            .then(responce => responce.data);

            return {
                type: GET_CUSTOM_AREAS,
                payload: request
            }
    }   

    export function getArea(id){
        const request = axios.get(`${AREA_SERVER}/${id}`)
            .then(responce => responce.data);
    
            return {
                type: GET_AREA,
                payload: request
            }
    }
    
    export function addArea(item){
        const request = axios.post(`${AREA_SERVER}`, item)
            .then(responce => responce.data);
    
            return {
                type: CREATE_AREA,
                payload: request
            }
    }
    
    export function updateArea(id, data){
        const request = axios.put(`${AREA_SERVER}/${id}`, data)
            .then(responce => responce.data);
    
            return {
                type: UPDATE_AREA,
                payload: request
            }
    }
    
    export function removeArea(id){
        const request = axios.delete(`${AREA_SERVER}/${id}`)
            .then(responce => responce.data);
    
            return {
                type: DELETE_AREA,
                payload: request
            }
    }
    