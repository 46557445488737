import axios from 'axios';
import { 
    GET_CORPORATESTRATEGY_ISSUES, 
    GET_DISTINCT_AREAS,
    ADD_CORPORATESTRATEGY_ISSUE,
    UPDATE_CORPORATESTRATEGY_ISSUE,
    REMOVE_CORPORATESTRATEGY_ISSUE,
    ARCHIVE_CORPORATESTRATEGY_ISSUE,
    GET_STRATEGIC_OBJECTIVES,
    UPDATE_STRATEGIC_OBJECTIVES,
    GET_FS_BUSINESS_UNIT_ISSUE,
    GET_ISSUES_BY_USER,
    UPDATE_STATUS,
    UPDATE_APPROVAL,
    GET_ARCHIVED_ISSUES,
    RESTORE_ARCHIVED_ISSUE,
    GET_FILTERED_ISSUES
 } from './types';
import { CORPORATESTRATEGY_ISSUE_SERVER, ORGANIZATION_DATA_SERVER } from '../../Components/Utils/Misc';


export function GetAllCorporateIssues(){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_CORPORATESTRATEGY_ISSUES,
            payload: request
        }
}

export function GetFilteredIssues(id, filters){
    const request = axios.post(`${CORPORATESTRATEGY_ISSUE_SERVER}/filtered/${id}`, filters)
        .then(responce => responce.data);

        return {
            type: GET_FILTERED_ISSUES,
            payload: request
        }
}

export function getArchivedIssues(){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}/archived`)
        .then(responce => responce.data);

        return {
            type: GET_ARCHIVED_ISSUES,
            payload: request
        }
}

export function GetAllIssuesByBusinessUnit(business_unit){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}/business_unit/${business_unit}`)
        .then(responce => responce.data);

        return {
            type: GET_FS_BUSINESS_UNIT_ISSUE,
            payload: request
        }
}

export function addCorporateIssue(item){
    const request = axios.post(`${CORPORATESTRATEGY_ISSUE_SERVER}`, item)
        .then(responce => responce.data);

        return {
            type: ADD_CORPORATESTRATEGY_ISSUE,
            payload: request
        }
}

export function updateCorporateIssue(id, item){
    const request = axios.put(`${CORPORATESTRATEGY_ISSUE_SERVER}/${id}`, item)
        .then(responce => responce.data);

        return {
            type: UPDATE_CORPORATESTRATEGY_ISSUE,
            payload: request
        }
}

export function archiveCorporateIssue(id, item){
    const request = axios.put(`${CORPORATESTRATEGY_ISSUE_SERVER}/archive/${id}`, item)
        .then(responce => responce.data);

        return {
            type: ARCHIVE_CORPORATESTRATEGY_ISSUE,
            payload: request
        }
}

export function removeIssue(id, item){
    const request = axios.delete(`${CORPORATESTRATEGY_ISSUE_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_CORPORATESTRATEGY_ISSUE,
            payload: request
        }
}

export function restoreArchivedIssue(id){
    const request = axios.put(`${CORPORATESTRATEGY_ISSUE_SERVER}/archive/restore/${id}`)
        .then(responce => responce.data);

        return {
            type: RESTORE_ARCHIVED_ISSUE,
            payload: request
        }
}

export function getDistictAreas(){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}/distinct_areas`)
                    .then(responce => responce.data);

    return {
        type: GET_DISTINCT_AREAS,
        payload: request
    }
}

export function getStrategicObjectives(){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}/strategic_objectives`)
                    .then(responce => responce.data)

    return {
        type: GET_STRATEGIC_OBJECTIVES,
        payload: request
    }
}

export function getByUser(){
    const request = axios.get(`${CORPORATESTRATEGY_ISSUE_SERVER}/by_user`)
                    .then(responce => responce.data)
    return {
        type: GET_ISSUES_BY_USER,
        payload: request
    }
}

export function updateStrategicObjectives(item){
    const request = axios.post(`${ORGANIZATION_DATA_SERVER}/strategic_objectives`, item)
                    .then(responce => responce.data)

    return {
        type: UPDATE_STRATEGIC_OBJECTIVES,
        payload: request
    }
}

export function updateStatus(id, dataToSubmit){
    const request = axios.put(`${CORPORATESTRATEGY_ISSUE_SERVER}/status/${id}`, dataToSubmit)
        .then(responce => responce.data);
        
        return {
            type: UPDATE_STATUS,
            payload: request
        }
}

export function updateApproval(id, dataToSubmit){
    const request = axios.put(`${CORPORATESTRATEGY_ISSUE_SERVER}/approval/${id}`, dataToSubmit)
        .then(responce => responce.data);
        
        return {
            type: UPDATE_APPROVAL,
            payload: request
        }
}