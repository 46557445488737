export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const COMPLETE_USER_SUBSCRIPTION = 'complete_user_subscription';
export const UPDATE_USER_SUBSCRIPTION = 'update_user_subscription';
export const CREATE_USER = 'create_user';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_ROLES = 'update_user_roles';
export const UPDATE_USER_PASSWORD = 'update_user_password';
export const ENABLE_USER = 'enable_user';
export const DISABLE_USER = 'disable_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const RESEND_EMAIL_VERIFICATION = 'resend_email_verification';
export const EMAIL_VERIFICATION = 'email_verification';
export const GET_ALL_USERS = 'get_all_users';
export const GET_USER = 'get_user';
export const GET_BYORGANIZATION = 'get_by_organization';
export const ADD_TO_ORGANIZATION = 'add_to_organization';
export const REMOVE_FROM_ORGANIZATION = 'remove_from_organization';
export const PASSWORD_RESET = 'password_reset';
export const PASSWORD_CHANGE = 'password_change';
export const REMOVE_USER = 'remove_user';
export const REINVITE_USER = 'reinvite_user';
export const GET_USER_LOGINS = 'get_user_logins';

export const GET_USER_BILLING = 'get_user_billing';
export const UPDATE_USER_BILLING = 'update_user_billing';

export const SUBSCRIPTION_VALIDATION = 'subscription_validation';
export const GET_USER_SUBSCROPTIONS = 'get_subscription';
export const GET_ALLUSER_SUBSCROPTIONS = 'get_all_subscription';
export const MIGRATE_SUBSCROPTIONS = 'migrate_subscription';

export const GET_PLANS = 'get_plans';
export const GET_PLAN = 'get_plan';
export const CREATE_PLAN = 'create_plan';
export const UPDATE_PLAN = 'update_plan';
export const REMOVE_PLAN = 'remove_plan';

export const GET_ROLES = 'get_roles';
export const GET_ROLE = 'get_role';
export const GET_ACTIONS = 'get_actions';
export const CREATE_ROLE = 'create_role';
export const UPDATE_ROLE = 'update_role';
export const REMOVE_ROLE = 'remove_role';

export const GET_ORGANIZATIONS = 'get_organizations';
export const GET_ORGANIZATION_BYID = 'get_organization_byId';
export const GET_ORGANIZATIONS_BYPARENT = 'get_organizations_byParent';
export const ADD_ORGANIZATION = 'add_organization';
export const REMOVE_ORGANIZATION = 'remove_organization';
export const UPDATE_ORGANIZATION = 'update_organization';

export const ADD_CHILD_ORGANIZATION = 'add_child_organization';
export const REMOVE_CHILD_ORGANIZATION = 'remove_child_organization';
export const UPDATE_CHILD_ORGANIZATION = 'update_child_organization';

export const SENT_INVITE = 'sent_invite';
export const REQUEST_INVITE = 'request_invite';
export const RESPOND_INVITE = 'respond_invite';
export const SELECT_ORGANIZATION = 'select_organization';
export const RETRIEVE_LICENSE = 'retriev_license';
export const CHANGE_OWNERSHIP = 'change_ownership';
export const ASSIGN_LICENSE = 'assign_license';
export const CLEAR_SELECTED = 'clear_selected_organization';
export const GET_ORGANIZATIONROLES_BYUSER = 'get_organizationRoles_byUser';

export const GUIDE_STEPS = 'guide_steps';
export const GET_ORG_DATA = 'get_organizational_data';

export const GET_BUSINESSUNITS = 'get_business_units';
export const GET_BUSINESSUNITS_BYENTITY = 'get_business_units_byentity';
export const GET_BUSINESSUNITS_BYUSER = 'get_business_units_byuser';
export const GET_BUSINESSUNITS_BYID = 'get_business_unit_byId';
export const ADD_BUSINESSUNITS = 'add_business_unit';
export const UPDATE_BUSINESSUNITS = 'update_business_unit';
export const REMOVE_BUSINESSUNIT = 'remove_business_unit';

export const GET_VISION_MISSION = 'get_vision_mission';
export const UPDATE_VISION_MISSION = 'update_vision_mission';

export const GET_ORGANIZATIONAL_VALUES = 'get_organizational_values';
export const GET_BYID_ORGANIZATIONAL_VALUE = 'get_byid_organizational_value';
export const CREATE_ORGANIZATIONAL_VALUE = 'create_organizational_value';
export const UPDATE_ORGANIZATIONAL_VALUE = 'update_organizational_value';
export const REMOVE_ORGANIZATIONAL_VALUE = 'remove_organizational_value';

export const GET_FILTERED_ISSUES = 'get_filtered_issues';
export const GET_CORPORATESTRATEGY_ISSUES = 'get_corporate_strategy_issues';
export const ADD_CORPORATESTRATEGY_ISSUE = 'add_corporate_strategy_issue';
export const ARCHIVE_CORPORATESTRATEGY_ISSUE = 'archive_corporate_strategy_issue';
export const UPDATE_CORPORATESTRATEGY_ISSUE = 'update_corporate_strategy_issue';
export const REMOVE_CORPORATESTRATEGY_ISSUE = 'remove_corporate_strategy_issue';
export const GET_FS_BUSINESS_UNIT_ISSUE = 'get_fs_business_unit_issue';
export const GET_ISSUES_BY_USER = 'get_issues_by_users';
export const UPDATE_STATUS = 'update_status';
export const UPDATE_APPROVAL = 'update_approval';
export const GET_ARCHIVED_ISSUES = 'get_archived_issues';
export const RESTORE_ARCHIVED_ISSUE = 'restore_archived_issue';

export const GET_STRATEGIC_OBJECTIVES = 'get_strategic_objectives';
export const UPDATE_STRATEGIC_OBJECTIVES = 'update_strategic_objectives';

export const GET_AREAS = 'get_areas';
export const GET_CUSTOM_AREAS = 'get_custom_areas';
export const GET_AREA = 'get_area';
export const CREATE_AREA = 'create_area';
export const UPDATE_AREA = 'update_area';
export const DELETE_AREA = 'delete_area';
export const GET_DISTINCT_AREAS = 'get_distinct_areas';

export const GET_GROUP_STRATEGIC_OBJECTIVES = 'get_group_strategic_objectives';
export const GET_GROUP_STRATEGIC_OBJECTIVES_BYID = 'get_group_strategic_objectives_byId';
export const ADD_GROUP_STRATEGIC_OBJECTIVE = 'add_group_strategic_objective';
export const UPDATE_GROUP_STRATEGIC_OBJECTIVE = 'update_group_strategy_objective';
export const REMOVE_GROUP_STRATEGIC_OBJECTIVE = 'remove_group_strategic_objective';

export const GET_STATUSES = 'get_statuses';

export const GET_FLAGS = 'get_flags';
export const GET_FLAG = 'get_flag';
export const PRINT_FLAGS = 'print_flags';
export const CREATE_FLAG = 'create_flag';
export const REMOVE_FLAG = 'remove_flag';
export const EDIT_FLAG = 'edit_flag';

export const GET_NOTES = 'get_notes';
export const GET_NOTE = 'get_note';
export const CREATE_NOTE = 'create_note';
export const REMOVE_NOTE = 'remove_note';
export const EDIT_NOTE = 'edit_note';

export const REPORT_CORPORATE_STATEMENTS = 'report_corporate_statements';
export const REPORT_CORPORATE_SCORE_CARD = 'report_corporate_score_card';
export const REPORT_CORPORATE_RISK_REGISTER = 'report_corporate_risk_register';
export const REPORT_CORPORATE_ROADMAP = 'report_corporate_roadmap';
export const REPORT_CORPORATE_STRUCTURE = 'report_corporate_structure';
export const REPORT_BUSINESS_UNIT_DELIVERABLES = 'report_business_unit_deliverables';

export const REPORT_FUNCTIONAL_SCORE_CARD = 'report_business_unit_score_card';
export const REPORT_FUNCTIONAL_RISK_REGISTER = 'report_business_unit_risk_register';
export const REPORT_FUNCTIONAL_ROADMAP = 'report_business_unit_roadmap';
export const REPORT_FUNCTIONAL_STRUCTURE = 'report_business_unit_structure';
export const REPORT_REVENUE_STREAM_DELIVERABLES = 'report_revenue_stream_deliverables';

export const GET_PAYMENT_OBJECT = 'get_payment_object';
export const VERIFY_PAYMENT = 'verify_payment';
export const GET_USER_TRANSACTIONS = 'get_user_transactions';

export const UPDATE_BILLING_PLAN = 'updat_billing_plan';
export const UPDATE_BILLING_DETAILS = 'updat_billing_details';

export const GETBYID_COMMENTS = 'getbyid_comments';
export const CREATE_COMMENT = 'create_comment';

export const GET_USER_PLAN = 'get_user_plan';
export const UPDATE_USER_PLAN = 'update_user_plan';
export const CANCEL_USER_PLAN = 'cancel_user_plan';
