import { GET_NOTES, GET_NOTE, CREATE_NOTE, REMOVE_NOTE, EDIT_NOTE } from '../Actions/types';

export default function(state={}, action){
    switch(action.type){
        case GET_NOTES:
            return {...state, notes: action.payload }
        case GET_NOTE:
            return {...state, note: action.payload }
        case CREATE_NOTE:
            return {...state, success: action.payload }
        case EDIT_NOTE:
            return {...state, success: action.payload }
        case REMOVE_NOTE:
            return {...state, success: action.payload }
        default:
            return state;
    }
}