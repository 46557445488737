// Server Routes
export const USER_SERVER = '/api/users';
export const SUBSCRIPTION_SERVER = '/api/subscription';
export const ROLE_SERVER = '/api/roles';
export const ORGANIZATION_SERVER = '/api/organization';
export const GUIDE_SERVER = '/api/guides';
export const BUSINESS_UNITS_SERVER = '/api/business_units';
export const ORGANIZATIONAL_VALUES_SERVER = '/api/organizational_values';
export const CORPORATESTRATEGY_ISSUE_SERVER = '/api/issues';
export const AREA_SERVER = '/api/areas';
export const VISION_MISSON_SERVER = '/api/organization/data/vision_mission';
export const ORGANIZATION_DATA_SERVER = '/api/organization/data';
export const GROUP_STRATEGIC_OBJECTIVES_SERVER = '/api/group_strategic_objectives';
export const STATUS_SERVER = '/api/status';
export const REPORT_SERVER = '/api/reports';
export const FLAG_SERVER = '/api/flags';
export const NOTE_SERVER = '/api/notes';
export const PLAN_SERVER = '/api/plan';
export const PAYMENT_SERVER = '/api/payment';
export const COMMENTS_SERVER = '/api/comments';
export const USERPLAN_SERVER = '/api/user/plan';
