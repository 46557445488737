import React, { useEffect,Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import { getAllSubscriptions, migrateUser } from '../../Redux/Actions/subscription_actions';
import PrimaryButton from '../UI/Buttons/primaryButton';

function PlanMigration() {
    const dispatch = useDispatch();
    const subs_users = useSelector(state => state.subscription?.user_subs)
    const plan_users = useSelector(state => state.subscription?.user_plans)

    useEffect(() => {
        dispatch(getAllSubscriptions());
    }, [])

    const handleMigration = (userId) => {
        dispatch(migrateUser(userId));
    }

    return (
        <Fragment>
            <Typography>Old Plans</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User Email</TableCell>
                        <TableCell>License</TableCell>
                        <TableCell>Subscription Status</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Next Payment</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subs_users && subs_users.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>{ user.email }</TableCell>
                            <TableCell>{ user.subscriptions[0]?.line_items[0]?.product }</TableCell>
                            <TableCell>{ user.subscriptions[0]?.subscription_status }</TableCell>
                            <TableCell>{ user.last_login }</TableCell>
                            <TableCell>{ user.subscriptions[0]?.next_payment_date }</TableCell>
                            <TableCell>
                                <PrimaryButton onClick={() => handleMigration(user._id)}>Migrate User</PrimaryButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Typography>New Plans</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User Email</TableCell>
                        <TableCell>Plan</TableCell>
                        <TableCell>Plan Status Status</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plan_users && plan_users.map((userPlan) => (
                        <TableRow key={userPlan._id}>
                            <TableCell>{ userPlan.user.email }</TableCell>
                            <TableCell>{ userPlan.plan.name }</TableCell>
                            <TableCell>{ userPlan.status }</TableCell>
                            <TableCell>{ userPlan.user.last_login }</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    )
}

export default PlanMigration
