import React, { useState, useEffect }from 'react'
import { connect } from 'react-redux'
import { updateApproval } from '../../../Redux/Actions/issues_actions';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions'
import FormField from '../../Utils/Forms/FormField';

import { makeStyles } from '@material-ui/core/styles'


import { Select, MenuItem, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
}));

function ApprovalDialog(props) {

    const { dispatch } = props;
    const [loading, setLoading] = useState(false);
    const [formType, setFormType] = useState('Add');
    const [formError, setFormError] = useState(false);
    const [FormErrorMsg, setFormErrorMsg] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [formData, setFormData] = useState({
        reason: {
            element: 'multiline',
            value: '',
            config: {
                name: 'reason',
                type: 'text',
                label: 'Reason',
                limit: 200
            },
            validation: {
                required: true,
            },
            valid: false,
            validationMessage: '',
            showLabel: true
        },
        notes: {
            element: 'multiline',
            value: '',
            config: {
                name: 'notes',
                type: 'text',
                label: 'Notes',
                limit: 200
            },
            validation: {
                required: false,
            },
            valid: true,
            validationMessage: '',
            showLabel: true
        },
        approval_status: {
            element: 'select',
            value: ' ',
            config: {
                name: 'approval_status',
                type: 'select',
                label: 'Approval Status',
                options: [
                    { key: 'Approved', value: 'Approved' },
                    { key: 'Not Approved', value: 'Not Approved' }]
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            showLabel: true
        },
    })

    const updateForm = (element) => {
        const newFormData = update(element, formData, 'approvals');

        setFormError(false);
        setFormData(newFormData);
    }

    const submitForm = (event) => {
        event.preventDefault();
        setLoading(true);

        let dataToSubmit = generateData(formData, 'approvals');
        let formIsValid = isFormValid(formData, 'approvals');

        if (formIsValid) {
            dispatch(updateApproval(props.issue._id, dataToSubmit)).then(responce => {
                if (responce.payload.success) {
                    props.onClose();
                    setLoading(false);
                } else {
                    setFormError(true)
                    setLoading(false);
                }
            });
        }
        else {
            setFormError(true);
            setFormErrorMsg('This form is invalid.');
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">Update Approval</DialogTitle>
            <DialogContent>
                <FormField
                    id={'reason'}
                    formData={formData.reason}
                    change={(element) => updateForm(element)}
                />
                <FormField
                    id={'notes'}
                    formData={formData.notes}
                    change={(element) => updateForm(element)}
                />
                <FormField
                    id={'approval_status'}
                    formData={formData.approval_status}
                    change={(element) => updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
                { loading ? <CircularProgress /> :
                <div>
                <Button onClick={props.onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={(event) => submitForm(event)} color="primary">
                    Submit
                </Button>
                </div> }
            </DialogActions>
        </Dialog>
    )
}

export default connect()(ApprovalDialog)
