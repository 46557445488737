import { makeStyles } from '@material-ui/core';
import React from 'react';
import clxs from 'clsx';
import { yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    item_status : {
        borderRadius: '100%',
        width: '15px',
        height: '15px',
        top: '7px',
        position: 'absolute',
        display: 'inline-block',
        margin: 'auto 1px',
        position:'relative'
    },
    red :{
        backgroundColor:'#cc171d',
    },
    yellow:{
        backgroundColor: '#fff700',
    },
    green: {
        backgroundColor:'#17cc2b',
    },
    grey : {
        backgroundColor:'#bfbfbf',
    },

}))


const StatusIndicator = (props) => {
    const classes = useStyles();

    return (
        <div>
            { props.color == 'red' ?
                <div className={ clxs(classes.item_status, classes.red) }> </div>
                : props.includeBlanks && 
                    <div className={ clxs(classes.item_status, classes.grey) }> </div>
                }
            { props.color == 'greem' ?
                <div className={ clxs(classes.item_status, classes.green) }> </div> 
                : props.includeBlanks && 
                    <div className={ clxs(classes.item_status, classes.grey) }> </div>
                }
            { props.color == 'yellow' ?
                <div className={ clxs(classes.item_status, classes.yellow) }> </div> 
                : props.includeBlanks && 
                    <div className={ clxs(classes.item_status, classes.grey) }> </div>
                }
            { props.color == 'grey' ?
                <div className={ clxs(classes.item_status, classes.grey) }> </div> 
                : props.includeBlanks && 
                    <div className={ clxs(classes.item_status, classes.grey) }> </div>
                }
        </div>
    );
};

export default StatusIndicator;