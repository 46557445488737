import { UPDATE_BILLING_DETAILS, UPDATE_BILLING_PLAN } from './types';

export function updateBillingPlan(plan){
    return {
        type: UPDATE_BILLING_PLAN,
        payload: plan
    }
}   

export function updateBillingDetails(billingDetails){
    return {
        type: UPDATE_BILLING_DETAILS,
        payload: billingDetails
    }
}  