import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { validate } from '../misc';
import FormField from '../../Utils/Forms/FormField';
import ScrollArea from 'react-scrollbar';
import FieldHelpInfo from '../fieldHelpInfo';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'

import Form from '../Form';
import Input from '../Form/input';
import HookSelect from '../Form/hookSelect';
import { Divider, Hidden, MenuItem, TextField } from '@material-ui/core';
import SubmitButton from '../Buttons/submitButton';
import PrimaryButton from '../Buttons/primaryButton';

import { useSelector, useDispatch } from 'react-redux'
import { getGroupStrategicObjectives, 
    addGroupStrategicObjective, 
    removeGroupStrategicObjective,
    updateGroupStrategicObjective } from '../../../Redux/Actions/group_strategic_objectives_actions'

const useStyles = makeStyles((theme) => ({
    list: {
        backgroundColor: theme.palette.background.paper,
        height: '250px',
        marginBottom: theme.spacing(2),
    },
    title: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    },
    select: {
        margin: theme.spacing(1,0,2)
    }
}))

const schema = yup.object().shape({
    objective: yup.string().required('Pelase select a name.'),
    type: yup.string().required(),
    focus: yup.string().required('Please sekect a area.')
})

const StrategicObjectivesDialogList = (props) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('Add');
    const keyObjectives = useSelector(state => state.group_strategic_objectives.group_strategic_objectives)
    const [selectedItem, setSelectedItem] = useState({});
    const classes = useStyles();

    useEffect(() => {
        dispatch(getGroupStrategicObjectives());
    }, [])

    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const handleClickOpen = () => {
        setOpen(true);
        setFormType('Add');
    };

    const handleEdit = (item) => {
        if(item){
            setOpen(true);
            setFormType('Edit');
            setSelectedItem(item);
        }
    }

    const onSubmit = (data) => {
        if (formType === 'Edit') {
            dispatch(updateGroupStrategicObjective(selectedItem._id, data)).then(responce => {
                dispatch(getGroupStrategicObjectives());
            })
        } else {
            dispatch(addGroupStrategicObjective(data)).then(responce => {
                dispatch(getGroupStrategicObjectives());
            })
        }

        setOpen(false);
        setSelectedItem({});
    };

    const handleCancel = () => {
        setOpen(false);
        setSelectedItem({});
    };

    const removeItem = (id) => {
        dispatch(removeGroupStrategicObjective(id)).then(responce => {
            dispatch(getGroupStrategicObjectives());
        })
    }
 
    return (
        <div>
            <Typography variant="h4" className={classes.title}>
                {props.dialogTitle}
            </Typography>
            <div className={classes.list}>
                <List dense={true} >
                    <ScrollArea
                        speed={0.8}
                        horizontal={false}
                        smoothScrolling={true}
                        className={classes.list}>

                        { keyObjectives && keyObjectives.map(item => {
                            console.log(`${item.type} - ${props.typeObjective}`)
                            return (
                            item.type == props.typeObjective ? 
                                <ListItem button key={item.id}>
                                    <ListItemText
                                        primary={item.objective}
                                        secondary={item.type ? item.type : null}
                                    />
                                    <ListItemSecondaryAction>
                                        { !item.group ? 
                                        <div>
                                            <Tooltip title="Edit" aria-label="Edit">
                                                <FontAwesomeIcon icon={faEdit} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}}  onClick={() => handleEdit(item)}/>
                                            </Tooltip>
                                            <Tooltip title="Delete" aria-label="Delete">
                                                <FontAwesomeIcon icon={faTrashAlt} style={{color: '#CC171D', paddingRight: '5px', cursor: 'pointer'}} onClick={() => removeItem(item._id)}/>
                                            </Tooltip>
                                        </div> : null }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            : null )
                        })}

                    </ScrollArea>
                </List>
            </div>

            <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.button}>
                {props.openDialogButtonText}
            </Button>

            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
                <DialogContent style={{ position: "relative"}}>
                    <DialogContentText>
                        {props.dialogContentText}
                    </DialogContentText>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                ref={register}
                                id="objective"
                                label="Name"
                                name="objective"
                                autoComplete="objective"
                                autoFocus
                                defaultValue={selectedItem.objective}
                                error={!!errors.objective}
                                helperText={errors?.objective?.message}
                            />

                            <TextField inputRef={register} name="type" id="type" value={props.typeObjective} inputProps={{ type: 'hidden' }} />

                            <HookSelect id='focus' name='focus' label='Focus' control={control} defaultValue={selectedItem.focus} error={ errors?.focus } className={classes.select}>
                                { props.level != 'business' &&
                                    <MenuItem key="corporate" value='corporate'>Corporate</MenuItem> }
                                <MenuItem key="business" value='business'>Business</MenuItem>
                                { props.level == 'business' &&
                                    <MenuItem key="business" value='functional'>Functional</MenuItem> }
                                <MenuItem key="both" value='both'>Both</MenuItem>
                            </HookSelect>
                            <Divider/>

                            <PrimaryButton onClick={handleCancel}>
                                Cancel
                            </PrimaryButton>
                            <SubmitButton >
                                {formType === "Add" ? 
                                    props.dialogSuccessButtonText
                                    :
                                    "Update"
                                }
                            </SubmitButton>
                        </Form>
                </DialogContent>
            </Dialog>
        </div>
    )
    
}

export default StrategicObjectivesDialogList