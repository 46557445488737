import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { password_change } from '../../Redux/Actions/user_actions'

import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Form from '../UI/Form'
import Input from '../UI/Form/input'
import PrimaryButton from '../UI/Buttons/primaryButton';
import SubmitButton from '../UI/Buttons/submitButton';


const schema = yup.object().shape({
    password: yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required.')
        .matches(/.*[!@#$%^&*]/, "Your password must contain at least one special character")
        .matches(/.*[0-9]/, "Your password must contain at least 1 numeric character"),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
})

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: '20px',
        height: '550px',
        width: '350px'
    },
    grid:{
        background: `url(${LoginBackground}) no-repeat`, 
        height: '800px'
    }
}))


function PasswordChange(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {register, handleSubmit, errors} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });

    const [loading, setLoading] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [formError, setFormError] = useState('')

    const onSubmit = (data) => {

        const token = props.match.params.token ? props.match.params.token : null;

        if(token){
            setLoading(true);

            dispatch(password_change(token,data)).then(response => {
                if (response.payload.success) {
                    setLoading(false);
                    setPasswordChanged(true);
                }
                else{
                    setLoading(false);
                    setFormError(response.payload.msg)
                }
            }).catch(error => {
                setLoading(false);
                console.log(error)
                setFormError(error.response.data.msg)
            })
        }
    }

        return (
            <Grid container direction="row" justify="center" alignItems="center" className={classes.grid}>
                <Grid item>
                    <Paper className={classes.root} elevation={5}>
                        { loading ? 
                            <div>
                                <h2 className="h2login">Updating Password...</h2>
                                <CircularProgress className={classes.progress} />
                            </div>
                            :
                            passwordChanged ?
                                <div>
                                    <h2 className="h2login">Password Updated</h2>
                                    <PrimaryButton href="/">Back to Login</PrimaryButton>
                                </div>
                                :
                                <div>
                                    <h2 className="h2login">Change Password</h2>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Input
                                            ref={register}
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            autoFocus
                                            autoComplete="current-password"
                                            error={!!errors.password}
                                            helperText={errors?.password?.message}
                                        />
                                        <Input
                                            ref={register}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            autoComplete="current-password"
                                            error={!!errors.confirmPassword}
                                            helperText={errors?.confirmPassword?.message}
                                        />

                                        {formError ?
                                            <Typography color="error">{formError}</Typography>
                                        : null}

                                        <SubmitButton>Change Password</SubmitButton>
                                    </Form>
                                </div>
                        }
                    </Paper>
                </Grid>
            </Grid>
        )
    
}

export default withRouter(PasswordChange);