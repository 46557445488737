import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../Utils/Forms/FormActions'

import { loginUser } from '../../Redux/Actions/user_actions'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        marginLeft: theme.spacing(50),
        marginRight: theme.spacing(70),
        marginTop: theme.spacing(20),
        height: '500px',
        width: '350px'
      },
}));

let recaptchaRef = React.createRef();

function SignIn(props) {

    const { dispatch } = props;
    const classes = useStyles();

    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState('');
    const [formData, setFormData] = useState({
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'email',
                type: 'email',
                label: 'Email Address',
                placeholder: 'Enter your Email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        password: {
            element: 'password',
            value: '',
            config: {
                name: 'password',
                type: 'password',
                placeholder: 'Enter your Password'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
        }
    })

    useEffect(() => {
        
    }, [])

    // const recaptchaCheck = (event) => {
    //     event.preventDefault();
    //     recaptchaInstance.execute();

    //     console.log({dataOnexecuteREcaptcha : formData})
    //     console.log('Submit waiting on recaptcha')
    // }

    function onReChange(value) {
        submitForm();
    }

    const submitForm = () => {
        let dataToSubmit = generateData(formData, 'login');
        let formIsValid = isFormValid(formData, 'login');
        

        if (formIsValid) {
            dispatch(loginUser(dataToSubmit)).then(responce => {
                if(responce.payload.loginSuccess){
                    console.log("Passed")
                    props.history.push('/entity_select');
                }else{   
                    if(responce.payload.email && !responce.payload.isVerified){
                        props.history.push('/register/email_verification/' + responce.payload.email);
                    }
                    else{
                        setFormError(true);
                        recaptchaRef.current.reset();
                    }
                }
            });
        }
        else {
            setFormError(true);
            recaptchaRef.current.reset();
        }
    }

    const updateForm = (element) => {
        const newFormData = update(element, { ...formData } , 'login');

        setFormError(false);
        setFormData(newFormData);
    }

    return (
        <div style={{ 
                background: `url(${ LoginBackground }) no-repeat`,
                height: '800px',
                position: 'absolute'
            }}>
            <Paper className={classes.root} elevation={5}>
                <form onSubmit={(event) => { event.preventDefault(); recaptchaRef.current.execute();  }}>
                        <h2 className="h2login">IQFin Strategy Tracker</h2>

                        <div className="login_input_container">
                            <FormField
                                id={'email'}
                                formData={formData.email}
                                change={(element) => updateForm(element)}
                            />

                            <FormField
                                id={'password'}
                                formData={formData.password}
                                change={(element) => updateForm(element)}
                            />

                            { formError ?
                                <div className="error_label">Username and Password does not match.</div>
                            : null }
                            <Button variant="contained" type='submit'
                                style={{
                                    backgroundColor: '#005773',
                                    color: 'white',
                                    fontWeight: [500],
                                    marginTop: '40px',
                                    marginBottom: '20px',
                                    width: '150px'
                                }}>
                                Sign In
                            </Button>
                            <Button href="/register"
                                style={{
                                    color: '#005773',
                                    width: '150px'
                                }}>
                                Register
                            </Button>
                            <Button href="/forgotpassword"
                                style={{
                                    color: '#005773',
                                    width: '220px'
                                }}>
                                Forgot your Password?
                            </Button>
                        </div>

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lem-dYUAAAAAH8yGDvGptPBYDIYIUf4sQp5YrK-"
                            onChange={onReChange}
                            size="invisible"
                        />
                    </form>
            </Paper>
            </div>
    );
    
}

export default connect()(withRouter(SignIn));