import { GETBYID_COMMENTS, CREATE_COMMENT } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GETBYID_COMMENTS:
            return {...state, success: action.payload.success, comments: action.payload.comments }
        case CREATE_COMMENT:
            return {...state, success: action.payload.success }
        default:
            return state;
    }
}