import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        margin: theme.spacing(3)
      },
      bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      top: {
        color: '#CC171D',
        animationDuration: '550ms',
        //position: 'absolute',
        left: 0,
      },
      circle: {
        strokeLinecap: 'round',
      },
}))

const MyCircularProgress = (props) => {
    const classes = useStyles();
    const { children, className, ...other } = props;

    return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <CircularProgress {...other} variant="indeterminate" disableShrink
            className={classes.top} classes={{
            circle: classes.circle,
            }}
            size={40}
            thickness={4}/>
            
            { props.caption ?  
            <Typography component='p'>{ props.caption }</Typography> : null }
        </div>
    );
};

export default MyCircularProgress;