import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox';

class PermissionsCheckbox extends Component {

    state = {
        isChecked : false
    }
    
    componentDidMount() {
        this.setPermissionValue(this.props.action_id, this.props.action)
    }

    setPermissionValue = (actionID, action) => {
        if(this.props.role && this.props.permissions){
            this.props.permissions.forEach(perm => {
                if(perm.action._id === actionID){   
                    if(perm.permission.indexOf[action] !== -1){
                        this.props.checkLoaded(true,actionID, action);
                        this.setState({
                            isChecked: true
                        })
                    }
                }
            });
        }
    }

    handleChecked = (event) => {
        this.props.change(event, this.props.action_id, this.props.action)

        this.setState({
            isChecked: event.target.checked
        })
    }

  render() {
    return (
      <div>
        <Checkbox
            checked={ this.state.isChecked  }
            onChange={(event) => this.handleChecked(event)}
        />
      </div>
    )
  }
}

export default PermissionsCheckbox