import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUser, updateUser, updateRoles } from '../../../Redux/Actions/user_actions';
import { getRoles } from '../../../Redux/Actions/role_actions';

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions';
import { toDropdown } from '../../UI/misc';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

class CreateUser extends Component {
  state = {
    roleDesc: '',
    formType: this.props.formType,
    selectedUser: this.props.selectedUser,
    formError: false,
    formErrorMsg: 'An error accured. Please try again later.',
    formSuccess: '',
    formData: {
      name: {
        element: 'input',
        value: '',
        config: {
          name: 'name',
          type: 'input',
          label: 'First Name',
          placeholder: 'Enter your First Name',
        },
        validation: {
          required: true,
        },
        valid: true,
        validationMessage: '',
        touched: false,
        showLabel: true,
      },
      lastname: {
        element: 'input',
        value: '',
        config: {
          name: 'lastname',
          type: 'input',
          label: 'Last Name',
          placeholder: 'Enter your Last Name',
        },
        validation: {
          required: true,
        },
        valid: true,
        validationMessage: '',
        touched: false,
        showLabel: true,
      },
      email: {
        element: 'input',
        value: '',
        config: {
          name: 'email',
          type: 'email',
          label: 'Email Address',
          placeholder: 'Enter your Email',
        },
        validation: {
          required: true,
          email: true,
        },
        valid: true,
        validationMessage: '',
        touched: false,
        showLabel: true,
      },
      roles: {
        element: 'checkbox_list',
        value: [],
        config: {
          name: 'roles[]',
          label: 'Select Role',
          options: [],
        },
        validation: {
          required: false,
          email: false,
        },
        valid: true,
        validationMessage: '',
        showLabel: true,
      },
    },
  };

  componentDidMount() {
    if (this.props.roleSelect) {
      this.setUserOptions();
    }

    if (this.state.selectedUser && this.state.formType === 'Edit') {
      this.populateForm(this.state.selectedUser);
    }
  }

  populateForm = (user) => {
    let newFormData = { ...this.state.formData };

    for (let key in newFormData) {
      newFormData[key].value = user[key];
    }

    this.setState({
      formData: newFormData,
    });
  };

  setUserOptions = () => {
    this.props.dispatch(getRoles()).then((responce) => {
      let newFormData = { ...this.state.formData };
      //Sanitize for checkbox list
      newFormData['roles'].config.options = toDropdown(this.props.roles, '_id', 'name');

      //Format selected feched user saved values to only IDs in array.
      for (var i = 0; i < newFormData['roles'].value.length; i++) {
        newFormData['roles'].value[i] = newFormData['roles'].value[i]._id;
      }

      this.setState({
        formData: newFormData,
      });
    });
  };

  updateRoles = (element) => {
    let newFormData = { ...this.state.formData };
    let newRolesData = { ...newFormData.roles };

    const roleId = element.event.target.value;
    const roleChecked = element.event.target.checked;
    let roles = newRolesData.value;

    if (roles.indexOf(roleId) === -1 && roleChecked) {
      roles = roles.push(roleId);
    } else if (roles.indexOf(roleId) > -1 && !roleChecked) {
      roles.splice(roles.indexOf(roleId), 1);
    }

    newRolesData.value = roles;
    newFormData = newRolesData;

    this.setState({
      formData: newFormData,
    });
  };

  updateForm = (element) => {
    const newFormData = update(element, this.state.formData, 'create_user');

    if (element.id === 'roles') {
      this.updateRoles(element);
    }

    if (element.id === 'role') {
      this.setRoleDescription(element.event.target.value);
    }

    this.setState({
      formError: false,
      formData: newFormData,
    });
  };

  submitForm(event) {
    event.preventDefault();

    let dataToSubmit = generateData(this.state.formData, 'create_user');
    let formIsValid = isFormValid(this.state.formData, 'create_user');

    console.log(dataToSubmit);

    if (this.props.user_type) {
      dataToSubmit.user_type = this.props.user_type;
    }

    if (formIsValid) {
      if (this.state.formType === 'Add') {
        this.props.dispatch(createUser(dataToSubmit)).then((responce) => {
          if (this.props.success) {
            this.props.userAdded(this.props.success);
          } else {
            const error = responce.payload;

            if (error && error.name && error.name === 'MongoError' && error.code === 11000) {
              this.setState({
                formErrorMsg: 'The email you have entered aready exists.',
                formError: true,
              });
            }

            if (error && error.message) {
              this.setState({
                formErrorMsg: error.message,
                formError: true,
              });
            }
          }
        });
      } else {
        this.props.dispatch(updateRoles(this.state.selectedUser.id, dataToSubmit)).then((responce) => {
          if (this.props.success) {
            this.props.userAdded(this.props.success);
          } else {
            const error = responce.payload.error;

            if (error && error.name && error.name === 'MongoError' && error.code === 11000) {
              this.setState({
                formErrorMsg: 'The email you have entered aready exists.',
                formError: true,
              });
            }
          }
        });
      }
    } else {
      this.setState({
        formErrorMsg: 'This creating is invalid.',
        formError: true,
      });
    }
  }

  render() {
    const { cancel } = this.props;

    return (
      <div>
        <FormField id={'name'} formData={this.state.formData.name} change={(element) => this.updateForm(element)} />
        <FormField id={'lastname'} formData={this.state.formData.lastname} change={(element) => this.updateForm(element)} />
        <FormField id={'email'} formData={this.state.formData.email} change={(element) => this.updateForm(element)} />

        {this.props.roleSelect ? (
          <div>
            <FormField id={'roles'} formData={this.state.formData.roles} change={(element) => this.updateForm(element)} />
          </div>
        ) : null}

        {this.state.formError ? <Typography color="error">{this.state.formErrorMsg}</Typography> : null}

        <Button
          onClick={() => cancel()}
          style={{
            color: '#005773',
            fontWeight: [500],
            marginTop: '40px',
            marginBottom: '20px',
            width: '150px',
          }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={(event) => this.submitForm(event)}
          style={{
            backgroundColor: '#005773',
            color: 'white',
            fontWeight: [500],
            marginTop: '40px',
            marginBottom: '20px',
            width: '150px',
          }}
        >
          {this.state.formType !== 'Edit' ? <span>Create User</span> : <span>Update User</span>}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  logged_in_user: state.user.userData,
  success: state.user.success,
  roles: state.role.roles,
});

CreateUser.propTypes = {
  userAdded: PropTypes.func,
  cancel: PropTypes.func,
};

export default connect(mapStateToProps)(CreateUser);
