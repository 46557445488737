import React, { useState, useEffect }from 'react'
import { connect } from 'react-redux'
import { updateStatus } from '../../../Redux/Actions/issues_actions';
import { getStatuses } from '../../../Redux/Actions/status_action';

import { makeStyles } from '@material-ui/core/styles'

import { Select, MenuItem, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
}));

function ChangeStatus(props) {

    const { dispatch } = props;
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getStatuses()).then(responce => {
            if(props.issue.status){
                setStatus(props.issue.status.name);
            }
        });
    }, [])

    const handleStatusChange = event => {
        setStatus(event.target.value);
    };

    const changeStatus = () => {
        setLoading(true);
        if (status) {
            dispatch(updateStatus(props.issue._id, { status })).then(response => {
                props.onStatusUpdate();
                props.onClose();

                setLoading(false);
            })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm" 
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title" >Update Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a new status:
                </DialogContentText>
                <Select
                    value={status}
                    onChange={handleStatusChange}
                    inputProps={{
                        name: 'status',
                        id: 'status',
                    }}
                    style={{
                        minWidth: '200px'
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {props.statuses ?
                        props.statuses.map(item => (
                            <MenuItem key={item._id} value={item._id} >{item.name}</MenuItem>
                        ))
                        : null
                    }
                </Select>
            </DialogContent>
            <DialogActions>
                { loading ? <CircularProgress /> :
                    <div>
                        <Button onClick={props.onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={changeStatus} color="primary">
                            Change
                        </Button>
                    </div>
                }
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => ({
    statuses: state.status.statuses,
})

export default connect(mapStateToProps)(ChangeStatus)
