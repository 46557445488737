import { GET_GROUP_STRATEGIC_OBJECTIVES, GET_GROUP_STRATEGIC_OBJECTIVES_BYID, UPDATE_GROUP_STRATEGIC_OBJECTIVE, ADD_GROUP_STRATEGIC_OBJECTIVE, REMOVE_GROUP_STRATEGIC_OBJECTIVE } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_GROUP_STRATEGIC_OBJECTIVES:
            return {...state, group_strategic_objectives: action.payload }
        case GET_GROUP_STRATEGIC_OBJECTIVES_BYID:
            return {...state, group_strategic_objective: action.payload }
        case ADD_GROUP_STRATEGIC_OBJECTIVE:
            return {...state, success: action.payload.success }
        case UPDATE_GROUP_STRATEGIC_OBJECTIVE:
            return {...state, success: action.payload.success }
        case REMOVE_GROUP_STRATEGIC_OBJECTIVE:
            return {...state, success: action.payload.success }
        default:
            return state;
    }
}