import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../Redux/Actions/plan.actions';
import { updateBillingPlan } from '../../Redux/Actions/billingProcess.actions';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PrimaryButton from '../UI/Buttons/primaryButton';
import { useHistory } from 'react-router-dom';
import PlanCard from './planCard';
import { getUserPlan } from '../../Redux/Actions/userPlan.actions';
import { Box, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 4),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const schema = yup.object().shape({
  plan: yup.string().required(),
  paymentMethod: yup.string().required('Please select a payment method'),
});

const RenewPlan = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reslover: yupResolver(schema),
  });

  const [isMonthly, setIsMonthly] = useState(false);

  const plans = useSelector((state) => state.plans.plans);
  const userPlan = useSelector((state) => state.userPlan.userplan);

  const handlePlanSelected = (plan) => {
    dispatch(updateBillingPlan({ ...plan, isRenew: true }));
    history.push(`/payment/billing-details`);
  };

  useEffect(() => {
    dispatch(getPlans());
    dispatch(getUserPlan());
  }, []);

  const handleFreqUpdate = (event) => {
    setIsMonthly(event.target.checked);
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary">
          Renew Your Plan
        </Typography>
      </Container>

      {userPlan && (
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Grid container direction="column" alignItems="center">
            <Typography align="center" color="textPrimary" gutterBottom>
              {userPlan.plan.name} @ R{userPlan.plan.price} {userPlan.plan.planFrequency}
            </Typography>
            <PrimaryButton onClick={() => handlePlanSelected(userPlan.plan)}>Continue</PrimaryButton>
          </Grid>
        </Container>
      )}

      <Container maxWidth="md" component="main">
        <Typography align="center" color="textPrimary" gutterBottom>
          Or Select a new plan
        </Typography>
        <Box alignItems="center" justifyContent="center">
          <Typography color="textPrimary" gutterBottom>
            Switch to our monthly plans:
            <Switch checked={isMonthly} onChange={handleFreqUpdate} name="isMonthly" />
            {isMonthly ? 'Monthly' : 'Yearly'}
          </Typography>
        </Box>
        <Grid container spacing={2} alignItems="flex-end">
          {plans
            ? plans.map((plan, index) =>
                plan.planFrequency == (isMonthly ? 'Monthly' : 'Yearly') && plan.visable ? (
                  // Enterprise card is full width at sm breakpoint
                  <Grid item key={plan.name} xs={12} sm={plan.description === 'Business Premium' ? 12 : 6} md={4}>
                    <PlanCard plan={plan} handlePlanSelected={() => handlePlanSelected(plan)} />
                  </Grid>
                ) : null
              )
            : null}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default RenewPlan;
