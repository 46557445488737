//import React from 'react';

export const toDropdown = (array, selected_key, selected_value , bracket_value = '') => {
    let dropdownReady = [];
    array.forEach((element, index) => {
        let dropdownItem = {
            key : selected_key !== '' ? element[selected_key] : element,
            value : selected_value !== '' ? element[selected_value] + (bracket_value != '' ? ' (' + element[bracket_value] + ')' : '') : element
        }

        dropdownReady.push(dropdownItem);
    });
    return dropdownReady;
}

export const reverseArray = (actualArray) => {
    let reversedArray = [];
    for(let i = actualArray.length-1;i>=0;i--){
        reversedArray.push(actualArray[i])
    }

    return reversedArray;
}

export const findIndex = (selected_array, value) => {
    let ret_index = 0;
    selected_array.forEach((item, index) => {
        if(item.key){
            if(item.key.toString() === value.toString() ){
                ret_index =  index;
            }
        }
        else{
            if(item === value ){
                ret_index = index;
            }
        }
    })

    return ret_index;
}

export const validate = (element) => {
    let error = [true, ''];

    if(element.validation.email){
        const valid = /\S+@\S+\.\S+/.test(element.value);
        const message = `${!valid ? 'Must be an valid email address': ''}`;
        error = !valid ? [valid, message] : error
    }


    if(element.validation.required){
        const valid = element.value.trim() !== '';
        const message = `${!valid ? 'This field is required': ''}`; 
        error = !valid ? [valid, message] : error
    }

    return error;
}