import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      margin:theme.spacing.unit * 2,
      width:'300px',
      
    },
    number: {
        fontSize: "60px",
        paddingLeft: theme.spacing.unit * 2,
    }
  });

function TotalObjects(props) {
    const { classes } = props;

    return (
        <div style={{float: 'left'}}>
        <Paper className={classes.root} elevation={3}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant="h5" component="h5">
                        {props.header}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.number}>
                        {props.total}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
      </div>
    )
}

TotalObjects.propTypes = {
    header: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(TotalObjects)
