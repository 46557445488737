import React from 'react';
import { connect } from 'react-redux';
import BackgroundAnimation from './BackgroundAnimation';
import Button from '@material-ui/core/Button';
import Organization from '../UI/organization';
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
import Permission from '../../HOC/Permission';
import BackToParentButton from '../UI/Buttons/backToParentButton';
import PrimaryButton from '../UI/Buttons/primaryButton';


const Welcome = (props) => {

    return (
        <div>
            <BackgroundAnimation />
            <Fade duration={1000} delay={1000}>
                <div className="welcome_container">
                    <h1>Strategy Tracker</h1>
                    <Organization/>
                    <div style={{ margin: "80px 0 20px 0"}}>
                    <Permission action="corporate.view" user={props.user} hideObject={true}>
                        { props.user.selectedOrganization.isGroup ? 
                            <Link to="/group/introduction" >
                                <PrimaryButton variant="contained" >
                                    Start
                                </PrimaryButton>
                            </Link>
                        :
                            <Link to="/introduction" >
                                <PrimaryButton variant="contained" >
                                    Start
                                </PrimaryButton>
                            </Link>
                        }
                    </Permission>
                    
                    {/* <Permission action="functional_strategy.view" user={props.user} hideObject={true}>
                        <Link to="/functional_strategy/introduction" >
                            <Button variant="contained"
                            style={{
                                margin: '10px',
                                backgroundColor: '#005773',
                                color: 'white',
                                fontWeight: [500]
                            }}>
                                Start Functional Strategy
                            </Button>
                        </Link>
                    </Permission> */}
                    </div>
                </div>
            </Fade>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user.userData
})


export default connect(mapStateToProps)(Welcome);
