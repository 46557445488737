import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBillingDetails } from '../../Redux/Actions/billingProcess.actions';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import HeaderText from '../UI/headerText';
import BackgroundItem from '../UI/backgroundItem';
import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';
import Form from '../UI/Form';
import Input from '../UI/Form/input';
import SubmitButton from '../UI/Buttons/submitButton';
import { Redirect, useHistory } from 'react-router-dom';
import { getUserBilling } from '../../Redux/Actions/user_actions';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },
  planHeader: {
    fontWeight: 200,
    fontSize: '23px',
  },
  planPrice: {
    fontWeight: 400,
    fontSize: '23px',
    color: theme.palette.primary.main,
  },
  planFrequency: {
    fontWeight: 300,
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  firstName: yup.string().required('Please enter a First Name.'),
  lastName: yup.string().required('Please enter a Last Name / Surname'),
  contactNumber: yup.string().required('Please enter a Contact Number'),
  email: yup.string().email('Please enter a valid email address').required('Please enter a email address'),
  address1: yup.string().required('Please enter a address'),
  address2: yup.string(),
  city: yup.string().required(),
  postalCode: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Please eneter a postal code'),
  companyName: yup.string(),
  registrationNumber: yup.string(),
  vatNumber: yup.number('VAT Number can only contain numbers.').transform((value) => (isNaN(value) ? undefined : value)),
});

function BillingDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const billing = useSelector((state) => state.user.billing);

  useEffect(() => {
    dispatch(getUserBilling());
  }, []);

  useEffect(() => {
    if (billing) {
      for (const [key, value] of Object.entries(billing)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, [billing]);

  const plan = useSelector((state) => state.billingProcess.plan);

  const onSubmit = (data) => {
    dispatch(updateBillingDetails(data));
    history.push(`/payment/order-review`);
  };

  return (
    <React.Fragment>
      <BackgroundItem path={GeneralBackground} />
      <Container maxWidth="sm">
        <Grid container spacing={5} direction="row" justify="center" alignItems="flex-start">
          <Grid item xs={12}>
            <HeaderText name="Billing Details" size={40} hideCompanyName />
          </Grid>
        </Grid>

        {plan ? (
          <Card className={classes.card}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item sm={8}>
                <Typography className={classes.planHeader}>{plan.name}</Typography>
                <Typography>{plan.description}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography className={classes.planPrice}>R {plan.price}</Typography>
                <Typography className={classes.planFrequency}>{plan.planFrequency == 'Monthly' ? 'per month' : 'anually'}</Typography>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Redirect to="/payment/select-plan" />
        )}

        <Grid container spacing={5} direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input ref={register} id="lastName" label="Last Name" name="lastName" autoComplete="lastName" error={!!errors.lastName} helperText={errors?.lastName?.message} />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="address1"
                    label="Address Line 1"
                    name="address1"
                    autoComplete="addressLine1"
                    error={!!errors.address1}
                    helperText={errors?.address1?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="address2"
                    label="Address Line 2"
                    name="address2"
                    autoComplete="addressLine2"
                    error={!!errors.address2}
                    helperText={errors?.address2?.message}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input ref={register} id="city" label="City/Town" name="city" autoComplete="city" error={!!errors.city} helperText={errors?.city?.message} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="postalCode"
                    label="Postal Code"
                    name="postalCode"
                    autoComplete="postalCode"
                    error={!!errors.postalCode}
                    helperText={errors?.postalCode?.message}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="contactNumber"
                    label="Contact Number"
                    name="contactNumber"
                    autoComplete="contactNumber"
                    error={!!errors.contactNumber}
                    helperText={errors?.contactNumber?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input ref={register} id="email" label="Email" name="email" autoComplete="email" error={!!errors.email} helperText={errors?.email?.message} />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    autoComplete="companyName"
                    error={!!errors.companyName}
                    helperText={errors?.companyName?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="registrationNumber"
                    label="Company Registration Number"
                    name="registrationNumber"
                    autoComplete="registrationNumber"
                    error={!!errors.registrationNumber}
                    helperText={errors?.registrationNumber?.message}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    ref={register}
                    id="vatNumber"
                    label="VAT Number"
                    name="vatNumber"
                    autoComplete="vatNumber"
                    error={!!errors.vatNumber}
                    helperText={errors?.vatNumber?.message}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} direction="row" justify="center" alignItems="flex-start">
                <Grid item>
                  <SubmitButton>Review your order</SubmitButton>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default BillingDetails;
