import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
 
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Typography } from '@material-ui/core';
import Input from '../UI/Form/input';
import Form from '../UI/Form';
import PrimaryButton from '../UI/Buttons/submitButton';

import { updatePassword } from '../../Redux/Actions/user_actions';
import SubmitButton from '../UI/Buttons/submitButton';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    }
}))

const schema = yup.object().shape({
    currentPassword: yup.string().required("Your current password is required."),
    newPassword: yup.string().required("Enter a new password"),
    confirmPassword: yup.string().required("Please confirm your password").oneOf([yup.ref('newPassword'), null], "Password does not match your new password")
});

const UpdatePassword = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, watch } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    }); 

    const classes = useStyles();

    const newPassword = watch("newPassword");
    const [updated, setUpdated] = useState(false);

    const onSubmit = (data) => {
        dispatch(updatePassword(data)).then(responce => {
            setUpdated(true);
        })
    }

    return (
        <Container className={ classes.root }>
            <Typography component='h2'>Update Password</Typography>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Input name='currentPassword' type='password' label="Current Password" ref={ register } error={ !! errors.currentPassword } helperText={errors?.currentPassword?.message} />
                <Input name='newPassword' type='password' label="New Password" ref={ register } error={ !! errors.newPassword } helperText={errors?.newPassword?.message} />
                <Input name='confirmPassword' type='password' label="Confirm Password" ref={ register } error={ !! errors.confirmPassword } helperText={errors?.confirmPassword?.message} />

                <SubmitButton type="submit">Update Password</SubmitButton>

                { updated ? 
                    <Typography component='span' color='error'>Password Updated!</Typography>
                : null }
            </Form>
        </Container>
    );
};

export default UpdatePassword;