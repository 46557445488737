import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { resend_email_verification } from '../../Redux/Actions/user_actions'

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import { makeStyles } from '@material-ui/core/styles';
import {Paper, Grid} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContent from '../UI/mySnackbarContent';
import PrimaryButton from '../UI/Buttons/primaryButton';


const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        height: '450px',
        width: '350px'
    },
    grid: {
        background: `url(${LoginBackground}) no-repeat`,
        height: '800px',
    },
}))


function EmailVerification(props) {
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleSendAgain = () => {
        if(props.match.params.email)
        {
            dispatch(resend_email_verification(props.match.params.email)).then(responce => {
                if(responce.payload.success){
                   setSuccessOpen(true);
                }
                else{
                    setErrorOpen(true);
                }
            }).catch(err => {
                setErrorOpen(true);
            });
        }
        else if(props.user){
            dispatch(resend_email_verification(props.user.userData.email)).then(responce => {
                if(responce.payload.success){
                    setSuccessOpen(true);
                }
                else{
                    setErrorOpen(true);
                }
            }).catch(err => {
                setErrorOpen(true);
            });
        }
        else{
            setErrorOpen(true);
        }
        
    }

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccessOpen(false);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setErrorOpen(false);
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.grid}>
            <Grid item>
            <Paper className={classes.root} elevation={5}>
                <h2 className="h2login">Verification</h2>
                <p>An email has been send to <b>{props.match.params.email}</b> Please click the link to verify your email address.</p>
                <p>If you did not receive your verification email. Click this button to send again.</p>

                <Grid container justify="space-between">
                    <PrimaryButton onClick={(event) => handleSendAgain(event)}>Resend Email</PrimaryButton>
                    <PrimaryButton href="/" >Back to Login</PrimaryButton>
                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}

                        open={successOpen}
                        autoHideDuration={6000}
                        onClose={handleSuccessClose}
                    >
                    <MySnackbarContent
                        onClose={handleSuccessClose}
                        variant="success"
                        message="The verification email was resent. Please check your inbox."
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}

                        open={errorOpen}
                        autoHideDuration={6000}
                        onClose={handleErrorClose}
                    >
                    <MySnackbarContent
                        onClose={handleErrorClose}
                        variant="error"
                        message="An Error accured. Please try again later."
                    />
                </Snackbar>

            </Paper>
            </Grid>
        </Grid>
    ) 
}

export default EmailVerification;