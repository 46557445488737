import axios from 'axios';
import { GET_FLAGS, GET_FLAG, CREATE_FLAG, REMOVE_FLAG, PRINT_FLAGS, EDIT_FLAG } from './types';
import { FLAG_SERVER } from '../../Components/Utils/Misc';

export function getFlags(){
    const request = axios.get(`${FLAG_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_FLAGS,
            payload: request
        }
}

export function getFlag(id){
    const request = axios.get(`${FLAG_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_FLAG,
            payload: request
        }
}

export function createFlag(data){
    const request = axios.post(`${FLAG_SERVER}`,data)
        .then(responce => responce.data);

        return {
            type: CREATE_FLAG,
            payload: request
        }
}

export function updateFlag(id, data){
    const request = axios.put(`${FLAG_SERVER}/${id}`,data)
        .then(responce => responce.data);

        return {
            type: EDIT_FLAG,
            payload: request
        }
}

export function removeFlag(id){
    const request = axios.delete(`${FLAG_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_FLAG,
            payload: request
        }
}

export function printFlags(){
    const request = axios.post(`${FLAG_SERVER}/print`, null, { responseType: 'blob'})
        .then(responce => responce.data);

        return {
            type: PRINT_FLAGS,
            payload: request
        }
}