import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux';
import { getOrganization ,updateOrganization } from '../../Redux/Actions/organization_actions';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../Utils/Forms/FormActions'

import {Paper, Button, Grid, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: '20px',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
}))

function OrganizationUpdate(props) {

    const [loading, setLoading] = useState(true);
    const [organization_id, setOrganization_id] = useState('');
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState('');
    const [formType, setFormType] = useState('Add');
    const [formData, setFormData] = useState({ 
        name: {
            element: 'input',
            value: '',
            config: {
                name: 'name',
                type: 'text',
                label: 'Organization Name'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'email',
                type: 'text',
                label: 'Organization Contact Email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        contact_number: {
            element: 'input',
            value: '',
            config: {
                name: 'contact_number',
                type: 'text',
                label: 'Organization Contact Number'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_first_name: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_first_name',
                type: 'text',
                label: 'First Name'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_last_name: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_last_name',
                type: 'text',
                label: 'Last Name'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_address_1: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_address_1',
                type: 'text',
                label: 'Address Line 1'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_address_2: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_address_2',
                type: 'text',
                label: 'Address Line 2'
            },
            validation: {
                required: false
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_city: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_city',
                type: 'text',
                label: 'City'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_postcode: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_postcode',
                type: 'text',
                label: 'Post Code'
            },
            validation: {
                required: true
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_phone: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_phone',
                type: 'text',
                label: 'Phone Number'
            },
            validation: {
                required: false
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_vat: {
            element: 'input',
            value: '',
            config: {
                name: 'billing_vat',
                type: 'text',
                label: 'VAT Number'
            },
            validation: {
                required: false
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        },
        billing_registration_number:{
            element: 'input',
            value: '',
            config: {
                name: 'billing_registration_number',
                type: 'text',
                label: 'Company Registration Number'
            },
            validation: {
                required: false
            },
            valid: true,
            validationMessage: '',
            touched: false,
            showLabel: true
        }
    })

    const { dispatch } = props;
    const classes = useStyles();
    
    useEffect(() => {
        const id = props.match.params.id;

        if(id){
            dispatch(getOrganization(id)).then(response => {
                if(response.payload){
                     populateForm(response.payload);
                }
            })
        }
    }, [])


    const populateForm = (organization) => {
        let newFormData = { ...formData };
    
        for (let key in newFormData) {
            newFormData[key].value = organization[key];
        }

        setOrganization_id(organization._id);
        setFormData(newFormData);
        setFormType('Edit');
        setLoading(false);
    } 

    const submitForm = (event) => {
        event.preventDefault();
        setLoading(true);

        let dataToSubmit = generateData(formData, 'update_org');
        let formIsValid = isFormValid(formData, 'update_org');

        if (formIsValid) {
             dispatch(updateOrganization(organization_id, dataToSubmit)).then(responce => {
                 if(responce.payload.success){
                     props.history.push('/organization');
                     setLoading(false);
                 }else{
                    setFormError(true);
                    setLoading(false);
                 }
             });
        }
        else {
            setFormError(true);
            setLoading(false);
        }
    }

    const updateForm = (element) => {
        const newFormData = update(element, formData, 'update_org');

        setFormData(newFormData);
        setFormError(false);
    }



    return (
        <div>
            <Paper className={classes.root} >
                { loading ? 
                <Grid container direction="column" justify="center" alignItems="center">
                    <CircularProgress />
                </Grid>
                 :
                 <div>
                    <h2 className="h2login">Organization Edit</h2>
                    <form onSubmit={(event) => submitForm(event)}>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    id={'name'}
                                    formData={formData.name}
                                    change={(element) => updateForm(element)}
                                />

                                <FormField
                                    id={'email'}
                                    formData={formData.email}
                                    change={(element) => updateForm(element)}
                                />

                                <FormField
                                    id={'contact_number'}
                                    formData={formData.contact_number}
                                    change={(element) => updateForm(element)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                            </Grid>
                        </Grid>
                        <h4>Billing Information</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    id={'billing_first_name'}
                                    formData={formData.billing_first_name}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_last_name'}
                                    formData={formData.billing_last_name}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_vat'}
                                    formData={formData.billing_vat}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_registration_number'}
                                    formData={formData.billing_registration_number}
                                    change={(element) => updateForm(element)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    id={'billing_address_1'}
                                    formData={formData.billing_address_1}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_address_2'}
                                    formData={formData.billing_address_2}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_city'}
                                    formData={formData.billing_city}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_postcode'}
                                    formData={formData.billing_postcode}
                                    change={(element) => updateForm(element)}
                                />
                                <FormField
                                    id={'billing_phone'}
                                    formData={formData.billing_phone}
                                    change={(element) => updateForm(element)}
                                />
                            </Grid>
                        </Grid>
                    
                        <Button variant="outlined"  onClick={() => { props.history.push('/organization'); }}>
                        Back
                        </Button>
                        <Button variant="contained"
                            onClick={(event) => submitForm(event)}
                            style={{
                                backgroundColor: '#005773',
                                color: 'white',
                                fontWeight: [500],
                                marginLeft: '10px',
                                //width: '170px'
                            }}>
                            Update
                        </Button>
                    </form>
                </div>
                }
            </Paper> 
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    edit_organization : state.organization.organization,
    success : state.organization.success
})


export default connect(mapStateToProps)(withRouter(OrganizationUpdate));