import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAreas } from '../../Redux/Actions/area_actions';

import BackendBackground from '../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';

// import CreateUser from './Forms/createUser';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    },
});


class Areas extends Component {
    state = {
        createUserDialogOpen: false
    }

    componentDidMount() {
        if (this.props.loggedInUser) {
            this.props.dispatch(getAreas()).then(response => {
                console.log(response);
            });
        }
    }

    openUserCreate = () => {
        this.setState({
            createUserDialogOpen: true
        })
    }

    handleDialogClose = () => {
        this.setState({
            createUserDialogOpen: false
        })
    }

    handleUserCreated = () => {
        this.handleDialogClose();
    }

    userCreateDialog = () => {
        return (
        <Dialog
        open={this.state.createUserDialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create a new Area</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add details of the area below. 
                </DialogContentText>
                
                {/* <CreateUser 
                cancel={() => this.handleDialogClose()}
                userAdded={() => this.handleUserCreated()} 
                roleSelect={true}/> */}
                
            </DialogContent>
        </Dialog>            
        )
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackendBackground />

                <Paper className={classes.root}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Area</TableCell>
                                <TableCell>Parent Area</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.areas && !this.props.areas.error ?
                                this.props.areas.map((area, index) => {

                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {area.name}
                                            </TableCell>
                                            <TableCell>{area.parent ? area.parent.name : ""}</TableCell>           
                                            <TableCell>
                                                <Button className={classes.button} variant="contained" color="secondary">Edit</Button>
                                                <Button className={classes.button} variant="contained" >Delete</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) :
                                <TableRow>
                                    <TableCell scope="row" colSpan="8">No Areas</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell >
                                    <Button 
                                    className={classes.button} 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => this.openUserCreate()}>Add New Area</Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>

                    { this.userCreateDialog() }
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    areas: state.areas.areas,
    loggedInUser: state.user.userData
})


export default connect(mapStateToProps)(withStyles(styles)(Areas))