import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectOrganization, ClearSelection } from '../../Redux/Actions/organization_actions';

import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ScrollArea from 'react-scrollbar';
import { Button, ButtonGroup, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 3)
    }
}))


const ListEntities = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData);
    const entities = useSelector(state => state.user.userData.organizations);
    const selectedEntity = useSelector(state => state.user.userData.selectedOrganization);
    
    const classes = useStyles();

    const changeEntity = (entityId) => {
        if (entityId) {
            dispatch(SelectOrganization(entityId));
        }
    }

    return (
        <ScrollArea
                className={classes.root}
                speed={0.8}
                horizontal={false}
                smoothScrolling={true}
                style={{
                    height:  '700px'
                }}>
        {user ?
            <div>
                <Table style={{ margin: '10px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Group</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { entities ? entities.map(org => (
                        org ?
                            <TableRow>
                                <TableCell>{org.organization_id.name} { selectedEntity._id == org.organization_id._id ? <Typography variant='overline' component='small' color='primary'>(Selected)</Typography> : null }</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{org.organization_id.parent ? org.organization_id.parent.name : null}</TableCell>
                                <TableCell align="right">
                                    <ButtonGroup>
                                            <Button color="primary" variant="outlined"
                                                onClick={() => changeEntity(org.organization_id._id)}                                       
                                                style={{
                                                    color: '#005773'
                                                }}>
                                                Manage
                                            </Button>
                                            { selectedEntity._id != org.organization_id._id ?
                                            <Button color="primary" variant="outlined"
                                                onClick={() => changeEntity(org.organization_id._id)}                                       
                                                style={{
                                                    color: '#005773'
                                                }}>
                                                Switch
                                            </Button>                
                                            : null}                  
                                    </ButtonGroup>                                
                                </TableCell>
                            </TableRow>
                        : null
                        )) : null }
                    </TableBody>
                </Table>
            </div>
        :
        null}
        </ScrollArea>
    );
};

export default ListEntities;