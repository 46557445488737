import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";

class Permission extends Component {
    state = {
        hasPermission: false,
        isloading: true,
    }

    componentDidMount = () => {
        let _hasPermission = false;
        //let alreadyTrue = false;

        if(this.props.action === 'everyone'){
            _hasPermission = true;
            this.setState({
                hasPermission: _hasPermission,
                isloading: false,
            })
        }
        else{
            if (this.props.user && this.props.user.roles) {
                this.props.user.roles.forEach(role => {
                    const permissions = role.permissions;
                    

                    const sl_actions = this.props.action.split('.');
                    const request_access = sl_actions[0];
                    const request_action = sl_actions[1];

                    if(request_access && request_action){
                        const access_permission = permissions[request_access];
                        
                        if(access_permission){
                            _hasPermission = access_permission[request_action];
                            

                            if(_hasPermission){
                                this.setState({
                                    hasPermission: _hasPermission,
                                    isloading: false,
                                })
                            }
                        }
                    }
                });

                
            }
        }
    }

    render() {
        return (
            !this.state.isloading ?
                this.state.hasPermission ?
                    this.props.children
                : 
                    this.props.hideObject ? 
                        null
                    :
                        <Redirect to="/authorised_access_only" />
            :null
        )
    }
}

Permission.propTypes = {
    // action: PropTypes.oneOf(['users', 'corporate', 'corporate_strategy', 'functional_strategy', 'reports', 'business_units', 'media', 'company_admin', 'admin', 'roles', 'profile']),
    // permission: PropTypes.oneOf(['Create', 'Read', 'Update', 'Delete', 'Execute']),
    user: PropTypes.object,
    hideObject: PropTypes.bool
}


export default connect()(Permission)
