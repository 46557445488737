import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganization } from '../../../Redux/Actions/organization_actions';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import RoadTimeline from './partials/roadTimeline';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  pdf: {
    padding: '10px 25px 25px 25px',
  },
}));

const RoadMap = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const organization = useSelector((state) => state.organization.organization);

  useEffect(() => {
    dispatch(getOrganization(props.entity));
  }, []);

  const config = {
    header: {
      top: {
        style: { backgroundColor: '#162336' },
      },
      middle: {
        style: { backgroundColor: '#005773', color: 'white' },
        selectedStyle: { backgroundColor: 'white', color: 'white' },
      },
      bottom: {
        style: { backgroundColor: '#005773', color: 'white', fontSize: 9 },
        selectedStyle: { backgroundColor: '#005773', color: 'white', fontWeight: 'bold' },
      },
    },
    taskList: {
      title: {
        label: 'Issues',
        style: { padding: '10px', textAlign: 'center' },
      },
      task: {
        //style:{backgroundColor:  '#6399AB'}
      },
      verticalSeparator: {},
    },
    dataViewPort: {
      rows: {
        style: { padding: '4px', height: 'auto' },
      },
      task: {
        showLabel: false, //If the task display the a lable
        style: {
          position: 'absolute',
          borderRadius: 14,
          color: 'white',
          textAlign: 'center',
          backgroundColor: '#6399AB',
        },
        selectedStyle: {}, //the style tp be applied  when selected
      },
    },
    links: {
      //The link between two task
      // color:'black',
      // selectedColor:'#ff00fa'
    },
  };

  const getList = () => {
    let retData = {};

    if (props.issues) {
      let items = [];
      let groups = [];

      for (let i = 0; i < props.issues.length; i++) {
        const issue = props.issues[i];
        const item = {
          id: issue._id,
          title: moment(issue.due_date).format('DD/MM/YYYY'),
          group: issue._id,
          start_time: moment(issue.due_date).toDate(),
          end_time: moment().add(1, 'day'),
          itemProps: {
            style: { color: '#000' },
          },
        };

        const group = {
          id: issue._id,
          title: issue.description,
          rightTitle: issue.area.name,
          height: 60,
        };

        const groupExists = groups.filter((g) => g.id == group.id).length > 0;
        if (!groupExists) {
          groups.push(group);
        }

        items.push(item);
      }

      retData.items = items;
      retData.groups = groups;
    }
    return retData;
  };

  return (
    <Container>
      <div id="pdfdiv" className={classes.pdf}>
        <h2>Road Map - {organization?.name}</h2>
        <RoadTimeline data={getList()} />

        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={4}>
            {moment().format('MM/DD/YYYY')}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            IQFin Strategy Tracker
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            Generated by {user.fullname}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default RoadMap;
