import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { logoutUser } from '../../Redux/Actions/user_actions';


import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import UserIcon from '../../Resources/images/icons/icon_userprofile.png';
import LogoutIcon from '../../Resources/images/icons/icon_logout.png';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    user_button: {
        backgroundColor: '#0a1c2d',
        margin: theme.spacing.unit,
        background: `url(${UserIcon}) no-repeat`,
        backgroundPosition: '16px 10px',
        '&:hover': {
            backgroundColor: '#0a1c2d'
        }
    },
    logout_button: {
        backgroundColor: '#5683A5',
        margin: theme.spacing.unit,
        background: `url(${LogoutIcon}) no-repeat`,
        backgroundPosition: '17px 15px',
        '&:hover': {
            backgroundColor: '#5683A5'
        }
    },
    change_button: {
        backgroundColor: '#5683A5',
        margin: theme.spacing.unit,
        color: '#0a1c2d',
        fontWeight: 'bold'
    },
    organizationNam: {
        marginLeft: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    }
});


class ProfileButtons extends Component {
    logoutHandler = () => {
        this.props.dispatch(logoutUser()).then(responce => {
            if(responce.payload.success){
                this.props.history.push('/');
            }
        })
    }

    handleProfileClick = () => {
        this.props.history.push("/dashboard")
    }

    renderProfileButtons = () => {
        const { classes } = this.props;
        return (
            this.props.user.userData ? 
                this.props.user.userData.isAuth ?
                    <div>
                        { this.props.user.userData.isAuth   ?
                        <Tooltip title="Dashboard" placement="bottom" enterDelay={500} leaveDelay={200}>
                            <Fab variant="extended" onClick={() => this.handleProfileClick()}>
                                Management Portal
                            </Fab>
                        </Tooltip>
                        : null }
                        <Tooltip title="Logout" placement="bottom" enterDelay={500} leaveDelay={200}>
                            <Fab classes={{ root: classes.logout_button }} onClick={() => this.logoutHandler()}>
                                <Icon></Icon>
                            </Fab>
                        </Tooltip> 
                    </div>
                :null
            :null
        )
    }

    render() {
        
        return (
            <div>
                <div className="profile_buttons_container">
                            {this.renderProfileButtons()}          
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ProfileButtons)));
