import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import BackendBackground from '../UI/backendBackground';
import Permission from '../../HOC/Permission';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { DialogTitle,DialogContentText,DialogContent,Dialog } from '@material-ui/core';

import ChangeOwner from './partial/changeOwner'
import Transfer from './transfer';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative',
        padding: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 700,
    },
    card:{
        margin: theme.spacing.unit * 2 ,
    }
}))

const CompanyDetails = (props) => {

    const dispatch = useDispatch();
    const [changeOwnerWindowOpen, setChangeOwnerWindowOpen] = useState(false)
    const [selectedLicense, setSelectedLicense] = useState({})
    const classes = useStyles();

    const entity = useSelector(state => state.user.userData.selectedOrganization);
    const parentEntity = useSelector(state => state.user.userData.parentEntity);
    const user = useSelector(state => state.user.userData);
    const organizations = useSelector(state => state.user.userData.organizations);

    const handleChangeOwnership = () => {
        setChangeOwnerWindowOpen(true);
    }

    const closeChangeOwnership = () => {
        setChangeOwnerWindowOpen(false);
    }
    

    const getLicense = () => {
        for(var i=0; i< organizations.length;i++){
            const organization = organizations[i];
            if(organization && entity){
                if(organization.organization_id._id.toString() === entity._id.toString()){
                    return organization.license;
                }
            }
        } 
    }

    const changeOwnerDialog = () => {
        return (
        <Dialog
        open={changeOwnerWindowOpen}
        onClose={closeChangeOwnership}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create a new User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select the user you want to make the owner.
                </DialogContentText>
                
                <ChangeOwner 
                    closeOwnerTransfer={(success) => closeChangeOwnership(success)}
                />          
            </DialogContent>
        </Dialog>            
        )
    }

    return (
        <div>
            <BackendBackground />
            <Paper className={classes.root}>
                <Grid container direction="row">
                    <h1 style={{ fontSize: 50}}>{entity.name}</h1>
                </Grid>
                <Grid container >
                    <Grid item xs={12} md={6}>
                        <p><b>Contact Email:</b> {entity.email}</p>
                        <p><b>Contact Number:</b> {entity.contact_number}</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                </Grid> 
                <Grid container>
                    <Permission action="corporate.update" user={user} hideObject={true} >
                        <Button variant="contained"
                            component="a"
                            href={'/organization/' + entity._id}
                            style={{
                                backgroundColor: '#005773',
                                color: 'white',
                                fontWeight: [500],
                                marginBottom: '10px',
                                width: '170px'
                            }}>
                            Update
                        </Button>
                        { entity.owner == user.user_id ? 
                            <Transfer />
                        :null }
                    </Permission>
                </Grid>  
            </Paper>
            { changeOwnerDialog() }
        </div>
    )
}

export default CompanyDetails
