import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';  
import html2canvas from 'html2canvas';

import { Dialog, DialogActions, DialogContent, DialogContentText , DialogTitle , Typography, makeStyles, Container, Divider, Grid } from '@material-ui/core';
import PrimaryButton from '../UI/Buttons/primaryButton';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    root: {
        padding: '20px'
    },
    header:{
        fontSize: '45px'
    }
}))

function PrintIssue(props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const strategicObjectives = useSelector(state => state.group_strategic_objectives.group_strategic_objectives);
    const users = useSelector(state => state.user.users);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const printDocument = () => {  
        const input = document.getElementById('pdfdiv');  

        html2canvas(input)  
          .then((canvas) => { 
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF('l', 'px', [ canvas.width, canvas.height ]);

            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);  
            pdf.setProperties({
                    title: props.title ? props.title : new Date().toDateString(),
            });
            

            window.open(pdf.output('bloburl'), '_blank');
        });
    }

    const getStrategicObjective = () => {

        if(!props.issue.key_objective?.objective){
            if(strategicObjectives){
                return strategicObjectives.find(item => item._id == props.issue.key_objective)?.objective;
            }
        }
        else if (props.issue.key_objective.objective){
            return props.issue.key_objective.objective;
        }
    }



    return (
        <React.Fragment>
       
        <PrimaryButton variant="outlined" onClick={handleClickOpen}>
          { props.buttonText ? props.buttonText : 'Print' }
        </PrimaryButton> 
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={handleClose}
          aria-labelledby="print-dialog-title"
        >
          <DialogTitle id="print-dialog-title">Print</DialogTitle>
          <DialogContent>
              <div id='pdfdiv' className={classes.root}>
                { props.issue ? 
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Typography variant='h2' component='h2' className={classes.header}>{props.issue.issue_type === 'current' ? 'Current' : 'Anticipated'} <strong style={{ color: '#005773' }}>{props.issue.area && props.issue.area.name}</strong> Issue</Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' >Description</Typography>
                            <Typography> {props.issue.description} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' >Impact</Typography>
                            <Typography> {props.issue.impact} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' >Action</Typography>
                            <Typography> {props.issue.action} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' >Anticipated Outcome</Typography>
                            <Typography> {props.issue.outcome} </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' >Focus</Typography>
                            <Typography> {props.issue.internalexternal} </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' >Strategic Theme</Typography>
                            <Typography> {props.issue.strategic_objective} </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' >{ props.issue.issueStrategyType == 'group' ? 'Group Key Objective' : 'Key Objective' }</Typography>
                            <Typography> { getStrategicObjective() } </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' >Key Performance Indicator</Typography>
                            <Typography> {props.issue.kpi} </Typography>
                        </Grid> 
                        <Grid item xs={12} md={6}>    
                            <Typography variant='h5' >Assigned Users</Typography>

                            { users ?
                                users.filter(u => props.issue.assigned_users.indexOf(u.id) != -1).map(user => (
                                    <Typography key={user._id}>{user.fullname}</Typography>
                                ))
                            : <Typography> No users assigned</Typography> }
                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' >Due Date</Typography>
                            <Typography> { moment(props.due_date).format('DD/MM/YYYY') } </Typography>
                        </Grid>
                    </Grid>
                : <Typography>No issue was selected.</Typography>}
              </div>
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={handleClose}>
              Close
            </PrimaryButton>
            <PrimaryButton onClick={printDocument}>
              Print
            </PrimaryButton>
          </DialogActions>
        </Dialog> 
      </React.Fragment>
    )
}

export default PrintIssue
