import { UPDATE_BILLING_DETAILS, UPDATE_BILLING_PLAN } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case UPDATE_BILLING_PLAN:
            return {...state, plan: action.payload }
        case UPDATE_BILLING_DETAILS:
            return {...state, billingDetails: action.payload }
        default:
            return state;
    }
}