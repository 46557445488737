import { GET_PLANS, GET_PLAN, CREATE_PLAN, UPDATE_PLAN, REMOVE_PLAN } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_PLANS:
            return {...state, plans: action.payload.plans, success: action.payload.success }
        case GET_PLAN:
            return {...state, plan: action.payload }
        case CREATE_PLAN:
            return {...state, success: action.payload }
        case UPDATE_PLAN:
            return {...state, success: action.payload }
        case REMOVE_PLAN:
            return {...state, success: action.payload }
        default:
            return state;
    }
}