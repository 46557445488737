import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core';
import clsx from 'clsx'


const useStyles = makeStyles((theme) => ({
    item : {
        backgroundColor: '#162336',
        minWidth: '130px',
        maxWidth: '284px',
        padding: '5px',
        margin: '5px',
        borderRadius: '2px',
        minHeight: '21px',
        display: 'inline-block',
        position: 'relative',
    },
    itemText :{
        fontSize: '12px',
        color: 'white',
        verticalAlign: 'middle',
        paddingLeft: '20px'
    },
    item_status : {
        borderRadius: '100%',
        width: '15px',
        height: '15px',
        top: '7px',
        position: 'absolute',
    },
    red :{
        backgroundColor:'#cc171d',
    },
    yellow:{
        backgroundColor: '#fff700',
    },
    green: {
        backgroundColor:'#17cc2b',
    },
    grey : {
        backgroundColor:'#bfbfbf',
    },
    theme_keys: {
        width: '410px',
        position: 'absolute',
        top: '10px',
        right: 0
    },
    
    theme_key_item: {
        display: 'block',
        width: '180px',
        padding: '4px',
        margin: '5px',
        display: 'inline-block',
    },
    theme_key_text: {
        paddingLeft: '20px',
        float: 'left',
        textTransform: 'uppercase',
        fontSize: 'small',
    }
}))

const ScoreCardItem = ({...props}) => {
    const classes = useStyles();

    const getStatus = (issue) => {
        switch(issue.status.name){
            case "Not Started" :
                return ( <div className={ clsx(classes.item_status, classes.grey) } ></div> )
            case "In Progress" : 
                return ( <div className={ clsx(classes.item_status, classes.yellow) } ></div> )
            case "Pending Approval" : 
                return ( <div className={ clsx(classes.item_status, classes.yellow) } ></div> )
            case "Done" : 
                return ( <div className={ clsx(classes.item_status, classes.green) } ></div> )
            case "Block" : 
                return ( <div className={ clsx(classes.item_status, classes.red) } ></div> )
            case "Not Done" : 
                return ( <div className={ clsx(classes.item_status, classes.red) } ></div> )
        }
    }

    return (
        <div className={classes.item}>
            <Typography className={ classes.itemText }>{ props.issue.description }</Typography> 
            { getStatus(props.issue) }
        </div>
    );
};

export default ScoreCardItem;