import React from 'react';
import { useSelector } from 'react-redux';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
  Container,
  Divider,
  Grid,
} from '@material-ui/core';
import PrimaryButton from '../Buttons/primaryButton';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  root: {
    padding: '20px',
  },
  header: {
    fontSize: '45px',
  },
}));

const IssueViewDialog = (props) => {
  const classes = useStyles();
  const strategicObjectives = useSelector(
    (state) => state.group_strategic_objectives.group_strategic_objectives
  );
  const users = useSelector((state) => state.user.users);

  const printDocument = () => {
    const input = document.getElementById('pdfdiv');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);

      pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
      pdf.setProperties({
        title: props.title ? props.title : new Date().toDateString(),
      });

      window.open(pdf.output('bloburl'), '_blank');
    });
  };

  const getStrategicObjective = () => {
    if (!props.issue.key_objective?.objective) {
      if (strategicObjectives) {
        return strategicObjectives.find((item) => item._id == props.issue.key_objective)?.objective;
      }
    } else if (props.issue.key_objective.objective) {
      return props.issue.key_objective.objective;
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="print-dialog-title"
      >
        <DialogTitle id="print-dialog-title">Print</DialogTitle>
        <DialogContent>
          <div id="pdfdiv" className={classes.root}>
            {props.issue ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2" component="h2" className={classes.header}>
                    {props.issue.issue_type === 'current' ? 'Current' : 'Anticipated'}{' '}
                    <strong style={{ color: '#005773' }}>
                      {props.issue.area && props.issue.area.name}
                    </strong>{' '}
                    Issue
                  </Typography>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Description</Typography>
                  <Typography> {props.issue.description} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Impact</Typography>
                  <Typography> {props.issue.impact} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Action</Typography>
                  <Typography> {props.issue.action} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Anticipated Outcome</Typography>
                  <Typography> {props.issue.outcome} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Focus</Typography>
                  <Typography> {props.issue.internalexternal} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Strategic Theme</Typography>
                  <Typography> {props.issue.strategic_objective} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">
                    {props.issue.issueStrategyType == 'group'
                      ? 'Group Key Objective'
                      : 'Key Objective'}
                  </Typography>
                  <Typography> {getStrategicObjective()} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Key Performance Indicator</Typography>
                  <Typography> {props.issue.kpi} </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Assigned Users</Typography>

                  {users ? (
                    users
                      .filter((u) => props.issue.assigned_users?.indexOf(u.id) != -1)
                      .map((user) => <Typography key={user._id}>{user.fullname}</Typography>)
                  ) : (
                    <Typography> No users assigned</Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Due Date</Typography>
                  <Typography> {moment(props.due_date).format('DD/MM/YYYY')} </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography>No issue was selected.</Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={props.onClose}>Close</PrimaryButton>
          <PrimaryButton onClick={printDocument}>Print</PrimaryButton>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth={true} >
                <DialogTitle id="form-dialog-title">View Issue</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6">
                                Description
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.description }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Impact
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.impact }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Actions
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.action }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Outcome
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.outcome }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Due Date
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.due_date ? 
                                    moment( props.issue.due_date) >= moment() ||  props.issue.status.name === "Done" ? 
                                        <Typography>
                                            { moment( props.issue.due_date).format('DD/MM/YYYY') }
                                        </Typography>
                                    :
                                        <Typography color="error">
                                            { moment( props.issue.due_date).format('DD/MM/YYYY') }
                                        </Typography>
                                :
                                <div>No Date</div>
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6">
                                Focus
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.internalexternal }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Type
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.issue_type }
                            </Typography>

                            

                            <Typography variant="h6" component="h6">
                                { props.issue.issueStrategyType && props.issue.issueStrategyType == 'group' ? 'Group Strategic Objective' : 
                                    props.issue.issueStrategyType == 'business' ? 'Business Strategic Objective' : 'Strategic Objective' }
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.key_objective ? props.issue.key_objective.type - props.issue.key_objective.objective  : null }
                            </Typography>

                            <Typography variant="h6" component="h6">
                                Key Performance Indicator
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.kpi }
                            </Typography>


                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6">
                                Assigned Users
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { props.issue.assigned_users ?
                                    props.issue.assigned_users.map(user => (
                                        <span>{ user.name }</span>
                                    )) 
                                :null }
                            </Typography>
                        </Grid>
                    </Grid>
                   
                   



                </DialogContent>
                <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog> */}
    </div>
  );
};

export default IssueViewDialog;
