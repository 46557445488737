import React, { useState, useEffect } from 'react';

import BackendBackground from '../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { getByUser, archiveCorporateIssue } from '../../Redux/Actions/issues_actions';
//import { getStatuses } from '../../Redux/Actions/status_action';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IssueViewDialog from '../UI/Dialog/issueViewDialog';
import StatusChange from '../Partials/Tasks/ChangeStatus';
import ApprovalDialog from '../Partials/Tasks/ApprovalDialog';
import Permission from '../../HOC/Permission';

import moment from 'moment';
import PrintIssue from '../Print/printIssue';
import PrimaryButton from '../UI/Buttons/primaryButton';
import Comments from '../Comments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    overflowX: 'auto',
    position: 'relative',
  },
  table: {
    minWidth: 700,
  },
  action_button: {
    margin: theme.spacing.unit,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function GroupStrategyTasks(props) {
  const { dispatch } = props;
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('DueDate');
  const [selected, setSelected] = React.useState([]);

  const [typeArchive, setTypeArchive] = useState('');
  const [statusOpen, setStatusOpen] = useState(false);
  const [issueOpen, setIssueOpen] = useState(false);
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [actionOpen, setActionOpen] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState({});

  useEffect(() => {
    dispatch(getByUser());
  }, []);

  const handleStatusOpen = (issue) => {
    setStatusOpen(true);
    setSelectedIssue(issue);
  };
  const handleStatusClose = () => {
    setStatusOpen(false);
    setSelectedIssue({});
  };

  const handleIssueOpen = (issue) => {
    setIssueOpen(true);
    setSelectedIssue(issue);
  };
  const handleIssueClose = () => {
    setIssueOpen(false);
    setSelectedIssue({});
  };

  const handleApprovalOpen = (issue) => {
    setApprovalOpen(true);
    setSelectedIssue(issue);
  };
  const handleApprovalClose = () => {
    setApprovalOpen(false);
    setSelectedIssue({});
  };

  const RefreshTable = () => {
    dispatch(getByUser());
  };

  const handleArchiveConfirmOpen = (task) => {
    setConfirmArchive(true);
    setSelectedIssue(task);
  };
  const handleArchiveConfirmClose = (task, confirmed) => {
    if (confirmed) {
      dispatch(archiveCorporateIssue(task._id));
    }

    setConfirmArchive(false);
    setSelectedIssue({});
  };

  const handleTypeArchiveChange = (event) => {
    setTypeArchive(event.target.value);
  };

  const archiveDialog = () => (
    <Dialog open={confirmArchive} onClose={() => handleArchiveConfirmClose(selectedIssue, false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Archive Task</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are about to archive a task. Are you sure you want to archive this task?
          <br />
          <strong>Description</strong>
          <br />
          {selectedIssue.description}
        </DialogContentText>
        <TextField autoFocus margin="dense" id="txtDelete" label="Type ARCHIVE to confirm" type="text" fullWidth onChange={handleTypeArchiveChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleArchiveConfirmClose(selectedIssue, false)} color="primary">
          Cancel
        </Button>
        {typeArchive.toLowerCase() === 'archive' ? (
          <Button onClick={() => handleArchiveConfirmClose(selectedIssue, true)} color="primary" autoFocus>
            Yes
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'assigned_users',
      numeric: false,
      disablePadding: false,
      label: 'Assigned',
    },
    { id: 'area', numeric: false, disablePadding: false, label: 'Focus Area' },
    {
      id: 'due_date',
      numeric: false,
      disablePadding: false,
      label: 'Due Date',
    },
    {
      id: 'strategic_objective',
      numeric: false,
      disablePadding: false,
      label: 'Strategic Objective',
    },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const cleanData = (array) => {
    let newData = [];

    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      console.log(item);
      const newItem = {
        id: item._id,
        description: item.description,
        status: item.status.name,
        due_date: item.due_date,
        area: item.area.name,
        assigned_users: item.assigned_users.map((user) => user.name + ' ' + user.lastname + '\n'),
        business_unit: item.business_unit,
        issue: item,
        strategic_objective: item.key_objective?.objective,
      };
      newData.push(newItem);
    }

    return newData;
  };

  return (
    <div>
      <BackendBackground />
      <Paper className={classes.root}>
        <h2>Tasks</h2>
        {props.issues ? (
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.issues ? cleanData(props.issues).length : 0}
            />
            <TableBody>
              {props.issues &&
                stableSort(cleanData(props.issues), getComparator(order, orderBy)).map((item, i) => {
                  return !item.business_unit ? (
                    <TableRow key={item.id}>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.assigned_users}</TableCell>
                      <TableCell>{item.area}</TableCell>
                      <TableCell>
                        {item.due_date ? (
                          moment(item.due_date) >= moment() || item.status.name === 'Done' ? (
                            <Typography>{moment(item.due_date).format('DD/MM/YYYY')}</Typography>
                          ) : (
                            <Typography color="error">{moment(item.due_date).format('DD/MM/YYYY')}</Typography>
                          )
                        ) : (
                          <div>No Date</div>
                        )}
                      </TableCell>
                      <TableCell>{item.strategic_objective}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        {item.status !== 'Pending Approval' ? (
                          item.status !== 'Done' ? (
                            <PrimaryButton className={classes.action_button} onClick={() => handleStatusOpen(item.issue)}>
                              Update Status
                            </PrimaryButton>
                          ) : (
                            <Permission action="corporate.update" user={props.user} hideObject={true}>
                              <PrimaryButton className={classes.action_button} onClick={() => handleStatusOpen(item.issue)}>
                                Update Status
                              </PrimaryButton>
                              <PrimaryButton className={classes.action_button} onClick={() => handleArchiveConfirmOpen(item.issue)}>
                                Archive
                              </PrimaryButton>
                            </Permission>
                          )
                        ) : (
                          <Permission action="corporate.update" user={props.user} hideObject={true}>
                            <PrimaryButton className={classes.action_button} onClick={() => handleApprovalOpen(item.issue)}>
                              Approval
                            </PrimaryButton>
                          </Permission>
                        )}
                        <PrintIssue title="Issue" issue={item.issue} buttonText="View" />
                        <Comments id={item.issue._id} commentType={'Issue'} key={item.issue._id} />
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
            </TableBody>
          </Table>
        ) : null}
      </Paper>

      {selectedIssue ? (
        <div>
          <StatusChange open={statusOpen} onClose={handleStatusClose} issue={selectedIssue} onStatusUpdate={() => RefreshTable()}></StatusChange>
          <ApprovalDialog open={approvalOpen} onClose={handleApprovalClose} issue={selectedIssue} onApprovalUpdate={() => RefreshTable()}></ApprovalDialog>
        </div>
      ) : null}
      {archiveDialog()}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  issues: state.strategy_issues.issues_by_user,
  statuses: state.status.statuses,
  user: state.user.userData,
});

export default connect(mapStateToProps)(GroupStrategyTasks);
