import axios from 'axios';
import { GET_PAYMENT_OBJECT, GET_USER_TRANSACTIONS } from './types';
import { PAYMENT_SERVER } from '../../Components/Utils/Misc';

export function getPaymentObject(billingDetails) {
  const request = axios.post(`${PAYMENT_SERVER}/subscription`, { ...billingDetails }).then((responce) => responce.data);

  return {
    type: GET_PAYMENT_OBJECT,
    payload: request,
  };
}

export function getUserTransactions() {
  const request = axios.get(`${PAYMENT_SERVER}/user/transactions`).then((responce) => responce.data);

  return {
    type: GET_USER_TRANSACTIONS,
    payload: request,
  };
}
