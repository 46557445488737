import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getOrganization } from '../../../Redux/Actions/organization_actions';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Table, TableHead, TableBody, TableCell, TableFooter, TableRow, Paper, Container } from  '@material-ui/core';
import moment from 'moment'
import ScoreCardItem from './partials/scoreCardItem';

const useStyles = makeStyles((theme) => ({
    root:{
        margin: theme.spacing(2)
    },
    score_card:{
        width: '100%',
        borderSpacing: '10px !important',
        borderCollapse: 'separate !important'
    },
    score_card_header: {
        backgroundColor: '#005773',
        color: 'white',
        padding: '20px',
        fontWeight: 400,
        fontSize: 'x-large',
        textTransform: 'uppercase'
    },
    kpi_cell: {
        backgroundColor: '#005773',
        color: 'white',
        borderRadius: '4px',
        width: '130px',
        height: '113px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 400
    },
    main_cell: {
        backgroundColor: '#6399AB',
        borderRadius: '4px'
    },
    rd_cell:{
        backgroundColor: '#6399AB',
        width: '180px',
        borderRadius: '4px'
    },
    theme:{
        backgroundColor: '#005773',
        color: 'white',
        borderRadius: '4px',
        width: '150px',
        textAlign: 'center',
        padding: '10px',
        display: 'inline-block'
    },
    text:{
        textTransform: 'uppercase',
        color: 'white',
        textAlign: 'center'
    },
    theme_cell:{
        width: '130px',
        textAlign: 'center',
        borderBottom: 'none'
    },
    theme_cell_big:{
        width: '310px',
        textAlign: 'center'
    },
    pdf:{
        padding: '10px 25px 25px 25px'
    }
}))


const ScoreCard = ({...props}) => {
    const classes  = useStyles();
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.userData);
    const organization = useSelector(state => state.organization.organization)

    const [issues, setIssues] = useState({
        finance: {
            growth: [],
            risk: [],
            transition: []
        },
        customer: {
            growth: [],
            risk: [],
            transition: []
        },
        operational: {
            growth: [],
            risk: [],
            transition: []
        },
        people: {
            growth: [],
            risk: [],
            transition: []
        },
        rd: [],
    })

    useEffect(() => {
        dispatch(getOrganization(props.entity))

        organizeIssues()
    }, [])

    const organizeIssues = () => {
        let newIssues = issues;

        if(props.issues){
            props.issues.map(issue => {

                const issueKPI = props.corporate ? issue.kpi : issue.area.name;

                switch(issueKPI){
                    case "Finance": 
                        switch(issue.strategic_objective){    
                            case "Growth":
                                    newIssues.finance.growth.push(issue);
                                break;
                            case "Risk":
                                    newIssues.finance.risk.push(issue);
                                break;
                            case "Transition":
                                    newIssues.finance.transition.push(issue);
                                break;
                        }
                        break;
                    case "Customer":
                        switch(issue.strategic_objective){    
                            case "Growth":
                                    newIssues.customer.growth.push(issue);
                                break;
                            case "Risk":
                                    newIssues.customer.risk.push(issue);
                                break;
                            case "Transition":
                                    newIssues.customer.transition.push(issue);
                                break;
                        }
                        break;
                    case "Operational":
                        switch(issue.strategic_objective){    
                            case "Growth":
                                    newIssues.operational.growth.push(issue);
                                break;
                            case "Risk":
                                    newIssues.operational.risk.push(issue);
                                break;
                            case "Transition":
                                    newIssues.operational.transition.push(issue);
                                break;
                        }
                        break;
                    case "People":
                        switch(issue.strategic_objective){    
                            case "Growth":
                                    newIssues.people.growth.push(issue);
                                break;
                            case "Risk":
                                    newIssues.people.risk.push(issue);
                                break;
                            case "Transition":
                                    newIssues.people.transition.push(issue);
                                break;
                        }
                        break;
                    case "Research & Development":
                            newIssues.rd.push(issue);
                        break;
                }

                
            })
        }

        setIssues(newIssues);
    } 

    return (
        <Container>
            <div id="pdfdiv" className={classes.pdf} >
            <h2>Score Card - { organization?.name }</h2>
            <Table className={classes.score_card}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} className={classes.score_card_header}>Corporate Score Card</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Finance</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        { issues?.finance?.growth && issues.finance.growth.map((issue, index) => (
                                            <ScoreCardItem key={index} issue={issue} />
                                        ))}
                                    </Grid>
                                    <Grid item xs={4}>
                                        { issues?.finance?.risk && issues.finance.risk.map((issue, index) => (
                                            <ScoreCardItem key={index} issue={issue} />
                                        ))}
                                    </Grid>
                                    <Grid item xs={4}>
                                        { issues?.finance?.transition && issues.finance.transition.map((issue, index) => (
                                            <ScoreCardItem key={index} issue={issue} />
                                        ))}
                                    </Grid>
                                </Grid>
                        </TableCell>
                        <TableCell rowSpan={4} className={classes.rd_cell}>
                                { issues?.rd && issues.rd.map((issue, index) => (
                                    <ScoreCardItem key={index} issue={issue} />
                                ))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Customer</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}>
                        <Grid container>
                                <Grid item xs={4}>
                                    { issues?.customer?.growth && issues.customer.growth.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.customer?.risk && issues.customer.risk.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.customer?.transition && issues.customer.transition.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Operational</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}>
                        <Grid container>
                                <Grid item xs={4}>
                                    { issues?.operational?.growth && issues.operational.growth.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.operational?.risk && issues.operational.risk.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.operational?.transition && issues.operational.transition.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>People</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}>
                        <Grid container>
                                <Grid item xs={4}>
                                    { issues?.people?.growth && issues.people.growth.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.people?.risk && issues.people.risk.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                                <Grid item xs={4}>
                                    { issues?.people?.transition && issues.people.transition.map((issue, index) => (
                                        <ScoreCardItem key={index} issue={issue} />
                                    ))}
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell className={`${classes.kpi_cell} ${ classes.text}`}>Static Theme</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell} >
                            <Table>
                                <TableRow>
                                    <TableCell className={`${classes.theme_cell} ${classes.theme_cell_big}`}>
                                        <div className={classes.theme}>Growth</div>
                                    </TableCell>
                                    <TableCell className={`${classes.theme_cell}`}>
                                        <div className={classes.theme}>Risk</div>
                                    </TableCell>
                                    <TableCell className={`${classes.theme_cell} ${classes.theme_cell_big}`}>
                                        <div className={classes.theme}>Transition/Exit</div>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                        <TableCell className={`${classes.rd_cell} ${ classes.text}`} >Research and Development</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={4} >{moment().format('MM/DD/YYYY')}</Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>IQFin Strategy Tracker</Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>Generated by { user.fullname }</Grid>    
            </Grid> 
            </div>
        </Container>
    );
};

export default ScoreCard;