import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { SelectOrganization, ClearSelection } from '../../Redux/Actions/organization_actions';

import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import OrganizationCard from '../UI/Cards/organizationCard';

import { canAddNewOrganization } from '../Utils/OrganizationActions'
import { logoutUser } from '../../Redux/Actions/user_actions'

import ScrollArea from 'react-scrollbar';
import { ButtonGroup, Typography } from '@material-ui/core';


function SelectBusinessStrategy(props) {

    const selectSuccess = useSelector(state => state.organization.selection_success)

    useEffect(() => {
        if(selectSuccess){
            props.dispatch(ClearSelection())
            props.history.push('/introduction')
        }
    }, [selectSuccess])

    const changeEntity = (entityId) => {
        if (entityId) {
            props.dispatch(SelectOrganization(entityId));
        }
    }

    const logoutHandler = () => {
        props.dispatch(logoutUser()).then(responce => {
            if(responce.payload.success){
                props.history.push('/');
            }
        })
    }

    // handleBack = () => {
    //     const qs_values = queryString.parse(this.props.location.search);
    //     if (qs_values.redirectTo) {
    //         this.props.history.push(qs_values.redirectTo)
    //     }
    //     else {
    //         this.props.history.push('/welcome')
    //     }
    // }

    const selectedEntityID = props.user.selectedOrganization._id;
    const selectedEntity = props.user.selectedOrganization;
    let checkParentID = null;

    if(selectedEntity.isGroup){
        
    }

    return (

        <div style={{
            //height: '800px',
            //width: '100%',
            padding: '30px',
            paddingTop: '70px',
            //position: 'absolute'
        }}>

         <ScrollArea
                speed={0.8}
                horizontal={false}
                smoothScrolling={true}
                style={{
                    height:  '700px'
                }}>
        {props.user ?
            <div>
                <h2>{selectedEntity.name}</h2>
                <Typography variant='body1'>Please select the business you want to do a strategy for.</Typography>
                <Table style={{ margin: '10px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Business Name</TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.user.organizations.map(org => (
                        org && org.organization_id.parent && org.organization_id.parent._id === selectedEntityID ?
                            <TableRow>
                                <TableCell>{org.organization_id.name}</TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right">
                                    <ButtonGroup>
                                            <Button color="primary" variant="text"
                                                onClick={() => changeEntity(org.organization_id._id)}                                       
                                                style={{
                                                    padding: '0px 5px',
                                                    color: '#005773'
                                                }}>
                                                Select
                                            </Button>                                
                                    </ButtonGroup>   
                                </TableCell>
                            </TableRow>
                        : null
                        ))}
                    </TableBody>
                </Table>

                { canAddNewOrganization(props.subscriptions, props.organizations, props.user.user_id ) ?
                    <Button style={{
                        margin: '0 auto',
                        marginBottom: '10px',
                        width: '170px',
                        display: 'block',
                        textAlign: 'center',
                    }} 
                    href="/organization_select">Add New</Button> 
                :null }

                

                <Button style={{
                    margin: '0 auto',
                    marginBottom: '10px',
                    width: '170px',
                    display: 'block',
                    textAlign: 'center',
                }} 
                onClick={() => logoutHandler()}>Logout</Button>
            </div>
        :
        null}
        </ScrollArea>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    success: state.organization.selection_success,
    subscriptions: state.user.userData.subscriptions,
    organizations: state.user.userData.organizations,
    user: state.user.userData
})


export default connect(mapStateToProps)(SelectBusinessStrategy);