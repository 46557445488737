import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import EntityList from '../Entity/entityList';

function EntitySelect(props) {
    return (
        <EntityList />
    )
}

export default EntitySelect;