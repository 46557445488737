import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAllUsers } from '../../Redux/Actions/user_actions'

import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, Button, ButtonGroup, Paper, Chip } from '@material-ui/core';

import UserDetails from './Dialogs/UserDetails'
import Reinvite from '../Users/Dialogs/Reinvite'

const useStyles = makeStyles(theme => {

})

function SystemUsers(props) {
    const { dispatch } = props;
    const classes = useStyles();

    const [detailsOpen, setDetailsOpen] = useState(false);
    const [selectedUserID, setSelectedUserID] = useState();

    const handleOpenDetails = (user) => {
        setSelectedUserID(user.id);
        setDetailsOpen(true);
    }

    const handleCloseDetails = () => {
        setSelectedUserID(undefined);
        setDetailsOpen(false);
    }

    useEffect(() => {
        dispatch(getAllUsers());
    }, [])

    return (
        <div>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Organizations</TableCell>
                        <TableCell>Owner Of</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { props.all_users ?
                        props.all_users.map((user,index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    { user.name + " " + user.lastname }
                                </TableCell>
                                <TableCell>
                                    { user.email } { user.verified ? <Chip variant="outlined" label="Verifeid" style={{ float: 'right'}}/> : <Chip variant="outlined" label="Not Verifeid" style={{ float: 'right'}} />}
                                </TableCell>   
                                <TableCell>
                                    { moment(user.last_login).format('DD-MM-Y H:m:s') }    
                                </TableCell>   
                                <TableCell>
                                    { user.organizations.length }    
                                </TableCell>   
                                <TableCell>
                                    No
                                </TableCell>   
                                <TableCell>
                                    <ButtonGroup>
                                        <Reinvite user={user} />
                                        <Button variant="outlined" color="secondary" size='small' onClick={() => handleOpenDetails(user)}>View</Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        ))
                        : null}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6} >
                            
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            { selectedUserID ?
                <UserDetails open={detailsOpen} handleClose={handleCloseDetails} selectedUserID={selectedUserID}/>
            : null }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    all_users: state.user.all_users
})


export default connect(mapStateToProps)(SystemUsers)
