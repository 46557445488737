import { GET_FLAGS, GET_FLAG, CREATE_FLAG, REMOVE_FLAG, PRINT_FLAGS, EDIT_FLAG } from '../Actions/types';

export default function(state={}, action){
    switch(action.type){
        case GET_FLAGS:
            return {...state, flags: action.payload }
        case GET_FLAG:
            return {...state, flag: action.payload }
        case CREATE_FLAG:
            return {...state, success: action.payload }
        case EDIT_FLAG:
            return {...state, success: action.payload }
        case REMOVE_FLAG:
            return {...state, success: action.payload }
        case PRINT_FLAGS:
            return {...state, pdf: action.payload }
        default:
            return state;
    }
}