import React, { Component } from 'react'
import { connect } from 'react-redux'
import { generateFunctionalStructure } from '../../../Redux/Actions/report_actions';

import BackendBackground from '../../UI/backendBackground';
//import FilterBar from '../filter_bar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress'

import FileSaver  from 'file-saver';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative',
        padding: '10px',
    },
    table: {
        minWidth: 700,
    },
});

let _isMounted = false;
class FunctionalStructure extends Component {

    state = {
        loadingReport : false,
        filters: {}
    }

    componentDidMount() {
        _isMounted = true;
    }

    componentWillUnmount() {
        _isMounted = false;
    }

    handleFilterChange(filters){
        if(_isMounted){
            this.setState({
                filters
            })
        }
        
    }

    generateReport = () => {
            this.setState({
                loadingReport: true
            })


        this.props.dispatch(generateFunctionalStructure(this.state.filters)).then(response => {
            FileSaver.saveAs(new Blob([this.props.pdf], {type: "application/pdf"}), "functional_structure_" + moment().format("DD_MM_YYYY") + ".pdf");

            if(_isMounted){
                this.setState({
                    loadingReport: false
                })
            }
        });
    }

    render() {

        const { classes } = this.props;

        return (
            <div>
                
                <BackendBackground />
                <Paper className={classes.root}>
                { this.state.loadingReport ?
                    <CircularProgress />
                :
                    <div>
                        <Button variant="contained" onClick={() => this.generateReport()} >Generate Report</Button>
                    </div>
                }
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    loggedInUser : state.user.userData,
    pdf : state.report.pdf
})


export default connect(mapStateToProps)(withStyles(styles)(FunctionalStructure))