import { Button, makeStyles, withTheme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 0, 1),
        color: '005773',
        fontWeight: [500],
        '&:hover': {
            color: '#005773',
        }
    }
}))

const TextButton = ({ children, ...props}) => {
    const classes = useStyles();
    
    return (
        <Button variant="text" className={classes.root} { ...props }>{children}</Button>
    );
};

export default TextButton;