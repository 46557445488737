import React, { Component } from 'react';
import ScrollArea from 'react-scrollbar';

import FormField from '../Utils/Forms/FormField';
import { clearFields } from '../Utils/Forms/FormActions';
import { validate, reverseArray } from '../UI/misc';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Permission from '../../HOC/Permission';
import UserSelect from '../UI/CustomFields/UserSelect';
import { connect } from 'react-redux';
import { getAllBusinessUnits, addBusinessUnit, removeBusinessUnit, getBusinessUnit, updateBusinessUnit } from '../../Redux/Actions/business_units_actions';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    rootPaper1: {
        ...theme.mixins.gutters(),
        paddingBottom: theme.spacing.unit * 4,
        paddingTop: theme.spacing.unit * 2,
        position: 'relative'
    },
    rootPaper2: {
        ...theme.mixins.gutters(),
        padding: '0px !important',
        paddingBottom: theme.spacing.unit * 2,
        height: '320px',
        position: 'relative',
        overflowWrap: 'break-word'
    },
    PaperItem: {
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
        backgroundColor: "#e6e6e6",
        color: "#404040",
        fontSize: '12px',
        marginRight: '35px'
    },
    button: {
        backgroundColor: '#005773',
        margin: theme.spacing.unit,
        backgroundPosition: '19px 12px',
        '&:hover': {
            backgroundColor: '#005773'
        },
        position: 'absolute',
        bottom: theme.spacing.unit * -3.5,
        right: theme.spacing.unit * -3.5,
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
});

class FunctionalUnitsList extends Component {

    state = {
        progress:{
            deleting: false,
        },
        loading: false,
        selectedBU: {},
        addDialogOpen: false,
        rsDialog: false,
        listItems: [],
        bu_revenue_streams: [],
        formType: 'Add',
        formError: false,
        formErrorMsg: '',
        formSuccess: '',
        formData: {
            business_unit: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'business_unit',
                    type: 'editableList_Input',
                    label: 'Functional Unit Name',
                    helperText: 'Please enter the name of your functional unit',
                    limit: 200
                },
                validation: {
                    required: true,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            revenue_stream: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'revenue_stream',
                    type: 'input',
                    label: 'Revenue Stream',
                    helperText: 'Press enter after each revenue stream to add them',
                    limit: 80
                },
                validation: {
                    required: false,
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            accountable_user: {
                value: ''
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getAllBusinessUnits()).then(response => {
            if (this.props.business_units) {
                this.setState({
                    listItems: this.props.business_units
                });
            }
        });
    }

    updateForm(element) {
        const newFormData = { ...this.state.formData }
        const newElement = { ...newFormData[element.id] }

        newElement.value = element.event.target.value;

        let valiData = validate(newElement);
        newElement.valid = valiData[0];
        newElement.validationMessage = valiData[1];

        newFormData[element.id] = newElement;

        this.setState({
            formError: false,
            formData: newFormData
        });
    }

    handleAdd = () => {
        if (this.state.formData.business_unit.value !== '') {
            const dataToSubmit = {
                name: this.state.formData.business_unit.value,
                revenue_streams: this.state.bu_revenue_streams,
                accountable_user: this.state.formData.accountable_user.value
            }
            let newFormData = { ...this.state.formData };

            this.setState({
                loading: true
            })
            

            if(this.state.formType === "Add"){
                this.props.dispatch(addBusinessUnit(dataToSubmit)).then(responce => {
                    if (this.props.add_success) {
                        newFormData["business_unit"].value = "";
                        this.handleAddDialogClose();

                        this.props.dispatch(getAllBusinessUnits()).then(response => {
                            if (this.props.business_units) {
                                this.setState({
                                    listItems: this.props.business_units,
                                    loading: false
                                })
                            }
                        });
                    }
                    else{
                        this.setState({
                            loading: false,
                            formError: true, 
                            formErrorMsg: 'Someting wwent wrong. Please try again later.'
                        })
                    }
                })
            }
            else{
                this.props.dispatch(updateBusinessUnit(this.state.selectedBU._id, dataToSubmit)).then(responce => {
                    if (this.props.add_success) {
                        newFormData["business_unit"].value = "";
                        this.handleAddDialogClose();

                        this.props.dispatch(getAllBusinessUnits()).then(response => {
                            if (this.props.business_units) {
                                this.setState({
                                    listItems: this.props.business_units,
                                    loading: false
                                })
                            }
                        });
                    }
                    else{
                        this.setState({
                            loading: false,
                            formError: true, 
                            formErrorMsg: 'Someting wwent wrong. Please try again later.'
                        })
                    }
                })
            }

            


            this.setState({
                formData: newFormData,
                bu_revenue_streams: []
            })
        }
    }

    handleEdit = (item) => {
        this.props.dispatch(getBusinessUnit(item._id)).then(responce => {
            let newFormData = { ...this.state.formData };

            newFormData["business_unit"].value = this.props.business_unit.name;
            newFormData["accountable_user"].value = this.props.business_unit.accountable_user;

            this.setState({
                selectedBU: item,
                bu_revenue_streams: this.props.business_unit.revenue_streams,
                formData: newFormData,
                formType: 'Edit',
                addDialogOpen : true
            })

        })
    }

    handleDelete = (item) => {
        let newProgress = { ...this.state.progress };
        newProgress.deleting = true;

        this.setState({
            progress: newProgress
        })
        

        this.props.dispatch(removeBusinessUnit(item._id)).then(response => {
            if (this.props.remove_success) {
                newProgress.deleting = false;

                this.setState({
                    progress: newProgress
                })

                this.props.dispatch(getAllBusinessUnits()).then(response => {
                    if (this.props.business_units) {
                        this.setState({
                            listItems: this.props.business_units
                        });
                    }
                });

            }
        });
    }

    handleRSAdd = () => {
        let newFormData = { ...this.state.formData };
        if (newFormData["revenue_stream"].value !== '') {
            const new_revenue_streams = this.state.bu_revenue_streams;
            new_revenue_streams.push(newFormData["revenue_stream"].value)
            newFormData["revenue_stream"].value = "";

            this.setState({
                bu_revenue_streams: new_revenue_streams,
                formData: newFormData,
                rsDialog: false
            })
        }
    }

    handleUserChange = (user) => {
        let newFormData = { ...this.state.formData };
        newFormData["accountable_user"].value = user;

        this.setState({
            formData: newFormData
        })
        
    }

    onChipRemove = (item) => {

        const bu_revenue_streams = [...this.state.bu_revenue_streams];
        const chipToDelete = bu_revenue_streams.indexOf(item);
        bu_revenue_streams.splice(chipToDelete, 1);

        this.setState({
            bu_revenue_streams
        })
    }

    openAdd = () => {
        this.setState({
            selectedBU: {},
            bu_revenue_streams: [],
            formType: 'Add',
            addDialogOpen: true
        })
    }

    handleAddDialogClose = () => {
        clearFields(this.state.formData, 'business_units');
        this.setState({
            addDialogOpen: false
        })
    }

    handleOpenRSDialog = () => {
        this.setState({
            rsDialog: true
        })
    }

    handleRSDialogClose = () => {
        this.setState({
            rsDialog: false
        })
    }

    generateList = () => {
        const { classes } = this.props;

        return (
            <div>

            <Paper className={classes.rootPaper2} elevation={5}>
                <ScrollArea
                    speed={0.8}
                    horizontal={false}
                    smoothScrolling={true}
                    style={{
                        height: '320px'
                    }}
                >

                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Functional Unit</TableCell>
                                <TableCell>Revenue Streams</TableCell>
                                <TableCell>Accountable Person</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.listItems ? reverseArray(this.state.listItems).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.revenue_streams.length > 0 ?
                                            <div>
                                                {item.revenue_streams.map((rs, i) => (
                                                    (item.revenue_streams.length !== i + 1 ?
                                                        <span key={i}>{rs}, </span>
                                                        :
                                                        <span key={i}>{rs}</span>
                                                    )
                                                ))}

                                            </div>
                                            : null}
                                    </TableCell>
                                    <TableCell>
                                        { item.accountable_user ? 
                                         <div>{item.accountable_user.name} {item.accountable_user.lastname }</div>
                                         :
                                         null}
                                    </TableCell>
                                    <TableCell align="right">
                                            <FontAwesomeIcon icon={faEdit} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}}  onClick={() => this.handleEdit(item)}/>
                                            <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#CC171D', paddingRight: '5px', cursor: 'pointer' }} onClick={() => this.handleDelete(item)} />

                                    </TableCell>
                                </TableRow>
                            )) : <div>The list is empty. </div>}
                        </TableBody>
                    </Table>
                </ScrollArea>
            </Paper>
            <Permission action="business_units.create" user={this.props.user} hideObject={true}>
                <Button variant="contained" onClick={() => this.openAdd()} style={{ marginTop: '10px'}}>Add Functional Unit</Button>
            </Permission>
            </div>
        )
    }

    addDialog = () => {
        const { classes } = this.props;
        return (
        <Dialog
        open={this.state.addDialogOpen}
        //onClose={this.handleAddDialogClose}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Add Functional Unit</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Business functions in most businesses, include finance & accounting, sales & marketing, production, purchasing, communications, human resources, administration, as well as research & development. However, your business is likely to have its own unique functional  Units not listed above.
                <br /> <br />In the field provided, list ALL Functional Units in the business contributing to delivering products and services.
                </DialogContentText>
                <Card style={{ paddingBottom: "20px", paddingRight: "20px", margin:"10px"}}>
                    <FormField
                        id={'business_unit'}
                        formData={this.state.formData.business_unit}
                        change={(element) => this.updateForm(element)}
                    />
               </Card>

                <Card style={{ padding: "10px", margin:"10px"}}>
                <h1 style={{
                        fontSize: "14px",
                        padding:"10px 7px 5px",
                        margin: 0,
                        textAlign: 'left'
                    }}>Revenu Streams</h1>
                        

                        <div style={{ padding: "10px 0"}}>
                            {this.state.bu_revenue_streams && this.state.bu_revenue_streams.length === 0 ? 
                                <span>Please add atleast one revenue stream.</span>    
                                :null
                            }
                            {this.state.bu_revenue_streams.map((item, index) => {
                                return (
                                    <Chip
                                    key={index}
                                    label={item}
                                    onDelete={() => this.onChipRemove(item)}
                                    className={classes.chip}
                                    />
                                );
                            })}
                        </div>
                            

                        <Button variant="contained" onClick={this.handleOpenRSDialog} color="primary">
                            Add/Remove Revenue Streams
                        </Button>
                    
                </Card>
               
                <Card style={{ padding: "10px", margin:"10px"}}>
                    <UserSelect change={(user) => this.handleUserChange(user)} label="Select" header="Select / Add Accountable Person" btnCreateName="Add new person" user_type="business_unit" selectedUser={ this.state.selectedBU.accountable_user ? this.state.selectedBU.accountable_user._id : null}/>
                </Card>
            </DialogContent>
            <DialogActions>
                { this.state.formError ? 
                <Typography color='error'>{this.state.formErrorMsg}</Typography> : null }
                <Button onClick={this.handleAddDialogClose} color="primary">
                Cancel
                </Button>
                { this.state.bu_revenue_streams && this.state.bu_revenue_streams.length > 0 ?
                <Button onClick={this.handleAdd} color="primary">

                    { !this.state.loading ?
                        this.state.formType === "Add" ? 
                        <div>Add</div>
                        :
                        <div>Update</div>
                        :
                        <CircularProgress size={20}/> 
                    }
                </Button>
                : null}
            </DialogActions>
        </Dialog>            
        )
    }

    rsDialog = () => {
        //const { classes } = this.props;
        return (
        <Dialog
        open={this.state.rsDialog}
        //onClose={this.handleAddDialogClose}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Add Revenue Stream</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill in the name of the revenue stream and click add to add it to your functional unit.
                </DialogContentText>

                <FormField
                    id={'revenue_stream'}
                    formData={this.state.formData.revenue_stream}
                    change={(element) => this.updateForm(element)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleRSDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={this.handleRSAdd} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>            
        )
    }

    render() {
        //const { classes } = this.props;
        return (
            <div>
                {this.generateList()}
                {this.addDialog()}
                {this.rsDialog()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        business_units: state.business_units.business_units,
        business_unit: state.business_units.business_unit,
        add_success: state.business_units.success,
        remove_success: state.business_units.remove_success,
        user: state.user.userData
    }
}

export default connect(mapStateToProps)(withStyles(styles)(FunctionalUnitsList));
