import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllOrganizations } from '../../Redux/Actions/organization_actions'

import BackendBackground from '../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';

//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

class CompanyLicensing extends Component {

    state = {
        openNewLicenseDialog :false,
        openDeactivateDialog: false,
        openChangeDialog : false,
        selectedLicense : {}
    }

    componentDidMount(){
        this.props.dispatch(getAllOrganizations()).then({

        })
    }

    handleDialogClose = (dialog) => {
        switch(dialog){
            case "NewLicenseDialog" :
                    this.setState({
                        openNewLicenseDialog: false,
                    })
                break;
            case "DeactivateDialog" :
                    this.setState({
                        openDeactivateDialog: false,
                        selectedLicense: {}
                    })
                break;
            case "ChangeDialog" :
                    this.setState({
                        openChangeDialog: false,
                        selectedLicense: {}
                    })
                break;
            default :
        }
    }

    handleDialogOpen = (dialog, selectedLicense = {}) => {
        switch(dialog){
            case "NewLicenseDialog" :
                    this.setState({
                        openNewLicenseDialog: true,
                    })
                break;
            case "DeactivateDialog" :
                    this.setState({
                        openDeactivateDialog: true,
                        selectedLicense
                    })
                break;
            case "ChangeDialog" :
                    this.setState({
                        openChangeDialog: true,
                        selectedLicense
                    })
                break;
            default:
                break;
        }
    }

    NewLicenseDialog = () => {
        return  (
            <Dialog
            open={this.state.openNewLicenseDialog}
            onClose={() => this.handleDialogClose("NewLicenseDialog")}
            aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add Licence to company.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add details of the license below. 
                    </DialogContentText>
                </DialogContent>
            </Dialog>       
        )     
    }

    DeactivateDialog = () => {
        return  (
            <Dialog
            open={this.state.openDeactivateDialog}
            onClose={() => this.handleDialogClose("DeactivateDialog")}
            aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Deactivate license.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add details of the license below. 
                    </DialogContentText>
                </DialogContent>
            </Dialog>   
        )
    }

    ChangeDialog = () => {
        return  (
            <Dialog
            open={this.state.openChangeDialog}
            onClose={() => this.handleDialogClose("ChangeDialog")}
            aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Deactivate license.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add details of the license below. 
                    </DialogContentText>
                </DialogContent>
            </Dialog>   
        )    
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackendBackground />

                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Comapany Name</TableCell>
                                <TableCell>License Type</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Order No.</TableCell>
                                <TableCell>Expiry Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.props.organizations ?
                                this.props.organizations.map((item,index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                        { item.license_details ? 
                                            <div>{item.license_details.product}</div>
                                        : <div>No License</div>} 
                                        </TableCell>   
                                        <TableCell>{ item.email }</TableCell>   
                                        <TableCell>
                                        { item.license_details ? 
                                            <div>{item.license_details.order_id}</div>
                                        : <div>No Order</div>} 
                                        </TableCell>   
                                        <TableCell>
                                        { item.license_details ? 
                                        <div>{ () => moment(item.license_details.next_payment_date) }</div>
                                        : <div>No Order</div>} </TableCell>
                                        <TableCell>
                                        { item.license_details ? 
                                        <div>{item.license_details.subscription_status === 'active' ? 'Active' : 'Not Active'}</div>
                                        : <div>Not Active</div>}    
                                        </TableCell>        
                                        <TableCell>
                                            <Button className={classes.button} variant="contained" color="secondary">Change</Button>
                                            <Button className={classes.button} variant="contained" >Deactivate</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : null}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell >
                                    <Button 
                                    className={classes.button} 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => this.handleDialogOpen("NewLicenseDialog") }>Add New License</Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>

                { this.NewLicenseDialog() }
                { this.DeactivateDialog() }
                { this.ChangeDialog() }

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    organizations: state.organization.organizations
})


export default connect(mapStateToProps)(withStyles(styles)(CompanyLicensing))