import { SUBSCRIPTION_VALIDATION,
    GET_USER_SUBSCROPTIONS,
GET_ALLUSER_SUBSCROPTIONS,
MIGRATE_SUBSCROPTIONS } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case SUBSCRIPTION_VALIDATION:
            return {...state, subscription: action.payload }
        case GET_USER_SUBSCROPTIONS:
            return {...state, user_subscriptions: action.payload }
        case GET_ALLUSER_SUBSCROPTIONS:
            return {...state, user_plans: action.payload.plans, user_subs: action.payload.subs }
        case MIGRATE_SUBSCROPTIONS:
            return {...state, success: action.payload.success }
        default:
            return state;
    }
}