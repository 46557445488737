import { GET_USER_PLAN, UPDATE_USER_PLAN, CANCEL_USER_PLAN } from '../Actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_USER_PLAN:
      return { ...state, userplan: action.payload.plan, success: action.payload.success, updateCardUrl: action.payload.updateCardUrl };
    case UPDATE_USER_PLAN:
      return { ...state, userplan: action.payload.plan, success: action.payload.success };
    case CANCEL_USER_PLAN:
      return { ...state, userplan: action.payload.plan, success: action.payload.success };
    default:
      return state;
  }
}
