import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import ScrollArea from 'react-scrollbar';
import Tooltip from '@material-ui/core/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing.unit,
        position: 'relative'
    },
});

const MyListItem = React.forwardRef(function MyListItem(props, ref) {
    return <ListItemText style={{width: "200px"}} {...props} ref={ref}/>  
});

const AppList = (props) => {
    const { classes } = props;

    const handleEdit = (item) => {
        props.onEdit(item);
    }

    const handleDelete = (item) => {
        props.onDelete(item);
    }

    const handleView = (item) => {
        props.onView(item);
    }

    return (
        <Paper className={classes.root} elevation={5}>
            <h2 style={{ margin: '0px' , fontSize: props.headerSize ? props.headerSize : 'initial' }}>{props.header}</h2>

            <List
                style={{
                    height: props.listHeight
                }}>
                <ScrollArea
                    speed={0.8}
                    horizontal={false}
                    smoothScrolling={true}
                    style={{
                        height: props.listHeight
                    }}>
                    {props.listItems.length > 0 ?
                        props.listItems.map((item, index) => (
                            <ListItem key={item._id}>
                                { props.isEditable && item.editable ?
                                    <ListItemText style={{width: "200px"}}
                                        primary={item.primary}
                                        secondary={item.secondary ? item.secondary : null}
                                    />  
                                : 
                                <Tooltip enterDelay={1000} title={`This can not be edited.`} aria-label="This can not be edited.">
                                    <MyListItem style={{width: "200px"}}
                                        primary={item.primary}
                                        secondary={item.secondary ? item.secondary : null}
                                    />  
                                </Tooltip>
                            }

                                { props.isEditable && item.editable ?
                                    <div styles="width: 45px;">
                                        <Tooltip title="Edit" aria-label="Edit">
                                            <FontAwesomeIcon icon={faEdit} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}}  onClick={() => handleEdit(item)}/>
                                        </Tooltip>
                                        <Tooltip title="Delete" aria-label="Delete">
                                            <FontAwesomeIcon icon={faTrashAlt} style={{color: '#CC171D', paddingRight: '5px', cursor: 'pointer'}} onClick={() => handleDelete(item)}/>
                                        </Tooltip>
                                    </div>
                                    :
                                    <Tooltip title="View" aria-label="View">
                                        <FontAwesomeIcon icon={faEye} style={{color: '#005773', paddingRight: '5px', cursor: 'pointer'}}  onClick={() => handleView(item)}/>
                                    </Tooltip>
                                }
                            </ListItem>
                        ))
                        : <div style={{ margin: '10px' }}>There are no items in this list yet.</div>
                    }
                </ScrollArea>
            </List>

        </Paper>
    );
};

AppList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppList);