import React, { Component } from 'react';
import {easePolyOut} from 'd3-ease';
import Animate from 'react-move/Animate';

class BackgroundAnimation extends Component {

    state= {
        stripes: [
            {
                background: '#162336',
                left:400,
                rotate:45,
                top:-610,
                delay:0,
                topstart:-1500,
            },
            {
                background: '#6399AB',
                left:90,
                rotate:-45,
                top:730,
                delay: 200,
                topstart:1500,
            },
            {
                background: '#cc171d',
                left:470,
                rotate:45,
                top:-690,
                delay: 400,
                topstart:-1500,
            },
            {
                background: '#005773',
                left:200,
                rotate:-45,
                top:800,
                delay: 600,
                topstart:1500,
            },
            {
                background: '#6399AB',
                left:530,
                rotate:45,
                top:-770,
                delay: 800,
                topstart:-1500,
            },
            {
                background: '#162336',
                left:200,
                rotate:-45,
                top:870,
                delay: 1000,
                topstart:1500,
            }
        ]
    }

    showStripes = () => (
        
        this.state.stripes.map((stripe,i) => (
            <Animate
                key={i}
                show={true}
                start={{
                    background: '#ffffff',
                    opacity:[1],
                    right: 1000,
                    rotate: [stripe.rotate],
                    top: [stripe.topstart],
                }}
                enter={{
                    background: [stripe.background],
                    //opacity:[1],
                    right: [stripe.left],
                    //rotate: [stripe.rotate],
                    top: [stripe.top],
                    timing:{delay:stripe.delay, duration:2000, ease: easePolyOut},
                    events:{
                        end(){
                        }
                    }
                }}
            >

                {({right,background,opacity,rotate,top})=>{
                    return(
                        <div
                            className="stripe"
                            style={{
                                background,
                                opacity,
                                rotate,
                                transform: `rotate(${rotate}deg) translate(${right}px,${top}px)`
                            }}
                        ></div>
                    )
                }}

            </Animate>
        ))

    )


    render () {
        return (
        <div className="featured_stripes">
            {this.showStripes()}
        </div>
        );
    }
}

export default BackgroundAnimation;