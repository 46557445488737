import React, { useEffect, useState} from 'react';
import  { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../Redux/Actions/user_actions';
import { CircularProgress } from '@material-ui/core';

function Logout(props) {
    const [navigate, setNavigate] = useState(false)

    useEffect(() => {
        logout();
    }, [])

    const logout = () => {
        props.dispatch(logoutUser()).then(responce => {
            console.log('logout');
            if(responce.payload.success){
                setNavigate(true)
                //props.history.push('/');
            }
        })
    }

    return (
        <div>
            { navigate ? 
                <Redirect to="/" push={true} /> : 

                <div>
                    <h2>Loggin you out.</h2>
                    <CircularProgress />
                </div>  
            }
        </div>
    )

}

export default connect()(withRouter(Logout))