import axios from 'axios';
import { REPORT_CORPORATE_SCORE_CARD,
        REPORT_CORPORATE_RISK_REGISTER,
        REPORT_CORPORATE_STATEMENTS,
        REPORT_CORPORATE_STRUCTURE,
        REPORT_CORPORATE_ROADMAP,
        REPORT_BUSINESS_UNIT_DELIVERABLES,
        REPORT_FUNCTIONAL_SCORE_CARD,
        REPORT_FUNCTIONAL_STRUCTURE,
        REPORT_FUNCTIONAL_RISK_REGISTER,
        REPORT_FUNCTIONAL_ROADMAP,
        REPORT_REVENUE_STREAM_DELIVERABLES } from './types';
import { REPORT_SERVER } from '../../Components/Utils/Misc';

export function generateCorporateScoreCard(filter_data){
    
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/score_card`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);

        console.log(request);

        return {
            type: REPORT_CORPORATE_SCORE_CARD,
            payload: request
        }
}

export function generateCorporateBUDeliverables(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/bu_deliverables`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);

        return {
            type: REPORT_BUSINESS_UNIT_DELIVERABLES,
            payload: request
        }
}

export function generateCorporateStatements(data){
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/statements`, data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_CORPORATE_STATEMENTS,
            payload: request
        }
}

export function generateCorporateStructure(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/structure`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_CORPORATE_STRUCTURE,
            payload: request
        }
}

export function generateCorporateRiskRegister(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/risk_register`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_CORPORATE_RISK_REGISTER,
            payload: request
        }
}

export function generateCorporateRoadMap(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/corporate/roadmap`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_CORPORATE_ROADMAP,
            payload: request
        }
}

export function generateFunctionalScoreCard(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/functional/score_card`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);

        return {
            type: REPORT_FUNCTIONAL_SCORE_CARD,
            payload: request
        }
}

export function generateFunctionalStructure(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/functional/structure`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_FUNCTIONAL_STRUCTURE,
            payload: request
        }
}

export function generateFunctionalRSDeliverables(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/functional/rs_deliverables`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_REVENUE_STREAM_DELIVERABLES,
            payload: request
        }
}

export function generateFunctionalRiskRegister(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/functional/risk_register`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_FUNCTIONAL_RISK_REGISTER,
            payload: request
        }
}

export function generateFunctionalRoadMap(filter_data){
    const request = axios.post(`${REPORT_SERVER}/generate/functional/roadmap`, filter_data, { responseType: 'blob'})
        .then(responce => responce.data);
        
        return {
            type: REPORT_FUNCTIONAL_ROADMAP,
            payload: request
        }
}