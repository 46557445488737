import axios from 'axios';
import { GET_USER_PLAN, UPDATE_USER_PLAN, CANCEL_USER_PLAN } from './types';
import { USERPLAN_SERVER } from '../../Components/Utils/Misc';

export function getUserPlan() {
  const request = axios.get(`${USERPLAN_SERVER}/`).then((responce) => responce.data);

  return {
    type: GET_USER_PLAN,
    payload: request,
  };
}

export function updateUserPlan(newPlanID) {
  const request = axios.put(`${USERPLAN_SERVER}/update`, { toPlan: newPlanID }).then((responce) => responce.data);

  return {
    type: UPDATE_USER_PLAN,
    payload: request,
  };
}

export function cancelUserPlan() {
  const request = axios.get(`${USERPLAN_SERVER}/cancel`).then((responce) => responce.data);

  return {
    type: CANCEL_USER_PLAN,
    payload: request,
  };
}
