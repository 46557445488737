import { Grid, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';

import BillingDetails from './billingDetails';
import PlanDetails from './planDetails';
import UserTransactions from './userTransactions';

const useStyles = makeStyles((theme) => ({}));

function Billing(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={6}>
          <BillingDetails />
        </Grid>
        <Grid item xs={6}>
          <PlanDetails />
        </Grid>
        <Grid item xs={12}>
          <UserTransactions />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Billing;
