import { REPORT_CORPORATE_SCORE_CARD,
    REPORT_CORPORATE_RISK_REGISTER,
    REPORT_CORPORATE_STATEMENTS,
    REPORT_CORPORATE_STRUCTURE,
    REPORT_CORPORATE_ROADMAP,
    REPORT_BUSINESS_UNIT_DELIVERABLES,
    REPORT_FUNCTIONAL_SCORE_CARD,
    REPORT_FUNCTIONAL_STRUCTURE,
    REPORT_FUNCTIONAL_RISK_REGISTER,
    REPORT_FUNCTIONAL_ROADMAP,
    REPORT_REVENUE_STREAM_DELIVERABLES  } from '../Actions/types'


export default function(state={}, action){
    switch(action.type){
        case REPORT_CORPORATE_SCORE_CARD:
            return { ...state, pdf : action.payload };
        case REPORT_CORPORATE_RISK_REGISTER:
            return { ...state, pdf : action.payload };
        case REPORT_CORPORATE_STATEMENTS:
            return { ...state, pdf : action.payload };
        case REPORT_CORPORATE_STRUCTURE:
            return { ...state, pdf : action.payload };
        case REPORT_CORPORATE_ROADMAP:
            return { ...state, pdf : action.payload };
        case REPORT_BUSINESS_UNIT_DELIVERABLES:
            return { ...state, pdf : action.payload };
        case REPORT_FUNCTIONAL_SCORE_CARD:
            return { ...state, pdf : action.payload };
        case REPORT_FUNCTIONAL_RISK_REGISTER:
            return { ...state, pdf : action.payload };
        case REPORT_FUNCTIONAL_STRUCTURE:
            return { ...state, pdf : action.payload };
        case REPORT_FUNCTIONAL_ROADMAP:
            return { ...state, pdf : action.payload };
        case REPORT_REVENUE_STREAM_DELIVERABLES:
            return { ...state, pdf : action.payload };
        default:
            return state;
    }
}

