import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { getUserBilling } from '../../Redux/Actions/user_actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 3),
    margin: theme.spacing(2),
  },
  header: {
    margin: theme.spacing(0, 0, 3),
    fontSize: '30px',
  },
}));

function BillingDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const billing = useSelector((state) => state.user.billing);

  useEffect(() => {
    dispatch(getUserBilling());
  }, []);

  return (
    <Paper className={classes.root}>
      <Typography varient="p" className={classes.header}>
        Billing Details
      </Typography>
      {billing && (
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={4}>
            <Typography>
              {billing.firstName} {billing.lastName}
            </Typography>
            <Typography>{billing.email}</Typography>
            <Typography>{billing.contactNumber}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{billing.address1}</Typography>
            <Typography>{billing.address2}</Typography>
            <Typography>{billing.city}</Typography>
            <Typography>{billing.postalCode}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{billing.companyName}</Typography>
            {billing.registrationNumber && <Typography>Reg No. {billing.registrationNumber}</Typography>}
            {billing.vatNumber && <Typography>Reg No. {billing.vatNumber}</Typography>}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default BillingDetails;
