import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from '../../Redux/Actions/plan.actions';
import { updateUserPlan } from '../../Redux/Actions/userPlan.actions';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../UI/Form';
import HookSelect from '../UI/Form/hookSelect';
import SubmitButton from '../UI/Buttons/submitButton';
import { Card, CardContent, Grid, MenuItem, Typography } from '@material-ui/core';
import MyCircularProgress from '../UI/ProgressBars/myCircularProgress';
import { Fragment } from 'react';

const schema = yup.object().shape({
    plan: yup.string().required()
})

function UpdatePlan(props) {
    const [updatePlanProgress, setUpdatePlanProgress] = useState(false);

    const dispatch = useDispatch();
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const currentPlan = useSelector(state => state.user.userData?.plan)
    const plans = useSelector(state => state.plans.plans)

    useEffect(() => {
        dispatch(getPlans())
    }, [])

    const onSubmit = (data) => {
        setUpdatePlanProgress(true)
        dispatch(updateUserPlan(data.plan)).then(response => {
            setUpdatePlanProgress(false)
            props.onClose();
        })
    }

    return (
        plans ?
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction='column' justify='center' >
                <Grid item xs={12}>
                    { currentPlan &&
                        <Card>
                            <CardContent>
                                <Typography>Current Plan</Typography>
                                <Typography>{currentPlan.name}</Typography>

                                <Typography>{currentPlan.price} {currentPlan.planFrequency}</Typography>
                            </CardContent>
                        </Card>
                    }
                </Grid>   
            </Grid>
            <Grid container alignItems='center' justify='center' direction='row'>
                { updatePlanProgress ? <MyCircularProgress caption="Updating Plan" /> :
                <Fragment>
                    <HookSelect id='plan' name='plan' label='Update to' control={control} error={ errors?.plan }>
                        { plans && plans.map((plan, index) => <MenuItem key={plan._id} value={plan._id}  >{plan.name}: R {plan.price} {plan.planFrequency}</MenuItem>) }
                    </HookSelect>
                    <SubmitButton>Update</SubmitButton> 
                </Fragment> 
                }
            </Grid> 
        </Form>: <MyCircularProgress caption='Loading Plans'/>
    )
}

export default UpdatePlan
