import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Timeline, { SidebarHeader, TimelineHeaders, DateHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

//https://github.com/namespace-ee/react-calendar-timeline#timeline-headers

const useStyles = makeStyles((theme) => ({}));

function RoadTimeline(props) {
  return (
    <Timeline
      groups={props.data.groups}
      items={props.data.items}
      defaultTimeStart={moment().add(-1, 'month')}
      defaultTimeEnd={moment().add(11, 'month')}
      sidebarWidth={300}
      canChangeGroup={false}
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => {
            return <div {...getRootProps()}></div>;
          }}
        </SidebarHeader>
        <DateHeader unit="primaryHeader" />
        <DateHeader />
      </TimelineHeaders>
    </Timeline>
  );
}

export default RoadTimeline;
