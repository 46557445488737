import React, { useState, useEffect } from 'react';
import { validate } from '../misc';
import FormField from '../../Utils/Forms/FormField';
import UserSelect from '../CustomFields/UserSelect';

import { Button, Divider, Grid, CircularProgress } from '@material-ui/core';

import { connect } from 'react-redux';
import { addCorporateIssue, updateCorporateIssue } from '../../../Redux/Actions/issues_actions';
import { getGroupStrategicObjectives } from '../../../Redux/Actions/group_strategic_objectives_actions';
import FieldHelpInfo from '../fieldHelpInfo';
import PrintIssue from '../../Print/printIssue';

function AddEditCSIssue(props) {
  const [creatorType, setCreatorType] = useState(props.creatorType ? 'Corporate Strategy' : props.creatorType);
  const [issueId, setIssueId] = useState('');
  const [formType, setFormType] = useState(props.selectedIssue._id ? 'Edit' : 'Add');
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState('');
  const [loaderAdding, setLoaderAdding] = useState(false);
  const [formData, setFormData] = useState({
    description: {
      element: 'multiline',
      value: '',
      config: {
        name: 'description',
        type: 'text',
        label: 'Description',
        limit: 200,
      },
      validation: {
        required: true,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    impact: {
      element: 'multiline',
      value: '',
      config: {
        name: 'impact',
        type: 'text',
        label: 'Impact',
        limit: 200,
      },
      validation: {
        required: true,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    action: {
      element: 'multiline',
      value: '',
      config: {
        name: 'action',
        type: 'text',
        label: 'Action',
        limit: 200,
      },
      validation: {
        required: true,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    outcome: {
      element: 'multiline',
      value: '',
      config: {
        name: 'outcome',
        type: 'text',
        label: 'Anticipated Outcome',
        limit: 200,
      },
      validation: {
        required: true,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    issue_type: {
      element: 'hidden',
      value: props.selectedType,
      config: {
        name: 'issue_type',
        type: 'hidden',
      },
      valid: true,
    },
    area: {
      element: 'hidden',
      value: '',
      config: {
        name: 'area',
        type: 'hidden',
      },
      valid: true,
    },
    strategic_objective: {
      element: 'select',
      value: '',
      config: {
        name: 'risk_objective',
        type: 'select',
        label: 'Strategic Theme',
        options: [
          { key: 'Growth', value: 'Growth' },
          { key: 'Risk', value: 'Risk' },
          { key: 'Transition', value: 'Transition' },
        ],
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
      showLabel: true,
    },
    key_objective: {
      element: 'select',
      value: ' ',
      config: {
        name: 'key_objective',
        type: 'select',
        label: 'Strategic Objective',
        options: [],
        visable: false,
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
      showLabel: true,
    },
    kpi: {
      element: 'select',
      value: ' ',
      config: {
        name: 'kpi',
        type: 'select',
        label: 'Key Performance Indicator',
        options: [
          { key: 'Finance', value: 'Finance' },
          { key: 'People', value: 'People' },
          { key: 'Customer', value: 'Customer' },
          { key: 'Operational', value: 'Operational' },
          { key: 'Research & Development', value: 'Research & Development' },
        ],
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
      showLabel: true,
    },
    internalexternal: {
      element: 'radio',
      value: '',
      config: {
        name: 'internalexternal',
        type: 'radio',
        label: 'Focus',
        options: [
          { key: 'internal', value: 'internal', label: 'Internal' },
          { key: 'external', value: 'external', label: 'External' },
          { key: 'both', value: 'both', label: 'Both' },
        ],
      },
      validation: {
        required: true,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    assigned_users: {
      element: 'hidden',
      config: {
        name: 'assigned_user',
        type: 'hidden',
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
    },
    business_unit: {
      element: 'hidden',
      config: {
        name: 'business_unit',
        type: 'hidden',
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
    },
    revenue_stream: {
      element: 'hidden',
      config: {
        name: 'revenue_stream',
        type: 'hidden',
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
    },
    due_date: {
      element: 'date',
      value: '',
      config: {
        name: 'due_date',
        type: 'date',
        label: 'Due Date',
      },
      validation: {
        required: true,
        email: false,
      },
      valid: false,
      validationMessage: '',
      showLabel: true,
    },
    hide_in_functional: {
      element: 'select',
      value: 'No',
      config: {
        name: 'hide_in_functional',
        type: 'select',
        label: props.creatorType == 'Group Strategy' ? 'Hide in Business Stratgy' : 'Hide in Functional Strategy',
        options: [
          { key: 'Yes', value: 'Yes' },
          { key: 'No', value: 'No' },
        ],
      },
      validation: {
        required: false,
      },
      valid: true,
      validationMessage: '',
      showLabel: true,
    },
  });

  const updateFields = (issue, issueId, formType) => {
    const newFormData = { ...formData };

    for (let key in newFormData) {
      if (key !== 'area' && key !== 'issue_type') {
        if (key == 'key_objective' && issue[key]) {
          newFormData[key].value = issue[key]._id;
          newFormData[key].valid = true;
          continue;
        }

        newFormData[key].value = issue[key];
        newFormData[key].valid = true;
      }
    }

    setIssueId(issueId);
    setFormType(formType);

    updateKeyObjectives(newFormData, newFormData['strategic_objective'].value);
    setFormData(newFormData);
  };

  useEffect(() => {
    if (props.selectedArea) {
      getBusinessUnit();
    }

    if (props.areas) {
      //Set sub areas when dialog opens.
      const id = props.selectedArea._id;
      const newArray = [];

      props.areas.forEach((item, i) => {
        if (item.parent) {
          if (item.parent._id === id) {
            newArray.push(item);
          }
        }
      });
    }

    props.dispatch(getGroupStrategicObjectives());

    updateFields(props.selectedIssue, props.selectedIssue._id, formType);
  }, [formData.business_unit]);

  const getBusinessUnit = () => {
    const newFormData = { ...formData };
    newFormData['area'].value = props.selectedArea._id;
    setFormData(newFormData);
  };

  const updateKeyObjectives = (newFormData, selectedStrategicObjective) => {
    let keyObjectives = [];

    props.group_strategic_objectives.forEach((gso) => {
      if (gso.type === selectedStrategicObjective) {
        keyObjectives.push({ value: gso.objective, key: gso._id });
      }
    });

    newFormData.key_objective.config.options = keyObjectives;
    newFormData.key_objective.config.visable = selectedStrategicObjective ? true : false;
  };

  const updateForm = (element, content) => {
    const newFormData = { ...formData };
    const newElement = { ...newFormData[element.id] };

    if (element.id === 'strategic_objective' && element.event.target.value) {
      updateKeyObjectives(newFormData, element.event.target.value);
    }

    if (content === '') {
      newElement.value = element.event.target.value;
    } else {
      newElement.value = content;
    }

    if (newElement.element === 'multiline') {
      newElement.charsUsed = newElement.value.length;
    }

    let valiData = validate(newElement);
    newElement.valid = valiData[0];
    newElement.validationMessage = valiData[1];
    newFormData[element.id] = newElement;

    setFormError(false);
    setFormData(newFormData);
  };

  const submitForm = (event) => {
    event.preventDefault();
    setLoaderAdding(true);

    let dataToSubmit = {};
    let formIsValid = true;

    const { closeDialog } = props;

    for (let key in formData) {
      if (key === 'subArea' && formData[key].value === '') {
        continue;
      }

      dataToSubmit[key] = formData[key].value;
      formIsValid = formData[key].valid && formIsValid;
    }

    if (props.business_unit) {
      dataToSubmit.business_unit = props.business_unit;
    }

    dataToSubmit.issueStrategyType = props.strategyType;

    if (formIsValid) {
      if (formType === 'Edit') {
        props.dispatch(updateCorporateIssue(issueId, dataToSubmit)).then((response) => {
          if (response.payload.success) {
            closeDialog({ event });
            setLoaderAdding(false);
          }
        });
      } else {
        props.dispatch(addCorporateIssue(dataToSubmit)).then((response) => {
          closeDialog({ event });
          setLoaderAdding(false);
        });
      }
    } else {
      setFormError(true);
      setLoaderAdding(false);
    }
  };

  const cancelForm = (event) => {
    event.preventDefault();

    const { closeDialog } = props;
    closeDialog({ event });
  };

  const updateSelectedUser = (users) => {
    let newFormData = { ...formData };
    newFormData['assigned_users'].value = users;

    setFormData(newFormData);
  };

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={10}>
          <h2 style={{ margin: '0px' }}>
            {props.selectedType === 'current' ? 'Current' : 'Anticipated'} <strong style={{ color: '#005773' }}>{props.selectedArea.name}</strong> Issue
          </h2>
        </Grid>
        <Grid item xs={12} md={2}>
          {formType != 'Add' && <PrintIssue issue={props.selectedIssue} />}
        </Grid>
      </Grid>
      <form onSubmit={(event) => submitForm(event)}>
        <Grid>
          <FormField id={'area'} formData={formData.area} change={(element) => updateForm(element, '')} />
          <FormField id={'description'} formData={formData.description} change={(element) => updateForm(element, '')} />
          <FieldHelpInfo formData={formData.description} />

          <FormField id={'impact'} formData={formData.impact} change={(element) => updateForm(element, '')} />
          <FieldHelpInfo formData={formData.impact} />

          <FormField id={'action'} formData={formData.action} change={(element) => updateForm(element, '')} />
          <FieldHelpInfo formData={formData.action} />

          <FormField id={'outcome'} formData={formData.outcome} change={(element) => updateForm(element, '')} />
          <FieldHelpInfo formData={formData.outcome} />
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <FormField id={'issue_type'} formData={formData.issue_type} change={(element) => updateForm(element, '')} />
            <br />
            <FormField id={'internalexternal'} formData={formData.internalexternal} change={(element) => updateForm(element, '')} />

            <div style={{ marginBottom: '10px' }}>
              <UserSelect
                change={(user) => updateSelectedUser(user)}
                header={props.creatorType !== 'Functional Strategy' ? 'Select / Add Accountable Person' : 'Select / Add Responsible Person'}
                btnCreateName={props.creatorType !== 'Functional Strategy' ? 'Add new Person' : 'Add new Person'}
                label={props.creatorType !== 'Functional Strategy' ? 'Select Accountable Person(s)' : 'Select Responsible Person'}
                multi={true}
                multiMax={props.assignedUsersMax ? props.assignedUsersMax : 1}
                selectedUsers={props.selectedIssue ? props.selectedIssue.assigned_users : null}
                user_type={props.creatorType === 'Functional Strategy' ? 'functional_user' : 'corporate_user'}
              />
            </div>

            {props.creatorType !== 'Functional Strategy' ? (
              <FormField id={'hide_in_functional'} formData={formData.hide_in_functional} change={(element, content) => updateForm(element, '')} />
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <FormField id={'strategic_objective'} formData={formData.strategic_objective} change={(element) => updateForm(element, '')} />

            {formData.key_objective.config.visable ? <FormField id={'key_objective'} formData={formData.key_objective} change={(element) => updateForm(element, '')} /> : null}

            <br />
            {props.creatorType !== 'Functional Strategy' ? <FormField id={'kpi'} formData={formData.kpi} change={(element) => updateForm(element, '')} /> : null}
            <br />
            <FormField id={'due_date'} formData={formData.due_date} change={(element, content) => updateForm(element, content)} />
          </Grid>
        </Grid>
        <Divider />

        {loaderAdding ? (
          <CircularProgress style={{ maegin: '5px auto' }} />
        ) : (
          <div>
            <Button
              onClick={(event) => submitForm(event)}
              variant="contained"
              style={{
                backgroundColor: '#005773',
                color: 'white',
                fontWeight: [500],
                fontSize: '17px',
                width: '170px',
                marginTop: '20px',
                float: 'right',
              }}
            >
              {formType === 'Add' ? 'Add' : 'Save'}
            </Button>
            <Button
              onClick={(event) => cancelForm(event)}
              variant="contained"
              style={{
                backgroundColor: '#005773',
                color: 'white',
                fontWeight: [500],
                fontSize: '17px',
                width: '170px',
                marginTop: '20px',
                float: 'left',
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    //areas: state.areas.areas,
    group_strategic_objectives: state.group_strategic_objectives.group_strategic_objectives,
    issues_success: state.strategy_issues.success,
    issue_edit_success: state.strategy_issues.edit_success,
  };
};

export default connect(mapStateToProps)(AddEditCSIssue);
