import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getRole, getActions } from '../../../Redux/Actions/role_actions'

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions'
import Button from '@material-ui/core/Button';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PermissionsCheckbox from '../../UI/CustomFields/PermissionsCheckbox';

const styles = theme => ({
    dialog: {
        width: theme.spacing.unit * 2,
    },
});

class EditRole extends Component {

    state = {
        fromType: "Create",
        roleId: null,
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: '',
        formData: {
            name: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'role_name',
                    type: 'input',
                    label: 'Role Name',
                    placeholder: 'Enter Role Name'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            desc: {
                element: 'multiline',
                value: '',
                config: {
                    name: 'desc',
                    type: 'input',
                    label: 'Description',
                    placeholder: 'Enter Role Description'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            role_actions: {
                value: [],
                valid: true,
            }
        }

    }

    updateFields(role, permissions, type, roleId) {
        const newFormData = {
            ...this.state.formData
        }

        for (let key in newFormData) {
            if (role) {
                newFormData[key].value = role[key];
                newFormData[key].valid = true;
            }
        }


        this.setState({
            roleId,
            formType: type,
            formdata: newFormData,
        })
    }

    componentDidMount() {
        this.props.dispatch(getActions());

        if (this.props.type === 'Edit' && this.props.roleId) {
            this.props.dispatch(getRole(this.props.roleId)).then(response => {
                this.updateFields(this.props.role.role, this.props.role.permissions, this.props.type, this.props.roleId);
            })
        }
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'update_user');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();

        let dataToSubmit = generateData(this.state.formData, 'update_user');
        let formIsValid = isFormValid(this.state.formData, 'update_user');
        console.log(dataToSubmit);

        if (formIsValid) {
            // this.props.dispatch(updateRole(dataToSubmit)).then(responce => {
            //     if (this.props.roleUpdated) {
            //         this.props.updated(this.props.roleUpdated);
            //     } else {
            //         const error = responce.payload.err;

            //         this.setState({
            //             formError: true
            //         })
            //     }
            // });
        }
        else {
            this.setState({
                formErrorMsg: 'This creating is invalid.',
                formError: true
            })
        }
    }

    handleChecked(event, action_id, permission) {
        let checked = event.target.checked;
        this.checkSave(checked , action_id, permission)
    }

    checkSave = (checked, action_id, permission) => {
        
        let newFormData = { ...this.state.formData };

        let role_actions = [];
        if (newFormData['role_actions'].value) {
            role_actions = newFormData['role_actions'].value;
        }

        let _permissions = [];
        let _role_actions_index = this.getIndex(action_id, role_actions, "actionID");
        if (role_actions[_role_actions_index]) {
            _permissions = role_actions[_role_actions_index].permissions;
        }

        if (checked) {
            if (_permissions.indexOf(permission) === -1) {
                _permissions.push(permission)


                if (role_actions[_role_actions_index]) {
                    role_actions[_role_actions_index].permissions = _permissions;
                }
                else {
                    role_actions.push({ actionID: action_id, permissions: _permissions })
                }

            }
        }
        else {
            const permissionIndex = _permissions.indexOf(permission);
            if (permissionIndex !== -1) {
                role_actions[_role_actions_index].permissions.splice(permissionIndex, 1);
            }
        }
        newFormData['role_actions'].value = role_actions;
        
        this.setState(state => {
            state.formData = newFormData;
        })
        console.log(newFormData);
    }

    getIndex = (value, arr, prop) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    generateActionsTable = () => {
        return (
            <div>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Action</TableCell>
                            <TableCell>Create</TableCell>
                            <TableCell>Read</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Delete</TableCell>
                            <TableCell>Execute</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.roleActions ?
                            this.props.roleActions.map((action, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{action.name}</TableCell>
                                        <TableCell>
                                            {this.props.role ?
                                                <PermissionsCheckbox
                                                    role={this.props.role.role}
                                                    permissions={this.props.role.permissions}
                                                    action_id={action._id}
                                                    action="Create"
                                                    change={(event) => this.handleChecked(event, action._id, "Create")} 
                                                    checkLoaded={(checked,actionID, action) => this.checkSave(checked , actionID, action)}/>
                                                : null
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {this.props.role ?
                                                <PermissionsCheckbox
                                                    role={this.props.role.role}
                                                    permissions={this.props.role.permissions}
                                                    action_id={action._id}
                                                    action="Read"
                                                    change={(event) => this.handleChecked(event, action._id, "Read")}
                                                    checkLoaded={(checked,actionID, action) => this.checkSave(checked , actionID, action)}/>
                                                : null
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {this.props.role ?
                                                <PermissionsCheckbox
                                                    role={this.props.role.role}
                                                    permissions={this.props.role.permissions}
                                                    action_id={action._id}
                                                    action="Update"
                                                    change={(event) => this.handleChecked(event, action._id, "Update")} 
                                                    checkLoaded={(checked,actionID, action) => this.checkSave(checked , actionID, action)}/>
                                                : null
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {this.props.role ?
                                                <PermissionsCheckbox
                                                    role={this.props.role.role}
                                                    permissions={this.props.role.permissions}
                                                    action_id={action._id}
                                                    action="Delete"
                                                    change={(event) => this.handleChecked(event, action._id, "Delete")} 
                                                    checkLoaded={(checked,actionID, action) => this.checkSave(checked , actionID, action)}/>
                                                : null
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {this.props.role ?
                                                <PermissionsCheckbox
                                                    role={this.props.role.role}
                                                    permissions={this.props.role.permissions}
                                                    action_id={action._id}
                                                    action="Execute"
                                                    change={(event) => this.handleChecked(event, action._id, "Execute")} 
                                                    checkLoaded={(checked,actionID, action) => this.checkSave(checked , actionID, action)}/>
                                                : null
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            }) :
                            <TableRow>
                                <TableCell scope="row" colSpan="8">Loading</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>



            </div>
        )
    }


    render() {
        const { cancel } = this.props;


        return (
            <div>
                <FormField
                    id={'name'}
                    formData={this.state.formData.name}
                    change={(element) => this.updateForm(element)}
                />
                <FormField
                    id={'desc'}
                    formData={this.state.formData.desc}
                    change={(element) => this.updateForm(element)}
                />

                {this.generateActionsTable()}


                {this.state.formError ?
                    <div className="error_label">{this.state.formErrorMsg}</div>
                    : null}

                <Button
                    onClick={() => cancel()}
                    style={{
                        color: '#005773',
                        fontWeight: [500],
                        marginTop: '40px',
                        marginBottom: '20px',
                        width: '150px'
                    }}>
                    Cancel
        </Button>

                <Button variant="contained"
                    onClick={(event) => this.submitForm(event)}
                    style={{
                        backgroundColor: '#005773',
                        color: 'white',
                        fontWeight: [500],
                        marginTop: '40px',
                        marginBottom: '20px',
                        width: '150px'
                    }}>
                    Create Role
        </Button>


            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    role: state.role.role,
    roleUpdated: state.role.success,
    roleActions: state.role.actions
})

export default connect(mapStateToProps)(withStyles(styles)(EditRole))