import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';

import Drawer from '@material-ui/core/Drawer';

import Divider from '@material-ui/core/Divider';
//import Badge from '@material-ui/core/Badge';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//import NotificationsIcon from '@material-ui/icons/Notifications';

import MenuItems from './menuItems';

import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Grid } from '@material-ui/core';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  colorPrimary: {
    backgroundColor: '#6399AB',
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 20,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 8,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    //padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  iconButton: {
    marginRight: '5px',
  },
}));

const MainMenu = (props) => {
  const classes = useStyles();

  const user = useSelector((state) => state.user.userData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(true);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setMenuOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} component="a" href="/profile">
        Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component="a" href="/profile/billing">
        Billing
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component="a" href="/entity_select?redirectTo=/dashboard">
        Manage/Switch Entity
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classNames(classes.appBar, classes.colorPrimary, menuOpen && classes.appBarShift)}>
        <Toolbar disableGutters={!menuOpen} className={classes.toolbar}>
          <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classNames(classes.menuButton, menuOpen && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {props.pageHeader ? props.pageHeader : ''}
          </Typography>

          <div>
            {user ? (
              <Grid container>
                <Grid item>
                  <IconButton className={classes.iconButton} edge="end" aria-label="account of current user" aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
                    <AccountCircle />
                  </IconButton>
                </Grid>
                <Grid item alignItems="center" alignContent="center" style={{ display: 'flex' }}>
                  <Typography variant="overline" component="p" style={{ lineHeight: '15px' }}>
                    {user.fullname}
                    <br />
                    <small>{user.selectedOrganization.name}</small>
                  </Typography>
                  {/* <Typography style={{ lineHeight: '15px', fontSize: '13px'}}></Typography> */}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}

      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !menuOpen && classes.drawerPaperClose),
        }}
        open={menuOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuItems user={user} isGroup={user ? user.selectedOrganization.isGroup : null} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {props.children}
      </main>
    </div>
  );
};

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MainMenu;
