import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUsersByOrganization } from '../../Redux/Actions/user_actions';
import { getStatuses } from '../../Redux/Actions/status_action';
import { getAllBusinessUnits } from '../../Redux/Actions/business_units_actions';

import FormField from '../Utils/Forms/FormField';
import { validate,generateData } from '../Utils/Forms/FormActions';
import { toDropdown } from '../UI/misc';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

let _isMounted = false;

class FilterBar extends Component {

    state = {      
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: '',
        formData: {
            users: {
                element: 'multi-select',
                value: [],
                config: {
                    name: 'users',
                    type: 'select',
                    label: 'User(s)',
                    options: [],
                    default: 'all',
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            bu: {
                element: 'select',
                value: '',
                config: {
                    name: 'bu',
                    type: 'select',
                    label: 'Select Business Unit',
                    options: [],
                    default: 'all',
                },
                validation: {
                    required: this.props.isFun,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            themes: {
                element: 'multi-select',
                value: [],
                config: {
                    name: 'themes',
                    type: 'select',
                    label: 'Strategic Themes',
                    options: [
                        { key: 'Growth', value: 'Growth' },
                        { key: 'Risk', value: 'Risk' },
                        { key: 'Transition', value: 'Transition' }],
                    default: 'all',
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            kpi: {
                element: 'multi-select',
                value: [],
                config: {
                    name: 'kpi',
                    type: 'select',
                    label: 'Key Performance Indicator',
                    options: [
                        { key: 'Finance', value: 'Finance' },
                        { key: 'Customer', value: 'Customer' },
                        { key: 'Operational', value: 'Operational' },
                        { key: 'People', value: 'People' },
                        { key: 'Research & Development', value: 'Research & Development' }],
                    default: 'all',
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            status: {
                element: 'multi-select',
                value: [],
                config: {
                    name: 'status',
                    type: 'select',
                    label: 'Status',
                    options: [],
                    default: 'all',
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            start_date: {
                element: 'date',
                value: '',
                config: {
                    name: 'start_date',
                    type: 'date',
                    label: 'Start Date'
                },
                validation: {
                    required: true,
                    email: false
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
            end_date: {
                element: 'date',
                value: '',
                config: {
                    name: 'end_date',
                    type: 'date',
                    label: 'End Date'
                },
                validation: {
                    required: true,
                    email: false
                },
                valid: false,
                validationMessage: '',
                showLabel: true
            },
        }
    }

    componentDidMount() {
        _isMounted = true;

        let newFormData = { ...this.state.formData };

        this.props.dispatch(getStatuses()).then(response => {
            if(this.props.statuses){
                newFormData['status'].config.options = toDropdown(this.props.statuses, "_id", "name");

                if (_isMounted) {
                    this.setState({
                        formData: newFormData
                    })
                }
            }
        })

        this.setUserOptions();
        this.props.dispatch(getAllBusinessUnits()).then(response => {
            if(this.props.business_units){
                newFormData['bu'].config.options = toDropdown(this.props.business_units, "_id", "name");

                if (_isMounted) {
                    this.setState({
                        formData: newFormData
                    })
                }
            }
        })
    }

    componentWillUnmount() {
        _isMounted = false;
    }

    setUserOptions = () => {
        this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id)).then(responce => {
            let newFormData = { ...this.state.formData };
            newFormData['users'].config.options = this.getUsersToDropdown();

            if (_isMounted) {
                this.setState({
                    formData: newFormData
                })
            }
        })
    }

    getUsersToDropdown = () => {
        let dropdownReady = [];
        if (this.props.users && this.props.users.length !== 0) {
            this.props.users.forEach((element, index) => {
                let dropdownItem = {
                    key: element['id'],
                    value: element['name'] + " " + element['lastname']
                }
                dropdownReady.push(dropdownItem);
                
            });
            return dropdownReady;
        }
    }

    updateForm(element, content) {
        const newFormData = { ...this.state.formData }
        const newElement = { ...newFormData[element.id] }

        if (content === "") {
            newElement.value = element.event.target.value;
        }
        else {
            newElement.value = content
        }

        let valiData = validate(newElement);
        newElement.valid = valiData[0];
        newElement.validationMessage = valiData[1];

        newFormData[element.id] = newElement;

        if (_isMounted) {
            this.setState({
                formError: false,
                formData: newFormData
            }, () => {
                let dataToSubmit = generateData(this.state.formData, 'report_filters');
                this.props.changed(dataToSubmit);
            });
        }
    }

    render() {
        // const { classes } = this.props;

        return (
            <div>
                <Grid container>
                    <Grid item md={4}>
                        <FormField
                            id={'kpi'}
                            formData={this.state.formData.kpi}
                            change={(element) => this.updateForm(element,"")}
                        />
                        <br />
                        <FormField
                            id={'status'}
                            formData={this.state.formData.status}
                            change={(element) => this.updateForm(element,"")}
                        />
                        
                    </Grid>
                    <Grid item md={4}>
                        <FormField
                            id={'users'}
                            formData={this.state.formData.users}
                            change={(element) => this.updateForm(element,"")}
                        />
                        <br />
                        <FormField
                            id={'themes'}
                            formData={this.state.formData.themes}
                            change={(element) => this.updateForm(element,"")}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <FormField
                            id={'start_date'}
                            formData={this.state.formData.start_date}
                            change={(element,content) => this.updateForm(element,content)}
                        />
                        <br />
                        <FormField
                            id={'end_date'}
                            formData={this.state.formData.end_date}
                            change={(element,content) => this.updateForm(element,content)}
                        />
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item md={12}>
                        { this.props.isFun ? 
                            <FormField
                                id={'bu'}
                                formData={this.state.formData.bu}
                                change={(element) => this.updateForm(element,"")}
                            />
                            :null
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    statuses: state.status.statuses,
    users: state.user.users,
    loggedInUser: state.user.userData,
    business_units: state.business_units.business_units
})


export default connect(mapStateToProps)(withStyles(styles)(FilterBar))