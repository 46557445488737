import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FormField from '../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../Utils/Forms/FormActions'

import LoginBackground from '../../Resources/images/backgrounds/background_login.png';

import { completeSubscription } from '../../Redux/Actions/user_actions'
import { validateSubscription } from '../../Redux/Actions/subscription_actions'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: '20px',
        margin: '120px 251px',
        height: '550px',
        width: '650px'
    },
});

class RegisterSubscription extends Component {
    state = {
        loadingRegistration: false,
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: '',
        formData: {
            name: {
                element: 'input',
                value: '',
                config: {
                    name: 'name',
                    type: 'input',
                    label: 'First Name',
                    placeholder: 'First Name'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            lastname: {
                element: 'input',
                value: '',
                config: {
                    name: 'lastname',
                    type: 'input',
                    label: 'Last Name',
                    placeholder: 'Last Name'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            email: {
                element: 'input',
                value: '',
                config: {
                    name: 'email',
                    type: 'email',
                    label: 'Email Address',
                    placeholder: 'Email',
                    disabled: true
                },
                validation: {
                    required: true,
                    email: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
                showLabel: true
            },
            password: {
                element: 'password',
                value: '',
                config: {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Password'
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
                touched: false,
            },
            confirmPassword: {
                element: 'password',
                value: '',
                config: {
                    name: 'confirmPassword',
                    type: 'password',
                    placeholder: 'Confirm your Password'
                },
                validation: {
                    required: true,
                    confirm: "password"
                },
                valid: true,
                validationMessage: '',
                touched: false,
            },
            subscription: {
                element: 'hidden',
                value: '',
                config: {
                    name: 'subscription',
                    type: 'hidden',
                },
                validation: {
                    required: true
                },
                valid: true,
                validationMessage: '',
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(validateSubscription(this.props.match.params.hash)).then(responce => {
            let newFormData = { ...this.state.formData }

            newFormData.email.value = this.props.subscription.email;
            newFormData.name.value = this.props.subscription.first_name;
            newFormData.lastname.value = this.props.subscription.last_name;
            newFormData.subscription.value = this.props.match.params.hash;

            this.setState({
                formData: newFormData
            });
        });
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'register');
        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    submitForm(event) {
        event.preventDefault();

        let dataToSubmit = generateData(this.state.formData, 'register');
        let formIsValid = isFormValid(this.state.formData, 'register');

        if (formIsValid) {
            this.setState({
                loadingRegistration: true
            })
            
            this.props.dispatch(completeSubscription(dataToSubmit)).then(responce => {
                if (responce.payload.registrationSuccess) {
                    this.props.history.push('/register/email_verification/' + dataToSubmit['email']);
                } else {
                    const error = responce.payload.err;
                    if (error.name === 'MongoError' && error.code === 11000) {

                        this.setState({
                            formErrorMsg: 'The email you have entered aready exists.'
                        })
                    }
                    else if(error){
                        this.setState({
                            formErrorMsg: error
                        })
                    }

                    this.setState({
                        formError: true
                    })
                }

                this.setState({
                    loadingRegistration: false
                })
            });
        }
        else {
            this.setState({
                formErrorMsg: 'Your registration is invalid.',
                formError: true
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{
                background: `url(${LoginBackground}) no-repeat`,
                height: '800px',
                position: 'absolute'
            }}>
                <Paper className={classes.root} elevation={5}>
                    <h2 className="h2login">Final Details</h2>
                   
                    <Grid container gutters>
                        <Grid item xs={6} style={{ padding: '0 10px'}}>
                            <form onSubmit={(event) => this.submitForm(event)}>

                                <div className="login_input_container">
                                    <FormField
                                        id={'subscription'}
                                        formData={this.state.formData.subscription}
                                    />
                                    <FormField
                                        id={'name'}
                                        formData={this.state.formData.name}
                                        change={(element) => this.updateForm(element)}
                                    />
                                    <FormField
                                        id={'lastname'}
                                        formData={this.state.formData.lastname}
                                        change={(element) => this.updateForm(element)}
                                    />
                                    <FormField
                                        id={'email'}
                                        formData={this.state.formData.email}
                                        change={(element) => this.updateForm(element)}
                                    />
                                    <FormField
                                        id={'password'}
                                        formData={this.state.formData.password}
                                        change={(element) => this.updateForm(element)}
                                    />
                                    <FormField
                                        id={'confirmPassword'}
                                        formData={this.state.formData.confirmPassword}
                                        change={(element) => this.updateForm(element)}
                                    />

                                    {this.state.formError ?
                                        <div className="error_label">{this.state.formErrorMsg}</div>
                                        : null}

                                    {this.state.formData.validationMessage ?
                                        <div className="error_label">{this.state.formData.validationMessage}</div>
                                        : null}
                                </div>
                            </form>
                        </Grid>
                        <Grid item xs={6}>
                            {this.props.subscription ?
                                <div>
                                    <Card elevation={6}>
                                        <CardContent>
                                            <Typography gutterBottom style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                Subscription Details
                                    </Typography>
                                            <Typography>{this.props.subscription.product}</Typography>
                                            <Typography>Organization Limit: {this.props.subscription.license.organization_limit}</Typography>
                                            <Typography>User Limit: {this.props.subscription.license.user_limit}</Typography>
                                            <Typography>Will automate emails: {this.props.subscription.license.automation ? 'Yes' : 'No'}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            {this.props.subscription.status === 'active' ? <Chip label="Active"></Chip> : null}
                                            {this.props.subscription.status === 'pending-cancel' ? <Chip label="Cancelled. Will expire at end of term."></Chip> : null}
                                            {this.props.subscription.status === 'pending' ? <Chip label="Pending"></Chip> : null}
                                            {this.props.subscription.status === 'on-hold' ? <Chip label="On Hold"></Chip> : null}
                                            {this.props.subscription.status === 'cancelled ' ? <Chip label="Cancelled"></Chip> : null}
                                            {this.props.subscription.status === 'expired ' ? <Chip label="Expired"></Chip> : null}

                                        </CardActions>

                                    </Card>
                                    { this.state.loadingRegistration ? 
                                    <CircularProgress />
                                    :
                                    <Button variant="contained"
                                        onClick={(event) => this.submitForm(event)}
                                        style={{
                                            backgroundColor: '#005773',
                                            color: 'white',
                                            fontWeight: [500],
                                            marginTop: '40px',
                                            marginBottom: '20px',
                                            width: '250px',
                                        }}>
                                        Complete Registration*
                                    </Button> }

                                    <p style={{ fontSize: '10px'}}>*We will send you a verification email to confirm you have access to the email address you have selected. If you wish to change your email please login to your customer profile on <a href="https://www.iqfin.co.za">www.iqfin.co.za</a> and select "Update Details".</p>

                                </div>
                                : null}
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    subscription: state.subscription.subscription,
    registrationSuccess: state.user.registrationSuccess
})


export default connect(mapStateToProps)(withRouter(withStyles(styles)(RegisterSubscription)));