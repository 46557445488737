import React, { Component } from 'react'
import { connect } from 'react-redux'

import { changeOwnership } from '../../../Redux/Actions/organization_actions';
import { getUsersByOrganization } from '../../../Redux/Actions/user_actions';

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid } from '../../Utils/Forms/FormActions'
import { toDropdown } from '../../UI/misc'
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


class ChangeOwner extends Component {
    state={
        formError: false,
        formErrorMsg: 'An error accured. Please try again later.',
        formSuccess: '',
        formData: {
            user: {
                element: 'select',
                value: ' ',
                config: {
                    name: 'user',
                    type: 'select',
                    label: 'Select User',
                    options: []
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
            requestLicense: {
                element: 'select',
                value: ' ',
                config: {
                    name: 'requestLicense',
                    type: 'select',
                    label: 'Request License',
                    options: ['None', 'Business Light', 'Business Premium', 'Enterprise']
                },
                validation: {
                    required: false,
                    email: false
                },
                valid: true,
                validationMessage: '',
                showLabel: true
            },
        }
    }

    componentDidMount(){
        this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id)).then(responce => {
            let newFormData = { ...this.state.formData };
            newFormData['user'].config.options = toDropdown(this.props.users, 'id', 'name');

            this.setState({
                formData: newFormData
            })
        })
    }

    updateForm = (element) => {
        const newFormData = update(element, this.state.formData, 'owner_change');

        this.setState({
            formError: false,
            formData: newFormData
        })
    }

    changeOwner = () => {
        let dataToSubmit = generateData(this.state.formData, 'owner_change');
        let formIsValid = isFormValid(this.state.formData, 'owner_change');

        if (formIsValid) {
            this.props.dispatch(changeOwnership(this.props.loggedInUser.selectedOrganization._id,dataToSubmit)).then(responce => {
                if(responce.payload.success){
                    this.setState({
                        formSuccess: true
                    })

                    this.props.closeOwnerTransfer(true);
                }
                else{
                    this.setState({
                        formError: true,
                        formErrorMsg: responce.payload.error
                    })
                }
            })
        }
    }

    render() {
        return (
            <div>
                <Card elevation={0}>
                    <CardContent>
                        <FormField
                            id={'user'}
                            formData={this.state.formData.user}
                            change={(element) => this.updateForm(element)}
                        />
                        <FormField
                            id={'requestLicense'}
                            formData={this.state.formData.requestLicense}
                            change={(element) => this.updateForm(element)}
                        />

                        {this.state.formError ?
                            <div className="error_label">{this.state.formErrorMsg}</div>
                        : null}
                    </CardContent>
                    <CardActions>
                        <Button variant="contained"
                            onClick={(event) => this.changeOwner(event)}
                            style={{
                                backgroundColor: '#005773',
                                color: 'white',
                                fontWeight: [500],
                                marginTop: '40px',
                                marginBottom: '20px',
                                width: 'auto'
                            }}>
                            <span>Transfer Ownership</span>
                        </Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    users: state.user.users,
    loggedInUser: state.user.userData
})


export default connect(mapStateToProps)(ChangeOwner)