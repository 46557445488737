import { GET_ROLES,
    GET_ROLE,
    GET_ACTIONS,
    CREATE_ROLE,
    UPDATE_ROLE,
    REMOVE_ROLE} from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_ROLES:
            return {...state, roles: action.payload }
        case GET_ROLE:
            return {...state, role: action.payload }
        case GET_ACTIONS:
            return {...state, actions: action.payload }
        case CREATE_ROLE:
            return {...state, success: action.payload.success }
        case UPDATE_ROLE:
            return {...state,  success: action.payload.success }
        case REMOVE_ROLE:
            return {...state }
        default:
            return state;
    }
}