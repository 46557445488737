import axios from 'axios';
import { GET_BUSINESSUNITS,
        GET_BUSINESSUNITS_BYUSER,
         ADD_BUSINESSUNITS,
         GET_BUSINESSUNITS_BYID,
         REMOVE_BUSINESSUNIT,
         GET_BUSINESSUNITS_BYENTITY,
         UPDATE_BUSINESSUNITS} from './types';
import { BUSINESS_UNITS_SERVER } from '../../Components/Utils/Misc';

export function getAllBusinessUnits(){
    const request = axios.get(`${BUSINESS_UNITS_SERVER}`)
        .then(responce => responce.data);

        return {
            type: GET_BUSINESSUNITS,
            payload: request
        }
}

export function getAllBusinessUnitsByEntity(id){
    const request = axios.get(`${BUSINESS_UNITS_SERVER}/entity/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_BUSINESSUNITS_BYENTITY,
            payload: request
        }
}

export function getAllBusinessUnitsByUser(){
    const request = axios.get(`${BUSINESS_UNITS_SERVER}/byuser`)
        .then(responce => responce.data);

        return {
            type: GET_BUSINESSUNITS_BYUSER,
            payload: request
        }
}

export function getBusinessUnit(id){
    const request = axios.get(`${BUSINESS_UNITS_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: GET_BUSINESSUNITS_BYID,
            payload: request
        }
}

export function addBusinessUnit(item){
    const request = axios.post(`${BUSINESS_UNITS_SERVER}`, item)
        .then(responce => responce.data);

        return {
            type: ADD_BUSINESSUNITS,
            payload: request
        }
}

export function updateBusinessUnit(id, data){
    const request = axios.put(`${BUSINESS_UNITS_SERVER}/${id}`, data)
        .then(responce => responce.data);

        return {
            type: UPDATE_BUSINESSUNITS,
            payload: request
        }
}

export function removeBusinessUnit(id){
    const request = axios.delete(`${BUSINESS_UNITS_SERVER}/${id}`)
        .then(responce => responce.data);

        return {
            type: REMOVE_BUSINESSUNIT,
            payload: request
        }
}

