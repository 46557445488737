import React, { useRef } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import AssignLicense from '../../Organization/partial/assignLicense';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: '20px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        height: '450px',
        width: '350px'
    },
    card: {
      minWidth: 275,
      margin: 25,
      display: 'inline-block'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));

function OrganizationCard(props)  {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
            { props.canAssignLicense ? <Chip style={{ position: "relative", float: "right" }} variant="outlined" size="small" label="Owner" /> : null }
            <Typography component='h2' style={{ marginBottom: '10px' }}>{props.organization.organization_id.name}</Typography>
                {props.organization.license ? 
                    <Typography>{props.organization.license.product}</Typography>
                :
                props.canAssignLicense ?
                  <Typography>No License Assigned</Typography>
                    : <Typography>License Information Hidden</Typography>
                }

            </CardContent>
            <CardActions>
              { !props.organization.license && props.canAssignLicense ?
                    <AssignLicense selectedOrganization={props.organization.organization_id}/>
                    : null
              }
                <Button color="primary"
                        onClick={() => props.changeOrganization(props.organization.organization_id._id)}
                        style={{
                            visibility: 'hidden',
                            color: '#005773',
                        }}>
                        Manage
                </Button>
                <Button color="primary"
                        onClick={() => props.changeOrganization(props.organization.organization_id._id)}
                        disabled={props.selectedOrganizationID === props.organization.organization_id._id}
                        style={{
                            color: '#005773',
                            display: props.selectedOrganizationID === props.organization.organization_id._id ? 'none' : 'block'
                        }}>
                        Select
                </Button>
            </CardActions>
        </Card>
    )
}

export default OrganizationCard
