import React, { useState, useEffect  } from 'react'
import { Router } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { changeOwnership } from '../../../Redux/Actions/organization_actions';
import { getUsersByOrganization } from '../../../Redux/Actions/user_actions';

import FormField from '../../Utils/Forms/FormField';
import { update, generateData, isFormValid, clearFields } from '../../Utils/Forms/FormActions'
import { toDropdown, findIndex } from '../../UI/misc'
import Button from '@material-ui/core/Button';

import { DialogActions,DialogTitle,DialogContentText,DialogContent,Dialog, Typography } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import UserSelect from '../../UI/CustomFields/UserSelect';

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Form from '../../UI/Form'
import Input from '../../UI/Form/input'
import SubmitButton from '../../UI/Buttons/submitButton'

const schema = yup.object().shape({
    user: yup.string().required(),
    billing_first_name: yup.string().required(),
    billing_last_name: yup.string().required(),
    billing_company_name: yup.string(),
    billing_address_1: yup.string().required(),
    billing_address_2: yup.string(),
    billing_city: yup.string().required(),
    billing_postcode: yup.string().required(),
    billing_phone: yup.string().required(),
    billing_vat: yup.string(),
    billing_registration_number: yup.string(),
})

const useStyles = makeStyles((theme) => ({
    dialog: {
      margin: theme.spacing(1),
      Width: 600,
    },
    errorMsg: {
        marginTop: theme.spacing(2)
    }
  }));

  const Transfer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const [userInputDialogOpen, setUserInputDialogOpen] = useState(false);

    useEffect(() => {
        register({ name: 'user' })
    }, [])

    const onSubmit = (data) => {
        dispatch(changeOwnership(props.loggedInUser.selectedOrganization._id, data)).then(responce => {
            if(responce.payload.success){
                setUserInputDialogOpen(false);
            }
            else{
                
            }
        })
    }

    const closeDialog = () => {
        setUserInputDialogOpen(false);
    }

    // Open input dialog to iniciate transfer. User details will go here.
    const openUserInputDialog = () => {
        closeDialog();

        setCurrentOpenDialog('userInputDialogOpen');
        setUserInputDialogOpen(true);
    }

    const userInputDialog = () => {
        return (
        <Dialog
            open={userInputDialogOpen}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
        >
            <DialogTitle id="form-dialog-title">Organization Transfer</DialogTitle>
            <DialogContent>
                <h4>User select</h4>
                    <div>Please select the user you want to make the owner.</div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <UserSelect change={(user) => setValue('user', user)} label="Select" header="" btnCreateName="Add new person" user_type="company_admin"/>

                        <h4>Billing</h4>
                        <Input ref={register} id="billing_first_name" label="First Name" name="billing_first_name" autoComplete="firstname" error={!!errors.billing_first_name} helperText={errors?.billing_first_name?.message} />
                        <Input ref={register} id="billing_last_name" label="Last Name" name="billing_last_name" autoComplete="lastname" error={!!errors.billing_last_name} helperText={errors?.billing_last_name?.message} />
                        <Input ref={register} id="billing_company_name" label="Company Name" name="billing_company_name" autoComplete="company" error={!!errors.billing_company_name} helperText={errors?.billing_company_name?.message} />
                        <Input ref={register} id="billing_address_1" label="Address Line 1" name="billing_address_1" autoComplete="address1" error={!!errors.billing_address_1} helperText={errors?.billing_address_1?.message} />
                        <Input ref={register} id="billing_address_2" label="Address Line 2" name="billing_address_2" autoComplete="address2" error={!!errors.billing_address_2} helperText={errors?.billing_address_2?.message} />
                        <Input ref={register} id="billing_city" label="City" name="billing_city" autoComplete="city" error={!!errors.billing_city} helperText={errors?.billing_city?.message} />
                        <Input ref={register} id="billing_postcode" label="Postal Code" name="billing_postcode" autoComplete="postalcode" error={!!errors.billing_postcode} helperText={errors?.billing_postcode?.message} />
                        <Input ref={register} id="billing_phone" label="Phone Numer" name="billing_phone" autoComplete="phone" error={!!errors.billing_phone} helperText={errors?.billing_phone?.message} />
                        <Input ref={register} id="billing_vat" label="Vat" name="billing_vat" autoComplete="vat" error={!!errors.billing_vat} helperText={errors?.billing_vat?.message} />
                        <Input ref={register} id="billing_registration_number" label="Registration Numebr" name="billing_registration_number" autoComplete="registration_number" error={!!errors.billing_registration_number} helperText={errors?.billing_registration_number?.message} />

                        <SubmitButton>Transfer</SubmitButton>
                    </Form>

                <h4>Please note:</h4>
                 <ul>
                     <li>This user will have organization administrator privileges.</li>
                     <li>You will also be a Portfolio manger on the company profile unless the new owner removes you.</li>
                     <li>User will receive email once transfer is complete.</li>
                     <li>Email will be sent to accounts@iqfin.co.za.</li>
                 </ul>
            </DialogContent>
        </Dialog> 
        )
    }

    return (
        <div>
            <Button style={{
                backgroundColor: '#005773',
                color: 'white',
                fontWeight: [500],
                marginBottom: '10px',
                marginLeft: '10px'
            }} onClick={() => { openUserInputDialog() }}>Transfer Ownership</Button>

            { userInputDialog() }
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    users: state.user.users,
    loggedInUser: state.user.userData
})


export default connect(mapStateToProps)(Transfer)