import React, { Component } from 'react'
import { connect } from 'react-redux'
import { email_verification } from '../../Redux/Actions/user_actions'
import LoginBackground from '../../Resources/images/backgrounds/background_login.png';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: '20px',
        marginLeft: theme.spacing.unit * 50,
        marginRight: theme.spacing.unit * 70,
        marginTop: theme.spacing.unit * 20,
        height: '450px',
        width: '350px'
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class EmailVerifeid extends Component {

    state = {
        loading: true
    }

    componentDidMount = () => {
        const token = this.props.match.params.token ? this.props.match.params.token : null;
        if (token) {
            this.props.dispatch(email_verification(token)).then(response => {
                this.setState({ loading: false })
            });
        }
    }


    render() {
        const { classes } = this.props;

        return (
            <div style={{
                background: `url(${LoginBackground}) no-repeat`,
                height: '800px',
                position: 'absolute'
            }}>

                <Paper className={classes.root} elevation={5}>
                    {this.props.match.params.token ?
                        this.state.loading ? 
                        <div>
                            <h2 className="h2login">Verifying...</h2>
                            <CircularProgress className={classes.progress} />
                        </div>
                        :
                            this.props.verified ?
                            <div>
                                <h2 className="h2login">Verification Success</h2>
                                <p>Your email address has been verifeid. Click below to login.</p>
                            </div>
                            :
                            <div>
                            <h2 className="h2login">Verification Failed</h2>
                                <p>Token not valid.</p>
                            </div>
                        :
                        <div>
                            <h2 className="h2login">Verification Failed</h2>
                            <p>No Token.</p>
                        </div>
                    }

                    <Button href="/"
                        style={{
                            backgroundColor: '#005773',
                            color: 'white',
                            fontWeight: [500],
                            marginTop: '40px',
                            marginBottom: '20px',
                            width: '150px'
                        }}>
                        Login
                    </Button>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    verified: state.user.verified
})


export default connect(mapStateToProps)(withStyles(styles)(EmailVerifeid))
