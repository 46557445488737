import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getUsersByOrganization } from '../../../Redux/Actions/user_actions';

import CreateUser from '../../Users/Forms/createUser';

import Button from '@material-ui/core/Button';
import FormField from '../../Utils/Forms/FormField';
import { update } from '../../Utils/Forms/FormActions';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    color: '#162336',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textLabel: {
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '11px',
    color: '#162336',
  },
  select: {
    minWidth: 200,
  },
});

let _isMounted = false;

class UserSelect extends Component {
  state = {
    createUserDialogOpen: false,
    formError: false,
    formSuccess: '',
    formData: {
      user: {
        element: this.props.multi ? 'multi-select' : 'select',
        value: this.props.multi ? [] : '',
        config: {
          name: 'user',
          type: 'select',
          label: this.props.label,
          options: [],
          maxSelect: this.props.multiMax ? this.props.multiMax : 1,
        },
        validation: {
          required: false,
          email: false,
        },
        valid: true,
        validationMessage: '',
        showLabel: true,
      },
    },
  };

  componentDidMount() {
    this.setUserOptions();
    _isMounted = true;
  }

  componentWillUnmount = () => {
    _isMounted = false;
  };

  setUserOptions = () => {
    this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id)).then((responce) => {
      let newFormData = { ...this.state.formData };
      newFormData['user'].config.options = this.getUsersToDropdown();

      if (this.props.selectedUser) {
        newFormData['user'].value = this.props.selectedUser;
      }

      if (this.props.multi && this.props.selectedUsers) {
        newFormData['user'].value = this.props.selectedUsers;
      }

      if (_isMounted) {
        this.setState({
          formData: newFormData,
        });
      }
    });
  };

  getUsersToDropdown = () => {
    let dropdownReady = [];

    if (this.props.users) {
      this.props.users.forEach((element, index) => {
        let dropdownItem = {
          key: element['id'],
          value: element['name'] + ' ' + element['lastname'],
        };
        dropdownReady.push(dropdownItem);
      });

      return dropdownReady;
    }
  };

  updateForm = (element) => {
    if (_isMounted) {
      this.setState(
        {
          formData: update(element, this.state.formData),
        },
        () => {
          this.props.change(this.state.formData['user'].value);
        }
      );
    }
  };

  openUserCreate = () => {
    if (_isMounted) {
      this.setState({
        createUserDialogOpen: true,
      });
    }
  };

  handleDialogClose = () => {
    if (_isMounted) {
      this.setState({
        createUserDialogOpen: false,
      });
    }
  };

  handleUserCreated = () => {
    this.handleDialogClose();
    this.setUserOptions();
  };

  userCreateDialog = () => {
    return (
      <Dialog open={this.state.createUserDialogOpen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add new User</DialogTitle>
        <DialogContent>
          <DialogContentText>Add details of the user below. The user will be asked to changed their password when they verify their email address.</DialogContentText>

          <CreateUser user_type={this.props.user_type} cancel={() => this.handleDialogClose()} userAdded={() => this.handleUserCreated()} formType="Add" />
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    //const { classes, change } = this.props;

    return (
      <div>
        {this.props.header != '' ? (
          <Grid container>
            <Grid item xs={12}>
              <h1
                style={{
                  fontSize: '14px',
                  padding: '10px 7px 5px',
                  margin: 0,
                  textAlign: 'left',
                }}
              >
                {this.props.header}
              </h1>
            </Grid>
          </Grid>
        ) : null}

        <Box>
          <Grid container>
            <Grid item xs={12}>
              <FormField id={'user'} formData={this.state.formData.user} change={(element) => this.updateForm(element)} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Button style={{ marginTop: '5px', marginLeft: '10px' }} variant="contained" size="small" onClick={() => this.openUserCreate()}>
                {this.props.btnCreateName ? this.props.btnCreateName : 'Add new User'}
              </Button>
            </Grid>
            {this.userCreateDialog()}
          </Grid>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  users: state.user.users,
  loggedInUser: state.user.userData,
});

export default connect(mapStateToProps)(withStyles(styles)(UserSelect));
