import { Button, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { SelectOrganization, ClearSelection } from '../../../Redux/Actions/organization_actions';

import React, { useEffect } from 'react';
import UserSelect from '../CustomFields/UserSelect';
import { useHistory } from 'react-router';
import StepperButton from './stepperButton';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1, 1, 2),
        backgroundColor: '#005773',
        color: 'white',
        fontWeight: [500],
        '&:hover': {
            color: '#005773',
        }
    }
}))




const BackToParentButton = ({ children, ...props}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData);
    const entity = useSelector(state => state.user.userData.selectedOrganization);
    const history = useHistory();

    const selectSuccess = useSelector(state => state.organization.selection_success)

    useEffect(() => {
        if(selectSuccess){
            dispatch(ClearSelection())
            if(props.retrunUrl)
                history.push(props.retrunUrl)
            else
                history.push('/welcome')
        }
    }, [selectSuccess])

    const changeEntity = () => {
        if (entity && entity.parent) {
            dispatch(SelectOrganization(entity.parent));
        }
    }
    
    return (
        entity && entity.parent ?
            <StepperButton { ...props } onClick={changeEntity} >{children}</StepperButton> : null
    );
};

export default BackToParentButton;