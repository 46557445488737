import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUsersByOrganization, disableUser, enableUser, removeUser } from '../../Redux/Actions/user_actions';

import BackendBackground from '../UI/backendBackground';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import {ButtonGroup} from '@material-ui/core';

import CreateUser from './Forms/createUser';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Reinvite from './Dialogs/Reinvite';
import UpdateRoles from './Dialogs/updateRoles';



const styles = theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative'
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    },
});


class Users extends Component {
    state = {
        userRemoveConfirmDialog: false,
        createUserDialogOpen: false,
        selectedUser: {},
        formType: "Add"
    }

    componentDidMount() {
        if (this.props.loggedInUser) {
            this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
        }
    }

    openUserCreate = () => {
        this.setState({
            createUserDialogOpen: true,
            formType: "Add"
        })
    }

    openUserEdit = (type, selectedUser) => {
        this.setState({
            createUserDialogOpen: true,
            formType: type,
            selectedUser
        })
    }

    handleDialogClose = () => {
        this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
        this.setState({
            createUserDialogOpen: false
        })
    }

    handleUserCreated = () => {
        this.handleDialogClose();
        this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
    }

    handleRemoveUser = (user) => {
        console.log(user);
        this.setState({
            userRemoveConfirmDialog: true,
            selectedUser: user
        })
    }

    closeRemoveUser = () =>{
        this.setState({
            userRemoveConfirmDialog: false,
            selectedUser: {}
        })
    }

    confirmUserRemoveDialog = () => {
        if(this.state.selectedUser){
            this.props.dispatch(removeUser(this.state.selectedUser.id)).then(responce => {

                if(this.props.removeSuccess){
                    this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
                    this.closeRemoveUser();
                }
            })
        }
    }

    userRemoveDialog = () => {
        return ( <Dialog
            open={this.state.userRemoveConfirmDialog}
            onClose={this.closeRemoveUser}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Remove {this.state.selectedUser.name} {this.state.selectedUser.lastname}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this user? <br />
                {this.state.selectedUser.name} {this.state.selectedUser.lastname}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.closeRemoveUser} color="primary">
                Cancel
            </Button>
            <Button onClick={() => this.confirmUserRemoveDialog()} color="primary" autoFocus>
                Remove
            </Button>
            </DialogActions>
        </Dialog>
        )
    }

    userCreateDialog = () => {
        return (
        <Dialog
        open={this.state.createUserDialogOpen}
        onClose={this.handleDialogClose}
        aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create a new User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add details of the user below. The user will be asked to changed their password when they verify their email address.
                </DialogContentText>
                
                <CreateUser 
                cancel={() => this.handleDialogClose()}
                userAdded={() => this.handleUserCreated()} 
                selectedUser={ this.state.selectedUser }
                formType={ this.state.formType }
                roleSelect={true}/>
                
            </DialogContent>
        </Dialog>            
        )
    }

    handleDisable = (user_id) => {
        if(user_id){
            this.props.dispatch(disableUser(user_id)).then(responce => {
                if(responce.payload.success){
                    this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
                }
            })
        }
    }

    handleEnable = (user_id) => {
        if(user_id){
            this.props.dispatch(enableUser(user_id)).then(responce => {
                if(responce.payload.success){
                    this.props.dispatch(getUsersByOrganization(this.props.loggedInUser.selectedOrganization._id));
                }
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <BackendBackground />

                <Paper className={classes.root}>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email Address</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.users && !this.props.users.error ?
                                this.props.users.map((user, index) => {

                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {user.name}
                                            </TableCell>
                                            <TableCell>{user.lastname}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.roles ? user.roles.map(role => (
                                                <div key={role._id}>{role.name}</div>
                                            )) : "No roles assigned"}</TableCell>
                                            <TableCell>
                                                <ButtonGroup>
                                                    {/* <UpdateRoles user={user} /> */}
                                                    <Reinvite user={user} />
                                                    <Button 
                                                        variant="outlined" 
                                                        color="primary"
                                                        onClick={() => this.openUserEdit("Edit", user)}>Edit</Button>
                                                    <Button 
                                                        variant="outlined" 
                                                        color="secondary"
                                                        onClick={() => this.handleRemoveUser(user)}>Remove</Button>
                                                </ButtonGroup>
                                                {/* { user.enabled === null || user.enabled === true ?
                                                    <Button className={classes.button} variant="contained" onClick={()=> this.handleDisable(user._id)}>Disable</Button>
                                                :
                                                    <Button className={classes.button} variant="contained" onClick={()=> this.handleEnable(user._id)}>Enable</Button>
                                                } */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) :
                                <TableRow>
                                    <TableCell scope="row" colSpan="8">No Users</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell >
                                    <Button 
                                    className={classes.button} 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => this.openUserCreate()}>Add New User</Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>

                    { this.userCreateDialog() }
                    { this.userRemoveDialog() }
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    users: state.user.users,
    loggedInUser: state.user.userData,
    removeSuccess: state.user.removeSuccess
})


export default connect(mapStateToProps)(withStyles(styles)(Users))