import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateCorporateStatements } from '../../../Redux/Actions/report_actions';

import BackendBackground from '../../UI/backendBackground';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, MenuItem, Paper, Typography } from '@material-ui/core';

import FileSaver  from 'file-saver';
import moment from 'moment';
import MyCircularProgress from '../../UI/ProgressBars/myCircularProgress';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

import Form from '../../UI/Form';
import SubmitButton from '../../UI/Buttons/submitButton';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import HookSelect from '../../UI/Form/hookSelect';
import PrintLayout from '../ReportLayouts/printLayout';
import Statements from '../ReportLayouts/statements';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        marginTop: theme.spacing.unit * 3,
        margin: '0 auto',
        overflowX: 'auto',
        position: 'relative',
        padding: '10px',
    },
    table: {
        minWidth: 700,
    },
    select: {
        width: '350px',
        margin: theme.spacing(2, 0, 2)
    }
}))

const schema = yup.object().shape({
    business: yup.string().required('This is required')
});

const BusinessStatements = (props) => {
    const classes = useStyles();
    const entities = useSelector(state => state.user.userData.organizations)
    const user = useSelector(state => state.user.userData)

    const [selectedBusiness, setSelectedBusiness] = useState('')

    const { register, handleSubmit, errors, control, watch } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    });



    const onSubmit = (data) => {
        setSelectedBusiness(data.business);
    }

    return (
        <div>
            <BackendBackground />
            <Paper className={classes.root}>
                <Typography component="p">The business statement Purpose, Vision, Mission, Values and Objectives sets the strategic direction of the organisation.</Typography>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    { user.selectedOrganization.parent || !user.selectedOrganization.isGroup ? 
                        <input type="hidden" ref={register} name="business" value={user.selectedOrganization._id} /> :
                        <HookSelect id="business-select"
                                className={classes.select}
                                name="business"
                                label="Choose Business"
                                control={control}
                            >
                            {entities ? entities.map(entity => (
                                entity.organization_id.parent != null ?
                                    <MenuItem key={entity.organization_id._id} value={entity.organization_id._id}>{entity.organization_id.name}</MenuItem>
                                    :
                                    null
                            )) : null}
                        </HookSelect>
                    }

                    <SubmitButton>Generate Report</SubmitButton>
                </Form>

                { selectedBusiness ? 
                    <PrintLayout landscape={true} title="Statements">
                        <Statements entity={ selectedBusiness }/>
                    </PrintLayout> : null }
            </Paper>
        </div>
    )
}

export default BusinessStatements