import React, { Component } from 'react';
import Stepper from '../UI/Stepper'
import HeaderText from '../UI/headerText';
import MediaIcons from '../UI/mediaIcons';
import BackgroundItem from '../UI/backgroundItem';

import GeneralBackground from '../../Resources/images/backgrounds/background_pages.png';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import OrganizationalValuesList from '../UI/CustomForms/OrganizationalValuesList';




class OrganizationalValues extends Component {
    state = {
        items: [],
        
        // videoFile: '',
        // audioFile: '',
    }

    componentDidMount() {
        // this.props.dispatch(getAllOrganizationalValues()).then(response => {
        //     if(this.props.organizational_values){
        //         this.setState({
        //             items: this.props.organizational_values.organizational_values
        //         })
        //     }
        // }); 
    }

    OnChange = (items) =>{
        // this.setState({
        //     items
        // })
        // this.props.dispatch(updateOrganizationalValues(items)); 
    }

    OnRemove = (item) => {
        //console.log(item);
    }

    StepContent = () => {
        return (
            <div style={{
                    position: 'relative',
                    padding: '20px 120px 20px 120px',                  
                }}>
                
                <Grid container spacing={16} direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} >
                        <HeaderText name="Business Values" size={40}/>
                        
                        <MediaIcons audioFile={this.state.audioFile} videoFile={this.state.videoFile}/> 
                    </Grid>
                </Grid>

                <Grid container spacing={16} direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} >
                        <OrganizationalValuesList /> 
                    </Grid>
                </Grid>
            </div>
        )
    };

    render() {
        return (
            <div>
                <BackgroundItem path={GeneralBackground}/>
                <Stepper Step={3} getStepContent={() => this.StepContent()}/>
            </div>
        );
    }
}

export default connect()(OrganizationalValues);