import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Table, TableHead, TableBody, TableCell, TableFooter, TableRow, Paper } from  '@material-ui/core';
import FilterBar from './filter_bar';

const useStyles = makeStyles((theme) => ({
    root:{
        margin: theme.spacing(2)
    },
    score_card:{
        width: '100%',
        borderSpacing: '10px !important',
        borderCollapse: 'separate !important'
    },
    score_card_header: {
        backgroundColor: '#005773',
        color: 'white',
        padding: '20px',
        fontWeight: 400,
        fontSize: 'x-large',
        textTransform: 'uppercase'
    },
    kpi_cell: {
        backgroundColor: '#005773',
        color: 'white',
        borderRadius: '4px',
        width: '130px',
        height: '113px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 400
    },
    main_cell: {
        backgroundColor: '#6399AB',
        borderRadius: '4px'
    },
    rd_cell:{
        backgroundColor: '#6399AB',
        width: '180px',
        borderRadius: '4px'
    },
    theme:{
        backgroundColor: '#005773',
        color: 'white',
        borderRadius: '4px',
        width: '150px',
        textAlign: 'center',
        padding: '10px',
        display: 'inline-block'
    },
    text:{
        textTransform: 'uppercase',
        color: 'white',
        textAlign: 'center'
    },
    theme_cell:{
        width: '130px',
        textAlign: 'center'
    },
    theme_cell_big:{
        width: '310px',
        textAlign: 'center'
    }
}))

const Reports = () => {
    const classes = useStyles();
    const [filters, setFilters] = useState({});


    const handleFilterChange = (filters) => {
        setFilters(filters);
    }

    return (
        <Paper className={classes.root}>
            <FilterBar changed={(filters) => handleFilterChange(filters)} isFun={true}/>

            <Table className={classes.score_card}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} className={classes.score_card_header}>Corporate Score Card</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Finance</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}></TableCell>
                        <TableCell rowSpan={4} className={classes.rd_cell}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Customer</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>Operational</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.kpi_cell}>People</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell}></TableCell>
                    </TableRow>
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <TableCell className={`${classes.kpi_cell} ${ classes.text}`}>Static Theme</TableCell>
                        <TableCell colSpan={5} className={classes.main_cell} >
                            <Table>
                                <TableRow>
                                    <TableCell className={`${classes.theme_cell} ${classes.theme_cell_big}`}>
                                        <div className={classes.theme}>Growth</div>
                                    </TableCell>
                                    <TableCell className={`${classes.theme_cell}`}>
                                        <div className={classes.theme}>Risk</div>
                                    </TableCell>
                                    <TableCell className={`${classes.theme_cell} ${classes.theme_cell_big}`}>
                                        <div className={classes.theme}>Transition/Exit</div>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                        <TableCell className={`${classes.rd_cell} ${ classes.text}`} >Research and Development</TableCell>
                    </TableRow>
                </TableFooter>

            </Table>
        </Paper>
    );
};

export default Reports;