import { GET_AREAS, GET_CUSTOM_AREAS, GET_AREA, CREATE_AREA, UPDATE_AREA, DELETE_AREA } from '../Actions/types'

export default function(state={}, action){
    switch(action.type){
        case GET_AREAS:
            return {...state, areas: action.payload }
        case GET_CUSTOM_AREAS:
            return {...state, custom_areas: action.payload }
        case GET_AREA:
            return {...state, area: action.payload }
        case CREATE_AREA:
            return {...state, success: action.payload }
        case UPDATE_AREA:
            return {...state, success: action.payload }
        case DELETE_AREA:
            return {...state, success: action.payload }
        default:
            return state;
    }
}