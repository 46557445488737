
export const validate = (element, formData = []) => {
    let error = [true, ''];
    if (element.validation.email) {
        var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const valid = re.test(element.value)
        const message = `${!valid ? 'Must be a valid email.' : ''}`

        error = !valid ? [valid, message] : error;
    }

    if (element.validation.confirm) {
        const valid = element.value.trim() === formData[element.validation.confirm].value;
        const message = `${!valid ? 'Passwords do not match.' : ''}`

        error = !valid ? [valid, message] : error;
    }

    if (element.validation.required) {
        const valid = element.value.trim() !== '';
        const message = `${!valid ? 'This field is required.' : ''}`

        error = !valid ? [valid, message] : error;
    }

    return error;
}

export const update = (element, formData, formName) => {
    const newFormData = {
        ...formData
    }
    const newElement = {
        ...newFormData[element.id]
    }

    if(newElement.element === 'multi-select' && newElement.config.maxSelect){
        if(element.event.target.value.length > newElement.config.maxSelect)
        {
            return newFormData;
        }
    }

    if(newElement.element === 'checkbox_list'){
        return newFormData;
    }

    
    if(newElement.element === 'checkbox'){
        newElement.value = element.event.target.checked;

        newFormData[element.id] = newElement;
        return newFormData;
    }

    newElement.value = element.event.target.value;

    if (element.blur) {
        let validData = validate(newElement, formData);
        newElement.valid = validData[0];
        newElement.validationMessage = validData[1];
    }
    newElement.touched = true;
    newFormData[element.id] = newElement;
    return newFormData;
}

export const updateRich = (id, editor, formData, formName) => {
    const newFormData = {
        ...formData
    }
    const newElement = {
        ...newFormData[id]
    }

    let validData = validate(newElement, formData);
    newElement.valid = validData[0];
    newElement.validationMessage = validData[1];

    newElement.value = editor;
    newElement.touched = true;
    newFormData[id] = newElement;

    return newFormData;
}

export const generateData = (formData, formName) => {
    let dataToSubmit = {}

    for (let key in formData) {
        if (key !== 'confirmPassword') {
            dataToSubmit[key] = formData[key].value;
        }
    }

    return dataToSubmit;
}

export const clearFields = (formData, formName) => {
    let newFormData = formData; 
    for (let key in newFormData) {
        newFormData[key].value = "";

        if (newFormData[key].element === 'checkbox'){
            newFormData[key].value = false;
        } 
    }

    return newFormData;
}

export const isFormValid = (formData, formName) => {
    let formIsValid = true;

    for (let key in formData) 
    {
        formIsValid = formData[key].valid && formIsValid;
    }

    return formIsValid;
}